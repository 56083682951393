<template>
  <div
    class="bg-white mb-2 shadow overflow-hidden rounded-md border-l-8"
    :class="colorBorder ? 'border-yellow' : 'border-blue-700'"
  >
    <div class="block hover:bg-gray-50">
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 px-4 grid grid-cols-4 gap-4">
          <div class="col-span-4 md:col-span-1">
            <p class="text-base font-medium text-gray-900">
              {{ task.userName }}
            </p>
            <p class="mt-2 flex items-center text-base text-gray-700">
              {{ viewRol }}
            </p>
          </div>
          <div class="col-span-4 md:col-span-1">
            <p class="text-base text-gray-900">{{ $t(task.task) }}</p>
            <p class="mt-2 flex items-center text-base text-gray-500">
              <span
                v-if="task.status === 'Realizado'"
                class="
                  px-6
                  py-1
                  inline-flex
                  leading-5
                  font-semibold
                  rounded-full
                  bg-green-200
                  text-green-800
                "
                >{{ $t("Realizado") }}</span
              >
              <span
                v-if="task.status === 'Pendiente'"
                class="
                  px-6
                  py-1
                  inline-flex
                  leading-5
                  font-semibold
                  rounded-full
                  bg-orange-200
                  text-orange-800
                "
                >{{ $t("Pendiente") }}</span
              >
              <span
                v-if="task.status === 'Programado'"
                class="
                  px-6
                  py-1
                  inline-flex
                  leading-5
                  font-semibold
                  rounded-full
                  bg-orange-200
                  text-orange-800
                "
                >{{ $t("Programado") }}</span
              >
              <span
                v-if="task.status === 'No realizado'"
                class="
                  px-6
                  py-1
                  inline-flex
                  leading-5
                  font-semibold
                  rounded-full
                  bg-red
                  text-redagrozono
                "
                >{{ $t("No realizado") }}</span
              >
            </p>
          </div>
          <div class="col-span-4 md:col-span-2">
            <p class="text-base text-gray-900 flex justify-between">
              <span>
                <span v-if="task.machineName">{{ task.machineName }} - </span>
                <span v-if="task.title">{{ task.title }}</span>
                <span v-if="task.country">{{ task.country }} -</span>
                <span v-if="task.province"> {{ task.province }} -</span>
                <span
                  v-if="task.customerName"
                  @click="openClientModal"
                  class="
                    font-semibold
                    text-blueagrozono
                    hover:text-gray-800
                    cursor-pointer
                  "
                >
                  {{ task.customerName }}
                </span>
                <span
                  v-if="task.farmName"
                  @click="openFarmModal"
                  class="
                    font-semibold
                    text-blueagrozono
                    hover:text-gray-800
                    cursor-pointer
                  "
                  >- {{ task.farmName }}
                </span>
                <span
                  v-if="task.inspection"
                  @click="openInspectionModal"
                  class="
                    font-semibold
                    text-blueagrozono
                    hover:text-gray-800
                    cursor-pointer
                  "
                >
                  - {{ task.inspection.protocolName }}
                </span>
                <span
                  v-if="task.protocolId"
                  @click="openTreatmentModal"
                  class="
                    font-semibold
                    text-blueagrozono
                    hover:text-gray-800
                    cursor-pointer
                  "
                >
                  - {{ $t("Ver recordatorios de inoculaciones") }}
                </span>
                <span
                  v-if="disabledTreatment"
                  class="
                    font-semibold
                    text-blueagrozono
                    hover:text-gray-800
                    cursor-pointer
                  "
                >
                  <router-link
                    :to="{
                      name: 'application-details',
                      params: { id: task.uid },
                    }"
                  >
                    - {{ $t("Detalles tratamiento") }}
                  </router-link>
                </span>
              </span>
              <button
                class="
                  w-20
                  mr-2
                  text-sm
                  font-semibold
                  py-2
                  px-2
                  rounded
                  text-blueagrozono
                  border border-blueagrozono
                  hover:text-white hover:bg-blueagrozono
                "
                type="button"
                v-if="task.userId === userId && task.taskType === 'tasks'"
                @click="openEditTaskModal"
              >
                {{ $t("Editar") }}
              </button>
            </p>
            <p
              class="mt-2 flex items-center text-base text-gray-700"
              v-if="task.taskType !== 'reminder'"
            >
              {{ task.description }}
            </p>
            <p
              class="mt-2 flex items-center text-base text-gray-700"
              v-if="task.taskType === 'reminder'"
            >
              {{
                $t(task.description, {
                  step: task.step,
                  totalSteps: task.totalSteps,
                  days: task.days,
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskCustomerModal from "@/pages/Tasks/TaskCustomerModal";
import TaskEditModal from "@/pages/Tasks/TaskEditModal";
import TaskFarmModal from "@/pages/Tasks/TaskFarmModal";
import ApplicationAssignmentInspectionModal from "@/pages/ApplicationAssignment/ApplicationAssignmentInspectionModal";
import TreatmentModal from "@/pages/tratamientos/TreatmentModal";

export default {
  name: "TaskCard",
  layout: "layout",
  components: {},
  data() {
    return {
      userId: "",
    };
  },
  props: {
    task: {
      required: true,
    },
  },
  computed: {
    colorBorder() {
      return (
        this.task.userRol === "comTechnicians" ||
        this.task.userRol === "comTechniciansResp" ||
        this.task.userRol === "distributors"
      );
    },
    viewRol() {
      let rol = "";
      if (this.task.userRol === "comTechnicians") {
        return (rol = this.$t("Comercial"));
      }

      if (this.task.userRol === "comTechniciansResp") {
        return (rol = this.$t("Resp. comercial"));
      }

      if (this.task.userRol === "distributors") {
        return (rol = this.$t("Distribuidor"));
      }

      if (this.task.userRol === "appTechnicians") {
        return (rol = this.$t("Técnico"));
      }

      if (this.task.userRol === "respTechnicians") {
        return (rol = this.$t("Resp. técnico"));
      }

      return rol;
    },
    disabledTreatment() {
      return (
        this.task.userId === this.userId &&
        this.task.taskType === "newTreatment"
      );
    },
  },
  methods: {
    openEditTaskModal: function () {
      this.$modal.show(TaskEditModal, {
        title: this.$t("Editar tarea"),
        params: { task: this.task },
      });
    },
    openClientModal: function () {
      this.$modal.show(TaskCustomerModal, {
        title: this.$t("Detalle cliente"),
        params: {
          id: this.task.customerId,
          type: this.task.customerType,
          task: this.task.taskType,
        },
      });
    },
    openFarmModal: function () {
      this.$modal.show(TaskFarmModal, {
        title: this.$t("Detalle finca"),
        params: { farmId: this.task.farmId, customerId: this.task.customerId },
      });
    },
    openInspectionModal: function () {
      this.$modal.show(ApplicationAssignmentInspectionModal, {
        title: this.$t("Detalle inspección"),
        params: { inspection: this.task.inspection },
      });
    },
    openTreatmentModal: function () {
      this.$modal.show(TreatmentModal, {
        title: this.$t("Detalles de la finca"),
        params: {
          treatmentId: this.task.uid,
          protocolId: this.task.protocolId,
        },
      });
    },
  },
  beforeMount() {
    this.userId = sessionStorage.getItem("user_id");
  },
};
</script>
