<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Tipos de cultivo") }}
    </div>

    <loader v-if="dataLoaded === false"></loader>

    <div v-if="dataLoaded === true">
      <div>
        <div class="sm:flex">
          <div class="flex items-center">
            <label>
              <input
                class="
                  w-40
                  appearance-none
                  block
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Buscar')"
                v-model="search"
              />
            </label>

            <button
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              v-if="userRol === 'appSuperAdministrators'"
              type="button"
              @click="addCropType"
            >
              {{ $t("Agregar tipo cultivo") }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Nombre") }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="typeCrop in cropTypesFilters"
                  v-bind:key="typeCrop.uid"
                >
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div @click="editCropType(typeCrop.uid)">
                      <span
                        v-if="
                          langStorage === 'es' ||
                          (langStorage === 'it' && !typeCrop.nameIt) ||
                          (langStorage === 'fr' && !typeCrop.nameFr)
                        "
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ typeCrop.name }}</span
                      >
                      <span
                        v-if="langStorage === 'it'"
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ typeCrop.nameIt }}</span
                      >
                      <span
                        v-if="langStorage === 'fr'"
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ typeCrop.nameFr }}</span
                      >
                    </div>
                  </td>
                </tr>

                <tr v-if="cropTypesFilters.length === 0">
                  <td class="border px-4 py-2" colspan="1">
                    {{
                      $t("No hay tipos de cultivos registrados en el sistema")
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import CropTypesAddPage from "@/pages/CropTypes/CropTypesAddPage";
import CropTypesEditPage from "@/pages/CropTypes/CropTypesEditPage";

export default {
  layout: "layout",
  name: "CropTypes",
  metaInfo: {
    title: "Tipos de cultivo",
  },
  components: {
    Loader,
  },
  data() {
    return {
      dataLoaded: false,
      cropTypes: [],
      search: "",
      userRol: sessionStorage.getItem("user_rol"),
      langStorage: localStorage.user_locale,
    };
  },
  computed: {
    cropTypesFilters() {
      let typeCropFilter = [];

      this.cropTypes.forEach((element) => {
        if (this.search === "") {
          typeCropFilter.push(element);
        } else {
          if (this.langStorage === "es") {
            if (
              element.name
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              typeCropFilter.push(element);
            }
          }

          if (this.langStorage === "it") {
            if (
              element.nameIt
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              typeCropFilter.push(element);
            }
          }

          if (this.langStorage === "fr") {
            if (
              element.nameFr
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              typeCropFilter.push(element);
            }
          }
        }
      });

      return typeCropFilter.sort(this.compareLangName);
    },
  },
  methods: {
    getCropTypes() {
      this.dataLoaded = false;
      this.search = "";

      this.cropTypes = [];
      db.collection("cropTypes")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          let _this = this;
          querySnapshot.forEach(function (doc) {
            _this.cropTypes.push(doc.data());
          });

          this.dataLoaded = true;
        });
    },
    addCropType() {
      this.$modal.show(
        CropTypesAddPage,
        {
          title: this.$t("Agregar tipo cultivo"),
          params: null,
        },
        { height: "auto" },
        { "before-close": this.getCropTypes }
      );
    },
    editCropType(id) {
      this.$modal.show(
        CropTypesEditPage,
        {
          title: this.$t("Editar tipo cultivo"),
          params: { id: id },
        },
        { height: "auto" },
        { "before-close": this.getCropTypes }
      );
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.getCropTypes();
  },
};
</script>
