<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="text-2xl font-semibold text-gray-800 p-6">
      <button
        class="
          bg-gray-200
          mr-2
          relative
          border border-gray-200
          text-xs
          font-semibold
          hover:bg-white
          text-gray-700
          pb-1
          px-3
          rounded
          focus:outline-none focus:shadow-outline
        "
        style="top: -2px"
        type="button"
        v-on:click="volverEditarFinca"
      >
        <Zondicon
          icon="arrow-thin-left"
          class="
            fill-current
            inline-block
            h-3
            text-gray-700
            cursor-pointer
            hover:text-gray-700
          "
        />
      </button>
      <div
        class="
          inline-block
          uppercase
          tracking-wide
          text-gray-700 text-xs
          font-bold
          mb-4
          px-3
        "
      >
        {{ params.farmName }}
      </div>
    </div>

    <div
      v-if="datosCargados === false"
      class="flex items-center justify-center flex-1 self-center"
    >
      <loader></loader>
    </div>

    <div v-if="datosCargados === true" class="mb-20 xl:mb-4 p-6">
      <div>
        <div class="sm:flex">
          <div class="flex items-center">
            <label>
              <input
                class="
                  w-40
                  appearance-none
                  block
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                placeholder="Buscar"
                v-model="buscar"
              />
            </label>

            <button
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              v-if="userRol === 'comTechnicians' || userRol === 'distributors'"
              type="button"
              @click="agregarRegistro"
            >
              {{ $t("Agregar inspección") }}
            </button>
          </div>
        </div>
      </div>

      <table class="table-auto w-full text-left">
        <thead>
          <tr>
            <th class="px-4 py-2 text-gray-800">
              <div class="inline-block mr-2">{{ $t("Inspección") }}</div>
            </th>
            <th class="px-4 py-2 text-gray-800">
              <div class="inline-block mr-2">{{ $t("Fecha") }}</div>
            </th>
            <th class="px-4 py-2 text-gray-800">
              <div class="inline-block mr-2">{{ $t("Estado") }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="inspeccion in inspeccionesFiltrados"
            v-bind:key="inspeccion.id"
          >
            <td class="px-4 py-3" style="width: 250px">
              <div v-on:click="editarRegistro(inspeccion.id)">
                <span
                  class="
                    font-semibold
                    text-blueagrozono
                    hover:text-gray-800
                    cursor-pointer
                  "
                  >{{ inspeccion.id }}</span
                >
              </div>
            </td>
            <td class="px-4 py-3 w-48" style="width: 150px">
              {{ inspeccion.inspectionDate }}
            </td>
            <td class="px-4 py-3 w-48" style="width: 60px">
              {{ inspeccion.state }}
            </td>
          </tr>

          <tr v-if="inspeccionesFiltrados.length === 0">
            <td class="border px-4 py-3" colspan="3">
              {{ $t("No hay inspecciones registradas para esta finca") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import { DateTime } from "luxon";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import { orderBy } from "lodash";
import HeaderModal from "@/components/HeaderModal";
import FincasInspeccionesAddPage from "@/pages/fincas/FincasInspeccionesAddPage";
import FincasInspeccionesEditPage from "@/pages/fincas/FincasInspeccionesEditPage";
import FincasEditPage from "@/pages/fincas/FincasEditPage";

const FincasInspecciones = {
  name: "FincasInspecciones",
  layout: "layout",
  components: {
    Zondicon,
    Loader,
    HeaderModal,
  },
  data() {
    return {
      datosCargados: false,
      inspecciones: [],
      mostrar: "Todos",
      buscar: "",

      listUsers: [],
      userRol: "",
      userId: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  computed: {
    inspeccionesFiltrados() {
      let arrayFiltrado = [];
      this.inspecciones.forEach((element) => {
        if (this.buscar === "") {
          arrayFiltrado.push(element);
        } else {
          if (
            element.id.toString().toLowerCase().indexOf(this.buscar) !== -1 ||
            element.inspectionDate
              .toString()
              .toLowerCase()
              .indexOf(this.buscar) !== -1 ||
            element.state.toString().toLowerCase().indexOf(this.buscar) !== -1
          ) {
            arrayFiltrado.push(element);
          }
        }
      });

      return orderBy(arrayFiltrado, ["state"], ["asc"]);
    },
  },
  methods: {
    getInspections() {
      let _this = this;
      _this.inspecciones = [];

      db.collection("inspections")
        .where("farmId", "==", this.params.farmId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let date = DateTime.fromISO(doc.data().inspectionDate).toFormat(
              "dd/MM/kkkk"
            );

            let arrayTemp = [];
            arrayTemp["id"] = doc.id;
            arrayTemp["comTechnicianName"] = doc.data().comTechnicianName;
            arrayTemp["customerName"] = doc.data().customerName;
            arrayTemp["farmName"] = doc.data().farmName;
            arrayTemp["inspectionDate"] = date;
            arrayTemp["state"] =
              doc.data().state === "sent" ? "Completado" : "Pendiente";

            if (
              _this.userRol === "appSuperAdministrators" ||
              _this.userRol === "appAdministrators"
            ) {
              _this.inspecciones.push(arrayTemp);
            }

            if (_this.userRol === "comTechniciansResp") {
              _this.listUsers.forEach((user) => {
                if (doc.data().comTechnicianId === user.id) {
                  _this.inspecciones.push(arrayTemp);
                }
              });
            }

            if (
              _this.userRol === "comTechnicians" &&
              doc.data().comTechnicianId === _this.userId
            ) {
              _this.listUsers.forEach((user) => {
                if (doc.data().comTechnicianId === user.id) {
                  _this.inspecciones.push(arrayTemp);
                }
              });
            }

            if (
              _this.userRol === "distributors" &&
              doc.data().comTechnicianId === _this.userId
            ) {
              _this.inspecciones.push(arrayTemp);
            }
          });

          this.datosCargados = true;
        });
    },
    agregarRegistro() {
      this.$emit("close");
      this.$modal.show(FincasInspeccionesAddPage, {
        title: this.$t("Agregar inspección"),
        params: {
          farmId: this.params.farmId,
          farmCustomer: this.params.farmCustomer,
          farmName: this.params.farmName,
        },
      });
    },
    editarRegistro(id) {
      this.$emit("close");
      this.$modal.show(FincasInspeccionesEditPage, {
        title: this.$t("Editar inspección"),
        params: {
          id: id,
          farmId: this.params.farmId,
          farmCustomer: this.params.farmCustomer,
          farmName: this.params.farmName,
        },
      });
    },
    volverEditarFinca() {
      this.$emit("close");
      this.$modal.show(FincasEditPage, {
        title: this.$t("Editar finca"),
        params: {
          id: this.params.farmId,
          customer: this.params.farmCustomer,
        },
      });
    },
    async getUsers() {
      let _this = this;

      if (_this.userRol === "comTechniciansResp") {
        // responsables comerciales
        await db
          .collection("comTechniciansResp")
          .where("deleted", "==", false)
          .where("uid", "==", _this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["id"] = doc.id;
              _this.listUsers.push(arrayTemp);
            });
          });

        // comerciales
        await db
          .collection("comTechnicians")
          .where("deleted", "==", false)
          .where("responsableId", "==", _this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["id"] = doc.id;
              _this.listUsers.push(arrayTemp);

              // distribuidores por comerciales
              db.collection("distributors")
                .where("deleted", "==", false)
                .where("comercialId", "==", doc.data().uid)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach(function (element) {
                    let arrayTemp = {};
                    arrayTemp["id"] = element.id;
                    _this.listUsers.push(arrayTemp);
                  });
                });
            });
          });
      }

      if (_this.userRol === "comTechnicians") {
        // comercial
        let arrayTemp = {};
        arrayTemp["id"] = _this.userId;
        _this.listUsers.push(arrayTemp);
        // distribuidores por comerciales
        db.collection("distributors")
          .where("deleted", "==", false)
          .where("comercialId", "==", _this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (element) {
              let arrayTemp = {};
              arrayTemp["id"] = element.id;
              _this.listUsers.push(arrayTemp);
            });
          });
      }

      this.getInspections();
    },
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.userRol = sessionStorage.getItem("user_rol");
    this.userId = sessionStorage.getItem("user_id");

    await this.getUsers();
  },
};

export default FincasInspecciones;
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: #bbcccc29;
}
</style>
