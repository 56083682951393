<script>
import HomePage from "@/pages/HomePage";
import LoginPage from "@/pages/LoginPage";
import RecoveryPage from "@/pages/RecoveryPage";
import LogoutPage from "@/pages/LogoutPage";
import Page404 from "@/pages/Page404";
import FincasPage from "@/pages/fincas/FincasPage";
import MachinesPage from "@/pages/Machines/MachinesPage";
import CropTypesPage from "@/pages/CropTypes/CropTypesPage";
import ProtocolosPage from "@/pages/protocolos/ProtocolosPage";
import TratamientosPage from "@/pages/tratamientos/TratamientosPage";
import TreatmentsStatusPage from "@/pages/TreatmentsStatus/TreatmentsStatusPage";
import ApplicationAssignmentList from "@/pages/ApplicationAssignment/ApplicationAssignmentList";
import ApplicationByAssignList from "@/pages/ApplicationByAssign/ApplicationByAssignList";
import ApplicationDetailList from "@/pages/ApplicationDetail/ApplicationDetailList";
import AreaComercialPage from "@/pages/area-comercial/AreaComercialPage";
import TechnicalAreaPage from "@/pages/TechnicalArea/TechnicalAreaPage";
import AdministrativeAreaPage from "@/pages/AdministrativeArea/AdministrativeAreaPage";
import CustomerLeadAreaPage from "@/pages/CustomerLeadArea/CustomerLeadAreaPage";
import TaskList from "@/pages/Tasks/TaskList";
import PendingTaskList from "@/pages/PendingTasks/PendingTaskList";

const routes = [
  { path: "/", name: "home", component: HomePage },
  { path: "/login", name: "login", component: LoginPage },
  { path: "/recovery", name: "recovery", component: RecoveryPage },
  { path: "/logout", name: "logout", component: LogoutPage },
  { path: "/fincas", name: "fincas", component: FincasPage },
  { path: "/maquinas", name: "maquinas", component: MachinesPage },
  { path: "/tipos-cultivo", name: "tiposCultivo", component: CropTypesPage },
  {
    path: "/tratamientos-estatus",
    name: "tratamientosEstatus",
    component: TreatmentsStatusPage,
  },
  { path: "/protocolos", name: "protocolos", component: ProtocolosPage },
  { path: "/tratamientos", name: "tratamientos", component: TratamientosPage },

  {
    path: "/area-comercial",
    name: "areaComercial",
    component: AreaComercialPage,
  },
  { path: "/area-tecnica", name: "areaTecnica", component: TechnicalAreaPage },
  {
    path: "/area-administrativa",
    name: "areaAdministrativa",
    component: AdministrativeAreaPage,
  },
  {
    path: "/area-clientes",
    name: "areaClientes",
    component: CustomerLeadAreaPage,
  },
  {
    path: "/applications-to-be-assigned",
    name: "applications-to-be-assigned",
    component: ApplicationByAssignList,
  },
  {
    path: "/application-assignment",
    name: "application-assignment",
    component: ApplicationAssignmentList,
  },
  {
    path: "/application-details/:id",
    name: "application-details",
    component: ApplicationDetailList,
  },
  {
    path: "/tasks",
    name: "tasks",
    component: TaskList,
  },
  {
    path: "/pending-tasks",
    name: "pendingTasks",
    component: PendingTaskList,
  },
  { path: "*", component: Page404 },
];

export default routes;
</script>
