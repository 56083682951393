<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <div class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos del cliente") }}
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="customerID"
            >
              {{ $t("Cliente") }}
            </label>
            <div class="block w-full relative mr-4">
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="customerID"
                :value="farm.customerID"
                :placeholder="this.$t('Cliente')"
                readonly
              />
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="id"
            >
              {{ $t("Id") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="id"
              :value="farm.id"
              :placeholder="this.$t('Id')"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idCreatedToShare"
            >
              {{ $t("Id created to share") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="idCreatedToShare"
              :value="farm.idCreatedToShare"
              :placeholder="this.$t('Id created to share')"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos de la finca") }}
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="name"
            >
              {{ $t("Nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="name"
              :value="farm.name"
              :placeholder="this.$t('Nombre')"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="province"
            >
              {{ $t("Provincia") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="province"
              :value="farm.province"
              :placeholder="this.$t('Provincia')"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="city"
            >
              {{ $t("Ciudad") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="city"
              :value="farm.city"
              :placeholder="this.$t('Ciudad')"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-2/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="address"
            >
              {{ $t("Dirección") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="address"
              :value="farm.address"
              :placeholder="this.$t('Dirección')"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="phone"
            >
              {{ $t("Teléfono") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="phone"
              :value="farm.phone"
              :placeholder="this.$t('Teléfono')"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="latitude"
            >
              {{ $t("Latitud") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="latitude"
              :value="farm.latitude"
              :placeholder="this.$t('Latitud')"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="longitude"
            >
              {{ $t("Longitud") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="longitude"
              :value="farm.longitude"
              :placeholder="this.$t('Longitud')"
              readonly
            />
          </div>
        </div>
        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="comTechnicianName"
            >
              {{ $t("Comercial nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="comTechnicianName"
              :value="farm.comTechnicianName"
              :placeholder="this.$t('Comercial nombre')"
              readonly
            />
          </div>
        </div>
        <div class="px-3 flex justify-end">
          <button-google-maps
            v-if="farm.latitude && farm.longitude"
            :latitude="farm.latitude"
            :longitude="farm.longitude"
          ></button-google-maps>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderModal from "@/components/HeaderModal";
import ButtonGoogleMaps from "@/components/ButtonGoogleMaps";
import Utilities from "@/plugins/Utilities";

const ApplicationAssignmentFarmModal = {
  name: "ApplicationAssignmentFarmModal",
  components: {
    HeaderModal,
    ButtonGoogleMaps,
  },
  data() {
    return {
      farm: {},
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.farm = this.params.farm;
  },
};

export default ApplicationAssignmentFarmModal;
</script>
