import { db } from '@/plugins/firebase.js'

export default class Utilities {
    static isLogged = function() {
        try{
            if(sessionStorage.getItem("user")){
                if(sessionStorage.getItem("user_hash") == this.generateHash(sessionStorage.getItem("user_id"))){
                    return true
                }
                else{
                    sessionStorage.clear()
                    return false
                }
            }

            return false
        }
        catch(e){
            return false
        }
    }

    static generateHash = function(s){
        let texto = String(s)
        return texto.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
    }

    static recalculateValuesProtocol = function(protocolId) {
        let sys1 = 0
        let sys2 = 0
        let sys2_1 = 0
        let sys3 = 0
        let sys4 = 0
        let sys5 = 0
        let sys6 = 0
        let sys6_1 = 0
        let sys7 = 0
        let sysbas = 0

        db.collection('protocolReminders')
        .where("protocolId", "==", protocolId)
        .where("deleted", "==", false)
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(function(doc) {
                if(doc.data().sys1 !== '' && !isNaN(doc.data().sys1))
                    sys1 += parseFloat(doc.data().sys1)

                if(doc.data().sys2 !== '' && !isNaN(doc.data().sys2))
                    sys2 += parseFloat(doc.data().sys2)

                if(doc.data().sys2_1 !== '' && !isNaN(doc.data().sys2_1))
                    sys2_1 += parseFloat(doc.data().sys2_1)

                if(doc.data().sys3 !== '' && !isNaN(doc.data().sys3))
                    sys3 += parseFloat(doc.data().sys3)

                if(doc.data().sys4 !== '' && !isNaN(doc.data().sys4))
                    sys4 += parseFloat(doc.data().sys4)

                if(doc.data().sys5 !== '' && !isNaN(doc.data().sys5))
                    sys5 += parseFloat(doc.data().sys5)

                if(doc.data().sys6 !== '' && !isNaN(doc.data().sys6))
                    sys6 += parseFloat(doc.data().sys6)

                if(doc.data().sys6_1 !== '' && !isNaN(doc.data().sys6_1))
                    sys6_1 += parseFloat(doc.data().sys6_1)

                if(doc.data().sys7 !== '' && !isNaN(doc.data().sys7))
                    sys7 += parseFloat(doc.data().sys7)

                if(doc.data().sysbas !== '' && !isNaN(doc.data().sysbas))
                    sysbas += parseFloat(doc.data().sysbas)

            });

            db.collection('protocols')
            .doc(protocolId)
            .update({
                SYS1: sys1,
                SYS2: sys2,
                SYS2_1: sys2_1,
                SYS3: sys3,
                SYS4: sys4,
                SYS5: sys5,
                SYS6: sys6,
                SYS6_1: sys6_1,
                SYS7: sys7,
                SYSBA: sysbas,
            })
            .then(() => {
                console.log('Datos del Protocolo actualizado exitosamente');
            })
        })
    }

    static  getNextIdAgro = function(collection) {

        let idAgro = 0

        return new Promise((resolve) => {
            db.collection(collection)
            .get()
            .then(querySnapshot => {

                querySnapshot.forEach(function(doc) {

                    if(parseInt(doc.data().idAgro) > idAgro){
                        idAgro = parseInt(doc.data().idAgro)
                    }

                });

                resolve(idAgro + 1)

            })
        })
    }

    static  getNextIdAgroFarms = function(customerId) {

        let idAgro = 0

        return new Promise((resolve) => {
            db.collection('customers').doc(customerId).collection('farms')
            .get()
            .then(querySnapshot => {

                querySnapshot.forEach(function(doc) {

                    if(parseInt(doc.data().idAgro) > idAgro){
                        idAgro = parseInt(doc.data().idAgro)
                    }

                });

                resolve(idAgro + 1)

            })
        })
    }

    static  getIdAgro = function(collection, id) {

        let idAgro = ""

        return new Promise((resolve) => {
            db.collection(collection)
            .doc(id)
            .get()
            .then(snapshot => {

                idAgro = snapshot.data().idAgro
                resolve(idAgro)
            })
        })
    }

    static removeAllProtocols = function() {
        db.collection('protocols')
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(function(doc) {

                db.collection('protocols')
                .doc(doc.id)
                .delete()
                .then(() => {

                    db.collection('protocolReminders')
                    .where("protocolId", "==", doc.id)
                    .get()
                    .then(querySnapshot => {

                        querySnapshot.forEach(function(doc2) {
                            db.collection('protocolReminders')
                            .doc(doc2.id)
                            .delete()
                            .then(() => {

                            })
                        });

                    })

                })

            });

        })
    }
}
