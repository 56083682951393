<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="text-2xl font-semibold text-gray-800 p-6">
      <button
        class="
          bg-gray-200
          mr-2
          relative
          border border-gray-200
          text-xs
          font-semibold
          hover:bg-white
          text-gray-700
          pb-1
          px-3
          rounded
          focus:outline-none focus:shadow-outline
        "
        style="top: -2px"
        type="button"
        v-on:click="volverListadoRecordatorios"
      >
        <Zondicon
          icon="arrow-thin-left"
          class="
            fill-current
            inline-block
            h-3
            text-gray-700
            cursor-pointer
            hover:text-gray-700
          "
        />
      </button>
      <div
        class="
          inline-block
          uppercase
          tracking-wide
          text-gray-700 text-xs
          font-bold
          mb-4
          px-3
        "
      >
        {{ nameProtocol }}
      </div>
    </div>

    <div class="flex flex-row items-center mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos del recordatorio") }}
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idAgro"
            >
              {{ $t("Id") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="idAgro"
              type="text"
              :placeholder="this.$t('Id')"
              v-model="idAgro"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="hours"
            >
              {{ $t("Horas") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="hours"
              type="text"
              :placeholder="this.$t('Horas')"
              v-model="hours"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="repetitionInDays"
            >
              {{ $t("Repetición en dias") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="repetitionInDays"
              type="text"
              :placeholder="this.$t('Repetición en dias')"
              v-model="repetitionInDays"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys1"
            >
              {{ $t("System") }} 1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys1"
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys2"
            >
              {{ $t("System") }} 2
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys2"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys2"
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys2_1"
            >
              {{ $t("System") }} 2.1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys2_1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys2_1"
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys3"
            >
              {{ $t("System") }} 3
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys3"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys3"
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys4"
            >
              {{ $t("System") }} 4
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys4"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys4"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys5"
            >
              {{ $t("System") }} 5
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys5"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys5"
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys6"
            >
              {{ $t("System") }} 6
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys6"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys6"
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys6_1"
            >
              {{ $t("System") }} 6.1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys6_1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys6_1"
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys7"
            >
              {{ $t("System") }} 7
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sys7"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys7"
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sysbas"
            >
              {{ $t("Basic") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="sysbas"
              type="text"
              :placeholder="this.$t('Basic')"
              v-model="sysbas"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-full px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="comments"
            >
              {{ $t("Comentarios") }}
            </label>
            <textarea
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              :placeholder="this.$t('Comentarios')"
              id="comments"
              v-model="comments"
            ></textarea>
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="mensajeError !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(mensajeError) }}</span>
          </div>

          <div
            v-if="mensajeExito !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(mensajeExito) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="submitDatos"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Crear nuevo") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: datosCargados === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";
import ProtocolRemindersModal from "@/pages/protocolos/ProtocolRemindersModal";

const ProtocolosRecordatoriosAdd = {
  name: "ProtocolosRecordatoriosAdd",
  layout: "layout",
  components: {
    Zondicon,
    Loader,
    HeaderModal,
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  data: function () {
    return {
      datosCargados: true,
      mensajeExito: "",
      mensajeError: "",

      idAgro: "",
      hours: "",
      repetitionInDays: "",
      sys1: "",
      sys2: "",
      sys2_1: "",
      sys3: "",
      sys4: "",
      sys5: "",
      sys6: "",
      sys6_1: "",
      sys7: "",
      sysbas: "",
      comments: "",
      nameProtocol: "",
    };
  },
  methods: {
    volverListadoRecordatorios: function () {
      this.$emit("close");
      this.$modal.show(ProtocolRemindersModal, {
        title: this.$t("Visualizar recordatorios"),
        params: {
          protocolId: this.params.protocolId,
          protocolName: this.params.protocolName,
        },
      });
    },
    crearRegistroBD: function () {
      let temp_sys1 = this.sys1 === "" || isNaN(this.sys1) ? 0 : this.sys1;
      let temp_sys2 = this.sys2 === "" || isNaN(this.sys2) ? 0 : this.sys2;
      let temp_sys2_1 =
        this.sys2_1 === "" || isNaN(this.sys2_1) ? 0 : this.sys2_1;
      let temp_sys3 = this.sys3 === "" || isNaN(this.sys3) ? 0 : this.sys3;
      let temp_sys4 = this.sys4 === "" || isNaN(this.sys4) ? 0 : this.sys4;
      let temp_sys5 = this.sys5 === "" || isNaN(this.sys5) ? 0 : this.sys5;
      let temp_sys6 = this.sys6 === "" || isNaN(this.sys6) ? 0 : this.sys6;
      let temp_sys6_1 =
        this.sys6_1 === "" || isNaN(this.sys6_1) ? 0 : this.sys6_1;
      let temp_sys7 = this.sys7 === "" || isNaN(this.sys7) ? 0 : this.sys7;
      let temp_sysbas =
        this.sysbas === "" || isNaN(this.sysbas) ? 0 : this.sysbas;

      let refProtocolReminders = db.collection("protocolReminders").doc();
      refProtocolReminders
        .set({
          idAgro: String(this.idAgro),
          hours: this.hours,
          repetitionInDays: this.repetitionInDays,
          sys1: temp_sys1,
          sys2: temp_sys2,
          sys2_1: temp_sys2_1,
          sys3: temp_sys3,
          sys4: temp_sys4,
          sys5: temp_sys5,
          sys6: temp_sys6,
          sys6_1: temp_sys6_1,
          sys7: temp_sys7,
          sysbas: temp_sysbas,
          comments: this.comments,
          protocolId: this.params.protocolId,
          deleted: false,

          uid: refProtocolReminders.id,
        })
        .then(async () => {
          this.datosCargados = true;
          this.mensajeExito = "El recordatorio ha sido agregado exitosamente";

          this.idAgro = "";
          this.hours = "";
          this.repetitionInDays = "";
          this.sys1 = "";
          this.sys2 = "";
          this.sys2_1 = "";
          this.sys3 = "";
          this.sys4 = "";
          this.sys5 = "";
          this.sys6 = "";
          this.sys6_1 = "";
          this.sys7 = "";
          this.sysbas = "";
          this.comments = "";

          // PARA SUMAR TODOS LOS CAMPOS SYS
          Utilities.recalculateValuesProtocol(this.params.protocolId);

          this.idAgro = await Utilities.getNextIdAgro("protocolReminders");

          this.volverListadoRecordatorios();
        })
        .catch(function (error) {
          let errorMessage = error.message;

          this.datosCargados = true;
          this.mensajeError =
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
            errorMessage;
        });
    },
    submitDatos: function () {
      this.datosCargados = false;
      this.mensajeExito = "";
      this.mensajeError = "";

      if (
        this.idAgro !== "" &&
        this.hours !== "" &&
        this.repetitionInDays !== ""
      ) {
        this.crearRegistroBD();
      } else {
        this.datosCargados = true;
        this.mensajeError =
          "Debe llenar correctamente todos los campos para guardarlos en el sistema. (id, horas, recordatorio)";
        //this.$parent.scrollModalTop();
      }
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.nameProtocol = this.params.protocolName;
    this.idAgro = await Utilities.getNextIdAgro("protocolReminders");
  },
};

export default ProtocolosRecordatoriosAdd;
</script>
