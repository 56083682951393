<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <div class="w-full">
        <div>
          <div class="flex flex-wrap mb-6">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos del cliente") }}
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="businessArea"
              >
                {{ $t("Plataformas asignadas") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="businessArea"
                :value="businessAreaName"
                :placeholder="this.$t('Plataformas asignadas')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="name"
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="name"
                :value="customer.name"
                :placeholder="this.$t('Nombre')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="email"
              >
                {{ $t("Email") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="email"
                :value="customer.email"
                :placeholder="this.$t('Email')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="country"
              >
                {{ $t("País") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="country"
                :value="customer.country"
                :placeholder="this.$t('País')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="province"
              >
                {{ $t("Provincia") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="province"
                :value="customer.province"
                :placeholder="this.$t('Provincia')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="creationDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="creationDate"
                type="text"
                :value="customer.date"
                :placeholder="this.$t('Fecha creación')"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="city"
              >
                {{ $t("Ciudad") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="city"
                :value="customer.city"
                :placeholder="this.$t('Ciudad')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="address"
              >
                {{ $t("Dirección") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="address"
                :value="customer.address"
                :placeholder="this.$t('Dirección')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="phone"
              >
                {{ $t("Teléfono") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="phone"
                :value="customer.phone"
                :placeholder="this.$t('Teléfono')"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="cp"
              >
                {{ $t("Cp") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="cp"
                :value="customer.cp"
                :placeholder="this.$t('Cp')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="cif"
              >
                {{ $t("Cif") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="cif"
                :value="customer.cif"
                :placeholder="this.$t('Cif')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="idAgro"
              >
                {{ $t("Id agro") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="idAgro"
                :value="customer.idAgro"
                :placeholder="this.$t('Id agro')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="idCreatedToShare"
              >
                {{ $t("Id created to share") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="idCreatedToShare"
                :value="customer.idCreatedToShare"
                :placeholder="this.$t('Id created to share')"
                readonly
              />
            </div>

            <div class="w-full md:w-2/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="contact"
              >
                {{ $t("Contacto") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="contact"
                :value="customer.contact"
                :placeholder="this.$t('Contacto')"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-full px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="details"
              >
                {{ $t("Detalles") }}
              </label>
              <textarea
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="details"
                :value="customer.details"
                :placeholder="this.$t('Detalles')"
                readonly
              ></textarea>
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechnicianName"
              >
                {{ $t("Comercial") }}
              </label>

              <div class="block w-full relative mr-4">
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border border-red-500
                    rounded
                    py-3
                    px-4
                    mb-3
                    leading-tight
                    focus:outline-none
                  "
                  type="text"
                  id="comTechnicianName"
                  :value="customer.comTechnicianName"
                  :placeholder="this.$t('Comercial')"
                  readonly
                />
              </div>
            </div>
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributorName"
              >
                {{ $t("Distribuidor") }}
              </label>

              <div class="block w-full relative mr-4">
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border border-red-500
                    rounded
                    py-3
                    px-4
                    mb-3
                    leading-tight
                    focus:outline-none
                  "
                  type="text"
                  id="distributorName"
                  :value="customer.distributorName"
                  :placeholder="this.$t('Distribuidor')"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full px-3 mb-6 md:mb-2">
              <div v-if="errorObservation" class="bg-red text-white px-4 py-2">
                <strong>{{ $t("Ha ocurrido un error") }}</strong>
                <div v-for="(error, index) in messageErrors" :key="index">
                  <span class="block">- {{ error.message }}</span>
                </div>
              </div>
            </div>
            <div class="w-full px-3 mb-6 md:mb-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Observaciones") }}
              </label>
            </div>

            <div class="w-full px-3 mb-6 md:mb-2">
              <div
                class="
                  relative
                  rounded
                  overflow-hidden
                  border border-red-500
                  mb-2
                  text-sm
                "
                v-for="(observation, index) in observations"
                v-bind:key="index"
              >
                <div class="px-4 pt-8">
                  <p class="text-gray-700">
                    <span class="font-semibold">{{
                      observation.date_observation
                    }}</span
                    >: {{ observation.observation }}
                  </p>
                </div>
                <div class="px-4 py-4 text-right">
                  <span
                    class="
                      inline-block
                      bg-gray-200
                      rounded-full
                      px-3
                      py-1
                      text-sm
                      font-semibold
                      text-gray-700
                      mr-2
                    "
                    v-if="observation.date_reminder !== ''"
                  >
                    {{ $t("Recordatorios") }} {{ observation.date_reminder }}
                  </span>
                  <div
                    class="absolute top-0 right-0 mt-2 text-xs"
                    v-if="viewEditObservation"
                  >
                    <a
                      class="rounded-full px-1 py-1 text-redagrozono mr-2"
                      href="#"
                      v-on:click="loadObservation(index)"
                    >
                      {{ $t("Editar") }}
                    </a>
                    <a
                      class="rounded-full px-1 py-1 text-redagrozono mr-2"
                      href="#"
                      v-on:click="deleteObservation(index)"
                    >
                      {{ $t("Eliminar") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap mb-6" v-if="viewEditObservation">
            <div class="w-full px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="customers_observaciones_observacion"
              >
                {{ $t("Nueva observación") }}
              </label>
              <textarea
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="customers_observaciones_observacion"
                type="text"
                :placeholder="this.$t('Nueva observación')"
                v-model="description"
              ></textarea>
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="customers_observaciones_fechaReminder"
              >
                {{ $t("Fecha recordatorio") }}
              </label>
              <datetime
                input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                id="customers_observaciones_fechaReminder"
                type="date"
                format="dd/MM/kkkk"
                placeholder="dd/mm/yyyy"
                v-model="date"
              ></datetime>
            </div>

            <div class="w-full md:w-1/2 px-3 text-right mt-2">
              <button
                class="
                  bg-yellow
                  mt-6
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-if="indexEditObservation === ''"
                v-on:click="validationObservation"
              >
                {{ $t("Agregar observación") }}
              </button>
              <button
                class="
                  bg-yellow
                  mt-6
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-if="indexEditObservation !== ''"
                v-on:click="editObservation"
              >
                {{ $t("Editar observación") }}
              </button>
            </div>
          </div>
          <div
            class="flex flex-row justify-end px-1"
            v-if="viewEditObservation"
          >
            <button
              class="
                bg-yellow
                mr-2
                px-3
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-2
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              type="button"
              v-on:click="editCustomer"
            >
              {{ $t("Guardar cambios") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase";
import { DateTime as luxon } from "luxon";
import { Datetime } from "vue-datetime";
import HeaderModal from "@/components/HeaderModal";

export default {
  name: "TaskCustomerModal",
  components: {
    datetime: Datetime,
    HeaderModal,
  },
  data() {
    return {
      customer: {},
      businessAreaName: "",
      observations: "",
      userRol: "",
      userId: "",
      viewEditObservation: false,
      indexEditObservation: "",
      description: "",
      date: "",
      messageErrors: "",
      date_today: "",
      errorObservation: false,
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  computed: {
    checkObservation() {
      let _this = this;
      return _this.observations.filter(function (element) {
        let createdDate = luxon
          .fromFormat(element.date_reminder, "d/MM/y")
          .toISO();

        if (!createdDate) {
          createdDate = luxon
            .fromFormat(element.date_reminder, "d/M/y")
            .toISO();
        }

        if (createdDate > _this.date_today) {
          return element;
        }
      });
    },
  },
  methods: {
    validationObservation() {
      this.messageErrors = [];
      let errors = {
        message: "",
      };
      let listErrors = [];
      let saveContinue = false;

      if (this.checkObservation.length === 0) {
        if (this.description === "") {
          saveContinue = true;
          errors.message = this.$t("Debe indicar una observación");
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }

        if (this.date === "") {
          saveContinue = true;
          errors.message = this.$t(
            "No existe una fecha de recordatorio a futuro, es necesario introducirla"
          );
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      } else {
        if (this.description === "") {
          saveContinue = true;
          errors.message = this.$t("Debe indicar una observación");
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.date !== "") {
        if (luxon.local().toISO() > this.date) {
          saveContinue = true;
          errors.message = this.$t(
            "La fecha introducida debe de ser superior a la fecha actual"
          );
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (saveContinue) {
        this.errorObservation = true;
        this.messageErrors = listErrors;
        setTimeout(() => {
          this.errorObservation = false;
        }, 5000);
      } else {
        this.errorObservation = false;
        this.messageErrors = [];
        this.addObservation();
      }
    },
    addObservation() {
      let date_reminder = this.date
        ? luxon.fromISO(this.date).toFormat("dd/MM/kkkk")
        : "";
      let date_observation = luxon
        .fromISO(luxon.local().toISO())
        .toFormat("dd/MM/kkkk");

      this.observations.push({
        notified: false,
        observation:
          JSON.parse(sessionStorage.getItem("businessAreaActive")).name +
          " " +
          this.description,
        businessAreaName: JSON.parse(
          sessionStorage.getItem("businessAreaActive")
        ).name,
        businessAreaId: JSON.parse(sessionStorage.getItem("businessAreaActive"))
          .uid,
        uid: this.randomUid(),
        date_reminder: date_reminder,
        date_observation: date_observation,
      });

      this.description = "";
      this.date = "";
    },
    deleteObservation(index) {
      this.observations.splice(index, 1);
    },
    loadObservation(index) {
      this.indexEditObservation = index;
      this.description = this.observations[index].observation.substr(
        this.observations[index].observation.indexOf(" ") + 1
      );
      let createdDate = luxon
        .fromFormat(this.observations[index].date_reminder, "d/MM/y")
        .toISO();

      if (!createdDate) {
        createdDate = luxon
          .fromFormat(this.observations[index].date_reminder, "d/M/y")
          .toISO();
      }

      this.date = createdDate;
    },
    editObservation() {
      this.errorObservation = false;
      if (this.description !== "") {
        this.observations[this.indexEditObservation].date_reminder = this.date
          ? luxon.fromISO(this.date).toFormat("dd/MM/kkkk")
          : "";

        this.observations[this.indexEditObservation].observation =
          JSON.parse(sessionStorage.getItem("businessAreaActive")).name +
          " " +
          this.description;

        this.description = "";
        this.date = "";
        this.indexEditObservation = "";
      } else {
        this.errorObservation = true;
        let error = {
          message: this.$t("Debe indicar una observación"),
        };
        let listErrors = [];
        listErrors.push(error);
        this.messageErrors = listErrors;
        setTimeout(() => {
          this.errorObservation = false;
        }, 5000);
      }
    },
    editCustomer() {
      let _this = this;

      if (_this.params.type === "customer") {
        let refCustomer = db.collection("customers").doc(_this.customer.uid);
        refCustomer
          .update({
            observaciones: _this.observations,
          })
          .then(() => {
            this.$emit("close");
            this.$root.$emit("reloadListTask");
          })
          .catch(function (error) {
            this.messageError =
              "Ha ocurrido un error al intentar guardar el registro en la BD. Error: " +
              error.message;
          });
      }

      if (_this.params.type === "lead") {
        let refCustomer = db.collection("leads").doc(_this.customer.uid);
        refCustomer
          .update({
            observaciones: _this.observations,
          })
          .then(() => {
            this.$root.$emit("closeModal");
            this.$root.$emit("reloadListTask");
          })
          .catch(function (error) {
            this.messageError =
              "Ha ocurrido un error al intentar guardar el registro en la BD. Error: " +
              error.message;
          });
      }
    },
    getCustomerOrLead() {
      let _this = this;

      if (this.params.type === "customer") {
        db.collection("customers")
          .where("uid", "==", this.params.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              _this.customer = doc.data();

              _this.customer.businessAreas.forEach((element) => {
                _this.businessAreaName = element.name;
              });

              this.disabledObservation();
              _this.observations = _this.customer.observaciones;

              if (doc.data().createdDate) {
                let indexDate = doc.data().createdDate.indexOf(" ");
                let date = doc.data().createdDate.slice(0, indexDate);
                let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                if (!createdDate) {
                  createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                }

                _this.customer["date"] = luxon
                  .fromISO(createdDate)
                  .toFormat("dd-MM-yyyy");
              }
            });
          });
      }

      if (this.params.type === "lead") {
        db.collection("leads")
          .where("uid", "==", this.params.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              _this.customer = doc.data();

              _this.customer.businessAreas.forEach((element) => {
                _this.businessAreaName = element.name;
              });
              this.disabledObservation();

              _this.observations = _this.customer.observaciones;

              if (doc.data().createdDate) {
                let indexDate = doc.data().createdDate.indexOf(" ");
                let date = doc.data().createdDate.slice(0, indexDate);
                let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                if (!createdDate) {
                  createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                }

                _this.customer["date"] = luxon
                  .fromISO(createdDate)
                  .toFormat("dd-MM-yyyy");
              }
            });
          });
      }
    },
    disabledObservation() {
      if (
        this.params.task === "contactCustomer" ||
        this.params.task === "contactLead" ||
        this.params.task === "newCustomer" ||
        this.params.task === "newLead"
      ) {
        if (
          this.userRol === "comTechnicians" &&
          this.customer.comTechnicianId === this.userId
        ) {
          this.viewEditObservation = true;
        }

        if (
          this.userRol === "distributors" &&
          this.customer.distributorId === this.userId
        ) {
          this.viewEditObservation = true;
        }

        if (this.userRol === "comTechniciansResp") {
          this.viewEditObservation = true;
        }
      }
    },
  },
  beforeMount() {
    this.userRol = sessionStorage.getItem("user_rol");
    this.userId = sessionStorage.getItem("user_id");

    let date = new Date(luxon.local().toISO());
    let date_format =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    this.date_today = luxon.fromFormat(date_format, "d/MM/y").toISO();

    if (!this.date_today) {
      this.date_today = luxon.fromFormat(date_format, "d/M/y").toISO();
    }

    this.getCustomerOrLead();
  },
};
</script>
