<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4 w-1/2">
      <router-link
        to="/application-assignment"
        class="
          text-sm
          font-semibold
          text-gray-700
          pb-1
          px-3
          rounded
          flex
          text-blueagrozono
          hover:text-gray-800
          w-full
          md:w-1/4
        "
      >
        <svg
          class="h-8 w-5 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M3.828 9l6.071-6.071-1.414-1.414L0 10l.707.707 7.778 7.778 1.414-1.414L3.828 11H20V9H3.828z"
          />
        </svg>
        <span class="ml-5 text-lg font-bold">{{ $t("Volver") }}</span>
      </router-link>
    </div>

    <loader v-if="dataLoaded === false"></loader>
    <div v-if="dataLoaded === true">
      <div class="text-xl font-semibold text-gray-800 mb-4">
        <button
          class="
            inline-flex
            items-center
            px-4
            py-2
            text-blueagrozono
            border border-blueagrozono
            leading-5
            font-bold
            rounded-md
            hover:text-white hover:bg-blueagrozono
          "
          type="button"
          @click="openDeliveryNoteModal"
        >
          {{ $t("Albaranes") }}
        </button>
      </div>
      <div class="bg-white shadow overflow-hidden sm:rounded-md my-6">
        <ul>
          <li>
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <div
                class="
                  -ml-4
                  -mt-4
                  flex
                  justify-between
                  items-center
                  flex-wrap
                  sm:flex-no-wrap
                "
              >
                <div class="ml-4 mt-4">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <h3 class="text-lg leading-6 font-bold text-gray-900">
                        {{ $t("Detalle") }}
                      </h3>
                      <p class="text-sm leading-5 text-gray-500">
                        {{ $t("Detalle") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ml-4 mt-4 flex-shrink-0 flex">
                  <span class="inline-flex rounded-md shadow-sm">
                    <button
                      v-if="
                        userRol === 'appSuperAdministrators' ||
                        userRol === 'respTechnicians' ||
                        userRol === 'appTechnicians'
                      "
                      type="button"
                      class="
                        relative
                        inline-flex
                        items-center
                        px-4
                        py-2
                        leading-5
                        font-bold
                        rounded-md
                        text-white
                        focus:outline-none
                        hover:text-white
                      "
                      @click="openAddDetailModal"
                      :class="
                        disabledButton
                          ? 'bg-gray-400 cursor-not-allowed border-none'
                          : 'text-blueagrozono hover:bg-blueagrozono border border-blueagrozono'
                      "
                      :disabled="disabledButton"
                    >
                      <span>{{ $t("Crear") }}</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 grid grid-cols-8 gap-8">
                  <div class="col-span-8">
                    <div
                      class="
                        uppercase
                        tracking-wide
                        text-gray-700 text-xs
                        font-bold
                        col-span-8
                      "
                    >
                      {{ $t("Caudales / sector m3/h") }}
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S1:
                      <span class="text-black font-semibold">{{
                        treatment.flow1
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S2:
                      <span class="text-black font-semibold">{{
                        treatment.flow2
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S3:
                      <span class="text-black font-semibold">{{
                        treatment.flow3
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S4:
                      <span class="text-black font-semibold">{{
                        treatment.flow4
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S5:
                      <span class="text-black font-semibold">{{
                        treatment.flow5
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S6:
                      <span class="text-black font-semibold">{{
                        treatment.flow6
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S7:
                      <span class="text-black font-semibold">{{
                        treatment.flow7
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S8:
                      <span class="text-black font-semibold">{{
                        treatment.flow8
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="border-t border-gray-200">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 grid grid-cols-8 gap-8">
                  <div class="col-span-8">
                    <div
                      class="
                        uppercase
                        tracking-wide
                        text-gray-700 text-xs
                        font-bold
                        col-span-8
                      "
                    >
                      {{ $t("Presión entrada / sector bar") }}
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S1:
                      <span class="text-black font-semibold">{{
                        treatment.entryPressure1
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S2:
                      <span class="text-black font-semibold">{{
                        treatment.entryPressure2
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S3:
                      <span class="text-black font-semibold">{{
                        treatment.entryPressure3
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S4:
                      <span class="text-black font-semibold">{{
                        treatment.entryPressure4
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S5:
                      <span class="text-black font-semibold">{{
                        treatment.entryPressure5
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S6:
                      <span class="text-black font-semibold">{{
                        treatment.entryPressure6
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S7:
                      <span class="text-black font-semibold">{{
                        treatment.entryPressure7
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S8:
                      <span class="text-black font-semibold">{{
                        treatment.entryPressure8
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="border-t border-gray-200">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 grid grid-cols-8 gap-8">
                  <div class="col-span-8">
                    <div
                      class="
                        uppercase
                        tracking-wide
                        text-gray-700 text-xs
                        font-bold
                        col-span-8
                      "
                    >
                      {{ $t("Presión salida / sector bar") }}
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S1:
                      <span class="text-black font-semibold">{{
                        treatment.exitPressure1
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S2:
                      <span class="text-black font-semibold">{{
                        treatment.exitPressure2
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S3:
                      <span class="text-black font-semibold">{{
                        treatment.exitPressure3
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S4:
                      <span class="text-black font-semibold">{{
                        treatment.exitPressure4
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S5:
                      <span class="text-black font-semibold">{{
                        treatment.exitPressure5
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S6:
                      <span class="text-black font-semibold">{{
                        treatment.exitPressure6
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S7:
                      <span class="text-black font-semibold">{{
                        treatment.exitPressure7
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S8:
                      <span class="text-black font-semibold">{{
                        treatment.exitPressure8
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="border-t border-gray-200">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 grid grid-cols-8 gap-8">
                  <div class="col-span-8">
                    <div
                      class="
                        uppercase
                        tracking-wide
                        text-gray-700 text-xs
                        font-bold
                        col-span-8
                      "
                    >
                      {{ $t("Superficies / sector") }}
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S1:
                      <span class="text-black font-semibold">{{
                        treatment.surface1
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S2:
                      <span class="text-black font-semibold">{{
                        treatment.surface2
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S3:
                      <span class="text-black font-semibold">{{
                        treatment.surface3
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S4:
                      <span class="text-black font-semibold">{{
                        treatment.surface4
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S5:
                      <span class="text-black font-semibold">{{
                        treatment.surface5
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S6:
                      <span class="text-black font-semibold">{{
                        treatment.surface6
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S7:
                      <span class="text-black font-semibold">{{
                        treatment.surface7
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      S8:
                      <span class="text-black font-semibold">{{
                        treatment.surface8
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="border-t border-gray-200">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 grid grid-cols-6 gap-6">
                  <div class="col-span-6">
                    <div
                      class="
                        uppercase
                        tracking-wide
                        text-gray-700 text-xs
                        font-bold
                        col-span-8
                      "
                    >
                      {{ $t("Horas totales máquina") }}
                    </div>
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      {{ $t("Inicio") }}:
                      <span class="text-black font-semibold">{{
                        treatment.hourMachineInit
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      {{ $t("Fin") }}:
                      <span class="text-black font-semibold">{{
                        treatment.hourMachineFinish
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      {{ $t("Total") }}:
                      <span class="text-black font-semibold">{{
                        treatment.totalMachinaHours
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="border-t border-gray-200">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 grid grid-cols-6 gap-6">
                  <div class="col-span-6">
                    <div
                      class="
                        uppercase
                        tracking-wide
                        text-gray-700 text-xs
                        font-bold
                        col-span-8
                      "
                    >
                      {{ $t("Horas totales grupo") }}
                    </div>
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      {{ $t("Inicio") }}:
                      <span class="text-black font-semibold">{{
                        treatment.hourGroupInit
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      {{ $t("Fin") }}:
                      <span class="text-black font-semibold">{{
                        treatment.hourGroupFinish
                      }}</span>
                    </div>
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      {{ $t("Total") }}:
                      <span class="text-black font-semibold">{{
                        treatment.totalGroupHours
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="border-t border-gray-200">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 grid grid-cols-6 gap-6">
                  <div class="col-span-6">
                    <div
                      class="
                        uppercase
                        tracking-wide
                        text-gray-700 text-xs
                        font-bold
                        col-span-8
                      "
                    >
                      {{ $t("Otros") }}
                    </div>
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      {{ $t("Fecha real tratamiento") }}:
                      <span
                        class="text-black font-semibold"
                        v-if="treatment.realTreatmentDate"
                      >
                        {{ treatment.realTreatmentDate | formatDate }}</span
                      >
                    </div>
                  </div>
                  <div class="col-span-6">
                    <div class="leading-5 font-medium text-gray-700 truncate">
                      {{ $t("Observaciones") }}:
                      <span class="text-black font-semibold">
                        {{ treatment.observation }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="border-t border-gray-200">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 grid grid-cols-6 gap-6">
                  <div class="col-span-6">
                    <div
                      class="
                        uppercase
                        tracking-wide
                        text-gray-700 text-xs
                        font-bold
                        col-span-8
                      "
                    >
                      {{ $t("Regulación de ph") }}
                    </div>
                  </div>
                  <div class="col-span-3 sm:col-span-2">
                    <div
                      class="leading-5 font-semibold text-black truncate"
                      v-if="this.treatment.phRegulationStatus"
                    >
                      <div
                        v-if="this.treatment.phRegulationStatus === 'yesTeam'"
                      >
                        {{ $t("Si, el equipo") }}
                      </div>
                      <div
                        v-if="this.treatment.phRegulationStatus === 'yesClient'"
                      >
                        {{ $t("Si, el cliente") }}
                      </div>
                      <div v-if="this.treatment.phRegulationStatus === 'no'">
                        {{ $t("No") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="bg-white shadow overflow-hidden sm:rounded-md my-6">
        <ul>
          <li>
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <div
                class="
                  -ml-4
                  -mt-4
                  flex
                  justify-between
                  items-center
                  flex-wrap
                  sm:flex-no-wrap
                "
              >
                <div class="ml-4 mt-4">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <h3 class="text-lg leading-6 font-bold text-gray-900">
                        {{ $t("Fotos") }}
                      </h3>
                      <p class="text-sm leading-5 text-gray-500">
                        {{ $t("Fotos") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ml-4 mt-4 flex-shrink-0 flex">
                  <span class="inline-flex rounded-md shadow-sm">
                    <button
                      v-if="
                        userRol === 'appSuperAdministrators' ||
                        userRol === 'respTechnicians' ||
                        userRol === 'appTechnicians'
                      "
                      class="
                        relative
                        inline-flex
                        items-center
                        px-4
                        py-2
                        leading-5
                        font-bold
                        rounded-md
                        text-white
                        focus:outline-none
                        hover:text-white
                      "
                      @click="openAddPhotoModal"
                      :class="
                        disabledButton
                          ? 'bg-gray-400 cursor-not-allowed border-none'
                          : 'text-blueagrozono hover:bg-blueagrozono border border-blueagrozono'
                      "
                      :disabled="disabledButton"
                    >
                      <span>{{ $t("Añadir fotos") }}</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4">
                  <viewer :options="options" class="images" :images="photos">
                    <img
                      v-for="photo in photos"
                      :src="photo"
                      :data-source="photo"
                      class="image imageWidth"
                      :key="photo"
                      :alt="photo.split('?image=').pop()"
                    />
                  </viewer>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="bg-white shadow overflow-hidden sm:rounded-md my-6">
        <ul>
          <li>
            <div class="bg-white px-4 py-5 sm:px-6">
              <div
                class="
                  -ml-4
                  -mt-4
                  flex
                  justify-between
                  items-center
                  flex-wrap
                  sm:flex-no-wrap
                "
              >
                <div class="ml-4 mt-4">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <h3 class="text-lg leading-6 font-bold text-gray-900">
                        {{ $t("Lectura") }}
                      </h3>
                      <p class="text-sm leading-5 text-gray-500">
                        {{ $t("Lectura") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ml-4 mt-4 flex-shrink-0 flex">
                  <span class="inline-flex rounded-md shadow-sm">
                    <button
                      v-if="
                        userRol === 'appSuperAdministrators' ||
                        userRol === 'respTechnicians' ||
                        userRol === 'appTechnicians'
                      "
                      class="
                        relative
                        inline-flex
                        items-center
                        px-4
                        py-2
                        leading-5
                        font-bold
                        rounded-md
                        text-white
                        focus:outline-none
                        hover:text-white
                      "
                      type="button"
                      @click="openAddReadingModal"
                      :class="
                        disabledButton
                          ? 'bg-gray-400 cursor-not-allowed border-none'
                          : 'text-blueagrozono hover:bg-blueagrozono border border-blueagrozono'
                      "
                      :disabled="disabledButton"
                    >
                      {{ $t("Añadir lectura") }}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li v-for="(reading, index) in orderReadings" :key="index">
            <ul class="border-t-2">
              <li>
                <div
                  class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6"
                >
                  <div
                    class="
                      -ml-4
                      -mt-4
                      flex
                      justify-between
                      items-center
                      flex-wrap
                      sm:flex-no-wrap
                    "
                  >
                    <div class="ml-4 mt-4">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <h3 class="text-lg leading-6 font-bold text-gray-700">
                            {{ $t("Lectura num") }}
                            <span class="text-black font-semibold">{{
                              reading.numberReading
                            }}</span>
                          </h3>
                          <p class="text-sm leading-5 text-gray-500">
                            {{ $t("Fecha") }}:
                            <span class="text-black font-semibold">{{
                              reading.date | formatDate
                            }}</span>
                            <span class="ml-4">
                              {{ $t("Hora") }}:
                              <span class="text-black font-semibold">{{
                                reading.hour | formatHour
                              }}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ml-8 mt-4 flex-shrink-0 flex">
                      <div
                        class="
                          leading-5
                          font-medium
                          text-gray-700
                          truncate
                          mr-6
                        "
                      >
                        {{ $t("Sector") }}:
                        <span class="text-black font-semibold">{{
                          reading.sector
                        }}</span>
                      </div>
                      <div class="leading-5 font-medium text-gray-700 truncate">
                        {{ $t("Lectura") }}:
                        <span class="text-black font-semibold">{{
                          reading.numberReading
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div class="min-w-0 flex-1 px-4 grid grid-cols-4 gap-4">
                      <div class="col-span-4">
                        <div
                          class="
                            uppercase
                            tracking-wide
                            text-gray-700 text-xs
                            font-bold
                            col-span-8
                          "
                        >
                          {{ $t("Entrada máquina") }}
                        </div>
                      </div>
                      <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Ph") }}:
                          <span class="text-black font-semibold">{{
                            reading.entryMachinePH
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Redox") }}:
                          <span class="text-black font-semibold">{{
                            reading.entryMachineRedox
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Temperatura") }}:
                          <span class="text-black font-semibold"
                            >{{ reading.entryMachineTemperature }}º</span
                          >
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Ppm") }}:
                          <span class="text-black font-semibold">{{
                            reading.entryMachinePPM
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="border-t border-gray-200">
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div class="min-w-0 flex-1 px-4 grid grid-cols-4 gap-4">
                      <div class="col-span-4">
                        <div
                          class="
                            uppercase
                            tracking-wide
                            text-gray-700 text-xs
                            font-bold
                            col-span-8
                          "
                        >
                          {{ $t("Salida máquina") }}
                        </div>
                      </div>
                      <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Ph") }}:
                          <span class="text-black font-semibold">{{
                            reading.exitMachinePH
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Redox") }}:
                          <span class="text-black font-semibold">{{
                            reading.exitMachineRedox
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Temperatura") }}:
                          <span class="text-black font-semibold"
                            >{{ reading.exitMachineTemperature }}º</span
                          >
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Ppm") }}:
                          <span class="text-black font-semibold">{{
                            reading.exitMachinePPM
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="border-t border-gray-200">
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div class="min-w-0 flex-1 px-4 grid grid-cols-4 gap-4">
                      <div class="col-span-4">
                        <div
                          class="
                            uppercase
                            tracking-wide
                            text-gray-700 text-xs
                            font-bold
                            col-span-8
                          "
                        >
                          {{ $t("Último gotero") }}
                        </div>
                      </div>
                      <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Ph") }}:
                          <span class="text-black font-semibold">{{
                            reading.lastDropperPH
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Redox") }}:
                          <span class="text-black font-semibold">{{
                            reading.lastDropperRedox
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Temperatura") }}:
                          <span class="text-black font-semibold"
                            >{{ reading.lastDropperTemperature }}º</span
                          >
                        </div>
                      </div>
                      <div
                        class="
                          mt-2
                          md:mt-0
                          col-span-4
                          sm:col-span-2
                          lg:col-span-1
                        "
                      >
                        <div
                          class="leading-5 font-medium text-gray-700 truncate"
                        >
                          {{ $t("Ppm") }}:
                          <span class="text-black font-semibold">{{
                            reading.lastDropperPPM
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="flex justify-end mb-10">
        <button
          v-if="
            (userRol === 'appSuperAdministrators' ||
              userRol === 'respTechnicians' ||
              userRol === 'appTechnicians') &&
            (treatment.state === 'pending' || !treatment.state)
          "
          class="
            relative
            inline-flex
            items-center
            px-4
            py-2
            leading-5
            font-bold
            rounded-md
            text-white
            focus:outline-none
            bg-orange-500
            hover:bg-orange-400
          "
          @click="changeStateCompleted"
        >
          <span>{{ $t("Completar") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { db, firebaseGlobal } from "@/plugins/firebase";
import Loader from "@/components/Loader";
import GetImage from "@/components/GetImage";
import "viewerjs/dist/viewer.css";
import Utilities from "@/plugins/Utilities";
import { orderBy } from "lodash";
import { DateTime as luxon } from "luxon";
import ApplicationDetailAddDetailModal from "@/pages/ApplicationDetail/ApplicationDetailAddDetailModal";
import ApplicationDetailAddPhotoModal from "@/pages/ApplicationDetail/ApplicationDetailAddPhotoModal";
import ApplicationDetailLayoutNoteModal from "@/pages/ApplicationDetail/ApplicationDetailLayoutNoteModal";
import ApplicationDetailAddReadingModal from "@/pages/ApplicationDetail/ApplicationDetailAddReadingModal";

const ApplicationDetailList = {
  name: "ApplicationDetailList",
  layout: "layout",
  components: {
    GetImage,
    Loader,
    ApplicationDetailAddDetailModal,
    ApplicationDetailAddPhotoModal,
    ApplicationDetailLayoutNoteModal,
    ApplicationDetailAddReadingModal,
  },
  data: function () {
    return {
      readings: [],
      urlPhotos: [],
      photos: [],
      treatment: [],
      treatmentId: "",
      dataLoaded: false,
      userRol: "",
      options: {
        toolbar: false,
        url: "data-source",
      },
    };
  },
  computed: {
    disabledButton() {
      return (
        this.treatment.state === "completed" ||
        this.treatment.state === "active"
      );
    },
    orderReadings() {
      return orderBy(this.readings, ["date", "hour"], ["desc", "desc"]);
    },
  },
  methods: {
    openAddReadingModal: function () {
      let lastReading = 0;

      if (this.orderReadings.length !== 0) {
        lastReading = this.orderReadings[0];
      }

      this.$modal.show(ApplicationDetailAddReadingModal, {
        title: this.$t("Añadir lectura"),
        params: { lastReading: lastReading, treatmentId: this.treatmentId },
      });
    },
    openAddDetailModal: function () {
      this.$modal.show(ApplicationDetailAddDetailModal, {
        title: this.$t("Añadir detalle"),
        params: { treatment: this.treatment },
      });
    },
    openAddPhotoModal: function () {
      this.$modal.show(ApplicationDetailAddPhotoModal, {
        title: this.$t("Añadir fotos"),
        params: { treatment: this.treatment },
      });
    },
    openDeliveryNoteModal: function () {
      this.$modal.show(ApplicationDetailLayoutNoteModal, {
        title: this.$t("Añadir fotos"),
        params: { treatmentID: this.treatmentId, treatment: this.treatment },
      });
    },
    getReadings() {
      let _this = this;
      _this.readings = [];

      db.collection("treatments")
        .doc(_this.treatmentId)
        .collection("readings")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _this.readings.push(doc.data());
          });

          _this.readings.sort(function (a, b) {
            const nameA = a.numberReading;
            const nameB = b.numberReading;

            let comparison = 0;
            if (nameA > nameB) {
              comparison = -1;
            } else if (nameA < nameB) {
              comparison = 1;
            }
            return comparison;
          });
        });
    },
    getPhotos() {
      let _this = this;
      this.dataLoaded = false;
      _this.photos = [];

      db.collection("treatments")
        .where("uid", "==", _this.treatmentId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let photosFirebase = doc.data().treatmentPathImages;
            _this.treatment = doc.data();

            photosFirebase.forEach(function (element) {
              let storageRef = firebaseGlobal.storage().ref();

              storageRef
                .child(element)
                .getDownloadURL()
                .then(function (url) {
                  _this.photos.push(url);
                })
                .catch(function (error) {});
            });
          });
          this.dataLoaded = true;
        });
    },
    changeStateCompleted() {
      if (
        confirm(
          this.$t("¿Realmente deseas cambiar el estado a") +
            " '" +
            this.$t("Completado") +
            "'?"
        )
      ) {
        let _this = this;
        let protocolReminders = [];
        db.collection("protocolReminders")
          .where("protocolId", "==", _this.treatment.protocolId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              protocolReminders.push(doc.data());
            });
            let orderProtocolReminders = orderBy(
              protocolReminders,
              ["hours"],
              ["asc"]
            );

            let steps = [];
            orderProtocolReminders.forEach((element, index) => {
              let date = new Date(_this.treatment.agrozonoDate.split("T")[0]);
              date.setDate(date.getDate() + parseInt(element.repetitionInDays));

              let formatDate =
                date.getDate() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getFullYear();

              let estimedDate = luxon.fromFormat(formatDate, "d/MM/y").toISO();

              if (!estimedDate) {
                estimedDate = luxon.fromFormat(formatDate, "d/M/y").toISO();
              }

              let step = {
                step: index,
                estimatedDate: estimedDate,
                step_date: "",
              };

              if (index === 0) {
                step = {
                  step: index,
                  step_date: _this.treatment.agrozonoDate,
                  estimatedDate: estimedDate,
                };
              }

              steps.push(step);
            });

            let checkSteps = steps.filter(function (element) {
              if (!element.step_date) {
                return element;
              }
            });

            let state = checkSteps.length === 0 ? "completed" : "active";

            let refTreatment = db
              .collection("treatments")
              .doc(_this.treatment.uid);

            refTreatment
              .update({
                steps: steps,
                state: state,
              })
              .then(() => {
                this.getPhotos();
              })
              .catch(function (error) {
                console.log(
                  "Ha ocurrido un error al intentar cambiar el registro en la BD. Error:" +
                    error.message
                );
              });
          });
      }
    },
  },
  created() {
    this.$root.$on("reloadReadings", () => {
      this.getReadings();
    });

    this.$root.$on("reloadDetails", () => {
      this.getPhotos();
    });
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");
    this.treatmentId = this.$route.params.id;
    this.dataLoaded = false;
    this.getReadings();
    this.getPhotos();
  },
};

export default ApplicationDetailList;
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: #bbcccc29;
}
.image {
  width: calc(50% - 10px);
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.imageWidth {
  width: calc(50% - 10px);
}

@media (min-width: 600px) {
  .imageWidth {
    width: calc(25% - 10px);
  }
}
</style>
