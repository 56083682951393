<template>
  <div class="h-full flex items-center justify-center flex-1">
    <Zondicon
      icon="hour-glass"
      class="fill-current inline-block mr-1 text-gray-300 loader"
    />
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";

const Loader = {
  name: "loader",
  components: {
    Zondicon,
  },
};

export default Loader;
</script>

<style scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: rotate steps(100) 3s infinite;
  height: 130px;
  width: 130px;
}
</style>
