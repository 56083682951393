<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-4 p-6">
      <div class="w-full">
        <div>
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                md:px-2
              "
            >
              {{ $t("Datos a rellenar por el responsable") }}
            </div>

            <div class="w-full md:w-1/3 md:px-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="machine"
              >
                {{ $t("Máquina") }} <span class="text-bold text-red">*</span>
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  :class="disabledMachine ? 'bg-gray-200' : 'bg-white'"
                  v-model="machine"
                  id="machine"
                >
                  <option value="">{{ $t("Seleccione una máquina") }}</option>
                  <option
                    v-for="machine in listMachines"
                    :value="machine"
                    :key="machine.uid"
                  >
                    {{ machine.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 md:px-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="appTechnician"
              >
                {{ $t("Técnico aplicador") }}
                <span class="text-bold text-red">*</span>
              </label>
              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  :class="disabledTechnician ? 'bg-gray-200' : 'bg-white'"
                  v-model="appTechnician"
                  id="appTechnician"
                  :disabled="disabledTechnician"
                >
                  <option value="">
                    {{ $t("Seleccione un técnico aplicador") }}
                  </option>
                  <option
                    v-for="technician in listTechnicians"
                    :value="technician"
                    :key="technician.uid"
                  >
                    {{ technician.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 md:px-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="agrozonoDate"
              >
                {{ $t("Fecha definitiva agrozono") }}
                <span class="text-bold text-red">*</span>
              </label>
              <datetime
                input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="date"
                format="dd/MM/kkkk"
                placeholder="dd/mm/yyyy"
                zone="local"
                id="agrozonoDate"
                value-zone="local"
                v-model="agrozonoDate"
              ></datetime>
            </div>
          </div>
          <div v-if="errorForm" class="bg-red text-white px-4 py-2 mb-4 w-full">
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">- {{ $t(messageError) }}</span>
          </div>
          <div class="flex justify-end mt-4">
            <button
              class="
                inline-flex
                items-center
                px-4
                py-2
                text-white
                leading-5
                font-bold
                rounded-md
              "
              :class="
                disabledTechnician
                  ? 'bg-gray-400'
                  : 'bg-orange-500 hover:bg-orange-400'
              "
              type="button"
              @click="submit"
              :disabled="disabledTechnician"
            >
              {{ $t("Asignar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import moment from "moment";
import { orderBy } from "lodash";
import HeaderModal from "@/components/HeaderModal";

const ApplicationByAssignModal = {
  name: "ApplicationByAssignModal",
  layout: "layout",
  components: {
    datetime: Datetime,
    HeaderModal,
  },
  data() {
    return {
      inspection: "",
      listTechnicians: [],
      listMachines: [],
      appTechnician: "",
      machine: "",
      agrozonoDate: "",
      disabledTechnician: true,
      disabledMachine: true,
      messageError: "",
      errorForm: false,
      respTechEmail: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  methods: {
    getTechnicians() {
      let _this = this;
      db.collection("appTechnicians")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.listTechnicians.push(doc.data());
          });

          _this.listTechnicians = orderBy(
            _this.listTechnicians,
            ["name"],
            ["asc"]
          );

          if (_this.inspection.appTechnicianId) {
            _this.listTechnicians.filter((technician) => {
              if (_this.inspection.appTechnicianId === technician.uid) {
                _this.appTechnician = technician;
              }
            });
          }
          _this.disabledTechnician = false;
        });
    },
    getMachines() {
      let _this = this;
      db.collection("machines")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.listMachines.push(doc.data());
          });

          _this.listMachines = orderBy(_this.listMachines, ["name"], ["asc"]);

          if (_this.inspection.machineId) {
            _this.listMachines.filter((machine) => {
              if (_this.inspection.machineId === machine.uid) {
                _this.machine = machine;
              }
            });
          }

          _this.disabledMachine = false;
        });
    },
    submit() {
      this.messageError = "Debe introducir todos los datos de este formulario";
      let error = false;

      if (this.machine === "") {
        error = true;
      }

      if (this.appTechnician === "") {
        error = true;
      }

      if (this.agrozonoDate === "") {
        error = true;
      }

      let dateToday = new Date();
      dateToday =
        dateToday.getFullYear() +
        "/" +
        (dateToday.getMonth() + 1) +
        "/" +
        dateToday.getDate();
      let dateAgrozono = moment(String(this.agrozonoDate)).format("YYYY/MM/DD");

      if (new Date(dateAgrozono) < new Date(dateToday)) {
        error = true;
        this.messageError =
          "La fecha definitiva introducida no puede ser anterior a la fecha actual";
      }

      if (error !== true) {
        this.errorForm = false;
        this.updateInspection();
      } else {
        this.errorForm = error;
      }
    },
    updateInspection() {
      let _this = this;

      let refInspection = db.collection("inspections").doc(this.inspection.id);

      refInspection
        .update({
          asignedTreatment: true,
          agrozonoDate: _this.agrozonoDate,
        })
        .then(() => {
          this.generateTreatment();
        })
        .catch(function (error) {
          this.errorForm = true;
          this.messageError =
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
            error.message;
        });
    },
    getRespTechEmail() {
      let _this = this;
      db.collection("delegations")
        .where("uid", "==", _this.inspection.respTechDelegationId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async function (doc) {
            _this.respTechEmail = doc.data().mail;
          });
        });
    },
    generateTreatment() {
      let _this = this;
      let refTreatment = db.collection("treatments").doc();

      refTreatment
        .set({
          appTechnicianId: _this.appTechnician.uid,
          appTechnicianName: _this.appTechnician.name,
          machineId: _this.machine.uid,
          machineName: _this.machine.name,
          agrozonoDate: _this.agrozonoDate,
          customerId: _this.inspection.customerId,
          customerName: _this.inspection.customerName,
          farmId: _this.inspection.farmId,
          farmName: _this.inspection.farmName,
          idAgro: _this.inspection.idAgro,
          idCreatedToShare: _this.inspection.idCreatedToShare,
          inspectionComTechEmail: _this.inspection.comTechnicianEmail,
          inspectionId: _this.inspection.id,
          lang: _this.inspection.lang,
          protocolId: _this.inspection.protocolId,
          protocolInspectionNameLocalized: _this.inspection.protocolName,
          respTechEmail: _this.respTechEmail,
          treatmentPathImages: [],
          state: "pending",
          uid: refTreatment.id,
        })
        .then(() => {
          this.$emit("close");
          this.$root.$emit("reloadListApplicationByAssign");
        })
        .catch(function (error) {
          this.errorForm = true;
          this.error =
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
            error.message;
        });
    },
  },
  beforeMount() {
    this.inspection = this.params.inspection;
    if (this.params.inspection.agrozonoDate) {
      this.agrozonoDate = this.params.inspection.agrozonoDate;
    }

    this.getTechnicians();
    this.getMachines();
    this.getRespTechEmail();
  },
};

export default ApplicationByAssignModal;
</script>
