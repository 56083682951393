<template>
  <div class="flex flex-row bg-greenlight px-5 py-3 text-white font-bold">
    <div class="justify-start">{{ title }}</div>
    <div class="self-end absolute right-0 mr-4" @click="$emit('close')">
      <Zondicon
        icon="close"
        class="
          fill-current
          inline-block
          h-4
          text-white
          cursor-pointer
          hover:text-gray-700
        "
      />
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";

const HeaderModal = {
  name: "HeaderModal",
  components: {
    Zondicon,
  },
  props: {
    title: {
      required: true,
    },
  },
};

export default HeaderModal;
</script>
