<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-4">
          <div
            class="
              w-full
              sm:grid sm:grid-cols-2 sm:gap-2
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
          >
            <div class="text-left flex items-center mb-4 md:mb-0">
              <div>
                {{ $t("Nueva lectura") }}
                <div>{{ $t("Lectura num") }} {{ numberReading }}</div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-2 sm:gap-2">
              <div class="pl-0 md:pl-2 sm:col-span-1">
                <label
                  class="block tracking-wide text-gray-700 text-xs font-bold"
                  for="sector"
                >
                  {{ $t("Sector") }} <span class="text-bold text-red">*</span>
                </label>
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-white
                    text-gray-700
                    border border-red-500
                    rounded
                    py-3
                    px-4
                    leading-tight
                    focus:outline-none focus:bg-white
                  "
                  type="text"
                  id="sector"
                  :placeholder="this.$t('Sector')"
                  v-model="sector"
                />
              </div>
              <div class="pl-0 md:pl-2 mt-2 sm:mt-0 sm:col-span-1">
                <label
                  class="block tracking-wide text-gray-700 text-xs font-bold"
                  for="numberReading"
                >
                  {{ $t("Lectura") }}
                </label>
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border border-red-500
                    rounded
                    py-3
                    px-4
                    leading-tight
                    focus:outline-none focus:bg-white
                  "
                  type="text"
                  id="numberReading"
                  v-model="numberReading"
                  disabled="disabled"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="md:grid md:grid-cols-4 md:gap-4 mb-6">
          <div class="col-span-4">
            <div
              class="
                w-full
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
                md:mb-0
              "
            >
              {{ $t("Entrada máquina") }}
            </div>
          </div>
          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="entryMachinePH"
            >
              {{ $t("Ph") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="entryMachinePH"
              v-model="entryMachinePH"
              :placeholder="this.$t('Ph')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="entryMachineRedox"
            >
              {{ $t("Redox") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="entryMachineRedox"
              v-model="entryMachineRedox"
              :placeholder="this.$t('Redox')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="entryMachineTemperature"
            >
              {{ $t("Temperatura") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="entryMachineTemperature"
              v-model="entryMachineTemperature"
              :placeholder="this.$t('Temperatura')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="entryMachinePPM"
            >
              {{ $t("Ppm") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="entryMachinePPM"
              v-model="entryMachinePPM"
              :placeholder="this.$t('Ppm')"
            />
          </div>
        </div>
        <div class="md:grid md:grid-cols-4 md:gap-4 mb-6">
          <div class="col-span-4">
            <div
              class="
                w-full
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
                md:mb-0
              "
            >
              {{ $t("Salida máquina") }}
            </div>
          </div>
          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="exitMachinePH"
            >
              {{ $t("Ph") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="exitMachinePH"
              v-model="exitMachinePH"
              :placeholder="this.$t('Ph')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="exitMachineRedox"
            >
              {{ $t("Redox") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="exitMachineRedox"
              v-model="exitMachineRedox"
              :placeholder="this.$t('Redox')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="exitMachineTemperature"
            >
              {{ $t("Temperatura") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="exitMachineTemperature"
              v-model="exitMachineTemperature"
              :placeholder="this.$t('Temperatura')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="exitMachinePPM"
            >
              {{ $t("Ppm") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="exitMachinePPM"
              v-model="exitMachinePPM"
              :placeholder="this.$t('Ppm')"
            />
          </div>
        </div>
        <div class="md:grid md:grid-cols-4 md:gap-4 mb-4">
          <div class="col-span-4">
            <div
              class="
                w-full
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
                md:mb-0
              "
            >
              {{ $t("Último gotero") }}
            </div>
          </div>
          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="lastDropperPH"
            >
              {{ $t("Ph") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="lastDropperPH"
              v-model="lastDropperPH"
              :placeholder="this.$t('Ph')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="lastDropperRedox"
            >
              {{ $t("Redox") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="lastDropperRedox"
              v-model="lastDropperRedox"
              :placeholder="this.$t('Redox')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="lastDropperTemperature"
            >
              {{ $t("Temperatura") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="lastDropperTemperature"
              v-model="lastDropperTemperature"
              :placeholder="this.$t('Temperatura')"
            />
          </div>

          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="lastDropperPPM"
            >
              {{ $t("Ppm") }} <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="lastDropperPPM"
              v-model="lastDropperPPM"
              :placeholder="this.$t('Ppm')"
            />
          </div>
          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="date"
            >
              {{ $t("Fecha") }} <span class="text-bold text-red">*</span>
            </label>
            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              type="date"
              format="dd/MM/kkkk"
              placeholder="dd/mm/yyyy"
              zone="local"
              id="date"
              value-zone="local"
              v-model="date"
            ></datetime>
          </div>
          <div class="col-span-4 sm:col-span-2 lg:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="hour"
            >
              {{ $t("Hora") }} <span class="text-bold text-red">*</span>
            </label>
            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              type="time"
              title="Hora"
              placeholder="HH:MM"
              zone="local"
              id="hour"
              :minute-step="5"
              value-zone="local"
              v-model="hour"
            ></datetime>
          </div>
        </div>
        <div v-if="errorForm" class="bg-red text-white px-4 py-2 mb-4 w-full">
          <strong>{{ $t("Ha ocurrido un error") }}</strong>
          <span class="block ml-2">- {{ $t(error) }}</span>
        </div>
        <div class="flex justify-end mt-4">
          <button
            class="
              inline-flex
              items-center
              px-4
              py-2
              text-white
              bg-orange-500
              leading-5
              font-bold
              rounded-md
              hover:bg-orange-400
            "
            v-on:click="validationForm"
            type="button"
          >
            {{ $t("Añadir lectura") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import HeaderModal from "@/components/HeaderModal";

const ApplicationDetailAddReadingModal = {
  name: "ApplicationDetailAddReadingModal",
  layout: "layout",
  components: {
    datetime: Datetime,
    HeaderModal,
  },
  data: function () {
    return {
      numberReading: "",
      sector: "",
      date: "",
      entryMachinePH: "",
      entryMachineRedox: "",
      entryMachineTemperature: "",
      entryMachinePPM: "",
      exitMachinePH: "",
      exitMachineRedox: "",
      exitMachineTemperature: "",
      exitMachinePPM: "",
      lastDropperPH: "",
      lastDropperRedox: "",
      lastDropperTemperature: "",
      lastDropperPPM: "",
      treatmentId: "",
      hour: "",
      error: "",
      errorForm: false,
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  methods: {
    validationForm() {
      let error = false;

      if (this.numberReading === "") {
        error = true;
      }

      if (this.sector === "") {
        error = true;
      }

      if (this.date === "") {
        error = true;
      }

      if (this.hour === "") {
        error = true;
      }

      if (this.entryMachinePH === "") {
        error = true;
      }

      if (this.entryMachineRedox === "") {
        error = true;
      }

      if (this.entryMachineTemperature === "") {
        error = true;
      }

      if (this.entryMachinePPM === "") {
        error = true;
      }

      if (this.exitMachinePH === "") {
        error = true;
      }

      if (this.exitMachineRedox === "") {
        error = true;
      }

      if (this.exitMachineTemperature === "") {
        error = true;
      }

      if (this.exitMachinePPM === "") {
        error = true;
      }

      if (this.lastDropperPH === "") {
        error = true;
      }

      if (this.lastDropperRedox === "") {
        error = true;
      }

      if (this.lastDropperTemperature === "") {
        error = true;
      }

      if (this.lastDropperPPM === "") {
        error = true;
      }

      if (error !== true) {
        this.saveReading();
      } else {
        this.errorForm = error;
        this.error = "Debe introducir todos los datos de este formulario";
      }
    },
    saveReading() {
      let refReading = db
        .collection("treatments")
        .doc(this.treatmentId)
        .collection("readings")
        .doc();

      refReading
        .set({
          numberReading: this.numberReading,
          sector: this.sector,
          date: this.date,
          entryMachinePH: !isNaN(parseFloat(this.entryMachinePH))
            ? parseFloat(this.entryMachinePH)
            : 0,
          entryMachineRedox: !isNaN(parseFloat(this.entryMachineRedox))
            ? parseFloat(this.entryMachineRedox)
            : 0,
          entryMachineTemperature: !isNaN(
            parseFloat(this.entryMachineTemperature)
          )
            ? parseFloat(this.entryMachineTemperature)
            : 0,
          entryMachinePPM: !isNaN(parseFloat(this.entryMachinePPM))
            ? parseFloat(this.entryMachinePPM)
            : 0,
          exitMachinePH: !isNaN(parseFloat(this.exitMachinePH))
            ? parseFloat(this.exitMachinePH)
            : 0,
          exitMachineRedox: !isNaN(parseFloat(this.exitMachineRedox))
            ? parseFloat(this.exitMachineRedox)
            : 0,
          exitMachineTemperature: !isNaN(
            parseFloat(this.exitMachineTemperature)
          )
            ? parseFloat(this.exitMachineTemperature)
            : 0,
          exitMachinePPM: !isNaN(parseFloat(this.exitMachinePPM))
            ? parseFloat(this.exitMachinePPM)
            : 0,
          lastDropperPH: !isNaN(parseFloat(this.lastDropperPH))
            ? parseFloat(this.lastDropperPH)
            : 0,
          lastDropperRedox: !isNaN(parseFloat(this.lastDropperRedox))
            ? parseFloat(this.lastDropperRedox)
            : 0,
          lastDropperTemperature: !isNaN(
            parseFloat(this.lastDropperTemperature)
          )
            ? parseFloat(this.lastDropperTemperature)
            : 0,
          lastDropperPPM: !isNaN(parseFloat(this.lastDropperPPM))
            ? parseFloat(this.lastDropperPPM)
            : 0,
          hour: this.hour,
          treatmentId: this.treatmentId,
          uid: refReading.id,
        })
        .then(() => {
          this.errorForm = false;
          this.$root.$emit("reloadReadings");
          this.$emit("close");
        })
        .catch(function (error) {
          this.errorForm = true;
          this.error =
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
            error.message;
        });
    },
  },
  beforeMount() {
    if (this.params.lastReading.numberReading) {
      this.numberReading = this.params.lastReading.numberReading + 1;
    } else {
      this.numberReading = 1;
    }

    this.treatmentId = this.params.treatmentId;
  },
};

export default ApplicationDetailAddReadingModal;
</script>
