<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <div class="w-full">
        <div>
          <div class="md:grid md:grid-cols-4 md:gap-4">
            <div class="col-span-4 sm:col-span-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="photo1"
              >
                {{ $t("Foto") }} 1
              </label>
              <p>
                <input
                  v-on:change="uploadPhoto1()"
                  type="file"
                  id="photo1"
                  name="photo1"
                  accept="image/*"
                  class="break-all"
                />
              </p>
              <img v-if="photo1" :src="photo1" class="image" alt="photo 1" />
              <div class="text-sm text-gray-500">{{ $t(photo1Status) }}</div>
            </div>
            <div class="col-span-4 sm:col-span-2 mt-2 sm:mt-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="photo2"
              >
                {{ $t("Foto") }} 2
              </label>
              <p>
                <input
                  v-on:change="uploadPhoto2()"
                  type="file"
                  id="photo2"
                  name="photo2"
                  accept="image/*"
                />
              </p>
              <img v-if="photo2" :src="photo2" class="image" alt="photo2" />
              <div class="text-sm text-gray-500">{{ $t(photo2Status) }}</div>
            </div>
            <div class="col-span-4 sm:col-span-2 mt-2 sm:mt-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="photo3"
              >
                {{ $t("Foto") }} 3
              </label>
              <p>
                <input
                  v-on:change="uploadPhoto3()"
                  type="file"
                  name="photo3"
                  id="photo3"
                  accept="image/*"
                />
              </p>
              <img v-if="photo3" :src="photo3" class="image" alt="photo3" />
              <div class="text-sm text-gray-500">{{ $t(photo3Status) }}</div>
            </div>
            <div class="col-span-4 sm:col-span-2 mt-2 sm:mt-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="photo4"
              >
                {{ $t("Foto") }} 4
              </label>
              <p>
                <input
                  v-on:change="uploadPhoto4()"
                  type="file"
                  name="photo4"
                  id="photo4"
                  accept="image/*"
                />
              </p>
              <img v-if="photo4" :src="photo4" class="image" alt="photo4" />
              <div class="text-sm text-gray-500">{{ $t(photo4Status) }}</div>
            </div>
          </div>
          <div class="flex justify-end mt-4">
            <button
              class="
                inline-flex
                items-center
                px-4
                py-2
                text-white
                leading-5
                font-bold
                rounded-md
              "
              :class="
                disabledButton
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-orange-500 hover:bg-orange-400'
              "
              type="button"
              @click="submit"
              :disabled="disabledButton"
            >
              {{ $t("Añadir") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, firebaseGlobal } from "@/plugins/firebase";
import HeaderModal from "@/components/HeaderModal";

const ApplicationDetailAddPhotoModal = {
  name: "ApplicationDetailAddPhotoModal",
  layout: "layout",
  components: {
    HeaderModal,
  },
  data: function () {
    return {
      photo1: require("@/assets/capture-image.png"),
      photo1Path: "",
      photo1Status: "Estado de la foto",
      photo2: require("@/assets/capture-image.png"),
      photo2Path: "",
      photo2Status: "Estado de la foto",
      photo3: require("@/assets/capture-image.png"),
      photo3Path: "",
      photo3Status: "Estado de la foto",
      photo4: require("@/assets/capture-image.png"),
      photo4Path: "",
      photo4Status: "Estado de la foto",
      checkUpload: false,
      disabledButton: true,
      treatmentPathImages: [],
      treatment: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  methods: {
    uploadPhoto1() {
      let file = document.querySelector("input[name=photo1]").files[0];

      let _this = this;
      let reader = new FileReader();
      reader.onload = function (e) {
        _this.photo1 = e.target.result;
        _this.photo1Status = "Subiendo foto al servidor";

        let storageRef = firebaseGlobal.storage().ref();

        let random =
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2);
        let url =
          "images/treatments/" +
          _this.params.treatment.uid +
          "/" +
          random +
          ".jpg";
        let imageRef = storageRef.child(url);

        imageRef.putString(e.target.result, "data_url").then(function () {
          _this.photo1Path = url;
          _this.photo1Status = "Foto subida correctamente";
          _this.checkUpload = true;
          _this.disabledButton = false;
        });
      };

      reader.onerror = function (error) {
        alert(error);
      };

      reader.readAsDataURL(file);
    },
    uploadPhoto2() {
      let file = document.querySelector("input[name=photo2]").files[0];

      let _this = this;
      let reader = new FileReader();
      reader.onload = function (e) {
        _this.photo2 = e.target.result;
        _this.photo2Status = "Subiendo foto al servidor";

        let storageRef = firebaseGlobal.storage().ref();

        let random =
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2);
        let url =
          "images/treatments/" +
          _this.params.treatment.uid +
          "/" +
          random +
          ".jpg";
        let imageRef = storageRef.child(url);

        imageRef.putString(e.target.result, "data_url").then(function () {
          _this.photo2Path = url;
          _this.photo2Status = "Foto subida correctamente";
          _this.checkUpload = true;
          _this.disabledButton = false;
        });
      };

      reader.onerror = function (error) {
        alert(error);
      };

      reader.readAsDataURL(file);
    },
    uploadPhoto3() {
      let file = document.querySelector("input[name=photo3]").files[0];

      let _this = this;
      let reader = new FileReader();
      reader.onload = function (e) {
        _this.photo3 = e.target.result;
        _this.photo3Status = "Subiendo foto al servidor";

        let storageRef = firebaseGlobal.storage().ref();

        let random =
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2);
        let url =
          "images/treatments/" +
          _this.params.treatment.uid +
          "/" +
          random +
          ".jpg";
        let imageRef = storageRef.child(url);

        imageRef.putString(e.target.result, "data_url").then(function () {
          _this.photo3Path = url;
          _this.photo3Status = "Foto subida correctamente";
          _this.checkUpload = true;
          _this.disabledButton = false;
        });
      };

      reader.onerror = function (error) {
        alert(error);
      };

      reader.readAsDataURL(file);
    },
    uploadPhoto4() {
      let file = document.querySelector("input[name=photo4]").files[0];

      let _this = this;
      let reader = new FileReader();
      reader.onload = function (e) {
        _this.photo4 = e.target.result;
        _this.photo4Status = "Subiendo foto al servidor";

        let storageRef = firebaseGlobal.storage().ref();

        let random =
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2);
        let url =
          "images/treatments/" +
          _this.params.treatment.uid +
          "/" +
          random +
          ".jpg";
        let imageRef = storageRef.child(url);

        imageRef.putString(e.target.result, "data_url").then(function () {
          _this.photo4Path = url;
          _this.photo4Status = "Foto subida correctamente";
          _this.checkUpload = true;
          _this.disabledButton = false;
        });
      };

      reader.onerror = function (error) {
        alert(error);
      };

      reader.readAsDataURL(file);
    },
    submit() {
      let _this = this;

      if (_this.checkUpload) {
        let listPhotos = [];

        if (_this.photo1Path) {
          listPhotos.push(_this.photo1Path);
        }

        if (_this.photo2Path) {
          listPhotos.push(_this.photo2Path);
        }

        if (_this.photo3Path) {
          listPhotos.push(_this.photo3Path);
        }

        if (_this.photo4Path) {
          listPhotos.push(_this.photo4Path);
        }

        let refTreatment = db.collection("treatments").doc(_this.treatment.uid);

        refTreatment
          .update({
            treatmentPathImages: listPhotos,
          })
          .then(() => {
            this.$emit("close");
            this.$root.$emit("reloadDetails");
          })
          .catch(function (error) {
            console.log(
              "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
                error.message
            );
          });
      }
    },
    getPhoto1(photoPath) {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(photoPath)
        .getDownloadURL()
        .then(function (url) {
          _this.photo1 = url;
        })
        .catch(function (error) {});

      return _this.photo1;
    },
    getPhoto2(photoPath) {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(photoPath)
        .getDownloadURL()
        .then(function (url) {
          _this.photo2 = url;
        })
        .catch(function (error) {});

      return _this.photo2;
    },
    getPhoto3(photoPath) {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(photoPath)
        .getDownloadURL()
        .then(function (url) {
          _this.photo3 = url;
        })
        .catch(function (error) {});

      return _this.photo3;
    },
    getPhoto4(photoPath) {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(photoPath)
        .getDownloadURL()
        .then(function (url) {
          _this.photo4 = url;
        })
        .catch(function (error) {});

      return _this.photo4;
    },
  },
  beforeMount() {
    this.treatmentPathImages = this.params.treatment.treatmentPathImages;
    this.treatment = this.params.treatment;

    if (this.treatmentPathImages[0]) {
      this.photo1Path = this.treatmentPathImages[0];
      this.photo1 = this.getPhoto1(this.photo1Path);
    }

    if (this.treatmentPathImages[1]) {
      this.photo2Path = this.treatmentPathImages[1];
      this.photo2 = this.getPhoto2(this.photo2Path);
    }

    if (this.treatmentPathImages[2]) {
      this.photo3Path = this.treatmentPathImages[2];
      this.photo3 = this.getPhoto3(this.photo3Path);
    }

    if (this.treatmentPathImages[3]) {
      this.photo4Path = this.treatmentPathImages[3];
      this.photo4 = this.getPhoto4(this.photo4Path);
    }
  },
};

export default ApplicationDetailAddPhotoModal;
</script>
