var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-modal',{attrs:{"title":_vm.title},on:{"close":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6"},[_c('form',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-wrap mb-6"},[_c('div',{staticClass:"\n            w-full\n            md:w-3/3\n            uppercase\n            text-gray-700 text-xl\n            font-bold\n            mb-4\n            px-4\n          "},[_vm._v(" "+_vm._s(_vm.treatment.farmName)+" ")]),_c('div',{staticClass:"w-full md:w-1/2 text-base px-4"},[_c('label',{staticClass:"\n              block\n              uppercase\n              tracking-wide\n              text-gray-700 text-xs\n              font-bold\n              mb-2\n            "},[_vm._v(" "+_vm._s(_vm.$t("Estatus del tratamiento"))+" ")]),_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"block w-full relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state),expression:"state"}],staticClass:"\n                  block\n                  appearance-none\n                  w-full\n                  border border-gray-400\n                  rounded\n                  px-4\n                  py-3\n                  pr-4\n                  leading-tight\n                ",class:_vm.disabledRol
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-white hover:border-gray-500 cursor-pointer',attrs:{"disabled":_vm.disabledRol},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.state=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.listStatus),function(status){return _c('option',{key:status.id,domProps:{"value":status.value}},[_vm._v(" "+_vm._s(status.name)+" ")])}),0),_c('div',{staticClass:"\n                  pointer-events-none\n                  absolute\n                  inset-y-0\n                  right-0\n                  flex\n                  items-center\n                  px-2\n                  text-gray-700\n                "},[_c('svg',{staticClass:"fill-current h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])])]),_c('button',{staticClass:"\n                w-full\n                ml-4\n                text-sm\n                font-semibold\n                text-white\n                py-2\n                px-4\n                rounded\n              ",class:_vm.disabledRol
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-yellow hover:bg-greenlight',attrs:{"type":"button","disabled":_vm.disabledRol},on:{"click":_vm.checkStatus}},[_vm._v(" "+_vm._s(_vm.$t("Guardar status"))+" ")])])]),_c('div',{staticClass:"w-full md:w-3/3 text-gray-800 text-base font-bold my-4 px-4"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.$t("Fecha de inicio"))+":")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.treatment.agrozonoDate))+" ")]),_c('div',{staticClass:"w-full flex mb-2 hidden"},[_c('div',{staticClass:"\n              w-full\n              md:w-3/5\n              text-gray-800 text-base\n              px-4\n              flex\n              items-end\n            "},[_c('div',[_c('p',{staticClass:"uppercase text-gray-700 text-xl font-bold mb-4"},[_vm._v(" "+_vm._s(_vm.treatment.farmName)+" ")]),_c('p',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.$t("Fecha de inicio"))+":")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.treatment.agrozonoDate))+" ")])])]),_c('div',{staticClass:"w-full md:w-2/5 text-base px-4 md:px-0"},[_c('label',{staticClass:"\n                block\n                uppercase\n                tracking-wide\n                text-gray-700 text-xs\n                font-bold\n                mb-2\n              "},[_vm._v(" "+_vm._s(_vm.$t("Estatus del tratamiento"))+" ")]),_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"block w-full relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state),expression:"state"}],staticClass:"\n                    block\n                    appearance-none\n                    w-full\n                    border border-gray-400\n                    rounded\n                    px-4\n                    py-3\n                    pr-4\n                    leading-tight\n                  ",class:_vm.disabledRol
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-white hover:border-gray-500 cursor-pointer',attrs:{"disabled":_vm.disabledRol},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.state=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.listStatus),function(status){return _c('option',{key:status.id,domProps:{"value":status.value}},[_vm._v(" "+_vm._s(status.name)+" ")])}),0),_c('div',{staticClass:"\n                    pointer-events-none\n                    absolute\n                    inset-y-0\n                    right-0\n                    flex\n                    items-center\n                    px-2\n                    text-gray-700\n                  "},[_c('svg',{staticClass:"fill-current h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])])]),_c('button',{staticClass:"\n                  w-full\n                  ml-4\n                  text-sm\n                  font-semibold\n                  text-white\n                  py-2\n                  px-4\n                  rounded\n                ",class:_vm.disabledRol
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-yellow hover:bg-greenlight',attrs:{"type":"button","disabled":_vm.disabledRol},on:{"click":_vm.checkStatus}},[_vm._v(" "+_vm._s(_vm.$t("Guardar status"))+" ")])])])]),_c('table',{staticClass:"table-auto w-full text-left"},[_c('thead',[_c('tr',[_c('th',{staticClass:"px-4 py-2 text-gray-800"},[_c('div',{staticClass:"inline-block mr-2"},[_vm._v(" "+_vm._s(_vm.$t("Tratamientos"))+" ("+_vm._s(_vm.$t("Total"))+" "+_vm._s(_vm.totalTreatments)+") ")])]),_c('th',{staticClass:"px-4 py-2 text-gray-800"},[_c('div',{staticClass:"inline-block mr-2"},[_vm._v(_vm._s(_vm.$t("Estado")))])]),_c('th',{staticClass:"px-4 py-2 text-gray-800"},[_c('div',{staticClass:"inline-block mr-2"},[_vm._v(" "+_vm._s(_vm.$t("Fecha de inoculación estimada"))+" ")])]),_c('th',{staticClass:"px-4 py-2 text-gray-800"},[_c('div',{staticClass:"inline-block mr-2"},[_vm._v(_vm._s(_vm.$t("Verificado")))])]),_c('th',{staticClass:"px-4 py-2 text-gray-800"})])]),(_vm.protocolReminders.length && _vm.listSteps)?_c('tbody',_vm._l((_vm.listSteps),function(step,index){return _c('treatment-step',{key:index,staticClass:"my-6",attrs:{"step":step,"treatment":_vm.treatment,"protocolReminders":_vm.protocolReminders,"index":index},on:{"eventEditStep":_vm.editSteps}})}),1):_vm._e()])]),_c('div',{staticClass:"mx-2"},[(_vm.errorForm)?_c('div',{staticClass:"bg-red text-white px-4 py-2 w-full"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Ha ocurrido un error")))]),_c('span',{staticClass:"block ml-2"},[_vm._v("- "+_vm._s(_vm.messageError))])]):_vm._e(),(_vm.messageConfirm)?_c('div',{staticClass:"bg-greenlight text-white px-4 py-2 w-full"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Excelente")))]),_c('span',{staticClass:"block sm:inline ml-2"},[_vm._v(_vm._s(_vm.$t(_vm.mensajeExito)))])]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }