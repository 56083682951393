<template>
  <div class="relative inline-block">
    <button
      @click="open = true"
      class="
        inline-flex
        items-center
        sm:pl-6
        py-2
        font-semibold
        text-white
        focus:outline-none
      "
    >
      <label class="uppercase text-gray-700">
        {{ businessAreaActive.name }}
      </label>
      <svg
        class="ml-2 h-6 w-6 text-gray-700"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
        />
      </svg>
    </button>
    <div
      v-if="open"
      @click="open = false"
      class="fixed inset-0"
      tabindex="-1"
    ></div>
    <transition
      enter-class="opacity-0 scale-90"
      enter-active-class="ease-out transition duration-150"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-active-class="ease-in transition duration-150"
      leave-to-class="opacity-0 scale-90"
    >
      <div v-if="open" class="absolute z-20 text-left">
        <div class="w-64 bg-white rounded-lg shadow-lg">
          <div class="py-1">
            <a
              v-for="business in businessAreas"
              :key="business.uid"
              @click="setBusiness(business)"
              :class="
                businessAreaActive.name === business.name
                  ? 'bg-gray-600 text-white hover:bg-gray-500'
                  : 'hover:bg-gray-200'
              "
              class="block px-6 py-3 leading-tight"
              >{{ business.name }}</a
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      businessAreaActive: {},
      businessAreas: [],
    };
  },
  props: {
    selected: {
      required: false,
    },
  },
  methods: {
    setBusiness(business) {
      this.businessAreaActive = business;
      sessionStorage.setItem("businessAreaActive", JSON.stringify(business));
      this.open = false;
      Event.fire("reloadBusinessAreaActive");
      Event.fire("checkBusinessArea");
      Event.fire("reloadList");
      Event.fire("reloadListCustomersAndLeads");
      Event.fire("reloadTasks");
    },
    getBusinessAreas() {
      this.businessAreas = JSON.parse(this.selected);

      let businessArea = this.businessAreas.sort(function (a, b) {
        const nameA = a.name;
        const nameB = b.name;

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      if (businessArea.length > 1) {
        businessArea.forEach((element) => {
          if (element.name.toString().indexOf("Agro3") !== -1) {
            this.businessAreaActive = element;
            sessionStorage.setItem(
              "businessAreaActive",
              JSON.stringify(element)
            );
            this.open = false;
            Event.fire("reloadBusinessAreaActive");
          }
        });
      }

      if (businessArea.length === 1) {
        businessArea.forEach((element) => {
          this.businessAreaActive = element;
          sessionStorage.setItem("businessAreaActive", JSON.stringify(element));
          this.open = false;
          Event.fire("reloadBusinessAreaActive");
        });
      }
    },
  },
  mounted() {
    this.getBusinessAreas();
  },
};
</script>
