<template>
  <div class="h-screen flex justify-center items-center bg-graylogin">
    <div class="w-full max-w-sm">
      <div
        class="
          bg-greenlight
          px-5
          py-3
          text-white
          font-bold
          rounded rounded-b-none
        "
      >
        {{ $t("Recuperar contraseña") }}
      </div>
      <form
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        @submit.prevent="onSubmit"
      >
        <div
          v-if="errorMessage !== ''"
          class="bg-red text-white px-4 py-2 mb-4"
        >
          <strong>{{ $t("Ha ocurrido un error") }}</strong>
          <span class="block sm:inline ml-2">{{ errorMessage }}</span>
        </div>

        <div
          v-if="successMessage !== ''"
          class="bg-greenlight text-white px-4 py-2 mb-4"
        >
          <strong>{{ $t("Excelente") }}</strong>
          <span class="block sm:inline ml-2">{{ successMessage }}</span>
        </div>

        <div v-if="step === 2" class="bg-yellow text-white px-4 py-2 mb-4">
          <span class="block sm:inline ml-2">{{
            $t(
              "Excelente. Hemos enviado un email a su correo electronico con un codigo para continuar la recuperacion de su contraseña. por favor, ingreselo en los campos de abajo para continuar"
            )
          }}</span>
        </div>

        <div class="mb-4" :class="{ hidden: step === 2 }">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
            {{ $t("Email") }}
          </label>
          <input
            class="
              shadow
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-gray-700
              leading-tight
              focus:outline-none focus:shadow-outline
            "
            id="email"
            type="text"
            :placeholder="this.$t('Email')"
            v-model="email"
          />
        </div>

        <div class="flex justify-between" :class="{ hidden: step === 2 }">
          <button
            class="
              bg-yellow
              hover:bg-greenlight
              text-white
              font-bold
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="submit"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Recuperar") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>

          <router-link
            to="/login"
            class="
              inline-block
              self-center
              text-right
              font-bold
              text-sm text-blue-500
              hover:text-blue-800
            "
          >
            {{ $t("Regresar") }}
          </router-link>
        </div>

        <div class="flex justify-between" :class="{ hidden: step === 1 }">
          <router-link
            to="/login"
            class="
              inline-block
              self-center
              text-right
              font-bold
              text-sm text-blue-500
              hover:text-blue-800
            "
          >
            {{ $t("Regresar") }}
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { firebaseGlobal } from "@/plugins/firebase.js";

const Recovery = {
  layout: "",
  name: "recovery",
  metaInfo: {
    title: "Recuperar contraseña",
  },
  data: function () {
    return {
      dataLoaded: true,
      successMessage: "",
      errorMessage: "",
      step: 1,
      email: "",
      password: "",
    };
  },
  methods: {
    onSubmit() {
      this.dataLoaded = false;
      this.successMessage = "";
      this.errorMessage = "";

      if (this.step !== 1 || this.email === "") {
        this.dataLoaded = true;
        this.errorMessage =
          "Debe llenar correctamente todos los campos para poder recuperar su contraseña";

        return;
      }

      let _this = this;
      firebaseGlobal
        .auth()
        .sendPasswordResetEmail(_this.email)
        .then(function () {
          _this.dataLoaded = true;
          _this.step = 2;
        })
        .catch(function (error) {
          let errorMessage = error.message;

          _this.dataLoaded = true;
          _this.errorMessage =
            "Ha ocurrido un error al intentar recuperar su contraseña. Error: " +
            errorMessage;
        });
    },
  },
};

export default Recovery;
</script>
