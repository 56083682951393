<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Área comercial") }}
    </div>

    <loader v-if="datosCargados === false"></loader>

    <div v-if="datosCargados === true">
      <div
        v-if="
          usuarioRol === 'appSuperAdministrators' ||
          usuarioRol === 'appAdministrators' ||
          usuarioRol === 'comTechniciansResp' ||
          usuarioRol === 'comTechnicians'
        "
      >
        <div class="sm:flex">
          <div class="block relative mr-4 w-full sm:w-64 mb-3 sm:mb-0">
            <label>
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                @change="filtrarRegistros"
                v-model="mostrar"
              >
                <option value="Todos">{{ $t("Todos los roles") }}</option>
                <option
                  value="delegations"
                  v-if="usuarioRol === 'appSuperAdministrators'"
                >
                  {{ $t("Delegaciones") }}
                </option>
                <option
                  value="distributions"
                  v-if="
                    usuarioRol === 'appSuperAdministrators' ||
                    usuarioRol === 'appAdministrators'
                  "
                >
                  {{ $t("Distribuidores") }}
                </option>
                <option
                  value="comTechnicians"
                  v-if="
                    usuarioRol === 'appSuperAdministrators' ||
                    usuarioRol === 'comTechnicians' ||
                    usuarioRol === 'comTechniciansResp' ||
                    usuarioRol === 'appAdministrators'
                  "
                >
                  {{ $t("Comerciales") }}
                </option>
                <option
                  value="comTechniciansResp"
                  v-if="
                    usuarioRol === 'appSuperAdministrators' ||
                    usuarioRol === 'comTechniciansResp'
                  "
                >
                  {{ $t("Responsables comerciales") }}
                </option>
              </select>
            </label>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>

          <div class="flex items-center">
            <label>
              <input
                class="
                  w-40
                  appearance-none
                  block
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Buscar')"
                v-model="buscar"
              />
            </label>

            <button
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              v-if="
                usuarioRol === 'appSuperAdministrators' ||
                usuarioRol === 'appAdministrators'
              "
              type="button"
              @click="agregarRegistro"
            >
              {{ $t("Agregar usuario") }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Nombre") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Delegación") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Correo electrónico") }}
                    </div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Roles usuario") }}
                    </div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Estado") }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="usuario in usuariosFiltrados" :key="usuario.id">
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div
                      v-on:click="editarRegistro(usuario.id, usuario.rol_type)"
                    >
                      <span
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{
                          usuario.nombre !== "" ? usuario.nombre : usuario.email
                        }}</span
                      >
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-4">
                    {{ usuario.delegation }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div :class="{ hidden: usuario.rol === 'Delegación' }">
                      {{ usuario.email }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    {{ usuario.rol }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    {{ usuario.status }}
                  </td>
                </tr>

                <tr v-if="usuariosFiltrados.length === 0">
                  <td class="border px-4 py-3" colspan="5">
                    {{
                      $t(
                        "No hay usuarios del área comercial registrados en el sistema"
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import AreaComercialAddPage from "@/pages/area-comercial/AreaComercialAddPage";
import AreaComercialEditPage from "@/pages/area-comercial/AreaComercialEditPage";

const AreaComercial = {
  layout: "layout",
  name: "AreaComercial",
  metaInfo: {
    title: "Área comercial",
  },
  components: {
    Zondicon,
    Loader,
  },
  data() {
    return {
      datosCargados: false,
      usuarios: [],
      mostrar: "Todos",
      buscar: "",
      usuarioRol: "",
      usuarioActual: "",
    };
  },
  methods: {
    filtrarRegistros() {
      this.datosCargados = false;
      this.buscar = "";

      this.usuarios = [];

      if (this.mostrar === "Todos" || this.mostrar === "delegations") {
        this.datosCargados = false;

        db.collection("delegations")
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = [];
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["delegation"] = "";
              arrayTemp["email"] = doc.data().mail;
              arrayTemp["status"] = doc.data().deleted ? "Inactivo" : "Activo";
              arrayTemp["rol"] = "Delegación";
              arrayTemp["rol_type"] = "delegations";

              if (_this.usuarioRol === "appSuperAdministrators") {
                _this.usuarios.push(arrayTemp);
              } else {
                if (
                  _this.usuarioRol === "delegations" &&
                  _this.usuarioActual === doc.id
                ) {
                  _this.usuarios.push(arrayTemp);
                }
              }
            });

            this.datosCargados = true;
          });
      }

      if (this.mostrar === "Todos" || this.mostrar === "distributions") {
        this.datosCargados = false;

        db.collection("distributors")
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = doc.data();
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["delegation"] = doc.data().delegationName;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["status"] = doc.data().deleted ? "Inactivo" : "Activo";
              arrayTemp["rol"] = "Distribuidor";
              arrayTemp["rol_type"] = "distributors";

              if (
                _this.usuarioRol === "appSuperAdministrators" ||
                _this.usuarioRol === "appAdministrators"
              ) {
                _this.usuarios.push(arrayTemp);
              } else {
                if (
                  _this.usuarioRol === "distributors" &&
                  _this.usuarioActual === doc.id
                ) {
                  _this.usuarios.push(arrayTemp);
                }
                if (
                  _this.usuarioRol === "delegations" &&
                  _this.usuarioActual === doc.data().delegationId
                ) {
                  _this.usuarios.push(arrayTemp);
                }
              }
            });

            this.datosCargados = true;
          });
      }

      if (this.mostrar === "Todos" || this.mostrar === "comTechniciansResp") {
        this.datosCargados = false;

        db.collection("comTechniciansResp")
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = doc.data();
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["delegation"] = doc.data().delegationName;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["status"] = doc.data().deleted ? "Inactivo" : "Activo";
              arrayTemp["rol"] = "Resp Comercial";
              arrayTemp["rol_type"] = "comTechniciansResp";

              if (_this.usuarioRol === "appSuperAdministrators") {
                _this.usuarios.push(arrayTemp);
              } else {
                if (
                  _this.usuarioRol === "comTechniciansResp" &&
                  _this.usuarioActual === doc.id
                ) {
                  _this.usuarios.push(arrayTemp);
                }
                if (
                  _this.usuarioRol === "comTechnicians" &&
                  _this.usuarioActual === doc.id
                ) {
                  _this.usuarios.push(arrayTemp);
                }
                if (_this.usuarioRol === "distributors") {
                  doc.data().distributorIds.forEach((element) => {
                    console.log(element + " vs " + _this.usuarioActual);

                    if (element === _this.usuarioActual) {
                      _this.usuarios.push(arrayTemp);
                    }
                  });
                }
              }
            });

            this.datosCargados = true;
          });
      }

      if (this.mostrar === "Todos" || this.mostrar === "comTechnicians") {
        this.datosCargados = false;

        db.collection("comTechnicians")
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = doc.data();
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["telefono"] = doc.data().telephone;
              arrayTemp["delegation"] = doc.data().delegationName;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["rol"] = "Comercial";
              arrayTemp["status"] = doc.data().deleted ? "Inactivo" : "Activo";
              arrayTemp["rol_type"] = "comTechnicians";

              if (
                _this.usuarioRol === "appSuperAdministrators" ||
                _this.usuarioRol === "appAdministrators"
              ) {
                _this.usuarios.push(arrayTemp);
              } else {
                if (
                  _this.usuarioRol === "comTechnicians" &&
                  _this.usuarioActual === doc.id
                ) {
                  _this.usuarios.push(arrayTemp);
                }
                if (_this.usuarioRol === "distributors") {
                  doc.data().distributorIds.forEach((element) => {
                    console.log(element + " vs " + _this.usuarioActual);

                    if (element === _this.usuarioActual) {
                      _this.usuarios.push(arrayTemp);
                    }
                  });
                }
              }
            });

            this.datosCargados = true;
          });
      }
    },
    agregarRegistro() {
      this.$modal.show(AreaComercialAddPage, {
        title: this.$t("Agregar usuario"),
        params: null,
      });
    },
    editarRegistro(id, rolType) {
      this.$modal.show(AreaComercialEditPage, {
        title: this.$t("Editar usuario"),
        params: {
          id: id,
          rolType: rolType,
        },
      });
    },
  },
  computed: {
    usuariosFiltrados() {
      let arrayFiltrado = [];
      this.usuarios.forEach((element) => {
        if (this.buscar === "") {
          arrayFiltrado.push(element);
        } else {
          if (
            element.nombre !== undefined &&
            element.telefono !== undefined &&
            element.email !== undefined
          ) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.buscar.toLowerCase()) !== -1 ||
              element.telefono
                .toString()
                .toLowerCase()
                .indexOf(this.buscar.toLowerCase()) !== -1 ||
              element.email
                .toString()
                .toLowerCase()
                .indexOf(this.buscar.toLowerCase()) !== -1
            ) {
              arrayFiltrado.push(element);
            }
          } else if (
            element.nombre !== undefined &&
            element.email !== undefined
          ) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.buscar.toLowerCase()) !== -1 ||
              element.email
                .toString()
                .toLowerCase()
                .indexOf(this.buscar.toLowerCase()) !== -1
            ) {
              arrayFiltrado.push(element);
            }
          } else if (element.nombre !== undefined) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.buscar.toLowerCase()) !== -1
            ) {
              arrayFiltrado.push(element);
            }
          }
        }
      });

      // PARA ORDENAR EL ARREGLO DE USUARIOS
      arrayFiltrado = arrayFiltrado.sort(this.compareName);

      return arrayFiltrado;
    },
  },
  created() {
    this.$root.$on("reloadListCommercial", () => {
      this.filtrarRegistros();
    });
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.usuarioRol = sessionStorage.getItem("user_rol");
    this.usuarioActual = sessionStorage.getItem("user_id");

    if (this.usuarioRol === "appAdminstrators") {
      this.mostrar = "Todos";
    }

    if (this.usuarioRol === "delegations") {
      this.mostrar = "Todos";
    }

    if (this.usuarioRol === "distributors") {
      this.mostrar = "Todos";
    }

    if (this.usuarioRol === "customers") {
      this.mostrar = "Clientes";
    }

    if (this.usuarioRol === "leads") {
      this.mostrar = "Leads";
    }

    this.filtrarRegistros();
  },
};

export default AreaComercial;
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: #bbcccc29;
}
</style>
