<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="text-2xl font-semibold text-gray-800 p-6">
      <button
        class="
          bg-gray-200
          mr-2
          relative
          border border-gray-200
          text-xs
          font-semibold
          hover:bg-white
          text-gray-700
          pb-1
          px-3
          rounded
          focus:outline-none focus:shadow-outline
        "
        style="top: -2px"
        type="button"
        v-on:click="volverListadoDeliveryNotes"
      >
        <Zondicon
          icon="arrow-thin-left"
          class="
            fill-current
            inline-block
            h-3
            text-gray-700
            cursor-pointer
            hover:text-gray-700
          "
        />
      </button>

      <div
        class="
          inline-block
          uppercase
          tracking-wide
          text-gray-700 text-xs
          font-bold
          mb-4
          px-3
        "
      >
        {{ farmName }}
      </div>
      <div
        class="
          inline-block
          uppercase
          tracking-wide
          text-gray-700 text-xs
          font-bold
          mb-4
          px-3
        "
      >
        {{ params.id }}
      </div>
    </div>

    <div
      v-if="registroEliminado === false"
      class="flex flex-row items-center mb-20 xl:mb-4 p-6"
    >
      <form class="w-full">
        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-full px-3 mb-3 text-right">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Fecha") }}:
            </label>
            <span class="text-md">{{ dateFormated }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3 text-right">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Albarán ref") }}:
            </label>
            <span class="text-md">{{ numRef }}</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Cliente") }}
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Nombre") }}:
            </label>
            <span class="text-md">{{ customerName }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Teléfono") }}:
            </label>
            <span class="text-md">{{ customerPhone }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Email") }}:
            </label>
            <span class="text-md">{{ customerEmail }}</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-4
              mb-4
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Distribuidor") }}
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Nombre") }}:
            </label>
            <span class="text-md">{{ distributorName }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Dirección") }}:
            </label>
            <span class="text-md">{{ distributorAddress }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Cif") }}:
            </label>
            <span class="text-md">{{ distributorCIF }}</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-4
              mb-4
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Tratamiento") }}
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Nombre") }}:
            </label>
            <span class="text-md">{{ tratamientoName }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Técnico aplicador") }}:
            </label>
            <span class="text-md">{{ tratamientoTecnicoAplicador }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Ubicación parcela") }}:
            </label>
            <span class="text-md">{{ tratamientoFincaDireccion }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Tipo de cultivo") }}:
            </label>
            <span class="text-md">{{ tratamientoTipoCultivo }}</span>
          </div>

          <div class="w-full md:w-1/4 px-3 mb-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Inicio tto") }}:
            </label>
            <span class="text-md">{{ tratamientoInicio }}</span>
          </div>

          <div class="w-full md:w-1/4 px-3 mb-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Fin tto") }}:
            </label>
            <span class="text-md">{{ tratamientoFin }}</span>
          </div>

          <div class="w-full md:w-2/4 px-3 mb-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Superficie tratada") }}:
            </label>
            <span class="text-md">{{ tratamientoSuperficie }}</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-4
              mb-0
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Aplicación de productos system") }}
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System basic") }}:
            </label>
            <span class="text-md">{{ sysbas }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 1:
            </label>
            <span class="text-md">{{ sys1 }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 2:
            </label>
            <span class="text-md">{{ sys2 }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 2.1:
            </label>
            <span class="text-md">{{ sys2_1 }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 3:
            </label>
            <span class="text-md">{{ sys3 }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 4:
            </label>
            <span class="text-md">{{ sys4 }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 5:
            </label>
            <span class="text-md">{{ sys5 }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 6:
            </label>
            <span class="text-md">{{ sys6 }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 6.1:
            </label>
            <span class="text-md">{{ sys6_1 }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 7:
            </label>
            <span class="text-md">{{ sys7 }}</span>
          </div>

          <div
            class="
              w-full
              md:w-1/2
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-5
              mb-0
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Cliente") }}
          </div>
          <div
            class="
              w-full
              md:w-1/2
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-5
              mb-0
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Técnico") }}
          </div>

          <div class="w-full md:w-1/2 px-3 border-gray-400 border p-2">
            <img
              v-if="customerSignature"
              v-bind:src="customerSignature"
              class="image"
              alt="customer signature"
            />
          </div>

          <div class="w-full md:w-1/2 px-3 border-gray-400 border p-2">
            <img
              v-if="techSignature"
              v-bind:src="techSignature"
              class="image"
              alt="tech signature"
            />
          </div>

          <div class="w-full md:w-full px-3 mt-4 p-2 text-center">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Ejemplar para el cliente") }}
            </label>
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="mensajeError !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(mensajeError) }}</span>
          </div>

          <div
            v-if="mensajeExito !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(mensajeExito) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="enviarDeliveryNote"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Enviar") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: datosCargados === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { firebaseGlobal } from "@/plugins/firebase.js";
import { db } from "@/plugins/firebase.js";
import { DateTime } from "luxon";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import TratamientosDeliveryNotesPage from "@/pages/tratamientos/TratamientosDeliveryNotesPage";
import HeaderModal from "@/components/HeaderModal";

const TratamientosDeliveryNotesEdit = {
  name: "TratamientosDeliveryNotesEdit",
  layout: "layout",
  components: {
    Zondicon,
    Loader,
    HeaderModal,
  },
  data: function () {
    return {
      datosCargados: true,
      mensajeExito: "",
      mensajeError: "",
      registroEliminado: false,

      dateFormated: "",
      ref: "",
      farmName: "",
      customerName: "",
      customerPhone: "",
      customerEmail: "",
      distributorName: "",
      distributorAddress: "",
      distributorCIF: "",
      tratamientoName: "",
      tratamientoTecnicoAplicador: "",
      tratamientoFincaDireccion: "",
      tratamientoTipoCultivo: "",
      tratamientoInicio: "",
      tratamientoFin: "",
      tratamientoSuperficie: "",
      sysbas: "",
      sys1: "",
      sys2: "",
      sys2_1: "",
      sys3: "",
      sys4: "",
      sys5: "",
      sys6: "",
      sys6_1: "",
      sys7: "",
      customerSignature: "",
      customerSignaturePath: "",
      techSignature: "",
      techSignaturePath: "",
      usuarioRol: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  methods: {
    volverListadoDeliveryNotes: function () {
      this.$emit("close");
      this.$modal.show(TratamientosDeliveryNotesPage, {
        title: this.$t("Albaranes de la finca"),
        params: {
          treatmentId: this.params.treatmentId,
        },
      });
    },
    enviarDeliveryNote: function () {
      this.datosCargados = false;

      let _this = this;
      let sendDeliveryNoteIndividual = firebaseGlobal
        .functions()
        .httpsCallable("sendDeliveryNoteIndividual");
      sendDeliveryNoteIndividual({
        treatmentId: _this.params.treatmentId,
        deliveryNoteId: _this.params.id,
      })
        .then(() => {
          this.volverListadoDeliveryNotes();
          this.datosCargados = true;
        })
        .catch((error) => {
          console.log(error);

          this.datosCargados = true;
        });
    },
    compareName(a, b) {
      const nameA = a.hours;
      const nameB = b.hours;

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.usuarioRol = sessionStorage.getItem("user_rol");

    this.datosCargados = false;

    let _this = this;

    await db
      .collection("treatments")
      .doc(_this.params.treatmentId)
      .get()
      .then(async function (doc) {
        _this.farmName = doc.data().farmName;

        await db
          .collection("treatments")
          .doc(_this.params.treatmentId)
          .collection("deliveryNotes")
          .doc(_this.params.id)
          .get()
          .then((snapshot) => {
            let date = DateTime.fromISO(snapshot.data().date).toFormat(
              "dd/MM/kkkk hh:mm:ss"
            );
            let initTime = DateTime.fromISO(
              snapshot.data().initTimeTreatment
            ).toFormat("hh:mm");
            let finishTime = DateTime.fromISO(
              snapshot.data().finishTimeTreatment
            ).toFormat("hh:mm");

            _this.dateFormated = date;
            _this.numRef = snapshot.data().numRef;

            _this.customerName = snapshot.data().customerName;
            _this.customerPhone = snapshot.data().customerTelf;
            _this.customerEmail = snapshot.data().customerEmail;

            _this.distributorName = snapshot.data().distributorName;
            _this.distributorAddress = snapshot.data().distributorAddress;
            _this.distributorCIF = snapshot.data().distributorCif;

            _this.tratamientoName = snapshot.data().inspectionProtocolName;
            _this.tratamientoFincaDireccion = snapshot.data().farmAddress;
            _this.tratamientoTecnicoAplicador = snapshot.data().appTechName;
            _this.tratamientoTipoCultivo = snapshot.data().cultivation;
            _this.tratamientoInicio = initTime;
            _this.tratamientoFin = finishTime;
            _this.tratamientoSuperficie = snapshot.data().surface;

            _this.sysbas = snapshot.data().sysbas;
            _this.sys1 = snapshot.data().system1;
            _this.sys2 = snapshot.data().system2;
            _this.sys2_1 = snapshot.data().system2_1;
            _this.sys3 = snapshot.data().system3;
            _this.sys4 = snapshot.data().system4;
            _this.sys5 = snapshot.data().system5;
            _this.sys6 = snapshot.data().system6;
            _this.sys6_1 = snapshot.data().system6_1;
            _this.sys7 = snapshot.data().system7;

            _this.customerSignaturePath = snapshot.data().customerSignaturePath;
            _this.techSignaturePath = snapshot.data().techSignaturePath;

            let storageRef = firebaseGlobal.storage().ref();

            if (_this.customerSignaturePath !== "") {
              storageRef
                .child(_this.customerSignaturePath)
                .getDownloadURL()
                .then(function (url) {
                  _this.customerSignature = url;
                })
                .catch(function (error) {});
            }

            if (_this.techSignaturePath !== "") {
              storageRef
                .child(_this.techSignaturePath)
                .getDownloadURL()
                .then(function (url) {
                  _this.techSignature = url;
                })
                .catch(function (error) {});
            }

            _this.datosCargados = true;
          });
      });
  },
};

export default TratamientosDeliveryNotesEdit;
</script>
