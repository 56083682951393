import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/auth'
import { modoApp } from '@/plugins/mode.js'

// Get a Firestore instance

const mode = modoApp
let configParams = {}

if(mode === "development") {
    configParams = {
        apiKey: "AIzaSyCtdo9BLNh_J1_29moJwLPFN4lBxO3E63Q",
        authDomain: "dev-agrozono-empdev.firebaseapp.com",
        databaseURL: "https://dev-agrozono-empdev.firebaseio.com",
        projectId: "dev-agrozono-empdev",
        storageBucket: "dev-agrozono-empdev.appspot.com",
        messagingSenderId: "607829217695",
        appId: "1:607829217695:web:ce5b381a150432a1872daa",
        measurementId: "G-NC0XR8WQSP"
    }
}

if(mode === "stagging") {
    configParams = {
        apiKey: "AIzaSyAYdZcrW0TAbRlG-LZW6BhDtAnafns58NQ",
        authDomain: "agrozono-sta-cd995.firebaseapp.com",
        databaseURL: "https://agrozono-sta-cd995.firebaseio.com",
        projectId: "agrozono-sta-cd995",
        storageBucket: "agrozono-sta-cd995.appspot.com",
        messagingSenderId: "1027882932704",
        appId: "1:1027882932704:web:28fed2dab159c3f7e37385",
        measurementId: "G-VPPLEKGZKS"
    }
}

if(mode === "production") {
    configParams = {
        apiKey: "AIzaSyBAcT-NGSYWWxzouPFIbbWHK2Or1J6yFSA",
        authDomain: "agrozono-6e32d.firebaseapp.com",
        databaseURL: "https://agrozono-6e32d.firebaseio.com",
        projectId: "agrozono-6e32d",
        storageBucket: "agrozono-6e32d.appspot.com",
        messagingSenderId: "520408844627",
        appId: "1:520408844627:web:2c2b6a365296a88379b9a6"
    }
}

firebase.initializeApp(configParams)

export const firebaseGlobal = firebase
export const db = firebase.firestore()
