<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-md my-6">
    <ul>
      <li>
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div
            class="
              -ml-4
              -mt-4
              flex
              justify-end
              items-center
              flex-wrap
              sm:flex-no-wrap
            "
          >
            <div class="ml-4 mt-4 flex-shrink-0 flex">
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  v-if="
                    userRol === 'appSuperAdministrators' ||
                    userRol === 'respTechnicians'
                  "
                  type="button"
                  class="
                    relative
                    inline-flex
                    items-center
                    px-4
                    py-2
                    leading-5
                    font-bold
                    rounded-md
                    text-redagrozono
                    bg-white
                    border border-redagrozono
                    hover:text-white hover:bg-redagrozono
                  "
                  @click="deleteInspection"
                >
                  <span>{{ $t("Eliminar") }}</span>
                </button>
                <button
                  v-if="
                    userRol === 'appSuperAdministrators' ||
                    userRol === 'respTechnicians'
                  "
                  type="button"
                  class="
                    relative
                    inline-flex
                    items-center
                    px-4
                    py-2
                    leading-5
                    font-bold
                    rounded-md
                    text-white
                    bg-orange-500
                    hover:bg-orange-400
                    focus:outline-none
                    ml-4
                  "
                  @click="openModal"
                >
                  <span>{{ $t("Asignar") }}</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div
              class="
                min-w-0
                flex-1
                px-4
                md:grid md:grid-cols-2
                xl:grid-cols-3
                md:gap-4
              "
            >
              <div>
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Fecha inspección") }}:
                  <span class="text-gray-900 font-semibold">{{
                    inspection.inspectionDate | formatDate
                  }}</span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Fecha pedida") }}:
                  <span class="text-gray-900 font-semibold">{{
                    inspection.ozoneDate | formatDate
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="border-t border-gray-200">
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
              <div>
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Provincia") }}:
                  <span class="text-gray-900 font-semibold">{{
                    inspection.farm.province
                  }}</span>
                </div>
                <div class="mt-2 leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Localidad") }}:
                  <span class="text-gray-900 font-semibold">{{
                    inspection.farm.city
                  }}</span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("País") }}:
                  <span class="text-gray-900 font-semibold">{{
                    $t("España")
                  }}</span>
                </div>
                <div class="mt-2 leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Delegación") }}:
                  <span class="text-gray-900 font-semibold">{{
                    inspection.farm.delegationName
                  }}</span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Comercial") }}:
                  <span class="text-gray-900 font-semibold">{{
                    inspection.farm.comTechnicianName
                  }}</span>
                </div>
                <div class="mt-2 leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Distribuidor") }}:
                  <span class="text-gray-900 font-semibold">{{
                    inspection.farm.distributorName
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="border-t border-gray-200">
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
              <div>
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Finca") }}:
                  <span
                    @click="openFarmModal"
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                  >
                    {{ inspection.farm.name }}
                  </span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Cliente") }}:
                  <span
                    @click="openClientModal"
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                  >
                    {{ inspection.customerName }}</span
                  >
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Inspección") }}:
                  <span
                    @click="openInspectionModal"
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                  >
                    {{ inspection.protocolName }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase";
import ApplicationByAssignModal from "@/pages/ApplicationByAssign/ApplicationByAssignModal";
import ApplicationAssignmentFarmModal from "@/pages/ApplicationAssignment/ApplicationAssignmentFarmModal";
import ApplicationAssignmentCustomerModal from "@/pages/ApplicationAssignment/ApplicationAssignmentCustomerModal";
import ApplicationAssignmentInspectionModal from "@/pages/ApplicationAssignment/ApplicationAssignmentInspectionModal";

const ApplicationAssignmentTreatment = {
  name: "applicationAssignmentTreatment",
  components: {
    ApplicationByAssignModal,
  },
  data() {
    return {
      farm: [],
      userRol: "",
      userPresent: "",
    };
  },
  props: {
    inspection: {
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$modal.show(ApplicationByAssignModal, {
        title: this.$t("Asignar"),
        params: { inspection: this.inspection },
      });
    },
    openFarmModal() {
      this.$modal.show(ApplicationAssignmentFarmModal, {
        title: this.$t("Detalle finca"),
        params: { farm: this.inspection.farm },
      });
    },
    openClientModal() {
      this.$modal.show(ApplicationAssignmentCustomerModal, {
        title: this.$t("Detalle cliente"),
        params: { customerID: this.inspection.customerId },
      });
    },
    openInspectionModal() {
      this.$modal.show(ApplicationAssignmentInspectionModal, {
        title: this.$t("Detalle inspección"),
        params: { inspection: this.inspection },
      });
    },
    deleteInspection() {
      if (
        confirm(
          this.$t("¿Realmente deseas eliminar la inspección?") +
            ' "' +
            this.inspection.id +
            '"?'
        )
      ) {
        let refInspection = db
          .collection("inspections")
          .doc(this.inspection.id);

        refInspection
          .update({
            deleted: true,
          })
          .then(() => {
            this.$root.$emit("reloadListApplicationByAssign");
          })
          .catch(function (error) {
            console.log(
              "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
                error.message
            );
          });
      }
    },
  },
  async beforeMount() {
    this.userRol = sessionStorage.getItem("user_rol");
    this.userPresent = sessionStorage.getItem("user_id");
  },
};

export default ApplicationAssignmentTreatment;
</script>
