<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <div class="w-full">
        <div class="grid grid-cols-6 gap-4 mb-2">
          <div
            class="
              col-span-6
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              md:px-2
            "
          >
            {{ $t("Datos a rellenar") }}
          </div>
          <div class="col-span-6 sm:col-span-2 md:px-2 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="agrozonoDate"
            >
              {{ $t("Fecha tarea") }}
              <span class="text-bold text-red">*</span>
            </label>
            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              type="date"
              format="dd/MM/kkkk"
              placeholder="dd/mm/yyyy"
              zone="local"
              id="agrozonoDate"
              value-zone="local"
              v-model="agrozonoDate"
            ></datetime>
          </div>
          <div class="col-span-6 sm:col-span-4 md:px-2 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="title_task"
            >
              {{ $t("Título") }}
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="title_task"
              :placeholder="this.$t('Título')"
              v-model="title_task"
            />
          </div>
          <div class="col-span-6 md:px-2 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="description"
            >
              {{ $t("Descripción") }}
              <span class="text-bold text-red">*</span>
            </label>
            <textarea
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              :placeholder="this.$t('Descripción')"
              id="description"
              v-model="description"
            ></textarea>
          </div>
        </div>
        <div v-if="errorForm" class="bg-red text-white px-4 py-2 mb-4 w-full">
          <strong>{{ $t("Ha ocurrido un error") }}</strong>
          <span class="block ml-2">- {{ messageError }}</span>
        </div>
        <div class="flex justify-end mt-4">
          <button
            class="
              inline-flex
              items-center
              px-4
              py-2
              text-white
              leading-5
              font-bold
              rounded-md
              bg-orange-500
              hover:bg-orange-400
            "
            type="button"
            @click="submit"
          >
            {{ $t("Agregar tarea") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import moment from "moment";
import HeaderModal from "@/components/HeaderModal";

export default {
  name: "TaskAddModal",
  layout: "layout",
  components: {
    datetime: Datetime,
    HeaderModal,
  },
  data() {
    return {
      agrozonoDate: "",
      title_task: "",
      description: "",
      businessArea: "",
      task: "Otras tareas",
      status: "Realizado",
      userName: "",
      messageError: "",
      errorForm: false,
    };
  },
  props: {
    title: {
      require: true,
    },
    params: {
      required: false,
    },
  },
  methods: {
    submit() {
      this.messageError = this.$t(
        "Debe introducir todos los datos de este formulario"
      );
      let error = false;

      if (this.agrozonoDate === "") {
        error = true;
      }

      if (this.title_task === "") {
        error = true;
      }

      if (this.description === "") {
        error = true;
      }

      let dateToday = new Date();
      dateToday =
        dateToday.getFullYear() +
        "/" +
        (dateToday.getMonth() + 1) +
        "/" +
        dateToday.getDate();
      let dateAgrozono = moment(String(this.agrozonoDate)).format("YYYY/MM/DD");

      if (new Date(dateAgrozono) > new Date(dateToday)) {
        error = true;
        this.messageError = this.$t(
          "La fecha introducida no puede ser superior a la fecha actual"
        );
      }

      if (error !== true) {
        this.errorForm = false;
        this.createTask();
      } else {
        this.errorForm = error;
      }
    },
    createTask() {
      let _this = this;
      let refTask = db.collection("tasks").doc();

      refTask
        .set({
          userName: this.userName,
          userId: sessionStorage.getItem("user_id"),
          userRol: sessionStorage.getItem("user_rol"),
          businessAreaId: JSON.parse(
            sessionStorage.getItem("businessAreaActive")
          ).uid,
          businessAreaName: JSON.parse(
            sessionStorage.getItem("businessAreaActive")
          ).name,
          deleted: false,
          task: "Otras tareas",
          status: "Realizado",
          createdDate: _this.agrozonoDate,
          title: _this.title_task,
          description: _this.description,
          uid: refTask.id,
        })
        .then(() => {
          this.$emit("close");
          this.$root.$emit("reloadListTask");
        })
        .catch(function (error) {
          this.errorForm = true;
          this.messageError =
            this.$t(
              "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
            ) + error.message;
        });
    },
  },
  beforeMount() {
    db.collection(sessionStorage.getItem("user_rol"))
      .doc(sessionStorage.getItem("user_id"))
      .get()
      .then((snapshot) => {
        this.userName = snapshot.data().name;
      });
  },
};
</script>
