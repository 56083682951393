<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Tareas") }}
    </div>

    <loader v-if="dataLoaded === false"></loader>

    <div v-if="dataLoaded === true">
      <div class="grid grid-cols-6 gap-4 w-full 2xl:w-4/5">
        <div class="col-span-6 md:col-span-1">
          <label
            class="block uppercase tracking-wide text-xs font-bold mb-2"
            for="date_from"
          >
            {{ $t("Desde") }}
          </label>
          <datetime
            input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="date"
            format="dd/MM/kkkk"
            placeholder="dd/mm/yyyy"
            zone="local"
            id="date_from"
            value-zone="local"
            v-model="date_from"
          ></datetime>
        </div>
        <div class="col-span-6 md:col-span-1">
          <label
            class="block uppercase tracking-wide text-xs font-bold mb-2"
            for="date_to"
          >
            {{ $t("Hasta") }}
          </label>
          <datetime
            input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="date"
            format="dd/MM/kkkk"
            placeholder="dd/mm/yyyy"
            zone="local"
            id="date_to"
            value-zone="local"
            v-model="date_to"
          ></datetime>
        </div>
        <div
          class="col-span-6 md:col-span-1 flex items-center"
          v-if="buttonResetDate"
        >
          <button
            class="
              w-full
              sm:mt-4
              mb-4
              sm:mb-0
              text-sm
              font-semibold
              text-blueagrozono
              border border-blueagrozono
              hover:text-white hover:bg-blueagrozono
              py-3
              px-4
              rounded
            "
            type="button"
            @click="resetDate"
          >
            {{ $t("Resetear fecha") }}
          </button>
        </div>
        <div
          class="col-span-6 md:col-span-3 flex items-center"
          v-if="errorDate"
        >
          <div
            class="
              bg-red
              border-l-4 border-redagrozono
              p-3
              mb-6
              sm:mt-4
              mb-4
              md:mb-0
            "
          >
            <div class="flex text-redagrozono">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-base text-redagrozono">
                  La fecha "hasta" debe ser superior a la fecha "desde"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-6 gap-4 w-full">
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
        >
          <label>
            <select
              class="
                block
                appearance-none
                w-full
                bg-white
                border border-gray-400
                rounded
                hover:border-gray-500
                px-4
                py-3
                pr-4
                leading-tight
                focus:outline-none focus:shadow-outline
              "
              v-model="rol"
            >
              <option value="all">{{ $t("Todos los roles") }}</option>
              <option
                value="comTechnicians"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'comTechniciansAdmin' ||
                  userRol === 'comTechnicians' ||
                  userRol === 'comTechniciansResp'
                "
              >
                {{ $t("Comercial") }}
              </option>
              <option
                value="distributors"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'comTechniciansAdmin' ||
                  userRol === 'distributors' ||
                  userRol === 'comTechniciansResp' ||
                  userRol === 'comTechnicians'
                "
              >
                {{ $t("Distribuidor") }}
              </option>
              <option
                value="comTechniciansResp"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'comTechniciansAdmin' ||
                  userRol === 'comTechniciansResp'
                "
              >
                {{ $t("Resp. comercial") }}
              </option>
              <option
                value="respTechnicians"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'respTechnicians'
                "
              >
                {{ $t("Resp. técnico") }}
              </option>
              <option
                value="appTechnicians"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'appTechnicians' ||
                  userRol === 'respTechnicians'
                "
              >
                {{ $t("Técnico") }}
              </option>
            </select>
          </label>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
              text-gray-700
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
        >
          <label>
            <select
              class="
                block
                appearance-none
                w-full
                bg-white
                border border-gray-400
                rounded
                hover:border-gray-500
                px-4
                py-3
                pr-4
                leading-tight
                focus:outline-none focus:shadow-outline
              "
              v-model="user"
            >
              <option value="all">{{ $t("Todos los usuarios") }}</option>
              <option
                v-for="user in orderUsersSelect"
                v-bind:key="user.uid"
                :value="user.uid"
              >
                {{ user.name }}
                <span v-if="user.rol === 'distributors'">(Distribuidor)</span>
                <span v-if="user.rol === 'respTechnicians'"
                  >(Resp. técnico)</span
                >
                <span v-if="user.rol === 'appTechnicians'">(Técnico)</span>
                <span v-if="user.rol === 'comTechnicians'">(Comercial)</span>
                <span v-if="user.rol === 'comTechniciansResp'"
                  >(Resp. comercial)</span
                >
              </option>
            </select>
          </label>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
              text-gray-700
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
          v-if="
            userRol === 'appSuperAdministrators' ||
            userRol === 'comTechniciansAdmin' ||
            userRol === 'comTechnicians' ||
            userRol === 'comTechniciansResp' ||
            userRol === 'distributors'
          "
        >
          <label>
            <select
              class="
                block
                appearance-none
                w-full
                border border-gray-400
                rounded
                px-4
                py-3
                pr-4
                leading-tight
              "
              v-model="customer"
              :class="
                user === 'all'
                  ? 'text-white bg-gray-400 cursor-not-allowed'
                  : 'text-black bg-white hover:border-gray-500 focus:outline-none focus:shadow-outline'
              "
              :disabled="user === 'all'"
            >
              <option value="all">{{ $t("Todos los clientes/leads") }}</option>
              <option
                v-for="customer in orderCustomersAndLeadsSelect"
                v-bind:key="customer.uid"
                :value="customer.uid"
              >
                {{ customer.name }}
              </option>
            </select>
          </label>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
              text-gray-700
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
          v-if="
            userRol === 'appSuperAdministrators' ||
            userRol === 'comTechniciansAdmin' ||
            userRol === 'comTechnicians' ||
            userRol === 'comTechniciansResp' ||
            userRol === 'distributors'
          "
        >
          <label>
            <select
              class="
                block
                appearance-none
                w-full
                border border-gray-400
                rounded
                px-4
                py-3
                pr-4
                leading-tight
              "
              v-model="farm"
              :class="
                customer === 'all'
                  ? 'text-white bg-gray-400 cursor-not-allowed'
                  : 'text-black bg-white hover:border-gray-500 focus:outline-none focus:shadow-outline'
              "
              :disabled="customer === 'all'"
            >
              <option value="all">{{ $t("Todas las fincas") }}</option>
              <option
                v-for="farm in orderFarmCustomers"
                v-bind:key="farm.id"
                :value="farm.id"
              >
                {{ farm.name }}
              </option>
            </select>
          </label>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
              text-gray-700
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
        >
          <label>
            <select
              class="
                block
                appearance-none
                w-full
                bg-white
                border border-gray-400
                rounded
                hover:border-gray-500
                px-4
                py-3
                pr-4
                leading-tight
                focus:outline-none focus:shadow-outline
              "
              v-model="tasks"
            >
              <option value="all">{{ $t("Todas las tareas") }}</option>
              <option
                value="contactCustomer"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'comTechniciansAdmin' ||
                  userRol === 'comTechnicians' ||
                  userRol === 'comTechniciansResp' ||
                  userRol === 'distributors'
                "
              >
                {{ $t("Contacto cliente") }}
              </option>
              <option
                value="contactLead"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'comTechniciansAdmin' ||
                  userRol === 'comTechnicians' ||
                  userRol === 'comTechniciansResp' ||
                  userRol === 'distributors'
                "
              >
                {{ $t("Contacto lead") }}
              </option>
              <option
                value="newCustomer"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'comTechniciansAdmin' ||
                  userRol === 'comTechnicians' ||
                  userRol === 'comTechniciansResp' ||
                  userRol === 'distributors'
                "
              >
                {{ $t("Nuevo cliente") }}
              </option>
              <option
                value="newLead"
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'comTechniciansAdmin' ||
                  userRol === 'comTechnicians' ||
                  userRol === 'comTechniciansResp' ||
                  userRol === 'distributors'
                "
              >
                {{ $t("Nuevo lead") }}
              </option>
              <option
                value="newInspection"
                v-if="
                  (userRol === 'appSuperAdministrators' ||
                    userRol === 'comTechniciansAdmin' ||
                    userRol === 'respTechnicians' ||
                    userRol === 'comTechnicians' ||
                    userRol === 'distributors' ||
                    userRol === 'comTechniciansResp') &&
                  businessAreaName === 'Agro3'
                "
              >
                {{ $t("Nueva inspección") }}
              </option>
              <option
                value="reminder"
                v-if="
                  (userRol === 'appSuperAdministrators' ||
                    userRol === 'comTechniciansAdmin' ||
                    userRol === 'comTechnicians' ||
                    userRol === 'distributors' ||
                    userRol === 'comTechniciansResp') &&
                  businessAreaName === 'Agro3'
                "
              >
                {{ $t("Recordatorio de inoculación") }}
              </option>
              <option
                value="newTreatment"
                v-if="
                  (userRol === 'appSuperAdministrators' ||
                    userRol === 'appTechnicians' ||
                    userRol === 'respTechnicians') &&
                  businessAreaName === 'Agro3'
                "
              >
                {{ $t("Nuevo tratamiento") }}
              </option>
              <option value="tasks">{{ $t("Otras tareas") }}</option>
            </select>
          </label>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
              text-gray-700
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
        >
          <label>
            <select
              class="
                block
                appearance-none
                w-full
                bg-white
                border border-gray-400
                rounded
                hover:border-gray-500
                px-4
                py-3
                pr-4
                leading-tight
                focus:outline-none focus:shadow-outline
              "
              v-model="state"
            >
              <option value="all">{{ $t("Todos los estados") }}</option>
              <option value="Realizado">{{ $t("Realizado") }}</option>
              <option value="Pendiente">{{ $t("Pendiente") }}</option>
            </select>
          </label>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
              text-gray-700
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
          v-if="
            userRol === 'comTechnicians' ||
            userRol === 'comTechniciansResp' ||
            userRol === 'distributors' ||
            userRol === 'appTechnicians' ||
            userRol === 'respTechnicians'
          "
        >
          <button
            class="
              bg-orange-500
              w-full
              mr-2
              text-sm
              font-semibold
              hover:bg-orange-400
              text-white
              py-3
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            @click="openAddTaskModal"
          >
            {{ $t("Agregar tarea") }}
          </button>
        </div>
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
        >
          <vue-excel-xlsx
            class="
              w-full
              mr-2
              text-sm
              font-semibold
              text-blueagrozono
              border border-blueagrozono
              hover:text-white hover:bg-blueagrozono
              py-3
              px-4
              rounded
            "
            :data="excelData"
            :columns="columns"
            filename="tareas"
            sheetname="tareas"
          >
            {{ $t("Exportar listado") }}
          </vue-excel-xlsx>
        </div>
        <div
          class="block relative w-full col-span-6 md:col-span-2 lg:col-span-1"
        >
          <vue-excel-xlsx
            :disabled="!date_from && !date_to"
            :class="
              date_from && date_to
                ? 'text-blueagrozono border-blueagrozono hover:text-white hover:bg-blueagrozono'
                : 'text-white bg-gray-400 cursor-not-allowed border-none'
            "
            class="w-full mr-2 text-sm font-semibold border py-3 px-4 rounded"
            :data="exportDataDate"
            :columns="exportColumnsDate"
            :filename="nameExport"
            sheetname="tareas"
          >
            {{ $t("Exportar informe") }}
          </vue-excel-xlsx>
        </div>
      </div>
      <div id="tasks">
        <task-column
          class="my-6"
          v-for="(groupTask, index) in groupTaskList"
          :groupTask="groupTask"
          :date="index"
          :key="index"
        ></task-column>
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "@/plugins/Utilities";
import Loader from "@/components/Loader";
import { Datetime } from "vue-datetime";
import { db } from "@/plugins/firebase";
import { orderBy, chain } from "lodash";
import { DateTime as luxon } from "luxon";
import TaskColumn from "@/pages/Tasks/TaskColumn";
import TaskAddModal from "@/pages/Tasks/TaskAddModal";

export default {
  layout: "layout",
  name: "TaskList",
  metaInfo: {
    title: "Tareas",
  },
  components: {
    Loader,
    datetime: Datetime,
    TaskColumn,
  },
  data() {
    return {
      state: "all",
      user: "all",
      customer: "all",
      farm: "all",
      rol: "all",
      tasks: "all",
      date_to: "",
      date_from: "",
      date_today: "",
      dataLoaded: true,
      userRol: "",
      userId: "",
      businessAreaName: "",
      errorDate: false,
      delegationId: "",
      listUsers: [],
      listTasks: [],
      listCustomerAndLeads: [],
      listFarms: [],
      columns: [
        {
          label: this.$t("Fecha real"),
          field: "date",
        },
        {
          label: this.$t("Fecha creación"),
          field: "dateCreated",
        },
        {
          label: this.$t("Comercial nombre"),
          field: "userName",
        },
        {
          label: this.$t("Roles usuario"),
          field: "rol",
        },
        {
          label: this.$t("Tareas"),
          field: "task",
        },
        {
          label: this.$t("Estado"),
          field: "status",
        },
        {
          label: this.$t("Máquina"),
          field: "machineName",
        },
        {
          label: this.$t("País"),
          field: "country",
        },
        {
          label: this.$t("Provincia"),
          field: "province",
        },
        {
          label: this.$t("Cliente"),
          field: "customerName",
        },
        {
          label: this.$t("Finca"),
          field: "farmName",
        },
        {
          label: this.$t("Descripción"),
          field: "description",
        },
      ],
    };
  },
  watch: {
    businessAreaName: async function (val) {
      if (val === "Agro3" || val === "TO3") {
        this.listTasks = [];
        this.listUsers = [];
        this.state = "all";
        this.user = "all";
        this.rol = "all";
        this.tasks = "all";
        await this.getUsersSelect();
      }
    },
    user: async function () {
      this.customer = "all";
    },
    customer: async function (val) {
      this.farm = "all";

      if (val !== "all") {
        this.getFarms(val);
      }
    },
  },
  computed: {
    nameExport() {
      let dateTo = "";
      let dateFrom = "";
      if (this.date_to && this.date_from) {
        dateTo = luxon.fromISO(this.date_to).toFormat("dd/MM/yyyy");
        dateFrom = luxon.fromISO(this.date_from).toFormat("dd/MM/yyyy");
      }

      return (
        dateFrom + "-" + dateTo + "-" + sessionStorage.getItem("user_name")
      );
    },
    orderUsersSelect() {
      let _this = this;
      let userOrder = _this.listUsers;

      if (_this.rol !== "all") {
        userOrder = userOrder.filter(function (element) {
          if (_this.rol === element.rol) {
            return element;
          }
        });
      }

      return orderBy(userOrder, ["rol", "name"], ["asc", "asc"]);
    },
    orderCustomersAndLeadsSelect() {
      let _this = this;
      let customerOrders = _this.listCustomerAndLeads;

      if (_this.user !== "all") {
        customerOrders = customerOrders.filter(function (element) {
          if (!element.distributorId) {
            if (_this.user === element.comTechnicianId) {
              return element;
            }
          }

          if (
            _this.user === element.comTechnicianId ||
            _this.user === element.distributorId
          ) {
            return element;
          }
        });
      }

      return orderBy(customerOrders, ["rol", "name"], ["asc", "asc"]);
    },
    orderFarmCustomers() {
      return orderBy(this.listFarms, ["rol", "name"], ["asc", "asc"]);
    },
    buttonResetDate() {
      return !!(this.date_to && this.date_from);
    },
    groupTaskListOrder() {
      let _this = this;
      _this.errorDate = false;
      let tasksOrder = _this.listTasks;

      tasksOrder.sort(function (a, b) {
        const nameA = luxon.fromFormat(a.date, "d-MM-y").toISO();
        const nameB = luxon.fromFormat(b.date, "d-MM-y").toISO();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = -1;
        } else if (nameA < nameB) {
          comparison = 1;
        }
        return comparison;
      });

      if (_this.date_to && _this.date_from) {
        if (_this.date_to > _this.date_from) {
          tasksOrder = tasksOrder.filter(function (element) {
            if (
              luxon.fromFormat(element.date, "d-MM-y").toISO() >=
                _this.date_from &&
              luxon.fromFormat(element.date, "d-MM-y").toISO() <= _this.date_to
            ) {
              return element;
            }
          });
        } else {
          _this.errorDate = true;
        }
      }

      if (_this.user !== "all" && _this.customer === "all") {
        tasksOrder = tasksOrder.filter(function (element) {
          if (_this.user === element.userId) {
            return element;
          }
        });
      }

      if (_this.customer !== "all") {
        tasksOrder = tasksOrder.filter(function (element) {
          if (_this.customer === element.customerId) {
            return element;
          }
        });
      }

      if (_this.farm !== "all") {
        tasksOrder = tasksOrder.filter(function (element) {
          if (_this.farm === element.farmId) {
            return element;
          }
        });
      }

      if (_this.rol !== "all") {
        tasksOrder = tasksOrder.filter(function (element) {
          if (_this.rol === element.userRol) {
            return element;
          }
        });
      }

      if (_this.tasks !== "all") {
        tasksOrder = tasksOrder.filter(function (element) {
          if (_this.tasks === element.taskType) {
            return element;
          }
        });
      }

      if (_this.state !== "all") {
        tasksOrder = tasksOrder.filter(function (element) {
          if (_this.state === element.status) {
            return element;
          }
        });
      }

      return tasksOrder;
    },
    groupTaskList() {
      let tasksOrder = this.groupTaskListOrder;

      return chain(tasksOrder).groupBy("date").value();
    },
    excelData() {
      let tasks = this.groupTaskListOrder;
      return tasks.map((element) => {
        let rol = "";

        if (element.userRol === "comTechnicians") {
          rol = this.$t("Comercial");
        }

        if (element.userRol === "comTechniciansResp") {
          rol = this.$t("Resp. comercial");
        }

        if (element.userRol === "distributors") {
          rol = this.$t("Distribuidor");
        }

        if (element.userRol === "appTechnicians") {
          rol = this.$t("Técnico");
        }

        if (element.userRol === "respTechnicians") {
          rol = this.$t("Resp. técnico");
        }

        return {
          date: element.date,
          dateCreated: element.dateCreated ? element.dateCreated : "",
          userName: element.userName,
          rol: rol,
          task: this.$t(element.task),
          status: this.$t(element.status),
          machineName: element.machineName,
          country: this.$t(element.country),
          province: element.province,
          customerName: element.customerName,
          farmName: element.farmName,
          description:
            element.taskType !== "reminder"
              ? element.description
              : this.$t(element.description, {
                  step: element.step,
                  totalSteps: element.totalSteps,
                  days: element.days,
                }),
        };
      });
    },
    exportColumnsDate() {
      let columns = [
        {
          label: this.$t("Cliente"),
          field: "clientName",
        },
      ];
      let dateArray = [];

      if (this.date_from && this.date_to) {
        dateArray = this.getDatesBetweenDates(
          new Date(this.date_from),
          new Date(this.date_to)
        );
      }

      dateArray.forEach(async function (date) {
        columns.push({
          label: luxon
            .fromJSDate(date)
            .setLocale(localStorage.getItem("user_locale"))
            .toFormat("ccc dd-MM-yyyy")
            .toUpperCase(),
          field: luxon.fromJSDate(date).toFormat("dd-MM-yyyy"),
        });
      });

      return columns;
    },
    exportDataDate() {
      let tasks = this.groupTaskListOrder;
      let groupByCustomerName = chain(tasks).groupBy("customerName").value();

      return Object.keys(groupByCustomerName).map((objectKey) => {
        let columns = this.exportColumnsDate;

        let columnData = {};

        columns.forEach((column) => {
          if (column.field === "clientName") {
            columnData = {
              clientName:
                objectKey !== "undefined" || objectKey !== undefined
                  ? objectKey
                  : "No asociado a cliente",
            };
          }

          if (column.field !== "clientName") {
            let values = [];

            groupByCustomerName[objectKey].map((item) => {
              if (column.field === item.date) {
                let description =
                  item.taskType !== "reminder"
                    ? item.description
                    : this.$t(item.description, {
                        step: item.step,
                        totalSteps: item.totalSteps,
                        days: item.days,
                      });

                let farmName =
                  item.farmName !== undefined
                    ? " - Finca: " + item.farmName
                    : "";

                values.push(
                  this.$t(item.task).toUpperCase() +
                    farmName +
                    " - " +
                    description
                );
              }
            });

            columnData[column.field] = values.join(" - ");
          }
        });

        return columnData;
      });
    },
  },
  methods: {
    async getFarms(val) {
      let _this = this;
      _this.listFarms = [];
      await db
        .collection("customers")
        .doc(val)
        .collection("farms")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.listFarms.push(doc.data());
          });
        });
    },
    async getCustomerAndLeads() {
      let _this = this;

      await db
        .collection("customers")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.listCustomerAndLeads.push(doc.data());
          });
        });

      await db
        .collection("leads")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.listCustomerAndLeads.push(doc.data());
          });
        });
    },
    openAddTaskModal: function () {
      this.$modal.show(TaskAddModal, {
        title: this.$t("Agregar tarea"),
        params: null,
      });
    },
    resetDate() {
      this.date_to = "";
      this.date_from = "";
    },
    async getUsersSelect() {
      let _this = this;

      if (
        this.userRol === "appSuperAdministrators" ||
        this.userRol === "comTechniciansAdmin" ||
        this.userRol === "appAdministrators"
      ) {
        // comerciales
        await db
          .collection("comTechnicians")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "comTechnicians";
              _this.listUsers.push(arrayTemp);
            });
          });

        // responsables comerciales
        await db
          .collection("comTechniciansResp")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "comTechniciansResp";
              _this.listUsers.push(arrayTemp);
            });
          });

        // distribuidor
        await db
          .collection("distributors")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "distributors";
              _this.listUsers.push(arrayTemp);
            });
          });

        if (this.userRol !== "comTechniciansAdmin") {
          // tecnico
          await db
            .collection("appTechnicians")
            .where("deleted", "==", false)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(function (doc) {
                let arrayTemp = {};
                arrayTemp["name"] = doc.data().name;
                arrayTemp["uid"] = doc.data().uid;
                arrayTemp["rol"] = "appTechnicians";
                _this.listUsers.push(arrayTemp);
              });
            });

          // responsable tecnico
          await db
            .collection("respTechnicians")
            .where("deleted", "==", false)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(function (doc) {
                let arrayTemp = {};
                arrayTemp["name"] = doc.data().name;
                arrayTemp["uid"] = doc.data().uid;
                arrayTemp["rol"] = "respTechnicians";
                _this.listUsers.push(arrayTemp);
              });
            });
        }
      }

      if (this.userRol === "comTechniciansResp") {
        // responsables comerciales
        await db
          .collection("comTechniciansResp")
          .where("deleted", "==", false)
          .where("uid", "==", this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "comTechniciansResp";
              _this.listUsers.push(arrayTemp);
            });
          });

        // comerciales
        await db
          .collection("comTechnicians")
          .where("deleted", "==", false)
          .where("responsableId", "==", this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "comTechnicians";
              _this.listUsers.push(arrayTemp);

              db.collection("distributors")
                .where("deleted", "==", false)
                .where("comercialId", "==", doc.data().uid)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach(function (element) {
                    let arrayTemp = {};
                    arrayTemp["name"] = element.data().name;
                    arrayTemp["uid"] = element.data().uid;
                    arrayTemp["rol"] = "distributors";
                    _this.listUsers.push(arrayTemp);
                  });
                });
            });
          });
      }

      if (this.userRol === "comTechnicians") {
        // comerciales
        await db
          .collection("comTechnicians")
          .where("deleted", "==", false)
          .where("uid", "==", this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "comTechnicians";
              _this.listUsers.push(arrayTemp);
            });
          });

        // distribuidor por comercialId
        await db
          .collection("distributors")
          .where("comercialId", "==", this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "distributors";
              _this.listUsers.push(arrayTemp);
            });
          });
      }

      if (this.userRol === "distributors") {
        // distribuidor por el id
        await db
          .collection("distributors")
          .where("uid", "==", this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "distributors";
              _this.listUsers.push(arrayTemp);
            });
          });
      }

      if (this.userRol === "respTechnicians") {
        // responsable tecnico
        await db
          .collection("respTechnicians")
          .where("deleted", "==", false)
          .where("uid", "==", this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "respTechnicians";
              _this.delegationId = doc.data().delegation_id;
              _this.listUsers.push(arrayTemp);
            });

            // tecnico
            db.collection("appTechnicians")
              .where("deleted", "==", false)
              .where("delegationId", "==", _this.delegationId)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                  let arrayTemp = {};
                  arrayTemp["name"] = doc.data().name;
                  arrayTemp["uid"] = doc.data().uid;
                  arrayTemp["rol"] = "appTechnicians";
                  _this.listUsers.push(arrayTemp);
                });
              });
          });
      }

      if (this.userRol === "appTechnicians") {
        // tecnico
        await db
          .collection("appTechnicians")
          .where("uid", "==", this.userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "appTechnicians";
              _this.listUsers.push(arrayTemp);
            });
          });
      }

      _this.getTasks();
    },
    getTasks() {
      let _this = this;
      _this.listTasks = [];
      if (
        _this.userRol === "appSuperAdministrators" ||
        _this.userRol === "appAdministrators" ||
        _this.userRol === "comTechniciansAdmin"
      ) {
        _this.getTasksAdmin();
      }

      if (_this.userRol === "appTechnicians") {
        _this.getTaskTechnicians(_this.userId);
      }

      if (_this.userRol === "respTechnicians") {
        _this.getTaskRespTechnicians(_this.userId);

        let appTechnicians = [];
        db.collection("appTechnicians")
          .where("deleted", "==", false)
          .where("delegationId", "==", _this.delegationId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let arrayTemp = {};
              arrayTemp["name"] = doc.data().name;
              arrayTemp["uid"] = doc.data().uid;
              arrayTemp["rol"] = "appTechnicians";
              appTechnicians.push(arrayTemp);
            });
            appTechnicians.forEach((technician) => {
              _this.getTaskTechnicians(technician.uid);
            });
          });
      }

      if (_this.userRol === "distributors") {
        _this.getTaskDistributors(_this.userId);
      }

      if (_this.userRol === "comTechnicians") {
        _this.getTaskComTechnicians(_this.userId);
        let distributors = this.listUsers.filter(function (element) {
          if (element.rol === "distributors") {
            return element;
          }
        });
        distributors.forEach((distributor) => {
          _this.getTaskDistributors(distributor.uid);
        });
      }

      if (_this.userRol === "comTechniciansResp") {
        _this.getTaskComTechniciansResp(_this.userId);

        let comTechnician = _this.listUsers.filter(function (element) {
          if (element.rol === "comTechnicians") {
            return element;
          }
        });

        comTechnician.forEach((technician) => {
          db.collection("distributors")
            .where("comercialId", "==", technician.uid)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(function (doc) {
                _this.getTaskDistributors(doc.data().uid);
              });
            });
          _this.getTaskComTechnicians(technician.uid);
        });
      }
    },
    async getTasksAdmin() {
      this.dataLoaded = false;
      let _this = this;
      _this.listTasks = [];

      // Contacto con cliente
      await db
        .collection("customers")
        .where("observaciones", "!=", [])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let observationLength = doc.data().observaciones.length - 1;
            let sumObservation = 0;
            doc.data().observaciones.forEach((observation) => {
              if (
                JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
                  observation.businessAreaId &&
                doc.data().comTechnicianId
              ) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().comTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (user) {
                      userRol = user.data().rol;
                    });

                    let createdDate = luxon
                      .fromFormat(observation.date_observation, "d/MM/y")
                      .toISO();

                    if (!createdDate) {
                      createdDate = luxon
                        .fromFormat(observation.date_observation, "d/M/y")
                        .toISO();
                    }

                    let arrayTemp = {};

                    if (
                      sumObservation === observationLength &&
                      observation.date_reminder
                    ) {
                      arrayTemp["status"] = "Pendiente";
                    } else {
                      sumObservation++;
                      arrayTemp["status"] = "Realizado";
                    }

                    if (!doc.data().distributorId) {
                      arrayTemp["userId"] = doc.data().comTechnicianId;
                      arrayTemp["userName"] = doc.data().comTechnicianName;
                      arrayTemp["userRol"] = userRol;
                    }

                    if (doc.data().distributorId) {
                      arrayTemp["userId"] = doc.data().distributorId;
                      arrayTemp["userName"] = doc.data().distributorName;
                      arrayTemp["userRol"] = "distributors";
                    }

                    arrayTemp["date"] = luxon
                      .fromISO(createdDate)
                      .toFormat("dd-MM-yyyy");

                    let dateCreated = luxon
                      .fromFormat(observation.date_created, "d/MM/y")
                      .toISO();

                    if (!dateCreated) {
                      dateCreated = luxon
                        .fromFormat(observation.date_created, "d/M/y")
                        .toISO();
                    }

                    arrayTemp["dateCreated"] = luxon
                      .fromISO(dateCreated)
                      .toFormat("dd-MM-yyyy");

                    arrayTemp["task"] = "Contacto cliente";
                    arrayTemp["taskType"] = "contactCustomer";
                    if (doc.data().country) {
                      arrayTemp["country"] = _this.capitalizeFirstLetter(
                        doc.data().country.trim().toLowerCase()
                      );
                    }
                    arrayTemp["province"] = doc.data().province;
                    arrayTemp["description"] = observation.observation;
                    arrayTemp["customerId"] = doc.data().uid;
                    arrayTemp["customerName"] = doc.data().name;
                    arrayTemp["customerType"] = "customer";

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
        });

      // contacto con lead
      await db
        .collection("leads")
        .where("observaciones", "!=", [])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let observationLength = doc.data().observaciones.length - 1;
            let sumObservation = 0;
            doc.data().observaciones.forEach((observation) => {
              if (
                JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
                  observation.businessAreaId &&
                doc.data().comTechnicianId
              ) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().comTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (user) {
                      userRol = user.data().rol;
                    });

                    let createdDate = luxon
                      .fromFormat(observation.date_observation, "d/MM/y")
                      .toISO();

                    if (!createdDate) {
                      createdDate = luxon
                        .fromFormat(observation.date_observation, "d/M/y")
                        .toISO();
                    }

                    let arrayTemp = {};

                    if (
                      sumObservation === observationLength &&
                      observation.date_reminder
                    ) {
                      arrayTemp["status"] = "Pendiente";
                    } else {
                      sumObservation++;
                      arrayTemp["status"] = "Realizado";
                    }

                    if (!doc.data().distributorId) {
                      arrayTemp["userId"] = doc.data().comTechnicianId;
                      arrayTemp["userName"] = doc.data().comTechnicianName;
                      arrayTemp["userRol"] = userRol;
                    }

                    if (doc.data().distributorId) {
                      arrayTemp["userId"] = doc.data().distributorId;
                      arrayTemp["userName"] = doc.data().distributorName;
                      arrayTemp["userRol"] = "distributors";
                    }

                    arrayTemp["date"] = luxon
                      .fromISO(createdDate)
                      .toFormat("dd-MM-yyyy");

                    let dateCreated = luxon
                      .fromFormat(observation.date_created, "d/MM/y")
                      .toISO();

                    if (!dateCreated) {
                      dateCreated = luxon
                        .fromFormat(observation.date_created, "d/M/y")
                        .toISO();
                    }

                    arrayTemp["dateCreated"] = luxon
                      .fromISO(dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["task"] = "Contacto lead";
                    arrayTemp["taskType"] = "contactLead";
                    if (doc.data().country) {
                      arrayTemp["country"] = _this.capitalizeFirstLetter(
                        doc.data().country.trim().toLowerCase()
                      );
                    }
                    arrayTemp["province"] = doc.data().province;
                    arrayTemp["description"] = observation.observation;
                    arrayTemp["customerId"] = doc.data().uid;
                    arrayTemp["customerName"] = doc.data().name;
                    arrayTemp["customerType"] = "lead";

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
        });

      // Nuevo cliente
      await db
        .collection("customers")
        .where("createdDate", "!=", "N/D")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let businessArea = doc
              .data()
              .businessAreas.filter(function (element) {
                if (
                  JSON.parse(sessionStorage.getItem("businessAreaActive"))
                    .uid === element.uid
                ) {
                  return element;
                }
              });

            if (
              businessArea.length === 1 &&
              !doc.data().deleted &&
              doc.data().comTechnicianId
            ) {
              let userRol = "";
              db.collection("users")
                .where("uid", "==", doc.data().comTechnicianId)
                .get()
                .then((query) => {
                  query.forEach(function (user) {
                    userRol = user.data().rol;
                  });

                  let indexDate = doc.data().createdDate.indexOf(" ");
                  let date = doc.data().createdDate.slice(0, indexDate);
                  let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                  if (!createdDate) {
                    createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                  }

                  let arrayTemp = {};

                  if (!doc.data().distributorId) {
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                  }

                  if (doc.data().distributorId) {
                    arrayTemp["userId"] = doc.data().distributorId;
                    arrayTemp["userName"] = doc.data().distributorName;
                    arrayTemp["userRol"] = "distributors";
                  }

                  arrayTemp["date"] = luxon
                    .fromISO(createdDate)
                    .toFormat("dd-MM-yyyy");
                  arrayTemp["status"] = "Realizado";
                  arrayTemp["task"] = "Nuevo cliente";
                  arrayTemp["taskType"] = "newCustomer";
                  if (doc.data().country) {
                    arrayTemp["country"] = _this.capitalizeFirstLetter(
                      doc.data().country.trim().toLowerCase()
                    );
                  }
                  arrayTemp["province"] = doc.data().province;
                  arrayTemp["description"] = doc.data().details;
                  arrayTemp["customerId"] = doc.data().uid;
                  arrayTemp["customerName"] = doc.data().name;
                  arrayTemp["customerType"] = "customer";

                  _this.listTasks.push(arrayTemp);
                });
            }
          });
        });

      // Nuevo leads
      await db
        .collection("leads")
        .where("createdDate", "!=", "N/D")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let businessArea = doc
              .data()
              .businessAreas.filter(function (element) {
                if (
                  JSON.parse(sessionStorage.getItem("businessAreaActive"))
                    .uid === element.uid
                ) {
                  return element;
                }
              });

            if (businessArea.length === 1 && !doc.data().deleted) {
              let userRol = "";
              db.collection("users")
                .where("uid", "==", doc.data().comTechnicianId)
                .get()
                .then((query) => {
                  query.forEach(function (user) {
                    userRol = user.data().rol;
                  });

                  let indexDate = doc.data().createdDate.indexOf(" ");
                  let date = doc.data().createdDate.slice(0, indexDate);
                  let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                  if (!createdDate) {
                    createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                  }

                  let arrayTemp = {};

                  if (!doc.data().distributorId) {
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                  }

                  if (doc.data().distributorId) {
                    arrayTemp["userId"] = doc.data().distributorId;
                    arrayTemp["userName"] = doc.data().distributorName;
                    arrayTemp["userRol"] = "distributors";
                  }

                  arrayTemp["date"] = luxon
                    .fromISO(createdDate)
                    .toFormat("dd-MM-yyyy");
                  arrayTemp["status"] = "Realizado";
                  arrayTemp["task"] = "Nuevo lead";
                  arrayTemp["taskType"] = "newLead";
                  if (doc.data().country) {
                    arrayTemp["country"] = _this.capitalizeFirstLetter(
                      doc.data().country.trim().toLowerCase()
                    );
                  }
                  arrayTemp["province"] = doc.data().province;
                  arrayTemp["description"] = doc.data().details;
                  arrayTemp["customerId"] = doc.data().uid;
                  arrayTemp["customerName"] = doc.data().name;
                  arrayTemp["customerType"] = "lead";

                  _this.listTasks.push(arrayTemp);
                });
            }
          });
        });

      // Otras tareas
      await db
        .collection("tasks")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (
              JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
              doc.data().businessAreaId
            ) {
              let arrayTemp = doc.data();
              arrayTemp["date"] = luxon
                .fromISO(doc.data().createdDate)
                .toFormat("dd-MM-yyyy");
              arrayTemp["taskType"] = "tasks";
              _this.listTasks.push(arrayTemp);
            }
          });
        });

      if (_this.businessAreaName === "Agro3") {
        // Recordatorio
        await db
          .collection("treatments")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (
                doc.data().state === "completed" ||
                doc.data().state === "active"
              ) {
                db.collection("inspections")
                  .where("id", "==", doc.data().inspectionId)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((inspection) => {
                      let protocolReminders = [];
                      db.collection("protocolReminders")
                        .where("protocolId", "==", doc.data().protocolId)
                        .get()
                        .then((querySnapshot) => {
                          querySnapshot.forEach((element) => {
                            protocolReminders.push(element.data());
                          });
                          let orderProtocolReminders = orderBy(
                            protocolReminders,
                            ["hours"],
                            ["asc"]
                          );
                          let totalSteps = orderProtocolReminders.length;

                          doc.data().steps.forEach((step) => {
                            let userRol = "";
                            db.collection("users")
                              .where(
                                "email",
                                "==",
                                doc.data().inspectionComTechEmail
                              )
                              .get()
                              .then((query) => {
                                query.forEach(function (element) {
                                  userRol = element.data().rol;
                                });

                                if (_this.date_today >= step.estimatedDate) {
                                  let arrayTemp = {};
                                  arrayTemp["date"] = luxon
                                    .fromISO(step.estimatedDate)
                                    .toFormat("dd-MM-yyyy");
                                  arrayTemp["userId"] =
                                    inspection.data().comTechnicianId;
                                  arrayTemp["userName"] =
                                    inspection.data().comTechnicianName;
                                  arrayTemp["userRol"] = userRol;
                                  arrayTemp["task"] =
                                    "Recordatorio de inoculación";
                                  arrayTemp["taskType"] = "reminder";
                                  arrayTemp["status"] = step.step_date
                                    ? "Realizado"
                                    : "Pendiente";
                                  arrayTemp["farmId"] = doc.data().farmId;
                                  arrayTemp["farmName"] = doc.data().farmName;
                                  arrayTemp["description"] =
                                    "Recordatorio {step} de {totalSteps} a {days} días de inicio";
                                  arrayTemp["step"] = step.step + 1;
                                  arrayTemp["totalSteps"] = totalSteps;
                                  arrayTemp["days"] =
                                    orderProtocolReminders[
                                      step.step
                                    ].repetitionInDays;
                                  arrayTemp["customerId"] =
                                    doc.data().customerId;
                                  arrayTemp["customerName"] =
                                    doc.data().customerName;
                                  arrayTemp["customerType"] = "customer";
                                  arrayTemp["machineId"] = doc.data().machineId;
                                  arrayTemp["machineName"] =
                                    doc.data().machineName;
                                  arrayTemp["protocolId"] =
                                    doc.data().protocolId;
                                  arrayTemp["uid"] = doc.id;
                                  _this.listTasks.push(arrayTemp);
                                }
                              });
                          });
                        });
                    });
                  });
              }
            });
          });

        // Nueva inspección
        await db
          .collection("inspections")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (doc.data().inspectionDate && doc.data().comTechnicianId) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().comTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (element) {
                      userRol = element.data().rol;
                    });
                    let arrayTemp = {};
                    arrayTemp["date"] = luxon
                      .fromISO(doc.data().inspectionDate)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["dateCreated"] = luxon
                      .fromISO(doc.data().dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["status"] = "Realizado";
                    arrayTemp["task"] = "Nueva inspección";
                    arrayTemp["taskType"] = "newInspection";
                    arrayTemp["farmId"] = doc.data().farmId;
                    arrayTemp["farmName"] = doc.data().farmName;
                    arrayTemp["description"] = doc.data().observation;
                    arrayTemp["customerId"] = doc.data().customerId;
                    arrayTemp["customerName"] = doc.data().customerName;
                    arrayTemp["customerType"] = "customer";
                    arrayTemp["uid"] = doc.id;
                    arrayTemp["inspection"] = doc.data();

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });

        if (_this.userRol !== "comTechniciansAdmin") {
          // Nuevo tratamiento
          await db
            .collection("treatments")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(function (doc) {
                if (doc.data().state !== "canceled") {
                  let userRol = "";
                  db.collection("users")
                    .where("uid", "==", doc.data().appTechnicianId)
                    .get()
                    .then((query) => {
                      query.forEach(function (element) {
                        userRol = element.data().rol;
                      });
                      let arrayTemp = {};

                      arrayTemp["status"] = "Realizado";

                      if (doc.data().state === "pending") {
                        arrayTemp["status"] = "Pendiente";
                      }

                      if (_this.date_today >= doc.data().agrozonoDate) {
                        arrayTemp["date"] = luxon
                          .fromISO(doc.data().agrozonoDate)
                          .toFormat("dd-MM-yyyy");
                        arrayTemp["userId"] = doc.data().appTechnicianId;
                        arrayTemp["userName"] = doc.data().appTechnicianName;
                        arrayTemp["userRol"] = userRol;
                        arrayTemp["task"] = "Nuevo tratamiento";
                        arrayTemp["taskType"] = "newTreatment";
                        arrayTemp["farmId"] = doc.data().farmId;
                        arrayTemp["farmName"] = doc.data().farmName;
                        arrayTemp["description"] = doc.data().observation;
                        arrayTemp["customerId"] = doc.data().customerId;
                        arrayTemp["customerName"] = doc.data().customerName;
                        arrayTemp["customerType"] = "customer";
                        arrayTemp["machineId"] = doc.data().machineId;
                        arrayTemp["machineName"] = doc.data().machineName;
                        arrayTemp["uid"] = doc.id;
                        _this.listTasks.push(arrayTemp);
                      }
                    });
                }
              });
            });
        }
      }
      this.dataLoaded = true;
    },
    async getTaskDistributors(userId) {
      this.dataLoaded = false;
      let _this = this;

      // Contacto con cliente
      await db
        .collection("customers")
        .where("observaciones", "!=", [])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let observationLength = doc.data().observaciones.length - 1;
            let sumObservation = 0;
            doc.data().observaciones.forEach((observation) => {
              if (
                JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
                  observation.businessAreaId &&
                doc.data().distributorId === userId
              ) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().distributorId)
                  .get()
                  .then((query) => {
                    query.forEach(function (user) {
                      userRol = user.data().rol;
                    });

                    let createdDate = luxon
                      .fromFormat(observation.date_observation, "d/MM/y")
                      .toISO();

                    if (!createdDate) {
                      createdDate = luxon
                        .fromFormat(observation.date_observation, "d/M/y")
                        .toISO();
                    }

                    let arrayTemp = {};

                    if (
                      sumObservation === observationLength &&
                      observation.date_reminder
                    ) {
                      arrayTemp["status"] = "Pendiente";
                    } else {
                      sumObservation++;
                      arrayTemp["status"] = "Realizado";
                    }

                    arrayTemp["date"] = luxon
                      .fromISO(createdDate)
                      .toFormat("dd-MM-yyyy");

                    let dateCreated = luxon
                      .fromFormat(observation.date_created, "d/MM/y")
                      .toISO();

                    if (!dateCreated) {
                      dateCreated = luxon
                        .fromFormat(observation.date_created, "d/M/y")
                        .toISO();
                    }

                    arrayTemp["dateCreated"] = luxon
                      .fromISO(dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().distributorId;
                    arrayTemp["userName"] = doc.data().distributorName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["task"] = "Contacto cliente";
                    arrayTemp["taskType"] = "contactCustomer";
                    if (doc.data().country) {
                      arrayTemp["country"] = _this.capitalizeFirstLetter(
                        doc.data().country.trim().toLowerCase()
                      );
                    }
                    arrayTemp["province"] = doc.data().province;
                    arrayTemp["description"] = observation.observation;
                    arrayTemp["customerId"] = doc.data().uid;
                    arrayTemp["customerName"] = doc.data().name;
                    arrayTemp["customerType"] = "customer";

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
        });

      // Contacto con lead
      await db
        .collection("leads")
        .where("observaciones", "!=", [])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let observationLength = doc.data().observaciones.length - 1;
            let sumObservation = 0;
            doc.data().observaciones.forEach((observation) => {
              if (
                JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
                  observation.businessAreaId &&
                doc.data().distributorId === userId
              ) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().distributorId)
                  .get()
                  .then((query) => {
                    query.forEach(function (user) {
                      userRol = user.data().rol;
                    });

                    let createdDate = luxon
                      .fromFormat(observation.date_observation, "d/MM/y")
                      .toISO();

                    if (!createdDate) {
                      createdDate = luxon
                        .fromFormat(observation.date_observation, "d/M/y")
                        .toISO();
                    }

                    let arrayTemp = {};

                    if (
                      sumObservation === observationLength &&
                      observation.date_reminder
                    ) {
                      arrayTemp["status"] = "Pendiente";
                    } else {
                      sumObservation++;
                      arrayTemp["status"] = "Realizado";
                    }

                    arrayTemp["date"] = luxon
                      .fromISO(createdDate)
                      .toFormat("dd-MM-yyyy");

                    let dateCreated = luxon
                      .fromFormat(observation.date_created, "d/MM/y")
                      .toISO();

                    if (!dateCreated) {
                      dateCreated = luxon
                        .fromFormat(observation.date_created, "d/M/y")
                        .toISO();
                    }

                    arrayTemp["dateCreated"] = luxon
                      .fromISO(dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().distributorId;
                    arrayTemp["userName"] = doc.data().distributorName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["task"] = "Contacto lead";
                    arrayTemp["taskType"] = "contactLead";
                    if (doc.data().country) {
                      arrayTemp["country"] = _this.capitalizeFirstLetter(
                        doc.data().country.trim().toLowerCase()
                      );
                    }
                    arrayTemp["province"] = doc.data().province;
                    arrayTemp["description"] = observation.observation;
                    arrayTemp["customerId"] = doc.data().uid;
                    arrayTemp["customerName"] = doc.data().name;
                    arrayTemp["customerType"] = "lead";

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
        });

      // Nuevo cliente
      await db
        .collection("customers")
        .where("createdDate", "!=", "N/D")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let businessArea = doc
              .data()
              .businessAreas.filter(function (element) {
                if (
                  JSON.parse(sessionStorage.getItem("businessAreaActive"))
                    .uid === element.uid
                ) {
                  return element;
                }
              });

            if (
              businessArea.length === 1 &&
              !doc.data().deleted &&
              doc.data().distributorId === userId
            ) {
              let userRol = "";
              db.collection("users")
                .where("uid", "==", doc.data().distributorId)
                .get()
                .then((query) => {
                  query.forEach(function (user) {
                    userRol = user.data().rol;
                  });

                  let indexDate = doc.data().createdDate.indexOf(" ");
                  let date = doc.data().createdDate.slice(0, indexDate);
                  let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                  if (!createdDate) {
                    createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                  }

                  let arrayTemp = {};

                  arrayTemp["date"] = luxon
                    .fromISO(createdDate)
                    .toFormat("dd-MM-yyyy");
                  arrayTemp["userId"] = doc.data().distributorId;
                  arrayTemp["userName"] = doc.data().distributorName;
                  arrayTemp["userRol"] = userRol;
                  arrayTemp["status"] = "Realizado";
                  arrayTemp["task"] = "Nuevo cliente";
                  arrayTemp["taskType"] = "newCustomer";
                  if (doc.data().country) {
                    arrayTemp["country"] = _this.capitalizeFirstLetter(
                      doc.data().country.trim().toLowerCase()
                    );
                  }
                  arrayTemp["province"] = doc.data().province;
                  arrayTemp["description"] = doc.data().details;
                  arrayTemp["customerId"] = doc.data().uid;
                  arrayTemp["customerName"] = doc.data().name;
                  arrayTemp["customerType"] = "customer";

                  _this.listTasks.push(arrayTemp);
                });
            }
          });
        });

      // Nuevo leads
      await db
        .collection("leads")
        .where("createdDate", "!=", "N/D")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let businessArea = doc
              .data()
              .businessAreas.filter(function (element) {
                if (
                  JSON.parse(sessionStorage.getItem("businessAreaActive"))
                    .uid === element.uid
                ) {
                  return element;
                }
              });

            if (
              businessArea.length === 1 &&
              !doc.data().deleted &&
              doc.data().distributorId === userId
            ) {
              let userRol = "";
              db.collection("users")
                .where("uid", "==", doc.data().distributorId)
                .get()
                .then((query) => {
                  query.forEach(function (user) {
                    userRol = user.data().rol;
                  });

                  let indexDate = doc.data().createdDate.indexOf(" ");
                  let date = doc.data().createdDate.slice(0, indexDate);
                  let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                  if (!createdDate) {
                    createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                  }

                  let arrayTemp = {};

                  arrayTemp["date"] = luxon
                    .fromISO(createdDate)
                    .toFormat("dd-MM-yyyy");
                  arrayTemp["userId"] = doc.data().distributorId;
                  arrayTemp["userName"] = doc.data().distributorName;
                  arrayTemp["userRol"] = userRol;
                  arrayTemp["status"] = "Realizado";
                  arrayTemp["task"] = "Nuevo lead";
                  arrayTemp["taskType"] = "newLead";
                  if (doc.data().country) {
                    arrayTemp["country"] = _this.capitalizeFirstLetter(
                      doc.data().country.trim().toLowerCase()
                    );
                  }
                  arrayTemp["province"] = doc.data().province;
                  arrayTemp["description"] = doc.data().details;
                  arrayTemp["customerId"] = doc.data().uid;
                  arrayTemp["customerName"] = doc.data().name;
                  arrayTemp["customerType"] = "lead";

                  _this.listTasks.push(arrayTemp);
                });
            }
          });
        });

      // Otras tareas
      await db
        .collection("tasks")
        .where("deleted", "==", false)
        .where("userId", "==", userId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (
              JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
              doc.data().businessAreaId
            ) {
              let arrayTemp = doc.data();
              arrayTemp["date"] = luxon
                .fromISO(doc.data().createdDate)
                .toFormat("dd-MM-yyyy");
              arrayTemp["taskType"] = "tasks";
              _this.listTasks.push(arrayTemp);
            }
          });
        });

      if (_this.businessAreaName === "Agro3") {
        // Recordatorio
        await db
          .collection("treatments")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (
                doc.data().state === "completed" ||
                doc.data().state === "active"
              ) {
                db.collection("inspections")
                  .where("id", "==", doc.data().inspectionId)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((inspection) => {
                      if (inspection.data().comTechnicianId === userId) {
                        let protocolReminders = [];
                        db.collection("protocolReminders")
                          .where("protocolId", "==", doc.data().protocolId)
                          .get()
                          .then((querySnapshot) => {
                            querySnapshot.forEach((element) => {
                              protocolReminders.push(element.data());
                            });
                            let orderProtocolReminders = orderBy(
                              protocolReminders,
                              ["hours"],
                              ["asc"]
                            );
                            let totalSteps = orderProtocolReminders.length;

                            doc.data().steps.forEach((step) => {
                              let userRol = "";
                              db.collection("users")
                                .where(
                                  "email",
                                  "==",
                                  doc.data().inspectionComTechEmail
                                )
                                .get()
                                .then((query) => {
                                  query.forEach(function (element) {
                                    userRol = element.data().rol;
                                  });

                                  if (_this.date_today >= step.estimatedDate) {
                                    let arrayTemp = {};
                                    arrayTemp["date"] = luxon
                                      .fromISO(step.estimatedDate)
                                      .toFormat("dd-MM-yyyy");
                                    arrayTemp["userId"] =
                                      inspection.data().comTechnicianId;
                                    arrayTemp["userName"] =
                                      inspection.data().comTechnicianName;
                                    arrayTemp["userRol"] = userRol;
                                    arrayTemp["task"] =
                                      "Recordatorio de inoculación";
                                    arrayTemp["taskType"] = "reminder";
                                    arrayTemp["status"] = step.step_date
                                      ? "Realizado"
                                      : "Pendiente";
                                    arrayTemp["farmId"] = doc.data().farmId;
                                    arrayTemp["farmName"] = doc.data().farmName;
                                    arrayTemp["description"] =
                                      "Recordatorio {step} de {totalSteps} a {days} días de inicio";
                                    arrayTemp["step"] = step.step + 1;
                                    arrayTemp["totalSteps"] = totalSteps;
                                    arrayTemp["days"] =
                                      orderProtocolReminders[
                                        step.step
                                      ].repetitionInDays;
                                    arrayTemp["customerId"] =
                                      doc.data().customerId;
                                    arrayTemp["customerName"] =
                                      doc.data().customerName;
                                    arrayTemp["customerType"] = "customer";
                                    arrayTemp["machineId"] =
                                      doc.data().machineId;
                                    arrayTemp["machineName"] =
                                      doc.data().machineName;
                                    arrayTemp["protocolId"] =
                                      doc.data().protocolId;
                                    arrayTemp["uid"] = doc.id;
                                    _this.listTasks.push(arrayTemp);
                                  }
                                });
                            });
                          });
                      }
                    });
                  });
              }
            });
          });

        // Nueva inspección
        await db
          .collection("inspections")
          .where("deleted", "==", false)
          .where("comTechnicianId", "==", userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (doc.data().inspectionDate) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", userId)
                  .get()
                  .then((query) => {
                    query.forEach(function (element) {
                      userRol = element.data().rol;
                    });
                    let arrayTemp = {};
                    arrayTemp["date"] = luxon
                      .fromISO(doc.data().inspectionDate)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["dateCreated"] = luxon
                      .fromISO(doc.data().dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["status"] = "Realizado";
                    arrayTemp["task"] = "Nueva inspección";
                    arrayTemp["taskType"] = "newInspection";
                    arrayTemp["farmId"] = doc.data().farmId;
                    arrayTemp["farmName"] = doc.data().farmName;
                    arrayTemp["description"] = doc.data().observation;
                    arrayTemp["customerId"] = doc.data().customerId;
                    arrayTemp["customerName"] = doc.data().customerName;
                    arrayTemp["customerType"] = "customer";
                    arrayTemp["uid"] = doc.id;
                    arrayTemp["inspection"] = doc.data();

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
      }

      this.dataLoaded = true;
    },
    async getTaskComTechnicians(userId) {
      this.dataLoaded = false;
      let _this = this;

      // Contacto con cliente
      await db
        .collection("customers")
        .where("observaciones", "!=", [])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let observationLength = doc.data().observaciones.length - 1;
            let sumObservation = 0;
            doc.data().observaciones.forEach((observation) => {
              if (
                JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
                  observation.businessAreaId &&
                doc.data().comTechnicianId === userId &&
                !doc.data().distributorId
              ) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().comTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (user) {
                      userRol = user.data().rol;
                    });

                    let createdDate = luxon
                      .fromFormat(observation.date_observation, "d/MM/y")
                      .toISO();

                    if (!createdDate) {
                      createdDate = luxon
                        .fromFormat(observation.date_observation, "d/M/y")
                        .toISO();
                    }

                    let arrayTemp = {};

                    if (
                      sumObservation === observationLength &&
                      observation.date_reminder
                    ) {
                      arrayTemp["status"] = "Pendiente";
                    } else {
                      sumObservation++;
                      arrayTemp["status"] = "Realizado";
                    }

                    arrayTemp["date"] = luxon
                      .fromISO(createdDate)
                      .toFormat("dd-MM-yyyy");

                    let dateCreated = luxon
                      .fromFormat(observation.date_created, "d/MM/y")
                      .toISO();

                    if (!dateCreated) {
                      dateCreated = luxon
                        .fromFormat(observation.date_created, "d/M/y")
                        .toISO();
                    }

                    arrayTemp["dateCreated"] = luxon
                      .fromISO(dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["task"] = "Contacto cliente";
                    arrayTemp["taskType"] = "contactCustomer";
                    if (doc.data().country) {
                      arrayTemp["country"] = _this.capitalizeFirstLetter(
                        doc.data().country.trim().toLowerCase()
                      );
                    }
                    arrayTemp["province"] = doc.data().province;
                    arrayTemp["description"] = observation.observation;
                    arrayTemp["customerId"] = doc.data().uid;
                    arrayTemp["customerName"] = doc.data().name;
                    arrayTemp["customerType"] = "customer";

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
        });

      // Contacto con lead
      await db
        .collection("leads")
        .where("observaciones", "!=", [])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let observationLength = doc.data().observaciones.length - 1;
            let sumObservation = 0;
            doc.data().observaciones.forEach((observation) => {
              if (
                JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
                  observation.businessAreaId &&
                doc.data().comTechnicianId === userId &&
                !doc.data().distributorId
              ) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().comTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (user) {
                      userRol = user.data().rol;
                    });

                    let createdDate = luxon
                      .fromFormat(observation.date_observation, "d/MM/y")
                      .toISO();

                    if (!createdDate) {
                      createdDate = luxon
                        .fromFormat(observation.date_observation, "d/M/y")
                        .toISO();
                    }

                    let arrayTemp = {};

                    if (
                      sumObservation === observationLength &&
                      observation.date_reminder
                    ) {
                      arrayTemp["status"] = "Pendiente";
                    } else {
                      sumObservation++;
                      arrayTemp["status"] = "Realizado";
                    }

                    arrayTemp["date"] = luxon
                      .fromISO(createdDate)
                      .toFormat("dd-MM-yyyy");

                    let dateCreated = luxon
                      .fromFormat(observation.date_created, "d/MM/y")
                      .toISO();

                    if (!dateCreated) {
                      dateCreated = luxon
                        .fromFormat(observation.date_created, "d/M/y")
                        .toISO();
                    }

                    arrayTemp["dateCreated"] = luxon
                      .fromISO(dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["task"] = "Contacto lead";
                    arrayTemp["taskType"] = "contactLead";
                    if (doc.data().country) {
                      arrayTemp["country"] = _this.capitalizeFirstLetter(
                        doc.data().country.trim().toLowerCase()
                      );
                    }
                    arrayTemp["province"] = doc.data().province;
                    arrayTemp["description"] = observation.observation;
                    arrayTemp["customerId"] = doc.data().uid;
                    arrayTemp["customerName"] = doc.data().name;
                    arrayTemp["customerType"] = "lead";

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
        });

      // Nuevo cliente
      await db
        .collection("customers")
        .where("createdDate", "!=", "N/D")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let businessArea = doc
              .data()
              .businessAreas.filter(function (element) {
                if (
                  JSON.parse(sessionStorage.getItem("businessAreaActive"))
                    .uid === element.uid
                ) {
                  return element;
                }
              });

            if (
              businessArea.length === 1 &&
              !doc.data().deleted &&
              doc.data().comTechnicianId === userId &&
              !doc.data().distributorId
            ) {
              let userRol = "";
              db.collection("users")
                .where("uid", "==", doc.data().comTechnicianId)
                .get()
                .then((query) => {
                  query.forEach(function (user) {
                    userRol = user.data().rol;
                  });

                  let indexDate = doc.data().createdDate.indexOf(" ");
                  let date = doc.data().createdDate.slice(0, indexDate);
                  let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                  if (!createdDate) {
                    createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                  }

                  let arrayTemp = {};

                  arrayTemp["date"] = luxon
                    .fromISO(createdDate)
                    .toFormat("dd-MM-yyyy");
                  arrayTemp["userId"] = doc.data().comTechnicianId;
                  arrayTemp["userName"] = doc.data().comTechnicianName;
                  arrayTemp["userRol"] = userRol;
                  arrayTemp["status"] = "Realizado";
                  arrayTemp["task"] = "Nuevo cliente";
                  arrayTemp["taskType"] = "newCustomer";
                  if (doc.data().country) {
                    arrayTemp["country"] = _this.capitalizeFirstLetter(
                      doc.data().country.trim().toLowerCase()
                    );
                  }
                  arrayTemp["province"] = doc.data().province;
                  arrayTemp["description"] = doc.data().details;
                  arrayTemp["customerId"] = doc.data().uid;
                  arrayTemp["customerName"] = doc.data().name;
                  arrayTemp["customerType"] = "customer";

                  _this.listTasks.push(arrayTemp);
                });
            }
          });
        });

      // Nuevo leads
      await db
        .collection("leads")
        .where("createdDate", "!=", "N/D")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let businessArea = doc
              .data()
              .businessAreas.filter(function (element) {
                if (
                  JSON.parse(sessionStorage.getItem("businessAreaActive"))
                    .uid === element.uid
                ) {
                  return element;
                }
              });

            if (
              businessArea.length === 1 &&
              !doc.data().deleted &&
              doc.data().comTechnicianId === userId &&
              !doc.data().distributorId
            ) {
              let userRol = "";
              db.collection("users")
                .where("uid", "==", doc.data().comTechnicianId)
                .get()
                .then((query) => {
                  query.forEach(function (user) {
                    userRol = user.data().rol;
                  });

                  let indexDate = doc.data().createdDate.indexOf(" ");
                  let date = doc.data().createdDate.slice(0, indexDate);
                  let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                  if (!createdDate) {
                    createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                  }

                  let arrayTemp = {};

                  arrayTemp["date"] = luxon
                    .fromISO(createdDate)
                    .toFormat("dd-MM-yyyy");
                  arrayTemp["userId"] = doc.data().comTechnicianId;
                  arrayTemp["userName"] = doc.data().comTechnicianName;
                  arrayTemp["userRol"] = userRol;
                  arrayTemp["status"] = "Realizado";
                  arrayTemp["task"] = "Nuevo lead";
                  arrayTemp["taskType"] = "newLead";
                  if (doc.data().country) {
                    arrayTemp["country"] = _this.capitalizeFirstLetter(
                      doc.data().country.trim().toLowerCase()
                    );
                  }
                  arrayTemp["province"] = doc.data().province;
                  arrayTemp["description"] = doc.data().details;
                  arrayTemp["customerId"] = doc.data().uid;
                  arrayTemp["customerName"] = doc.data().name;
                  arrayTemp["customerType"] = "lead";

                  _this.listTasks.push(arrayTemp);
                });
            }
          });
        });

      // Otras tareas
      await db
        .collection("tasks")
        .where("deleted", "==", false)
        .where("userId", "==", userId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (
              JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
              doc.data().businessAreaId
            ) {
              let arrayTemp = doc.data();
              arrayTemp["date"] = luxon
                .fromISO(doc.data().createdDate)
                .toFormat("dd-MM-yyyy");
              arrayTemp["taskType"] = "tasks";
              _this.listTasks.push(arrayTemp);
            }
          });
        });

      if (_this.businessAreaName === "Agro3") {
        // Recordatorio
        await db
          .collection("treatments")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (
                doc.data().state === "completed" ||
                doc.data().state === "active"
              ) {
                db.collection("inspections")
                  .where("id", "==", doc.data().inspectionId)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((inspection) => {
                      if (inspection.data().comTechnicianId === userId) {
                        let protocolReminders = [];
                        db.collection("protocolReminders")
                          .where("protocolId", "==", doc.data().protocolId)
                          .get()
                          .then((querySnapshot) => {
                            querySnapshot.forEach((element) => {
                              protocolReminders.push(element.data());
                            });
                            let orderProtocolReminders = orderBy(
                              protocolReminders,
                              ["hours"],
                              ["asc"]
                            );
                            let totalSteps = orderProtocolReminders.length;

                            doc.data().steps.forEach((step) => {
                              let userRol = "";
                              db.collection("users")
                                .where(
                                  "email",
                                  "==",
                                  doc.data().inspectionComTechEmail
                                )
                                .get()
                                .then((query) => {
                                  query.forEach(function (element) {
                                    userRol = element.data().rol;
                                  });

                                  if (_this.date_today >= step.estimatedDate) {
                                    let arrayTemp = {};
                                    arrayTemp["date"] = luxon
                                      .fromISO(step.estimatedDate)
                                      .toFormat("dd-MM-yyyy");
                                    arrayTemp["userId"] =
                                      inspection.data().comTechnicianId;
                                    arrayTemp["userName"] =
                                      inspection.data().comTechnicianName;
                                    arrayTemp["userRol"] = userRol;
                                    arrayTemp["task"] =
                                      "Recordatorio de inoculación";
                                    arrayTemp["taskType"] = "reminder";
                                    arrayTemp["status"] = step.step_date
                                      ? "Realizado"
                                      : "Pendiente";
                                    arrayTemp["farmId"] = doc.data().farmId;
                                    arrayTemp["farmName"] = doc.data().farmName;
                                    arrayTemp["description"] =
                                      "Recordatorio {step} de {totalSteps} a {days} días de inicio";
                                    arrayTemp["step"] = step.step + 1;
                                    arrayTemp["totalSteps"] = totalSteps;
                                    arrayTemp["days"] =
                                      orderProtocolReminders[
                                        step.step
                                      ].repetitionInDays;
                                    arrayTemp["customerId"] =
                                      doc.data().customerId;
                                    arrayTemp["customerName"] =
                                      doc.data().customerName;
                                    arrayTemp["customerType"] = "customer";
                                    arrayTemp["machineId"] =
                                      doc.data().machineId;
                                    arrayTemp["machineName"] =
                                      doc.data().machineName;
                                    arrayTemp["protocolId"] =
                                      doc.data().protocolId;
                                    arrayTemp["uid"] = doc.id;
                                    _this.listTasks.push(arrayTemp);
                                  }
                                });
                            });
                          });
                      }
                    });
                  });
              }
            });
          });

        // Nueva inspección
        await db
          .collection("inspections")
          .where("deleted", "==", false)
          .where("comTechnicianId", "==", userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (doc.data().inspectionDate) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", userId)
                  .get()
                  .then((query) => {
                    query.forEach(function (element) {
                      userRol = element.data().rol;
                    });
                    let arrayTemp = {};
                    arrayTemp["date"] = luxon
                      .fromISO(doc.data().inspectionDate)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["dateCreated"] = luxon
                      .fromISO(doc.data().dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["status"] = "Realizado";
                    arrayTemp["task"] = "Nueva inspección";
                    arrayTemp["taskType"] = "newInspection";
                    arrayTemp["farmId"] = doc.data().farmId;
                    arrayTemp["farmName"] = doc.data().farmName;
                    arrayTemp["description"] = doc.data().observation;
                    arrayTemp["customerId"] = doc.data().customerId;
                    arrayTemp["customerName"] = doc.data().customerName;
                    arrayTemp["customerType"] = "customer";
                    arrayTemp["uid"] = doc.id;
                    arrayTemp["inspection"] = doc.data();

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
      }
      this.dataLoaded = true;
    },
    async getTaskComTechniciansResp(userId) {
      this.dataLoaded = false;
      let _this = this;

      // Contacto con cliente
      await db
        .collection("customers")
        .where("observaciones", "!=", [])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let observationLength = doc.data().observaciones.length - 1;
            let sumObservation = 0;
            doc.data().observaciones.forEach((observation) => {
              if (
                JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
                  observation.businessAreaId &&
                doc.data().comTechnicianId === userId &&
                !doc.data().distributorId
              ) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().comTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (user) {
                      userRol = user.data().rol;
                    });

                    let createdDate = luxon
                      .fromFormat(observation.date_observation, "d/MM/y")
                      .toISO();

                    if (!createdDate) {
                      createdDate = luxon
                        .fromFormat(observation.date_observation, "d/M/y")
                        .toISO();
                    }

                    let arrayTemp = {};

                    if (
                      sumObservation === observationLength &&
                      observation.date_reminder
                    ) {
                      arrayTemp["status"] = "Pendiente";
                    } else {
                      sumObservation++;
                      arrayTemp["status"] = "Realizado";
                    }

                    arrayTemp["date"] = luxon
                      .fromISO(createdDate)
                      .toFormat("dd-MM-yyyy");

                    let dateCreated = luxon
                      .fromFormat(observation.date_created, "d/MM/y")
                      .toISO();

                    if (!dateCreated) {
                      dateCreated = luxon
                        .fromFormat(observation.date_created, "d/M/y")
                        .toISO();
                    }

                    arrayTemp["dateCreated"] = luxon
                      .fromISO(dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["task"] = "Contacto cliente";
                    arrayTemp["taskType"] = "contactCustomer";
                    if (doc.data().country) {
                      arrayTemp["country"] = _this.capitalizeFirstLetter(
                        doc.data().country.trim().toLowerCase()
                      );
                    }
                    arrayTemp["province"] = doc.data().province;
                    arrayTemp["description"] = observation.observation;
                    arrayTemp["customerId"] = doc.data().uid;
                    arrayTemp["customerName"] = doc.data().name;
                    arrayTemp["customerType"] = "customer";

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
        });

      // Contacto con lead
      await db
        .collection("leads")
        .where("observaciones", "!=", [])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let observationLength = doc.data().observaciones.length - 1;
            let sumObservation = 0;
            doc.data().observaciones.forEach((observation) => {
              if (
                JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
                  observation.businessAreaId &&
                doc.data().comTechnicianId === userId &&
                !doc.data().distributorId
              ) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().comTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (user) {
                      userRol = user.data().rol;
                    });

                    let createdDate = luxon
                      .fromFormat(observation.date_observation, "d/MM/y")
                      .toISO();

                    if (!createdDate) {
                      createdDate = luxon
                        .fromFormat(observation.date_observation, "d/M/y")
                        .toISO();
                    }

                    let arrayTemp = {};

                    if (
                      sumObservation === observationLength &&
                      observation.date_reminder
                    ) {
                      arrayTemp["status"] = "Pendiente";
                    } else {
                      sumObservation++;
                      arrayTemp["status"] = "Realizado";
                    }

                    arrayTemp["date"] = luxon
                      .fromISO(createdDate)
                      .toFormat("dd-MM-yyyy");

                    let dateCreated = luxon
                      .fromFormat(observation.date_created, "d/MM/y")
                      .toISO();

                    if (!dateCreated) {
                      dateCreated = luxon
                        .fromFormat(observation.date_created, "d/M/y")
                        .toISO();
                    }

                    arrayTemp["dateCreated"] = luxon
                      .fromISO(dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["task"] = "Contacto lead";
                    arrayTemp["taskType"] = "contactLead";
                    if (doc.data().country) {
                      arrayTemp["country"] = _this.capitalizeFirstLetter(
                        doc.data().country.trim().toLowerCase()
                      );
                    }
                    arrayTemp["province"] = doc.data().province;
                    arrayTemp["description"] = observation.observation;
                    arrayTemp["customerId"] = doc.data().uid;
                    arrayTemp["customerName"] = doc.data().name;
                    arrayTemp["customerType"] = "lead";

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
        });

      // Nuevo cliente
      await db
        .collection("customers")
        .where("createdDate", "!=", "N/D")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let businessArea = doc
              .data()
              .businessAreas.filter(function (element) {
                if (
                  JSON.parse(sessionStorage.getItem("businessAreaActive"))
                    .uid === element.uid
                ) {
                  return element;
                }
              });

            if (
              businessArea.length === 1 &&
              !doc.data().deleted &&
              doc.data().comTechnicianId === userId &&
              !doc.data().distributorId
            ) {
              let userRol = "";
              db.collection("users")
                .where("uid", "==", doc.data().comTechnicianId)
                .get()
                .then((query) => {
                  query.forEach(function (user) {
                    userRol = user.data().rol;
                  });

                  let indexDate = doc.data().createdDate.indexOf(" ");
                  let date = doc.data().createdDate.slice(0, indexDate);
                  let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                  if (!createdDate) {
                    createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                  }

                  let arrayTemp = {};

                  arrayTemp["date"] = luxon
                    .fromISO(createdDate)
                    .toFormat("dd-MM-yyyy");
                  arrayTemp["userId"] = doc.data().comTechnicianId;
                  arrayTemp["userName"] = doc.data().comTechnicianName;
                  arrayTemp["userRol"] = userRol;
                  arrayTemp["status"] = "Realizado";
                  arrayTemp["task"] = "Nuevo cliente";
                  arrayTemp["taskType"] = "newCustomer";
                  if (doc.data().country) {
                    arrayTemp["country"] = _this.capitalizeFirstLetter(
                      doc.data().country.trim().toLowerCase()
                    );
                  }
                  arrayTemp["province"] = doc.data().province;
                  arrayTemp["description"] = doc.data().details;
                  arrayTemp["customerId"] = doc.data().uid;
                  arrayTemp["customerName"] = doc.data().name;
                  arrayTemp["customerType"] = "customer";

                  _this.listTasks.push(arrayTemp);
                });
            }
          });
        });

      // Nuevo leads
      await db
        .collection("leads")
        .where("createdDate", "!=", "N/D")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let businessArea = doc
              .data()
              .businessAreas.filter(function (element) {
                if (
                  JSON.parse(sessionStorage.getItem("businessAreaActive"))
                    .uid === element.uid
                ) {
                  return element;
                }
              });

            if (
              businessArea.length === 1 &&
              !doc.data().deleted &&
              doc.data().comTechnicianId === userId &&
              !doc.data().distributorId
            ) {
              let userRol = "";
              db.collection("users")
                .where("uid", "==", doc.data().comTechnicianId)
                .get()
                .then((query) => {
                  query.forEach(function (user) {
                    userRol = user.data().rol;
                  });

                  let indexDate = doc.data().createdDate.indexOf(" ");
                  let date = doc.data().createdDate.slice(0, indexDate);
                  let createdDate = luxon.fromFormat(date, "d/MM/y").toISO();

                  if (!createdDate) {
                    createdDate = luxon.fromFormat(date, "d/M/y").toISO();
                  }

                  let arrayTemp = {};

                  arrayTemp["date"] = luxon
                    .fromISO(createdDate)
                    .toFormat("dd-MM-yyyy");
                  arrayTemp["userId"] = doc.data().comTechnicianId;
                  arrayTemp["userName"] = doc.data().comTechnicianName;
                  arrayTemp["userRol"] = userRol;
                  arrayTemp["status"] = "Realizado";
                  arrayTemp["task"] = "Nuevo lead";
                  arrayTemp["taskType"] = "newLead";
                  if (doc.data().country) {
                    arrayTemp["country"] = _this.capitalizeFirstLetter(
                      doc.data().country.trim().toLowerCase()
                    );
                  }
                  arrayTemp["province"] = doc.data().province;
                  arrayTemp["description"] = doc.data().details;
                  arrayTemp["customerId"] = doc.data().uid;
                  arrayTemp["customerName"] = doc.data().name;
                  arrayTemp["customerType"] = "lead";

                  _this.listTasks.push(arrayTemp);
                });
            }
          });
        });

      // Otras tareas
      await db
        .collection("tasks")
        .where("deleted", "==", false)
        .where("userId", "==", userId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (
              JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
              doc.data().businessAreaId
            ) {
              let arrayTemp = doc.data();
              arrayTemp["date"] = luxon
                .fromISO(doc.data().createdDate)
                .toFormat("dd-MM-yyyy");
              arrayTemp["taskType"] = "tasks";
              _this.listTasks.push(arrayTemp);
            }
          });
        });

      if (_this.businessAreaName === "Agro3") {
        // Recordatorio
        await db
          .collection("treatments")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (
                doc.data().state === "completed" ||
                doc.data().state === "active"
              ) {
                db.collection("inspections")
                  .where("id", "==", doc.data().inspectionId)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((inspection) => {
                      if (inspection.data().comTechnicianId === userId) {
                        let protocolReminders = [];
                        db.collection("protocolReminders")
                          .where("protocolId", "==", doc.data().protocolId)
                          .get()
                          .then((querySnapshot) => {
                            querySnapshot.forEach((element) => {
                              protocolReminders.push(element.data());
                            });
                            let orderProtocolReminders = orderBy(
                              protocolReminders,
                              ["hours"],
                              ["asc"]
                            );
                            let totalSteps = orderProtocolReminders.length;

                            doc.data().steps.forEach((step) => {
                              let userRol = "";
                              db.collection("users")
                                .where(
                                  "email",
                                  "==",
                                  doc.data().inspectionComTechEmail
                                )
                                .get()
                                .then((query) => {
                                  query.forEach(function (element) {
                                    userRol = element.data().rol;
                                  });

                                  if (_this.date_today >= step.estimatedDate) {
                                    let arrayTemp = {};
                                    arrayTemp["date"] = luxon
                                      .fromISO(step.estimatedDate)
                                      .toFormat("dd-MM-yyyy");
                                    arrayTemp["userId"] =
                                      inspection.data().comTechnicianId;
                                    arrayTemp["userName"] =
                                      inspection.data().comTechnicianName;
                                    arrayTemp["userRol"] = userRol;
                                    arrayTemp["task"] =
                                      "Recordatorio de inoculación";
                                    arrayTemp["taskType"] = "reminder";
                                    arrayTemp["status"] = step.step_date
                                      ? "Realizado"
                                      : "Pendiente";
                                    arrayTemp["farmId"] = doc.data().farmId;
                                    arrayTemp["farmName"] = doc.data().farmName;
                                    arrayTemp["description"] =
                                      "Recordatorio {step} de {totalSteps} a {days} días de inicio";
                                    arrayTemp["step"] = step.step + 1;
                                    arrayTemp["totalSteps"] = totalSteps;
                                    arrayTemp["days"] =
                                      orderProtocolReminders[
                                        step.step
                                      ].repetitionInDays;
                                    arrayTemp["customerId"] =
                                      doc.data().customerId;
                                    arrayTemp["customerName"] =
                                      doc.data().customerName;
                                    arrayTemp["customerType"] = "customer";
                                    arrayTemp["machineId"] =
                                      doc.data().machineId;
                                    arrayTemp["machineName"] =
                                      doc.data().machineName;
                                    arrayTemp["protocolId"] =
                                      doc.data().protocolId;
                                    arrayTemp["uid"] = doc.id;
                                    _this.listTasks.push(arrayTemp);
                                  }
                                });
                            });
                          });
                      }
                    });
                  });
              }
            });
          });

        // Nueva inspección
        await db
          .collection("inspections")
          .where("deleted", "==", false)
          .where("comTechnicianId", "==", userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (doc.data().inspectionDate) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", userId)
                  .get()
                  .then((query) => {
                    query.forEach(function (element) {
                      userRol = element.data().rol;
                    });
                    let arrayTemp = {};
                    arrayTemp["date"] = luxon
                      .fromISO(doc.data().inspectionDate)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["dateCreated"] = luxon
                      .fromISO(doc.data().dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["status"] = "Realizado";
                    arrayTemp["task"] = "Nueva inspección";
                    arrayTemp["taskType"] = "newInspection";
                    arrayTemp["farmId"] = doc.data().farmId;
                    arrayTemp["farmName"] = doc.data().farmName;
                    arrayTemp["description"] = doc.data().observation;
                    arrayTemp["customerId"] = doc.data().customerId;
                    arrayTemp["customerName"] = doc.data().customerName;
                    arrayTemp["customerType"] = "customer";
                    arrayTemp["uid"] = doc.id;
                    arrayTemp["inspection"] = doc.data();

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });
      }
      this.dataLoaded = true;
    },
    async getTaskTechnicians(userId) {
      this.dataLoaded = false;
      let _this = this;

      // Otras tareas
      await db
        .collection("tasks")
        .where("deleted", "==", false)
        .where("userId", "==", userId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (
              JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
              doc.data().businessAreaId
            ) {
              let arrayTemp = doc.data();
              arrayTemp["date"] = luxon
                .fromISO(doc.data().createdDate)
                .toFormat("dd-MM-yyyy");
              arrayTemp["taskType"] = "tasks";
              _this.listTasks.push(arrayTemp);
            }
          });
        });

      if (_this.businessAreaName === "Agro3") {
        // Nuevo tratamiento
        await db
          .collection("treatments")
          .where("appTechnicianId", "==", userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (doc.data().state !== "canceled") {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().appTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (element) {
                      userRol = element.data().rol;
                    });
                    let arrayTemp = {};

                    arrayTemp["status"] = "Realizado";

                    if (doc.data().state === "pending") {
                      arrayTemp["status"] = "Pendiente";
                    }

                    if (_this.date_today >= doc.data().agrozonoDate) {
                      arrayTemp["date"] = luxon
                        .fromISO(doc.data().agrozonoDate)
                        .toFormat("dd-MM-yyyy");
                      arrayTemp["userId"] = doc.data().appTechnicianId;
                      arrayTemp["userName"] = doc.data().appTechnicianName;
                      arrayTemp["userRol"] = userRol;
                      arrayTemp["task"] = "Nuevo tratamiento";
                      arrayTemp["taskType"] = "newTreatment";
                      arrayTemp["farmId"] = doc.data().farmId;
                      arrayTemp["farmName"] = doc.data().farmName;
                      arrayTemp["description"] = doc.data().observation;
                      arrayTemp["customerId"] = doc.data().customerId;
                      arrayTemp["customerName"] = doc.data().customerName;
                      arrayTemp["customerType"] = "customer";
                      arrayTemp["machineId"] = doc.data().machineId;
                      arrayTemp["machineName"] = doc.data().machineName;
                      arrayTemp["uid"] = doc.id;
                      _this.listTasks.push(arrayTemp);
                    }
                  });
              }
            });
          });
      }

      this.dataLoaded = true;
    },
    async getTaskRespTechnicians(userId) {
      this.dataLoaded = false;
      let _this = this;

      if (_this.businessAreaName === "Agro3") {
        // Nueva inspección
        await db
          .collection("inspections")
          .where("deleted", "==", false)
          .where("respTechDelegationId", "==", _this.delegationId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (doc.data().inspectionDate && doc.data().comTechnicianId) {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().comTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (element) {
                      userRol = element.data().rol;
                    });
                    let arrayTemp = {};
                    arrayTemp["date"] = luxon
                      .fromISO(doc.data().inspectionDate)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["dateCreated"] = luxon
                      .fromISO(doc.data().dateCreated)
                      .toFormat("dd-MM-yyyy");
                    arrayTemp["userId"] = doc.data().comTechnicianId;
                    arrayTemp["userName"] = doc.data().comTechnicianName;
                    arrayTemp["userRol"] = userRol;
                    arrayTemp["status"] = "Realizado";
                    arrayTemp["task"] = "Nueva inspección";
                    arrayTemp["taskType"] = "newInspection";
                    arrayTemp["farmId"] = doc.data().farmId;
                    arrayTemp["farmName"] = doc.data().farmName;
                    arrayTemp["description"] = doc.data().observation;
                    arrayTemp["customerId"] = doc.data().customerId;
                    arrayTemp["customerName"] = doc.data().customerName;
                    arrayTemp["customerType"] = "customer";
                    arrayTemp["uid"] = doc.id;
                    arrayTemp["inspection"] = doc.data();

                    _this.listTasks.push(arrayTemp);
                  });
              }
            });
          });

        // Nuevo tratamiento
        await db
          .collection("treatments")
          .where("appTechnicianId", "==", userId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (doc.data().state !== "canceled") {
                let userRol = "";
                db.collection("users")
                  .where("uid", "==", doc.data().appTechnicianId)
                  .get()
                  .then((query) => {
                    query.forEach(function (element) {
                      userRol = element.data().rol;
                    });
                    let arrayTemp = {};

                    arrayTemp["status"] = "Realizado";

                    if (doc.data().state === "pending") {
                      arrayTemp["status"] = "Pendiente";
                    }

                    if (_this.date_today >= doc.data().agrozonoDate) {
                      arrayTemp["date"] = luxon
                        .fromISO(doc.data().agrozonoDate)
                        .toFormat("dd-MM-yyyy");
                      arrayTemp["userId"] = doc.data().appTechnicianId;
                      arrayTemp["userName"] = doc.data().appTechnicianName;
                      arrayTemp["userRol"] = userRol;
                      arrayTemp["task"] = "Nuevo tratamiento";
                      arrayTemp["taskType"] = "newTreatment";
                      arrayTemp["farmId"] = doc.data().farmId;
                      arrayTemp["farmName"] = doc.data().farmName;
                      arrayTemp["description"] = doc.data().observation;
                      arrayTemp["customerId"] = doc.data().customerId;
                      arrayTemp["customerName"] = doc.data().customerName;
                      arrayTemp["customerType"] = "customer";
                      arrayTemp["machineId"] = doc.data().machineId;
                      arrayTemp["machineName"] = doc.data().machineName;
                      arrayTemp["uid"] = doc.id;
                      _this.listTasks.push(arrayTemp);
                    }
                  });
              }
            });
          });
      }

      // Otras tareas
      await db
        .collection("tasks")
        .where("deleted", "==", false)
        .where("userId", "==", userId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (
              JSON.parse(sessionStorage.getItem("businessAreaActive")).uid ===
              doc.data().businessAreaId
            ) {
              let arrayTemp = doc.data();
              arrayTemp["date"] = luxon
                .fromISO(doc.data().createdDate)
                .toFormat("dd-MM-yyyy");
              arrayTemp["taskType"] = "tasks";
              _this.listTasks.push(arrayTemp);
            }
          });
        });

      this.dataLoaded = true;
    },
  },
  created() {
    this.$root.$on("reloadListTask", () => {
      this.getTasks();
    });
    Event.listen("reloadTasks", () => {
      this.businessAreaName = JSON.parse(
        sessionStorage.getItem("businessAreaActive")
      ).name;
    });
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    let date = new Date(luxon.local().toISO());
    let date_format =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    this.date_today = luxon.fromFormat(date_format, "d/MM/y").toISO();

    if (!this.date_today) {
      this.date_today = luxon.fromFormat(date_format, "d/M/y").toISO();
    }

    this.getCustomerAndLeads();

    this.businessAreaName = JSON.parse(
      sessionStorage.getItem("businessAreaActive")
    ).name;

    this.userRol = sessionStorage.getItem("user_rol");
    this.userId = sessionStorage.getItem("user_id");
  },
};
</script>
