<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="md:grid md:grid-cols-4 md:gap-4 mb-6">
          <div class="col-span-4">
            <div
              class="
                w-full
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
                md:mb-0
              "
            >
              <button
                class="
                  bg-gray-200
                  mr-2
                  relative
                  border border-gray-200
                  text-xs
                  font-semibold
                  hover:bg-white
                  text-gray-700
                  pb-1
                  px-3
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                style="top: -2px"
                type="button"
                @click="openDetailLayoutNote"
              >
                <Zondicon
                  icon="arrow-thin-left"
                  class="
                    fill-current
                    inline-block
                    h-3
                    text-gray-700
                    cursor-pointer
                    hover:text-gray-700
                  "
                />
              </button>
            </div>
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="
                uppercase
                block
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Albarán ref") }}: {{ idCreatedToShare }}
            </label>
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="
                uppercase
                block
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Fecha") }}: {{ date | formatDate }}
            </label>
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="delegationName"
            >
              {{ $t("Delegación") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('Delegación')"
              id="delegationName"
              v-model="delegationName"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="distributorName"
            >
              {{ $t("Distribuidor") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="distributorName"
              v-model="distributorName"
              :placeholder="this.$t('Distribuidor')"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="customerName"
            >
              {{ $t("Cliente") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="customerName"
              v-model="customerName"
              :placeholder="this.$t('Cliente')"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="customerPhone"
            >
              {{ $t("Teléfono") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="customerPhone"
              v-model="customerPhone"
              :placeholder="this.$t('Teléfono')"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="appTechName"
            >
              {{ $t("Técnico aplicador") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="appTechName"
              v-model="appTechName"
              :placeholder="this.$t('Técnico aplicador')"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="customerEmail"
            >
              {{ $t("Correo electrónico") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="customerEmail"
              v-model="customerEmail"
              :placeholder="this.$t('Correo electrónico')"
              readonly
            />
          </div>
          <div
            class="
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              my-6
              md:my-0
              col-span-4
            "
          >
            {{ $t("Ubicación") }}
          </div>
          <div class="col-span-4">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="farmAddress"
            >
              {{ $t("Dirección parcela") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="farmAddress"
              v-model="farmAddress"
              :placeholder="this.$t('Dirección parcela')"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="city"
            >
              {{ $t("Localidad") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="city"
              v-model="city"
              :placeholder="this.$t('Localidad')"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="province"
            >
              {{ $t("Provincia") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="province"
              v-model="province"
              :placeholder="this.$t('Provincia')"
              readonly
            />
          </div>
          <div
            class="
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              my-6
              md:my-0
              col-span-4
            "
          >
            {{ $t("Tratamiento") }}
          </div>
          <div class="col-span-4">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="protocolName"
            >
              {{ $t("Protocolo") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="protocolName"
              v-model="protocolName"
              :placeholder="this.$t('Protocolo')"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="cultivation"
            >
              {{ $t("Tipo de cultivo") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="cultivation"
              v-model="cultivation"
              :placeholder="this.$t('Tipo de cultivo')"
              readonly
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="subType"
            >
              {{ $t("Subtipo") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="subType"
              v-model="subType"
              :placeholder="this.$t('Subtipo')"
              readonly
            />
          </div>
          <div class="col-span-2 sm:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="initTimeTreatment"
            >
              {{ $t("Inicio tto") }} <span class="text-bold text-red">*</span>
            </label>
            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
              type="time"
              title="Hora"
              placeholder="HH:MM"
              zone="local"
              id="initTimeTreatment"
              :minute-step="5"
              value-zone="local"
              v-model="initTimeTreatment"
            ></datetime>
          </div>
          <div class="col-span-2 sm:col-span-1">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="finishTimeTreatment"
            >
              {{ $t("Fin tto") }} <span class="text-bold text-red">*</span>
            </label>
            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
              type="time"
              title="Hora"
              placeholder="HH:MM"
              zone="local"
              id="finishTimeTreatment"
              :minute-step="5"
              value-zone="local"
              v-model="finishTimeTreatment"
            ></datetime>
          </div>
          <div class="col-span-4 sm:col-span-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="surface"
            >
              {{ $t("Superficie tratada") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              id="surface"
              v-model="surface"
              :placeholder="this.$t('Superficie tratada')"
              readonly
            />
          </div>
        </div>
        <div class="grid grid-cols-10 gap-8 mb-6">
          <div
            class="
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              col-span-10
              mb-4
              md:mb-0
            "
          >
            {{ $t("Aplicación de productos system") }}
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="systemBasic"
            >
              {{ $t("System basic") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="systemBasic"
              v-model="systemBasic"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system1"
            >
              {{ $t("System") }} 1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system1"
              v-model="system1"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system2"
            >
              {{ $t("System") }} 2
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system2"
              v-model="system2"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system2_1"
            >
              {{ $t("System") }} 2.1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system2_1"
              v-model="system2_1"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system3"
            >
              {{ $t("System") }} 3
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system3"
              v-model="system3"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system4"
            >
              {{ $t("System") }} 4
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system4"
              v-model="system4"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system5"
            >
              {{ $t("System") }} 5
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system5"
              v-model="system5"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system6"
            >
              {{ $t("System") }} 6
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system6"
              v-model="system6"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system6_1"
            >
              {{ $t("System") }} 6.1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system6_1"
              v-model="system6_1"
              readonly
            />
          </div>
          <div class="sm:col-span-2 col-span-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="system7"
            >
              {{ $t("System") }} 7
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('System')"
              id="system7"
              v-model="system7"
              readonly
            />
          </div>
        </div>
        <div class="grid grid-cols-10 gap-8 mb-4">
          <div class="sm:col-span-5 col-span-10">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="customerSignaturePath"
            >
              {{ $t("Firma cliente") }}
              <span class="text-bold text-red">*</span>
            </label>
            <textarea
              class="
                hidden
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('Firma cliente')"
              id="customerSignaturePath"
              v-model="customerSignaturePath"
            ></textarea>

            <img
              v-if="customerSignature"
              :src="customerSignature"
              class="image"
              alt="customer signature"
            />

            <VueSignaturePad
              class="border border-gray-400"
              width="100%"
              height="200px"
              ref="signatureCustomerPad"
              :class="{ hidden: customerSignature !== '' }"
            />

            <div class="text-sm text-gray-500">
              {{ $t(customerSignatureStatus) }}
            </div>

            <div
              class="text-right"
              :class="{ hidden: customerSignature !== '' }"
            >
              <button
                class="
                  bg-gray-600
                  mt-2
                  ml-4
                  text-sm
                  font-semibold
                  hover:bg-gray-500
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-on:click="clearSignatureCustomer"
              >
                {{ $t("Limpiar") }}
              </button>
              <button
                class="
                  bg-gray-600
                  mt-2
                  ml-4
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-on:click="saveSignatureCustomer"
              >
                {{ $t("Terminar firma") }}
              </button>
            </div>
          </div>
          <div class="sm:col-span-5 col-span-10">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="technicianSignaturePath"
            >
              {{ $t("Firma técnico") }}
              <span class="text-bold text-red">*</span>
            </label>
            <textarea
              class="
                hidden
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('Firma técnico')"
              id="technicianSignaturePath"
              v-model="technicianSignaturePath"
            ></textarea>

            <img
              v-if="technicianSignature"
              :src="technicianSignature"
              class="image"
              alt="technician signature"
            />

            <VueSignaturePad
              class="border border-gray-400"
              width="100%"
              height="200px"
              ref="signatureTechnicianPad"
              :class="{ hidden: technicianSignature !== '' }"
            />

            <div class="text-sm text-gray-500">
              {{ $t(technicianSignatureStatus) }}
            </div>

            <div
              class="text-right"
              :class="{ hidden: technicianSignature !== '' }"
            >
              <button
                class="
                  bg-gray-600
                  mt-2
                  ml-4
                  text-sm
                  font-semibold
                  hover:bg-gray-500
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-on:click="clearSignatureTechnician"
              >
                {{ $t("Limpiar") }}
              </button>
              <button
                class="
                  bg-gray-600
                  mt-2
                  ml-4
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-on:click="saveSignatureTechnician"
              >
                {{ $t("Terminar firma") }}
              </button>
            </div>
          </div>
          <div class="sm:col-span-5 col-span-10">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="customerDNI"
            >
              {{ $t("DNI cliente") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('DNI cliente')"
              id="customerDNI"
              v-model="customerDNI"
            />
          </div>
        </div>
        <div
          v-if="errorForm"
          class="bg-red text-white px-4 py-2 mb-2 col-span-4"
        >
          <strong>{{ $t("Ha ocurrido un error") }}</strong>
          <div v-for="(error, index) in messageErrors" :key="index">
            <span class="block ml-2">-{{ $t(error.message) }}</span>
          </div>
        </div>
        <div class="flex justify-end mt-4">
          <button
            class="
              inline-flex
              items-center
              px-4
              py-2
              text-white
              bg-orange-500
              leading-5
              font-bold
              rounded-md
              hover:bg-orange-400
            "
            v-on:click="validationForm"
            type="button"
          >
            {{ $t("Guardar") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db, firebaseGlobal } from "@/plugins/firebase";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Zondicon from "vue-zondicons";
import moment from "moment";
import HeaderModal from "@/components/HeaderModal";
import ApplicationDetailLayoutNoteModal from "@/pages/ApplicationDetail/ApplicationDetailLayoutNoteModal";

const ApplicationDetailAddDeliveryNoteModal = {
  name: "ApplicationDetailAddDeliveryNoteModal",
  layout: "layout",
  components: {
    datetime: Datetime,
    Zondicon,
    HeaderModal,
    ApplicationDetailLayoutNoteModal,
  },
  data: function () {
    return {
      customer: "",
      distributor: "",
      farm: "",
      inspection: "",
      delegation: "",
      protocol: "",
      subType: "",

      cultivation: "",
      protocolName: "",
      nameTreatment: "",
      customerDNI: "",

      customerSignatureStatus: "",
      customerSignaturePath: "",
      customerSignature: "",

      technicianSignatureStatus: "",
      technicianSignaturePath: "",
      technicianSignature: "",

      idCreatedToShare: "",
      date: new Date().toISOString(),
      delegationName: "",
      distributorName: "",
      customerName: "",
      customerPhone: "",
      customerEmail: "",
      appTechName: "",
      farmAddress: "",
      city: "",
      province: "",
      initTimeTreatment: "",
      finishTimeTreatment: "",
      surface: "",
      systemBasic: 0,
      system1: 0,
      system2: 0,
      system2_1: 0,
      system3: 0,
      system4: 0,
      system5: 0,
      system6: 0,
      system6_1: 0,
      system7: 0,
      treatment: "",
      messageErrors: [],
      errorForm: false,
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  methods: {
    openDetailLayoutNote() {
      this.$emit("close");
      this.$modal.show(ApplicationDetailLayoutNoteModal, {
        title: this.$t("Visualización de albaranes"),
        params: {
          treatmentID: this.treatment.uid,
          treatment: this.params.treatment,
        },
      });
    },
    validationForm() {
      this.messageErrors = [];
      let errors = {
        message: "",
      };
      let listErrors = [];
      let saveContinue = false;

      if (
        this.initTimeTreatment === "" ||
        this.finishTimeTreatment === "" ||
        this.customerSignaturePath === "" ||
        this.technicianSignaturePath === ""
      ) {
        saveContinue = true;
        errors.message = "Debe introducir todos los datos de este formulario";
        listErrors.push(errors);
        errors = {
          message: "",
        };
      }

      if (this.initTimeTreatment !== "" && this.finishTimeTreatment !== "") {
        let initTime = moment(this.initTimeTreatment).format("HH:mm");
        let finishTime = moment(this.finishTimeTreatment).format("HH:mm");

        if (initTime.toString() >= finishTime.toString()) {
          saveContinue = true;
          errors.message =
            "La hora de inicio de tratamiento no puede ser superior o igual a la hora fin de tratamiento";
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (saveContinue) {
        this.errorForm = saveContinue;
        this.messageErrors = listErrors;
      } else {
        this.messageErrors = [];
        this.saveDeliveryNote();
      }
    },
    saveDeliveryNote() {
      let _this = this;
      let refDeliveryNote = db
        .collection("treatments")
        .doc(this.treatment.uid)
        .collection("deliveryNotes")
        .doc();

      refDeliveryNote
        .set({
          appTechName: _this.treatment.appTechnicianName
            ? _this.treatment.appTechnicianName
            : "",
          city: _this.farm.city ? _this.farm.city : "",
          comTechnicianEmail: _this.inspection.comTechnicianEmail
            ? _this.inspection.comTechnicianEmail
            : "",
          comTechnicianName: _this.inspection.comTechnicianName
            ? _this.inspection.comTechnicianName
            : "",
          contactEmail: _this.inspection.contactEmail
            ? _this.inspection.contactEmail
            : "",
          contactName: _this.inspection.contactName
            ? _this.inspection.contactName
            : "",
          cultivation: _this.cultivation,
          customerBase64Signature: _this.customerSignature,
          customerDNI: _this.customerDNI,
          customerEmail: _this.customerEmail,
          customerName: _this.customerName,
          customerSignaturePath: _this.customerSignaturePath,
          customerTelf: _this.customerPhone,
          date: _this.date,
          dateSentEmailDeliveryNote: _this.date,
          delegationId: _this.delegation.uid ? _this.delegation.uid : "",
          delegationName: _this.delegationName ? _this.delegationName : "",
          distributorAddress: _this.distributor.address
            ? _this.distributor.address
            : "",
          distributorCif: _this.distributor.cif ? _this.distributor.cif : "",
          distributorId: _this.farm.distributorId
            ? _this.farm.distributorId
            : "",
          distributorName: _this.distributorName ? _this.distributorName : "",
          farmAddress: _this.farmAddress,
          farmName: _this.farm.name,
          finishTimeTreatment: _this.finishTimeTreatment,
          firstDeliveryNote: true,
          idAgro: _this.params.lastDeliveryNote.toString(),
          idCreatedToShare: _this.idCreatedToShare,
          initTimeTreatment: _this.initTimeTreatment,
          inspectionComTechnicianEmail: _this.inspection.comTechnicianEmail,
          inspectionProtocolID: _this.treatment.protocolId,
          inspectionProtocolName: _this.protocolName,
          inspectionProtocolNameLocalized: _this.protocolName,
          lang: _this.treatment.lang,
          numRef: _this.idCreatedToShare,
          province: _this.farm.province,
          surface: _this.surface,
          systemBasic: _this.systemBasic,
          system1: _this.system1,
          system2: _this.system2,
          system2_1: _this.system2_1,
          system3: _this.system3,
          system4: _this.system4,
          system5: _this.system5,
          system6: _this.system6,
          system6_1: _this.system6_1,
          system7: _this.system7,
          techBase64Signature: _this.technicianSignature,
          techSignaturePath: _this.technicianSignaturePath,
          treatmentId: _this.treatment.uid,
          uid: refDeliveryNote.id,
        })
        .then(() => {
          this.errorForm = false;
          this.openDetailLayoutNote();
        })
        .catch(function (error) {
          this.errorForm = true;
          this.messageErrors.message =
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error: " +
            error.message;
        });
    },
    getCustomer() {
      let _this = this;
      db.collection("customers")
        .where("uid", "==", _this.treatment.customerId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _this.customer = doc.data();
            _this.customerName = doc.data().name;
            _this.customerPhone = doc.data().phone;
            _this.customerEmail = doc.data().email;
          });
        });
    },
    getProtocol() {
      let _this = this;
      db.collection("protocols")
        .where("uid", "==", _this.treatment.protocolId)
        .where("status", "==", "active")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _this.protocol = doc.data();
            _this.protocolName = doc.data().name;
            this.getSystems();
          });
        });
    },
    getSystems() {
      let totalSurface = 0;

      if (this.treatment.surface1) {
        totalSurface += this.treatment.surface1;
      }

      if (this.treatment.surface2) {
        totalSurface += this.treatment.surface2;
      }

      if (this.treatment.surface3) {
        totalSurface += this.treatment.surface3;
      }

      if (this.treatment.surface4) {
        totalSurface += this.treatment.surface4;
      }

      if (this.treatment.surface5) {
        totalSurface += this.treatment.surface5;
      }

      if (this.treatment.surface6) {
        totalSurface += this.treatment.surface6;
      }

      if (this.treatment.surface7) {
        totalSurface += this.treatment.surface7;
      }

      if (this.treatment.surface8) {
        totalSurface += this.treatment.surface8;
      }

      this.systemBasic = Math.round(this.protocol.SYSBA * totalSurface);
      this.system1 = Math.round(this.protocol.SYS1 * totalSurface);
      this.system2 = Math.round(this.protocol.SYS2 * totalSurface);
      this.system2_1 = Math.round(this.protocol.SYS2_1 * totalSurface);
      this.system3 = Math.round(this.protocol.SYS3 * totalSurface);
      this.system4 = Math.round(this.protocol.SYS4 * totalSurface);
      this.system5 = Math.round(this.protocol.SYS5 * totalSurface);
      this.system6 = Math.round(this.protocol.SYS6 * totalSurface);
      this.system6_1 = Math.round(this.protocol.SYS6_1 * totalSurface);
      this.system7 = Math.round(this.protocol.SYS7 * totalSurface);
      this.surface = totalSurface;
    },
    getInspection() {
      let _this = this;
      db.collection("inspections")
        .where("id", "==", _this.treatment.inspectionId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _this.inspection = doc.data();
            _this.cultivation = doc.data().cultivation;
            _this.subType = doc.data().cultivationSubtype;
            this.getFarm();
          });
        });
    },
    getFarm() {
      let _this = this;
      db.collection("customers")
        .doc(_this.treatment.customerId)
        .collection("farms")
        .where("id", "==", _this.inspection.farmId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _this.farm = doc.data();
            _this.farmAddress = doc.data().address;
            _this.province = doc.data().province;
            _this.city = doc.data().city;
            this.getDistributor();
            this.getDelegation();
          });
        });
    },
    getDistributor() {
      let _this = this;
      if (_this.farm.distributorId) {
        db.collection("distributors")
          .where("uid", "==", _this.farm.distributorId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              _this.distributor = doc.data();
              _this.distributorName = doc.data().name;
            });
          });
      }
    },
    getDelegation() {
      let _this = this;
      if (_this.farm.delegationId) {
        db.collection("delegations")
          .where("uid", "==", _this.farm.delegationId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              _this.delegation = doc.data();
              _this.delegationName = doc.data().name;
            });
          });
      }
    },
    clearSignatureCustomer() {
      this.$refs.signatureCustomerPad.undoSignature();
      this.customerSignatureStatus = "";
    },
    saveSignatureCustomer() {
      if (this.$refs.signatureCustomerPad.isEmpty() === false) {
        const { data } = this.$refs.signatureCustomerPad.saveSignature();

        let _this = this;
        let storageRef = firebaseGlobal.storage().ref();

        let random =
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2);
        let url = "images/deliveryNotes/" + _this.id + "/" + random + ".jpg";
        let imageRef = storageRef.child(url);

        _this.customerSignature = data;

        imageRef.putString(data, "data_url").then(function () {
          _this.customerSignaturePath = url;
          _this.customerSignatureStatus =
            "Imagen subida al servidor correctamente";
        });
      }
    },
    clearSignatureTechnician() {
      this.$refs.signatureTechnicianPad.undoSignature();
      this.technicianSignatureStatus = "";
    },
    saveSignatureTechnician() {
      if (this.$refs.signatureTechnicianPad.isEmpty() === false) {
        const { data } = this.$refs.signatureTechnicianPad.saveSignature();

        let _this = this;
        let storageRef = firebaseGlobal.storage().ref();

        let random =
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2);
        let url = "images/deliveryNotes/" + _this.id + "/" + random + ".jpg";
        let imageRef = storageRef.child(url);

        _this.technicianSignature = data;

        imageRef.putString(data, "data_url").then(function () {
          _this.technicianSignaturePath = url;
          _this.technicianSignatureStatus =
            "Imagen subida al servidor correctamente";
        });
      }
    },
  },
  beforeMount() {
    this.treatment = this.params.treatment;
    this.appTechName = this.treatment.appTechnicianName;
    this.getCustomer();
    this.getInspection();
    this.getProtocol();
    this.idCreatedToShare =
      this.treatment.idCreatedToShare + "/" + this.params.lastDeliveryNote;
  },
};

export default ApplicationDetailAddDeliveryNoteModal;
</script>
