<template>
  <div class="relative">
    <select
      class="
        block
        appearance-none
        w-full
        bg-white
        border border-gray-400
        rounded
        hover:border-gray-500
        px-4
        py-3
        pr-4
        leading-tight
        focus:outline-none focus:shadow-outline
      "
      id="rol"
      v-model="selected"
      @change="onChange"
    >
      <option value="">
        {{ $t("Seleccione un país") }}
      </option>
      <option
        v-for="country in countries"
        :value="country.name"
        v-bind:key="country.name"
      >
        {{ $t(country.name) }}
      </option>
    </select>
    <div
      class="
        pointer-events-none
        absolute
        inset-y-0
        right-0
        flex
        items-center
        px-2
        text-gray-700
      "
    >
      <svg
        class="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: "",
      countries: [
        { name: "Afganistán" },
        { name: "Albania" },
        { name: "Alemania" },
        { name: "Andorra" },
        { name: "Angola" },
        { name: "Anguila" },
        { name: "Antártico" },
        { name: "Antigua y Barbuda" },
        { name: "Arabia Saudita" },
        { name: "Argelia" },
        { name: "Argentina" },
        { name: "Armenia" },
        { name: "Aruba" },
        { name: "Australia" },
        { name: "Austria" },
        { name: "Azerbaiyán" },
        { name: "Bahamas" },
        { name: "Bahréin" },
        { name: "Bangladesh" },
        { name: "Barbados" },
        { name: "Bélgica" },
        { name: "Belice" },
        { name: "Benín" },
        { name: "Bermuda" },
        { name: "Bielorrusia" },
        { name: "Bolivia" },
        { name: "Bosnia-Herzegovina" },
        { name: "Botsuana" },
        { name: "Brasil" },
        { name: "Brunei Darussalam" },
        { name: "Bulgaria" },
        { name: "Burkina Faso" },
        { name: "Burundi" },
        { name: "Bután" },
        { name: "Camboya" },
        { name: "Camerún" },
        { name: "Canadá" },
        { name: "Cape Verde" },
        { name: "Caribe Neerlandés" },
        { name: "Chad" },
        { name: "Chile" },
        { name: "China" },
        { name: "Chipre" },
        { name: "Colombia" },
        { name: "Comoras" },
        { name: "Congo" },
        { name: "Corea del Norte" },
        { name: "Corea del Sur" },
        { name: "Costa de Marfil" },
        { name: "Costa Rica" },
        { name: "Croacia" },
        { name: "Cuba" },
        { name: "Curasao" },
        { name: "Dinamarca" },
        { name: "Dominica" },
        { name: "Ecuador" },
        { name: "Egipto" },
        { name: "El Salvador" },
        { name: "Emiratos Árabes Unidos" },
        { name: "Eritrea" },
        { name: "Eslovaquia" },
        { name: "Eslovenia" },
        { name: "España" },
        { name: "Estados Unidos" },
        { name: "Estonia" },
        { name: "Ethiopia" },
        { name: "Filipinas" },
        { name: "Finlandia" },
        { name: "Fiyi" },
        { name: "Francia" },
        { name: "Gabón" },
        { name: "Gambia" },
        { name: "Georgia" },
        { name: "Ghana" },
        { name: "Gibraltar" },
        { name: "Granada" },
        { name: "Grecia" },
        { name: "Groenlandia" },
        { name: "Guadalupe" },
        { name: "Guam" },
        { name: "Guatemala" },
        { name: "Guayana Francesa" },
        { name: "Guernsey" },
        { name: "Guinea" },
        { name: "Guinea Ecuatorial" },
        { name: "Guinea-bissau" },
        { name: "Guyana" },
        { name: "Haití" },
        { name: "Honduras" },
        { name: "Hong Kong" },
        { name: "Hungría" },
        { name: "India" },
        { name: "Indonesia" },
        { name: "Iran" },
        { name: "Iraq" },
        { name: "Irlanda" },
        { name: "Isla Bouvet" },
        { name: "Isla de Jersey" },
        { name: "Isla de Man" },
        { name: "Isla de Navidad" },
        { name: "Isla Norfolk" },
        { name: "Islandia" },
        { name: "Islas Åland" },
        { name: "Islas Caimán" },
        { name: "Islas Cocos" },
        { name: "Islas Cook" },
        { name: "Islas Feroe" },
        { name: "Islas Georgias del Sur y Sandwich del Sur" },
        { name: "Islas Heard y Mcdonald" },
        { name: "Islas Malvinas" },
        { name: "Islas Marianas del Norte" },
        { name: "Islas Marshall" },
        { name: "Islas Pitcairn" },
        { name: "Islas Salomón" },
        { name: "Islas Svalbard y Jan Mayen" },
        { name: "Islas Turcas y Caicos" },
        { name: "Islas Vírgenes Británicas" },
        { name: "Islas Vírgenes de los Estados Unidos" },
        { name: "Israel" },
        { name: "Italia" },
        { name: "Jamaica" },
        { name: "Japón" },
        { name: "Jordania" },
        { name: "Kazajistán" },
        { name: "Kenia" },
        { name: "Kirguistán" },
        { name: "Kiribati" },
        { name: "Kósovo" },
        { name: "Kuwait" },
        { name: "Laos" },
        { name: "Lesotho" },
        { name: "Letonia" },
        { name: "Líbano" },
        { name: "Liberia" },
        { name: "Libia" },
        { name: "Liechtenstein" },
        { name: "Lituania" },
        { name: "Luxemburgo" },
        { name: "Macao" },
        { name: "Macedonia" },
        { name: "Madagascar" },
        { name: "Malasia" },
        { name: "Malawi" },
        { name: "Maldivas" },
        { name: "Mali" },
        { name: "Malta" },
        { name: "Marruecos" },
        { name: "Martinica" },
        { name: "Mauricio" },
        { name: "Mauritania" },
        { name: "Mayotte" },
        { name: "Micronesia (Estados Federados)" },
        { name: "Moldavia" },
        { name: "Mónaco" },
        { name: "Mongolia" },
        { name: "Montenegro" },
        { name: "Montserrat" },
        { name: "Mozambique" },
        { name: "Myanmar" },
        { name: "Namibia" },
        { name: "Nauru" },
        { name: "Nepal" },
        { name: "Netherlands Antilles" },
        { name: "Nicaragua" },
        { name: "Níger" },
        { name: "Nigeria" },
        { name: "Niue" },
        { name: "Noruega" },
        { name: "Nueva Caledonia" },
        { name: "Nueva Zelanda" },
        { name: "Omán" },
        { name: "Países Bajos (Holanda)" },
        { name: "Pakistán" },
        { name: "Palau" },
        { name: "Palestina" },
        { name: "Panamá" },
        { name: "Papúa Nueva Guinea" },
        { name: "Paraguay" },
        { name: "Perú" },
        { name: "Polinesia Francesa" },
        { name: "Polonia" },
        { name: "Portugal" },
        { name: "Puerto Rico" },
        { name: "Qatar" },
        { name: "Reino Unido" },
        { name: "República Centroafricana" },
        { name: "República Checa" },
        { name: "República Dominicana" },
        { name: "Reunión" },
        { name: "Ruanda" },
        { name: "Rumania" },
        { name: "Rusia" },
        { name: "Sahara occidental" },
        { name: "Samoa" },
        { name: "Samoa americana" },
        { name: "San Bartolomé" },
        { name: "San Cristóbal y Nieves" },
        { name: "San Marino" },
        { name: "San Martín" },
        { name: "San Martín SX" },
        { name: "San Pedro y Miquelón" },
        { name: "San Vicente y las Granadinas" },
        { name: "Santa Helena" },
        { name: "Santa Lucía" },
        { name: "Santo Tomé y Príncipe" },
        { name: "Senegal" },
        { name: "Serbia" },
        { name: "Seychelles" },
        { name: "Sierra Leona" },
        { name: "Singapur" },
        { name: "Siria" },
        { name: "Somalia" },
        { name: "Sri Lanka" },
        { name: "Sudáfrica" },
        { name: "Sudán" },
        { name: "Sudán del Sur" },
        { name: "Suecia" },
        { name: "Suiza" },
        { name: "Suriname" },
        { name: "Swazilandia" },
        { name: "Tailandia" },
        { name: "Taiwán" },
        { name: "Tanzania" },
        { name: "Tayikistán" },
        { name: "Territorio Británico en el Océano Índico" },
        { name: "Territorios Australes Franceses" },
        { name: "Timor Oriental" },
        { name: "Togo" },
        { name: "Tokelau" },
        { name: "Tonga" },
        { name: "Trinidad y Tobago" },
        { name: "Túnez" },
        { name: "Turkmenistán" },
        { name: "Turquía" },
        { name: "Tuvalu" },
        { name: "Ucrania" },
        { name: "Uganda" },
        { name: "Uruguay" },
        { name: "Uzbekistán" },
        { name: "Vanuatu" },
        { name: "Vaticano" },
        { name: "Venezuela" },
        { name: "Vietnam" },
        { name: "Wallis y Futuna" },
        { name: "Yemen" },
        { name: "Yibuti" },
        { name: "Zambia" },
        { name: "Zimbabue" },
      ],
    };
  },
  watch: {
    default: {
      handler: async function () {
        this.selected = this.default;
      },
    },
  },
  props: {
    disabled: {
      required: false,
    },
    default: {
      required: false,
    },
  },
  methods: {
    onChange(event) {
      this.selected = event.target.value;
      this.$emit("input", event.target.value);
    },
    defaultSelected() {
      if (this.default) {
        return (this.selected = this.default);
      }
    },
  },
  computed: {
    style() {
      if (this.disabled) {
        return "bg-gray-200 border-gray-300";
      }

      return "border-gray-300";
    },
  },
  mounted() {
    this.defaultSelected();
  },
};
</script>
