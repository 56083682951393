<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-2/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nameEs"
            >
              {{ $t("Nombre") }} ({{ $t("Español") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="nameEs"
              type="text"
              :placeholder="this.$t('Nombre')"
              v-model="nameEs"
            />
          </div>
          <div class="w-full md:w-2/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nameIt"
            >
              {{ $t("Nombre") }} ({{ $t("Italiano") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('Nombre')"
              id="nameIt"
              v-model="nameIt"
            />
          </div>
          <div class="w-full md:w-2/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nameFr"
            >
              {{ $t("Nombre") }} ({{ $t("Frances") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('Nombre')"
              id="nameFr"
              v-model="nameFr"
            />
          </div>
        </div>

        <div class="mx-2 mb-4">
          <div
            v-if="errorMessage !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ $t(errorMessage) }}</span>
          </div>

          <div
            v-if="successMessage !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(successMessage) }}</span>
          </div>
        </div>
        <div
          v-if="
            userRol === 'appSuperAdministrators' ||
            userRol === 'comTechniciansAdmin' ||
            userEmail === 'rcomercial@agrozono.net'
          "
          class="flex flex-row justify-end space-x-4"
        >
          <button
            class="
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
              bg-white
              hover:bg-greenlight
              text-greenlight
              hover:text-white
              border border-greenlight
            "
            type="button"
            v-on:click="closeModal"
          >
            {{ $t("Cancelar") }}
          </button>
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="checkProtocol"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Aceptar") }}
          </button>
          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";
import ProtocolosEditPage from "@/pages/protocolos/ProtocolosEditPage";

export default {
  name: "ProtocolConfirmCopy",
  layout: "layout",
  components: {
    HeaderModal,
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  data: function () {
    return {
      dataLoaded: true,
      successMessage: "",
      errorMessage: "",
      idAgroProtocols: "",
      idAgroProtocolReminders: "",

      idAgro: "",
      idCreatedToShare: "",
      nameEs: "",
      nameIt: "",
      nameFr: "",
      order: "",
      version: "",
      status: "",
      use: "",
      country: "",
      date_creation: "",
      date_discontinuated: "",
      sys1: "",
      sys2: "",
      sys2_1: "",
      sys3: "",
      sys4: "",
      sys5: "",
      sys6: "",
      sys6_1: "",
      sys7: "",
      sysbas: "",
      protocolReminders: [],
      userRol: "",
      userEmail: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
      this.$modal.show(ProtocolosEditPage, {
        title: this.$t("Editar protocolo"),
        params: {
          id: this.params.id,
        },
      });
    },
    checkProtocol() {
      if (this.nameEs === "" || this.nameIt === "" || this.nameFr === "") {
        this.errorMessage = "Debe de introducir todos los datos";
        return;
      }

      this.copyProtocol();
    },
    copyProtocol() {
      let _this = this;
      _this.dataLoaded = false;
      _this.errorMessage = "";

      let refProtocol = db.collection("protocols").doc();
      refProtocol
        .set({
          idAgro: String(_this.idAgroProtocols),
          idCreatedToShare: "ES/" + _this.idAgroProtocols,
          name: _this.nameEs,
          nameIt: _this.nameIt,
          nameFr: _this.nameFr,
          order: _this.order,
          version: _this.version,
          status: "pending",
          use: _this.use,
          country: _this.country,
          date_creation: _this.date_creation,
          date_discontinuated: _this.date_discontinuated,
          SYS1: _this.sys1,
          SYS2: _this.sys2,
          SYS2_1: _this.sys2_1,
          SYS3: _this.sys3,
          SYS4: _this.sys4,
          SYS5: _this.sys5,
          SYS6: _this.sys6,
          SYS6_1: _this.sys6_1,
          SYS7: _this.sys7,
          SYSBA: _this.sysbas,
          deleted: false,

          uid: refProtocol.id,
        })
        .then(async () => {
          for (const doc of _this.protocolReminders) {
            _this.idAgroProtocolReminders = _this.idAgroProtocolReminders + 1;
            let refProtocolReminder = await db
              .collection("protocolReminders")
              .doc();
            await refProtocolReminder
              .set({
                idAgro: String(_this.idAgroProtocolReminders),
                hours: doc.hours,
                repetitionInDays: doc.repetitionInDays,
                sys1: doc.sys1,
                sys2: doc.sys2,
                sys2_1: doc.sys2_1,
                sys3: doc.sys3,
                sys4: doc.sys4,
                sys5: doc.sys5,
                sys6: doc.sys6,
                sys6_1: doc.sys6_1,
                sys7: doc.sys7,
                sysbas: doc.sysbas,
                comments: doc.comments,
                protocolId: refProtocol.id,
                deleted: false,

                uid: refProtocolReminder.id,
              })
              .then(() => {});
          }

          _this.successMessage =
            "Se ha realizado la copia de este protocolo correctamente";
          this.openProtocolModal(refProtocol.id);
        })
        .catch(function (error) {
          let errorMessage = error.message;

          _this.dataLoaded = true;
          _this.errorMessage =
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
            errorMessage;
        });
    },
    openProtocolModal(id) {
      this.$emit("close");
      this.$modal.show(ProtocolosEditPage, {
        title: this.$t("Editar protocolo"),
        params: {
          id: id,
        },
      });
    },
    getProtocols() {
      db.collection("protocols")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.idAgro = snapshot.data().idAgro;
          this.idCreatedToShare = snapshot.data().idCreatedToShare;
          this.nameEs = "(Copia) " + snapshot.data().name;
          if (snapshot.data().nameIt) {
            this.nameIt = "(Copia) " + snapshot.data().nameIt;
          }

          if (snapshot.data().nameFr) {
            this.nameFr = "(Copie) " + snapshot.data().nameFr;
          }

          this.order = snapshot.data().order;
          this.version = snapshot.data().version;
          this.status = snapshot.data().status;
          this.use = snapshot.data().use;
          this.country = snapshot.data().country;
          this.date_creation = snapshot.data().date_creation;
          this.date_discontinuated = snapshot.data().date_discontinuated;
          this.sys1 = snapshot.data().SYS1;
          this.sys2 = snapshot.data().SYS2;
          this.sys2_1 = snapshot.data().SYS2_1;
          this.sys3 = snapshot.data().SYS3;
          this.sys4 = snapshot.data().SYS4;
          this.sys5 = snapshot.data().SYS5;
          this.sys6 = snapshot.data().SYS6;
          this.sys6_1 = snapshot.data().SYS6_1;
          this.sys7 = snapshot.data().SYS7;
          this.sysbas = snapshot.data().SYSBA;

          this.dataLoaded = true;
        });

      db.collection("protocolReminders")
        .where("protocolId", "==", this.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.protocolReminders.push(doc.data());
          });
        });
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");
    this.userEmail = sessionStorage.getItem("user");
    this.idAgroProtocols = await Utilities.getNextIdAgro("protocols");
    this.idAgroProtocolReminders = await Utilities.getNextIdAgro(
      "protocolReminders"
    );

    this.dataLoaded = false;

    this.getProtocols();
  },
};
</script>
