<template>
  <div>Logout</div>
</template>

<script>
import { firebaseGlobal } from "@/plugins/firebase.js";

const Logout = {
  name: "Logout",
  beforeMount() {
    firebaseGlobal.auth().signOut();

    sessionStorage.clear();
    this.$router.push({ name: "login" });
  },
};

export default Logout;
</script>
