<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-4">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos del tipo de cultivo") }}
          </div>

          <div class="w-full md:w-2/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="name"
            >
              {{ $t("Nombre") }} ({{ $t("Español") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="name"
              type="text"
              :placeholder="this.$t('Nombre')"
              v-model="nameEs"
            />
          </div>
        </div>
        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-2/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nameIt"
            >
              {{ $t("Nombre") }} ({{ $t("Italiano") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="nameIt"
              :placeholder="this.$t('Nombre')"
              v-model="nameIt"
            />
          </div>
        </div>
        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-2/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nameFr"
            >
              {{ $t("Nombre") }} ({{ $t("Frances") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="nameFr"
              :placeholder="this.$t('Nombre')"
              v-model="nameFr"
            />
          </div>
        </div>
        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idAgro"
            >
              {{ $t("Id") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="idAgro"
              type="text"
              :placeholder="this.$t('Id')"
              v-model="idAgro"
              readonly
            />
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="messageError !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ messageError }}</span>
          </div>

          <div
            v-if="messageSuccess !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(messageSuccess) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="submit"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Crear nuevo") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";

export default {
  name: "TypesCropAdd",
  layout: "layout",
  components: {
    HeaderModal,
  },
  data() {
    return {
      dataLoaded: true,
      messageSuccess: "",
      messageError: "",
      nameEs: "",
      nameIt: "",
      nameFr: "",
      idAgro: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: false,
    },
  },
  methods: {
    submit() {
      this.dataLoaded = false;
      this.messageSuccess = "";
      this.messageError = "";

      if (this.nameEs === "" || this.nameIt === "" || this.nameFr === "") {
        this.dataLoaded = true;
        this.messageError = this.$t(
          "Debe llenar correctamente todos los campos para guardarlos en el sistema"
        );
        return;
      }
      this.createCropType();
    },
    createCropType() {
      let refCropType = db.collection("cropTypes").doc();
      refCropType
        .set({
          name: this.nameEs,
          nameIt: this.nameIt,
          nameFr: this.nameFr,
          idAgro: String(this.idAgro),
          deleted: false,
          uid: refCropType.id,
        })
        .then(() => {
          this.dataLoaded = true;
          this.messageSuccess =
            "El tipo de cultivo ha sido agregado exitosamente";

          this.$emit("close");
        })
        .catch(function (error) {
          let errorMessage = error.message;

          this.dataLoaded = true;
          this.messageError =
            this.$t(
              "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
            ) + errorMessage;
        });
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.idAgro = await Utilities.getNextIdAgro("cropTypes");
  },
};
</script>
