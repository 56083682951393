<template>
  <div class="h-screen flex justify-center items-center bg-graylogin">
    <div class="w-full max-w-md text-center">
      <div class="text-6xl">404</div>
      <div class="text-2xl">Lo sentimos. Página no encontrada</div>

      <router-link
        to="/"
        class="
          inline-block
          mt-4
          self-center
          text-right
          font-bold
          text-sm text-blue-500
          hover:text-blue-800
        "
      >
        Ir al Inicio
      </router-link>
    </div>
  </div>
</template>

<script>
const Page404 = {
  layout: "",
  name: "404page",
  metaInfo: {
    title: "Página no encontrada",
  },
};

export default Page404;
</script>
