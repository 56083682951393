<template>
  <div
    class="
      w-full
      h-full
      flex flex-col
      items-center
      justify-center
      flex-1
      self-center
    "
  >
    <h1 class="block text-5xl">{{ $t("Bienvenido") }}</h1>
    <h5 class="text-xl">
      {{
        $t(
          "Este es el sistema administrativo de agrozono donde podrá visualizar y editar los registros de su sistema"
        )
      }}
    </h5>
  </div>
</template>

<script>
import Utilities from "@/plugins/Utilities.js";

const Home = {
  layout: "layout",
  name: "Home",
  metaInfo: {
    title: "Home",
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }
  },
};

export default Home;
</script>
