<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Clientes y leads") }}
    </div>

    <loader v-if="dataLoaded === false"></loader>

    <div v-if="dataLoaded === true">
      <div class="flex flex-row items-center mb-4">
        <div>
          <div class="sm:flex">
            <div class="block relative mr-4 w-full sm:w-64 mb-3 sm:mb-0">
              <label>
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  @change="getCustomersAndLeads"
                  v-model="selectUser"
                >
                  <option value="all">{{ $t("Todos los roles") }}</option>
                  <option value="Clientes">{{ $t("Clientes") }}</option>
                  <option value="Leads">{{ $t("Leads") }}</option>
                </select>
              </label>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>

            <div class="flex items-center">
              <label>
                <input
                  class="
                    w-40
                    appearance-none
                    block
                    bg-white
                    text-gray-700
                    border border-red-500
                    rounded
                    py-3
                    px-4
                    leading-tight
                    focus:outline-none focus:bg-white
                  "
                  type="text"
                  :placeholder="this.$t('Buscar')"
                  v-model="search"
                />
              </label>
              <button
                class="
                  bg-yellow
                  ml-4
                  mr-2
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-3
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                v-if="
                  userRol === 'comTechnicians' || userRol === 'distributors'
                "
                type="button"
                @click="addCustomerLead"
              >
                {{ $t("Agregar cliente/lead") }}
              </button>
            </div>

            <vue-excel-xlsx
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              :data="exportExcel"
              :columns="columnsExcel"
              filename="clientes y leads"
              sheetname="clientes y leads"
            >
              {{ $t("Exportar listado") }}
            </vue-excel-xlsx>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Comercial") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Cliente") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Teléfono") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Correo electrónico") }}
                    </div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Roles usuario") }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in filteredUsers" :key="index">
                  <td class="px-6 py-4 whitespace-no-wrap w-4">
                    {{ user.commercial }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div @click="editCustomerLead(user.id, user.rol_type)">
                      <span
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ user.name !== "" ? user.name : user.email }}</span
                      >
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-4">
                    {{ user.phone }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">{{ user.email }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    {{ user.rol }}
                  </td>
                </tr>

                <tr v-if="filteredUsers.length === 0">
                  <td class="border px-4 py-3" colspan="6">
                    {{
                      $t("No hay clientes o leads registrados en el sistema")
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import AreaClientesAddPage from "@/pages/CustomerLeadArea/CustomerLeadAreaAddPage";
import AreaClientesEditPage from "@/pages/CustomerLeadArea/CustomerLeadAreaEditPage";

export default {
  layout: "layout",
  name: "AreaCustomersAndLeads",
  metaInfo: {
    title: "Clientes y leads",
  },
  components: {
    Loader,
  },
  data() {
    return {
      dataLoaded: false,
      users: [],
      selectUser: "all",
      search: "",
      userRol: sessionStorage.getItem("user_rol"),
      userLogin: sessionStorage.getItem("user_id"),
      distributors: [],
      comTechnicians: [],
      columnsExcel: [
        {
          label: this.$t("Cliente"),
          field: "client",
        },
        {
          label: this.$t("Teléfono"),
          field: "phone",
        },
        {
          label: this.$t("Correo electrónico"),
          field: "email",
        },
        {
          label: this.$t("País"),
          field: "country",
        },
        {
          label: this.$t("Provincia"),
          field: "province",
        },
        {
          label: this.$t("Fecha creación"),
          field: "createdDate",
        },
        {
          label: this.$t("Ciudad"),
          field: "city",
        },
        {
          label: this.$t("Dirección"),
          field: "address",
        },
        {
          label: this.$t("Cp"),
          field: "cp",
        },
        {
          label: this.$t("Cif"),
          field: "cif",
        },
        {
          label: this.$t("Id agro"),
          field: "idAgro",
        },
        {
          label: this.$t("Id created to share"),
          field: "idCreatedToShare",
        },
        {
          label: this.$t("Contacto"),
          field: "contact",
        },
        {
          label: this.$t("Detalles"),
          field: "details",
        },
        {
          label: this.$t("Comercial"),
          field: "commercial",
        },
        {
          label: this.$t("Distribuidor"),
          field: "distributor",
        },
        {
          label: this.$t("Roles usuario"),
          field: "rol",
        },
      ],
    };
  },
  computed: {
    exportExcel() {
      return this.filteredUsers.map((element) => {
        return {
          commercial: element.commercial,
          client: element.name,
          phone: element.phone,
          email: element.email,
          country: element.country,
          province: element.province,
          createdDate: element.createdDate,
          city: element.city,
          address: element.address,
          cp: element.cp,
          cif: element.cif,
          idAgro: element.idAgro,
          idCreatedToShare: element.idCreatedToShare,
          contact: element.contact,
          details: element.details,
          distributor: element.distributorName,
          rol: this.$t(element.rol),
        };
      });
    },
    techniciansFilteredByCurrentUser() {
      return this.comTechnicians.filter((technical) => {
        if (technical.responsableId === this.userLogin) {
          return technical;
        }
      });
    },
    filteredUsers() {
      let arrayFiltered = [];

      this.users.forEach((element) => {
        if (this.search === "") {
          arrayFiltered.push(element);
        } else {
          if (
            element.name !== undefined &&
            element.commercial !== undefined &&
            element.phone !== undefined &&
            element.email !== undefined
          ) {
            if (
              element.name
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.commercial
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.phone
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.email
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          } else if (
            element.name !== undefined &&
            element.commercial !== undefined &&
            element.email !== undefined
          ) {
            if (
              element.name
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.commercial
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.email
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          } else if (element.name !== undefined) {
            if (
              element.name
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          }
        }
      });

      return arrayFiltered.sort(this.compareNameEn);
    },
  },
  methods: {
    getCustomersAndLeads() {
      this.dataLoaded = false;
      this.search = "";
      this.users = [];

      if (this.selectUser === "all" || this.selectUser === "Clientes") {
        this.dataLoaded = false;

        db.collection("customers")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            let _this = this;

            querySnapshot.forEach(function (doc) {
              let businessAreas = doc.data().businessAreas;
              let checkBusinessAreas = null;

              if (businessAreas) {
                checkBusinessAreas = businessAreas.filter((element) => {
                  if (
                    element.uid ===
                    JSON.parse(sessionStorage.getItem("businessAreaActive")).uid
                  ) {
                    return element;
                  }
                });
              } else {
                doc.data();
              }

              if (checkBusinessAreas && checkBusinessAreas.length !== 0) {
                let arrayTemp = doc.data();
                arrayTemp["id"] = doc.id;
                arrayTemp["name"] = doc.data().name;
                arrayTemp["commercial"] = doc.data().comTechnicianName;
                arrayTemp["phone"] = doc.data().phone;
                arrayTemp["email"] = doc.data().email;
                arrayTemp["rol"] = "Cliente";
                arrayTemp["rol_type"] = "customers";

                if (
                  _this.userRol === "appSuperAdministrators" ||
                  _this.userRol === "appAdministrators" ||
                  _this.userRol === "comTechniciansAdmin"
                ) {
                  _this.users.push(arrayTemp);
                }

                if (
                  _this.userRol === "customers" &&
                  _this.userLogin === doc.id
                ) {
                  _this.users.push(arrayTemp);
                }

                if (_this.userRol === "comTechniciansResp") {
                  _this.techniciansFilteredByCurrentUser.forEach(
                    (technical) => {
                      if (technical.id === doc.data().comTechnicianId) {
                        _this.users.push(arrayTemp);
                      }
                    }
                  );
                }

                if (
                  _this.userRol === "comTechnicians" &&
                  _this.userLogin === doc.data().comTechnicianId
                ) {
                  _this.users.push(arrayTemp);
                }

                if (
                  _this.userRol === "distributors" &&
                  _this.userLogin === doc.data().distributorId
                ) {
                  _this.users.push(arrayTemp);
                }
              }
            });

            this.dataLoaded = true;
          });
      }

      if (this.selectUser === "all" || this.selectUser === "Leads") {
        this.dataLoaded = false;

        db.collection("leads")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            let _this = this;

            querySnapshot.forEach(function (doc) {
              let checkBusinessAreas = doc
                .data()
                .businessAreas.filter((element) => {
                  if (
                    element.uid ===
                    JSON.parse(sessionStorage.getItem("businessAreaActive")).uid
                  ) {
                    return element;
                  }
                });

              if (checkBusinessAreas.length !== 0) {
                let arrayTemp = doc.data();
                arrayTemp["id"] = doc.id;
                arrayTemp["name"] = doc.data().name;
                arrayTemp["commercial"] = doc.data().comTechnicianName;
                arrayTemp["phone"] = doc.data().phone;
                arrayTemp["email"] = doc.data().email;
                arrayTemp["rol"] = "Lead";
                arrayTemp["rol_type"] = "leads";

                if (
                  _this.userRol === "appSuperAdministrators" ||
                  _this.userRol === "appAdministrators" ||
                  _this.userRol === "comTechniciansAdmin"
                ) {
                  _this.users.push(arrayTemp);
                }

                if (_this.userRol === "leads" && _this.userLogin === doc.id) {
                  _this.users.push(arrayTemp);
                }

                if (_this.userRol === "comTechniciansResp") {
                  _this.techniciansFilteredByCurrentUser.forEach(
                    (technical) => {
                      if (technical.id === doc.data().comTechnicianId) {
                        _this.users.push(arrayTemp);
                      }
                    }
                  );
                }

                if (
                  _this.userRol === "comTechnicians" &&
                  _this.userLogin === doc.data().comTechnicianId
                ) {
                  _this.users.push(arrayTemp);
                }

                if (
                  _this.userRol === "distributors" &&
                  _this.userLogin === doc.data().distributorId
                ) {
                  _this.users.push(arrayTemp);
                }
              }
            });

            this.dataLoaded = true;
          });
      }
    },
    addCustomerLead() {
      this.$modal.show(
        AreaClientesAddPage,
        {
          title: this.$t("Agregar usuario"),
          params: null,
        },
        { height: "auto" },
        { "before-close": this.getCustomersAndLeads }
      );
    },
    editCustomerLead(id, rolType) {
      this.$modal.show(
        AreaClientesEditPage,
        {
          title: this.$t("Editar usuario"),
          params: { id: id, rolType: rolType },
        },
        { height: "auto" },
        { "before-close": this.getCustomersAndLeads }
      );
    },
    getDistributors() {
      let _this = this;
      db.collection("distributors")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let arrayTemp = doc.data();
            arrayTemp["id"] = doc.id;

            _this.distributors.push(arrayTemp);
          });
        });
    },
    getComTechnicians() {
      let _this = this;
      db.collection("comTechnicians")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let arrayTemp = doc.data();
            arrayTemp["id"] = doc.id;

            _this.comTechnicians.push(arrayTemp);
          });
        });
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.getDistributors();
    this.getComTechnicians();
    this.getCustomersAndLeads();
  },
};
</script>
