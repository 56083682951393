<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div
      v-if="deletedRegister === false"
      class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6"
    >
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos de la cuenta") }}
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="rol"
            >
              {{ $t("Rol asignado") }}
            </label>
            <div class="inline-block relative w-full">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="rol"
                v-model="rol"
                readonly
              >
                <option value="">{{ $t("Seleccione un rol") }}</option>
                <option value="appTechnicians">{{ $t("Técnico") }}</option>
                <option value="respTechnicians">
                  {{ $t("Responsable técnico") }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="email"
            >
              {{ $t("Email") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="email"
              type="text"
              :placeholder="this.$t('Email')"
              v-model="email"
            />
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="password"
            >
              {{ $t("Password") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="password"
              type="password"
              placeholder="*******"
              v-model="password"
            />
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Plataformas asignadas") }}
            </label>
            <div class="block w-full relative mb-4">
              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="(business, index) in listBusinessAreas"
                :key="index"
                :for="`businessAreas${index}`"
              >
                <input
                  class="mr-2 leading-tight"
                  :id="`businessAreas${index}`"
                  type="checkbox"
                  v-model="businessAreas"
                  :value="business"
                />
                <span class="text-gray-700 text-xs">
                  {{ business.name }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div v-if="rol">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              <template v-if="rol === 'appTechnicians'">{{
                $t("Datos del técnico")
              }}</template>
              <template v-if="rol === 'respTechnicians'">{{
                $t("Datos del técnico responsable")
              }}</template>
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="name"
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                id="name"
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="name"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="country"
              >
                {{ $t("País") }}
              </label>
              <div class="block w-full relative mr-4">
                <selectCountry
                  id="country"
                  v-model="country"
                  :default="country"
                ></selectCountry>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="lang"
              >
                {{ $t("Idioma") }}
              </label>
              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  id="lang"
                  v-model="lang"
                >
                  <option value="">{{ $t("Seleccione un idioma") }}</option>
                  <option value="Español">{{ $t("Español") }}</option>
                  <option value="Italiano">{{ $t("Italiano") }}</option>
                  <option value="Frances">{{ $t("Frances") }}</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="createdDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="createdDate"
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegation"
              >
                {{ $t("Delegación") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  id="delegation"
                  v-model="delegationId"
                  @change="changeDelegacion"
                >
                  <option value="">
                    {{ $t("Seleccione una delegación") }}
                  </option>
                  <option
                    v-for="delegacion in delegations"
                    :value="delegacion.id"
                    :key="delegacion.id"
                  >
                    {{ delegacion.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegationName"
              >
                {{ $t("Delegación nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                id="delegationName"
                type="text"
                :placeholder="this.$t('Delegación nombre')"
                v-model="delegationName"
              />
            </div>
          </div>
        </div>

        <div class="mx-2 mb-4">
          <div
            v-if="errorMessage !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ errorMessage }}</span>
          </div>

          <div
            v-if="successMessage !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(successMessage) }}</span>
          </div>
        </div>
        <div v-if="deletedRegister === false" class="flex flex-row justify-end">
          <button
            class="
              bg-white
              mr-2
              text-sm
              font-semibold
              border border-redagrozono
              hover:bg-redagrozono
              text-redagrozono
              hover:text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            @click="confirmDisable"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Inhabilitar registro") }}
          </button>

          <button
            class="
              hidden
              bg-white
              mr-2
              text-sm
              font-semibold
              border border-redagrozono
              hover:bg-redagrozono
              text-redagrozono
              hover:text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            @click="confirmDelete"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Eliminar") }}
          </button>

          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            @click="onSubmit"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Guardar cambios") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { firebaseGlobal } from "@/plugins/firebase.js";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import SelectCountry from "@/components/SelectCountry";
import HeaderModal from "@/components/HeaderModal";

const AreaTecnicaEdit = {
  name: "AreaTecnicaEdit",
  layout: "layout",
  components: {
    Loader,
    SelectCountry,
    HeaderModal,
  },
  props: {
    title: {
      require: true,
    },
    params: {
      required: true,
    },
  },
  data: function () {
    return {
      dataLoaded: true,
      successMessage: "",
      errorMessage: "",
      deletedRegister: false,
      email: "",
      password: "",
      rol: "",
      name: "",
      createdDate: "",
      country: "",
      lang: "",
      businessAreas: [],
      listBusinessAreas: [],
      delegations: [],
      delegationId: "",
      delegationName: "",
    };
  },
  methods: {
    confirmDisable() {
      if (confirm(this.$t("¿Realmente deseas inhabilitar el usuario?"))) {
        this.disableRegister();
      }
    },
    disableRegister() {
      this.dataLoaded = false;
      let _this = this;

      let dateCurrent = new Date();
      let dateRemoved =
        dateCurrent.getDate() +
        "/" +
        (dateCurrent.getMonth() + 1) +
        "/" +
        dateCurrent.getFullYear() +
        " " +
        dateCurrent.getHours() +
        ":" +
        dateCurrent.getMinutes() +
        ":" +
        dateCurrent.getSeconds();

      let newData = {
        id: this.params.id,
        email: this.email,
        emailVerified: false,
        displayName: this.nombre,
        disabled: true,
      };

      if (this.password.length > 0) {
        newData["password"] = this.password;
      }

      let editUserAdmin = firebaseGlobal
        .functions()
        .httpsCallable("editUserAdmin");

      editUserAdmin(newData)
        .then((result) => {
          if (result.data.code !== 1) {
            _this.dataLoaded = true;
            _this.errorMessage = result.data.message;

            return;
          }

          if (this.rol === "appTechnicians") {
            db.collection("appTechnicians")
              .doc(_this.params.id)
              .update({
                deleted: true,
                disabledDate: dateRemoved,
              })
              .then(() => {
                _this.dataLoaded = true;
                _this.successMessage =
                  "El usuario ha sido inhabilitado exitosamente";
                _this.deletedRegister = true;

                this.$emit("close");
              });
          }

          if (this.rol === "respTechnicians") {
            db.collection("respTechnicians")
              .doc(_this.params.id)
              .update({
                deleted: true,
                disabledDate: dateRemoved,
              })
              .then(() => {
                _this.dataLoaded = true;
                _this.successMessage =
                  "El usuario ha sido inhabilitado exitosamente";
                _this.deletedRegister = true;

                this.$emit("close");
              });
          }
        })
        .catch(function (error) {
          let errorMessage = error.message;

          _this.dataLoaded = true;
          _this.errorMessage =
            _this.$t(
              "Ha ocurrido un error al intentar inhabilitar este usuario. Error:"
            ) + errorMessage;
        });
    },
    confirmDelete() {
      if (
        confirm(
          this.$t("¿Realmente deseas eliminar el usuario") + this.email + "?"
        )
      ) {
        this.deleteRegister();
      }
    },
    deleteRegister() {
      this.dataLoaded = false;
      let _this = this;

      let removeUserAdmin = firebaseGlobal
        .functions()
        .httpsCallable("removeUserAdmin");

      removeUserAdmin({
        id: _this.params.id,
      })
        .then((result) => {
          if (result.data.code === 1) {
            _this.dataLoaded = true;
            _this.errorMessage = result.data.message;

            return;
          }

          db.collection(_this.params.rolType)
            .doc(_this.params.id)
            .delete()
            .then(() => {
              _this.dataLoaded = true;
              _this.successMessage =
                "El usuario ha sido eliminado exitosamente";
              _this.deletedRegister = true;

              db.collection("users").doc(_this.params.id).delete();

              this.$emit("close");
            });
        })
        .catch(function (error) {
          let errorMessage = error.message;

          _this.dataLoaded = true;
          _this.errorMessage =
            _this.$t(
              "Ha ocurrido un error al intentar eliminar este usuario. Error:"
            ) + errorMessage;
        });
    },
    onSubmit() {
      this.dataLoaded = false;
      this.successMessage = "";
      this.errorMessage = "";

      if (this.email === "" && this.businessAreas.length === 0) {
        this.dataLoaded = true;
        this.errorMessage = this.$t(
          "Debe llenar correctamente todos los campos para guardarlos en el sistema"
        );

        return;
      }

      let newData = {
        id: this.params.id,
        email: this.email,
        emailVerified: false,
        displayName: this.nombre,
        disabled: true,
      };

      if (this.password.length > 0) {
        newData["password"] = this.password;
      }

      let _this = this;

      let editUserAdmin = firebaseGlobal
        .functions()
        .httpsCallable("editUserAdmin");

      editUserAdmin(newData)
        .then((result) => {
          if (result.data.code !== 1) {
            _this.dataLoaded = true;
            _this.errorMessage = result.data.message;

            return;
          }

          if (this.rol === "appTechnicians") {
            db.collection("appTechnicians")
              .doc(_this.params.id)
              .update({
                name: _this.name,
                email: _this.email,
                mail: _this.email,
                delegationId: _this.delegationId,
                delegationName: _this.delegationName,
                deleted: false,
              })
              .then(() => {
                _this.dataLoaded = true;
                _this.successMessage =
                  "El usuario ha sido editado exitosamente";

                db.collection("users").doc(_this.params.id).update({
                  email: _this.email,
                  country: _this.country,
                  businessAreas: _this.businessAreas,
                  lang: _this.lang,
                });

                if (sessionStorage.getItem("user") === _this.email) {
                  this.setLang(_this.lang);
                }

                this.$emit("close");
              });
          }

          if (this.rol === "respTechnicians") {
            db.collection("respTechnicians")
              .doc(_this.params.id)
              .update({
                name: _this.name,
                email: _this.email,
                mail: _this.email,
                delegation_id: _this.delegationId,
                delegationName: _this.delegationName,
                deleted: false,
              })
              .then(() => {
                _this.dataLoaded = true;
                _this.successMessage =
                  "El usuario ha sido editado exitosamente";

                db.collection("users").doc(_this.params.id).update({
                  email: _this.email,
                  country: _this.country,
                  businessAreas: _this.businessAreas,
                  lang: _this.lang,
                });

                if (sessionStorage.getItem("user") === _this.email) {
                  this.setLang(_this.lang);
                }

                this.$emit("close");
              });
          }
        })
        .catch(function (error) {
          let errorMessage = error.message;

          _this.dataLoaded = true;
          _this.errorMessage =
            _this.$t(
              "Ha ocurrido un error al intentar editar este usuario. Error:"
            ) + errorMessage;
        });
    },
    changeDelegacion() {
      if (this.delegationId === "") {
        this.delegationName = "";

        return;
      }

      this.delegations.forEach((delegation) => {
        if (delegation.id === this.delegationId) {
          this.delegationName = delegation.name;
        }
      });
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    if (this.params.rolType === "appTechnicians") {
      this.dataLoaded = false;

      db.collection("appTechnicians")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.name = snapshot.data().name;
          this.email = snapshot.data().email;
          this.rol = this.params.rolType;
          this.createdDate = snapshot.data().createdDate;
          this.delegationId = snapshot.data().delegationId;
          this.delegationName = snapshot.data().delegationName;
          this.dataLoaded = true;
        });
    }

    if (this.params.rolType === "respTechnicians") {
      this.dataLoaded = false;

      db.collection("respTechnicians")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.name = snapshot.data().name;
          this.email = snapshot.data().email;
          this.rol = this.params.rolType;
          this.createdDate = snapshot.data().createdDate;
          this.delegationId = snapshot.data().delegation_id;
          this.delegationName = snapshot.data().delegationName;
          this.dataLoaded = true;
        });
    }

    let _this = this;
    db.collection("delegations")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.delegations.push(arrayTemp);
        });
      });

    db.collection("users")
      .doc(this.params.id)
      .get()
      .then((snapshot) => {
        this.country = snapshot.data().country ?? "España";
        this.lang = snapshot.data().lang ?? "Español";
        if (snapshot.data().businessAreas) {
          this.businessAreas = snapshot.data().businessAreas;
        }
      });

    db.collection("businessAreas")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          _this.listBusinessAreas.push(doc.data());
          _this.listBusinessAreas.sort(function (a, b) {
            const nameA = a.name;
            const nameB = b.name;

            let comparison = 0;
            if (nameA > nameB) {
              comparison = 1;
            } else if (nameA < nameB) {
              comparison = -1;
            }
            return comparison;
          });
        });
      });
  },
};

export default AreaTecnicaEdit;
</script>
