<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Área técnica") }}
    </div>

    <loader v-if="dataLoaded === false"></loader>

    <div v-if="dataLoaded === true">
      <div>
        <div class="sm:flex">
          <div class="block relative mr-4 w-full sm:w-64 mb-3 sm:mb-0">
            <label>
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                @change="getUsers"
                v-model="roleFilter"
              >
                <option value="Todos">{{ $t("Todos los roles") }}</option>
                <option value="Tecnicos">{{ $t("Técnicos") }}</option>
                <option value="Responsables Tecnicos">
                  {{ $t("Responsables técnicos") }}
                </option>
              </select>
            </label>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>

          <div class="flex items-center">
            <label>
              <input
                class="
                  w-40
                  appearance-none
                  block
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Buscar')"
                v-model="search"
              />
            </label>

            <button
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              v-if="
                userRol === 'appSuperAdministrators' ||
                userRol === 'appAdministrators'
              "
              type="button"
              @click="createUser"
            >
              {{ $t("Agregar usuario") }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Nombre") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Correo electrónico") }}
                    </div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Roles usuario") }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in filteredUsers" :key="user.id">
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div @click="editUser(user.id, user.rol_type)">
                      <span
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{
                          user.nombre !== "" ? user.nombre : user.email
                        }}</span
                      >
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    {{ user.email }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    {{ user.rol }}
                  </td>
                </tr>

                <tr v-if="filteredUsers.length === 0">
                  <td class="border px-4 py-3" colspan="4">
                    {{
                      $t(
                        "No hay usuarios del área técnica registrados en el sistema"
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import TechnicalAreaAddPage from "@/pages/TechnicalArea/TechnicalAreaAddPage";
import TechnicalAreaEditPage from "@/pages/TechnicalArea/TechnicalAreaEditPage";

const AreaTecnica = {
  layout: "layout",
  name: "AreaTecnica",
  metaInfo: {
    title: "Área técnica",
  },
  components: {
    Zondicon,
    Loader,
  },
  data() {
    return {
      dataLoaded: false,
      users: [],
      roleFilter: "Todos",
      search: "",
      userRol: sessionStorage.getItem("user_rol"),
    };
  },
  methods: {
    getUsers() {
      this.dataLoaded = false;
      this.search = "";

      this.users = [];

      if (this.roleFilter === "Todos" || this.roleFilter === "Tecnicos") {
        this.dataLoaded = false;

        db.collection("appTechnicians")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = [];
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["rol"] = "Técnico";
              arrayTemp["rol_type"] = "appTechnicians";

              _this.users.push(arrayTemp);
            });

            this.dataLoaded = true;
          });
      }

      if (
        this.roleFilter === "Todos" ||
        this.roleFilter === "Responsables Tecnicos"
      ) {
        this.dataLoaded = false;

        db.collection("respTechnicians")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = doc.data();
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["rol"] = "Responsable Técnico";
              arrayTemp["rol_type"] = "respTechnicians";

              _this.users.push(arrayTemp);
            });

            this.dataLoaded = true;
          });
      }
    },
    createUser() {
      this.$modal.show(
        TechnicalAreaAddPage,
        {
          title: this.$t("Agregar usuario"),
          params: null,
        },
        { height: "auto" },
        { "before-close": this.getUsers }
      );
    },
    editUser(id, rolType) {
      this.$modal.show(
        TechnicalAreaEditPage,
        {
          title: this.$t("Editar usuario"),
          params: {
            id: id,
            rolType: rolType,
          },
        },
        { height: "auto" },
        { "before-close": this.getUsers }
      );
    },
  },
  computed: {
    filteredUsers() {
      let arrayFiltered = [];

      this.users.forEach((element) => {
        if (this.search === "") {
          arrayFiltered.push(element);
        } else {
          if (
            element.nombre !== undefined &&
            element.telefono !== undefined &&
            element.email !== undefined
          ) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.telefono
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.email
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          } else if (
            element.nombre !== undefined &&
            element.email !== undefined
          ) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.email
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          } else if (element.nombre !== undefined) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          }
        }
      });

      // PARA ORDENAR EL ARREGLO DE USUARIOS
      arrayFiltered = arrayFiltered.sort(this.compareName);

      return arrayFiltered;
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.getUsers();
  },
};

export default AreaTecnica;
</script>
