<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <div class="w-full">
        <div>
          <div class="flex flex-wrap mb-6">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos del cliente") }}
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="name"
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="name"
                :value="customer.name"
                :placeholder="this.$t('Nombre')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="email"
              >
                {{ $t("Email") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="email"
                :value="customer.email"
                :placeholder="this.$t('Email')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="country"
              >
                {{ $t("País") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="country"
                :value="customer.country"
                :placeholder="this.$t('País')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="province"
              >
                {{ $t("Provincia") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="province"
                :value="customer.province"
                :placeholder="this.$t('Provincia')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="creationDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="creationDate"
                type="text"
                :placeholder="this.$t('Fecha creación')"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="city"
              >
                {{ $t("Ciudad") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="city"
                :value="customer.city"
                :placeholder="this.$t('Ciudad')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="address"
              >
                {{ $t("Dirección") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="address"
                :value="customer.address"
                :placeholder="this.$t('Dirección')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="phone"
              >
                {{ $t("Teléfono") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="phone"
                :value="customer.phone"
                :placeholder="this.$t('Teléfono')"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="cp"
              >
                {{ $t("Cp") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="cp"
                :value="customer.cp"
                :placeholder="this.$t('Cp')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="cif"
              >
                {{ $t("Cif") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="cif"
                :value="customer.cif"
                :placeholder="this.$t('Cif')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="idAgro"
              >
                {{ $t("Id agro") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="idAgro"
                :value="customer.idAgro"
                :placeholder="this.$t('Id agro')"
                readonly
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="idCreatedToShare"
              >
                {{ $t("Id created to share") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="idCreatedToShare"
                :value="customer.idCreatedToShare"
                :placeholder="this.$t('Id created to share')"
                readonly
              />
            </div>

            <div class="w-full md:w-2/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="contact"
              >
                {{ $t("Contacto") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="contact"
                :value="customer.contact"
                :placeholder="this.$t('Contacto')"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-full px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="details"
              >
                {{ $t("Detalles") }}
              </label>
              <textarea
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                type="text"
                id="details"
                :value="customer.details"
                :placeholder="this.$t('Detalles')"
                readonly
              ></textarea>
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechnicianName"
              >
                {{ $t("Comercial") }}
              </label>

              <div class="block w-full relative mr-4">
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border border-red-500
                    rounded
                    py-3
                    px-4
                    mb-3
                    leading-tight
                    focus:outline-none
                  "
                  type="text"
                  id="comTechnicianName"
                  :value="customer.comTechnicianName"
                  :placeholder="this.$t('Comercial')"
                  readonly
                />
              </div>
            </div>
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributorName"
              >
                {{ $t("Distribuidor") }}
              </label>

              <div class="block w-full relative mr-4">
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border border-red-500
                    rounded
                    py-3
                    px-4
                    mb-3
                    leading-tight
                    focus:outline-none
                  "
                  type="text"
                  id="distributorName"
                  :value="customer.distributorName"
                  :placeholder="this.$t('Distribuidor')"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase";
import HeaderModal from "@/components/HeaderModal";

const ApplicationAssignmentCustomerModal = {
  name: "ApplicationAssignmentCustomerModal",
  components: {
    HeaderModal,
  },
  data() {
    return {
      customer: {},
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  methods: {
    getCustomer() {
      let _this = this;

      db.collection("customers")
        .where("uid", "==", this.params.customerID)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _this.customer = doc.data();
          });
        });
    },
  },
  beforeMount() {
    this.getCustomer();
  },
};

export default ApplicationAssignmentCustomerModal;
</script>
