<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Máquinas") }}
    </div>
    <loader v-if="dataLoaded === false"></loader>
    <div v-if="dataLoaded === true">
      <div>
        <div class="sm:flex">
          <div class="flex items-center">
            <label>
              <input
                class="
                  w-40
                  appearance-none
                  block
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Buscar')"
                v-model="search"
              />
            </label>

            <button
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              v-if="userRol === 'appSuperAdministrators'"
              type="button"
              @click="addMachine"
            >
              {{ $t("Agregar máquina") }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Nombre") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Delegación") }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="machine in machinesFilters" v-bind:key="machine.uid">
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div v-on:click="editMachine(machine.uid)">
                      <span
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ machine.name }}</span
                      >
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    {{ machine.delegationName }}
                  </td>
                </tr>

                <tr v-if="machines.length === 0">
                  <td class="border px-4 py-2" colspan="2">
                    {{ $t("No hay máquinas registrados en el sistema") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import MachinesAddPage from "@/pages/Machines/MachinesAddPage";
import MachinesEditPage from "@/pages/Machines/MachinesEditPage";

export default {
  layout: "layout",
  name: "Machine",
  metaInfo: {
    title: "Máquinas",
  },
  components: {
    Loader,
  },
  data() {
    return {
      dataLoaded: false,
      machines: [],
      search: "",
      userRol: "",
    };
  },
  computed: {
    machinesFilters() {
      let machinesFilter = [];
      this.machines.forEach((element) => {
        if (this.search === "") {
          machinesFilter.push(element);
        } else {
          if (
            element.name
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1 ||
            element.delegationName
              .toString()
              .toLowerCase()
              .indexOf(this.search) !== -1
          ) {
            machinesFilter.push(element);
          }
        }
      });

      return machinesFilter.sort(this.compareNameEn);
    },
  },
  methods: {
    getMachines() {
      this.dataLoaded = false;
      this.search = "";

      this.machines = [];
      let _this = this;
      db.collection("machines")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.machines.push(doc.data());
          });

          this.dataLoaded = true;
        });
    },
    addMachine() {
      this.$modal.show(
        MachinesAddPage,
        {
          title: this.$t("Agregar máquina"),
          params: null,
        },
        { height: "auto" },
        { "before-close": this.getMachines }
      );
    },
    editMachine(id) {
      this.$modal.show(
        MachinesEditPage,
        {
          title: this.$t("Editar máquina"),
          params: { id: id },
        },
        { height: "auto" },
        { "before-close": this.getMachines }
      );
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");

    this.getMachines();
  },
};
</script>
