<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos del cliente") }}
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="customerId"
            >
              {{ $t("Cliente") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="customerId"
                v-model="customerId"
                @change="fincaChangeCliente"
              >
                <option value="">{{ $t("Seleccione un cliente") }}</option>
                <option
                  v-for="cliente in listado_clientes"
                  :value="cliente.id"
                  v-bind:key="cliente.id"
                >
                  {{ cliente.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idAgro"
            >
              {{ $t("Id") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="idAgro"
              type="text"
              :placeholder="this.$t('Id')"
              v-model="idAgro"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idCreatedToShare"
            >
              {{ $t("Id created to share") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="idCreatedToShare"
              type="text"
              :placeholder="this.$t('Id created to share')"
              v-model="idCreatedToShare"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos de la finca") }}
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nombre"
            >
              {{ $t("Nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="nombre"
              type="text"
              :placeholder="this.$t('Nombre')"
              v-model="nombre"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="province"
            >
              {{ $t("Provincia") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="province"
              type="text"
              :placeholder="this.$t('Provincia')"
              v-model="province"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="city"
            >
              {{ $t("Ciudad") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="city"
              type="text"
              :placeholder="this.$t('Ciudad')"
              v-model="city"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-2/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="address"
            >
              {{ $t("Dirección") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="address"
              type="text"
              :placeholder="this.$t('Dirección')"
              v-model="address"
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="phone"
            >
              {{ $t("Teléfono") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="phone"
              type="text"
              :placeholder="this.$t('Teléfono')"
              v-model="phone"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="latitude"
            >
              {{ $t("Latitud") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="latitude"
              type="text"
              :placeholder="this.$t('Latitud')"
              v-model="latitude"
            />
          </div>

          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="longitude"
            >
              {{ $t("Longitud") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="longitude"
              type="text"
              :placeholder="this.$t('Longitud')"
              v-model="longitude"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="distributorId"
            >
              {{ $t("Distribuidor") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="distributorId"
                v-model="distributorId"
                @change="fincaChangeDistribuidor"
              >
                <option value="">{{ $t("Seleccione un distribuidor") }}</option>
                <option
                  v-for="distribuidor in listado_distribuidores"
                  :value="distribuidor.id"
                  v-bind:key="distribuidor.id"
                >
                  {{ distribuidor.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="distributorName"
            >
              {{ $t("Distribuidor nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="distributorName"
              type="text"
              :placeholder="this.$t('Distribuidor nombre')"
              v-model="distributorName"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="delegationId"
            >
              {{ $t("Delegación") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="delegationId"
                v-model="delegationId"
                @change="fincaChangeDelegacion"
              >
                <option value="">{{ $t("Seleccione una delegación") }}</option>
                <option
                  v-for="delegacion in listado_delegaciones"
                  :value="delegacion.id"
                  v-bind:key="delegacion.id"
                >
                  {{ delegacion.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="delegationName"
            >
              {{ $t("Delegación nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="delegationName"
              type="text"
              :placeholder="this.$t('Delegación nombre')"
              v-model="delegationName"
            />
          </div>

          <div
            class="w-full md:w-1/3 px-3 mb-6 md:mb-0"
            :class="{
              hidden:
                usuarioRol === 'comTechnicians' ||
                usuarioRol === 'distributors',
            }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="comTechnicianId"
            >
              {{ $t("Comercial") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="comTechnicianId"
                v-model="comTechnicianId"
                @change="fincaChangeComercial"
              >
                <option value="">{{ $t("Seleccione un comercial") }}</option>
                <option
                  v-for="comercial in listado_comerciales"
                  :value="comercial.id"
                  v-bind:key="comercial.id"
                >
                  {{ comercial.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: usuarioRol === 'admin' }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="comTechnicianName"
            >
              {{ $t("Comercial nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="comTechnicianName"
              type="text"
              :placeholder="this.$t('Comercial nombre')"
              v-model="comTechnicianName"
              readonly
            />
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="mensajeError !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ $t(mensajeError) }}</span>
          </div>

          <div
            v-if="mensajeExito !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(mensajeExito) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="submitDatos"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Crear nuevo") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: datosCargados === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import { orderBy } from "lodash";
import HeaderModal from "@/components/HeaderModal";

const FincasAdd = {
  name: "FincasAdd",
  layout: "layout",
  components: {
    Zondicon,
    Loader,
    HeaderModal,
  },
  data() {
    return {
      datosCargados: true,
      mensajeExito: "",
      mensajeError: "",

      usuarioRol: "",
      usuarioActual: "",

      nombre: "",
      customerId: "",
      customerIdAgro: "",
      province: "",
      city: "",
      address: "",
      phone: "",
      idAgro: "",
      idCreatedToShare: "",
      latitude: "",
      longitude: "",
      delegationId: "",
      delegationName: "",
      delegationIdAgro: "",
      distributorId: "",
      distributorName: "",
      distributorIdAgro: "",
      comTechnicianId: "",
      comTechnicianName: "",
      comTechnicianIdAgro: "",

      listado_clientes: [],
      listado_delegaciones: [],
      listado_distribuidores: [],
      listado_comerciales: [],
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: false,
    },
  },
  methods: {
    crearRegistroBD() {
      let refFarms = db
        .collection("customers")
        .doc(this.customerId)
        .collection("farms")
        .doc();

      let dateCurrent = new Date();
      let fechaCreado =
        dateCurrent.getDate() +
        "/" +
        (dateCurrent.getMonth() + 1) +
        "/" +
        dateCurrent.getFullYear() +
        " " +
        dateCurrent.getHours() +
        ":" +
        dateCurrent.getMinutes() +
        ":" +
        dateCurrent.getSeconds();

      console.log(fechaCreado);

      refFarms
        .set({
          name: this.nombre,
          customerID: this.customerId,
          province: this.province,
          city: this.city,
          address: this.address,
          phone: this.phone,
          idAgro: String(this.idAgro),
          idCreatedToShare: this.idCreatedToShare,
          latitude: this.latitude,
          longitude: this.longitude,
          delegationId: this.delegationId,
          delegationName: this.delegationName,
          distributorId: this.distributorId,
          distributorName: this.distributorName,
          comTechnicianId: this.comTechnicianId,
          comTechnicianName: this.comTechnicianName,
          deleted: false,
          createdDate: fechaCreado,
          disabledDate: "N/D",

          id: refFarms.id,
          uid: refFarms.id,
        })
        .then(() => {
          this.datosCargados = true;
          this.mensajeExito = "La finca ha sido agregada exitosamente";

          this.nombre = "";
          this.customerId = "";
          this.customerIdAgro = "";
          this.province = "";
          this.city = "";
          this.address = "";
          this.phone = "";
          this.idAgro = "";
          this.idCreatedToShare = "";
          this.latitude = "";
          this.longitude = "";
          this.delegationId = "";
          this.delegationName = "";
          this.delegationIdAgro = "";
          this.distributorId = "";
          this.distributorName = "";
          this.distributorIdAgro = "";
          this.comTechnicianId = "";
          this.comTechnicianName = "";
          this.comTechnicianIdAgro = "";

          this.$emit("close");
          this.$root.$emit("reloadListFarms");
        })
        .catch(function (error) {
          let errorMessage = error.message;

          this.datosCargados = true;
          this.mensajeError =
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
            errorMessage;
        });
    },
    submitDatos() {
      this.datosCargados = false;
      this.mensajeExito = "";
      this.mensajeError = "";

      if (
        this.nombre !== "" &&
        this.customerId !== "" &&
        this.comTechnicianId !== ""
      ) {
        this.crearRegistroBD();
      } else {
        this.datosCargados = true;
        this.mensajeError =
          "Debe llenar correctamente todos los campos para guardarlos en el sistema. (nombre, cliente, comercial)";
      }
    },
    fincaChangeCliente: async function () {
      if (this.customerId === "") {
        this.idAgro = "";
        this.customerIdAgro = "";
      } else {
        this.idAgro = await Utilities.getNextIdAgroFarms(this.customerId);

        for (const x in this.listado_clientes) {
          if (this.listado_clientes[x].id === this.customerId) {
            this.customerIdAgro = this.listado_clientes[x].idAgro;
            this.distributorId = this.listado_clientes[x].distributorId;
            this.distributorName = this.listado_clientes[x].distributorName;

            await this.generateIdShare();
            break;
          }
        }
      }
    },
    fincaChangeDelegacion() {
      if (this.delegationId === "") {
        this.delegationName = "";
        this.delegationIdAgro = "";
      } else {
        for (const x in this.listado_delegaciones) {
          if (this.listado_delegaciones[x].id === this.delegationId) {
            this.delegationName = this.listado_delegaciones[x].name;
            this.delegationIdAgro = this.listado_delegaciones[x].idAgro;

            this.generateIdShare();
            break;
          }
        }
      }
    },
    fincaChangeDistribuidor() {
      if (this.distributorId === "") {
        this.distributorName = "";
        this.distributorIdAgro = "";
      } else {
        for (const x in this.listado_distribuidores) {
          if (this.listado_distribuidores[x].id === this.distributorId) {
            this.distributorName = this.listado_distribuidores[x].name;
            this.distributorIdAgro = this.listado_distribuidores[x].idAgro;

            this.generateIdShare();
            break;
          }
        }
      }
    },
    fincaChangeComercial() {
      if (this.comTechnicianId === "") {
        this.comTechnicianName = "";
        this.comTechnicianIdAgro = "";
      } else {
        for (const x in this.listado_comerciales) {
          if (this.listado_comerciales[x].id === this.comTechnicianId) {
            this.comTechnicianName = this.listado_comerciales[x].name;
            this.comTechnicianIdAgro = this.listado_comerciales[x].idAgro;

            // PARA CARGAR LOS DATOS DEL COMERCIAL
            this.delegationId = this.listado_comerciales[x].delegationId;
            this.delegationName = this.listado_comerciales[x].delegationName;
            this.delegationIdAgro = "";

            this.generateIdShare();
            break;
          }
        }
      }
    },
    generateIdShare: async function () {
      let distributorIdAgro = await Utilities.getIdAgro(
        "distributors",
        this.distributorId
      );

      this.idCreatedToShare =
        "ES/" +
        distributorIdAgro +
        "/" +
        this.customerIdAgro +
        "/" +
        this.idAgro;
    },
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.usuarioRol = sessionStorage.getItem("user_rol");
    this.usuarioActual = sessionStorage.getItem("user_id");

    let _this = this;

    await db
      .collection("customers")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          if (
            _this.usuarioRol === "appAdministrators" ||
            (_this.usuarioRol === "comTechnicians" &&
              _this.usuarioActual === doc.data().comTechnicianId) ||
            (_this.usuarioRol === "distributors" &&
              _this.usuarioActual === doc.data().distributorId)
          ) {
            let arrayTemp = doc.data();
            arrayTemp["id"] = doc.id;
            arrayTemp["idAgro"] = doc.data().idAgro;

            _this.listado_clientes.push(arrayTemp);
          }
        });

        _this.listado_clientes = orderBy(
          _this.listado_clientes,
          ["name"],
          ["asc"]
        );
      });

    await db
      .collection("delegations")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          arrayTemp["idAgro"] = doc.data().idAgro;

          _this.listado_delegaciones.push(arrayTemp);
        });
      });

    await db
      .collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          if (_this.usuarioRol === "appAdministrators") {
            let arrayTemp = doc.data();
            arrayTemp["id"] = doc.id;
            arrayTemp["idAgro"] = doc.data().idAgro;

            _this.listado_comerciales.push(arrayTemp);
          }

          if (
            _this.usuarioRol === "comTechnicians" &&
            _this.usuarioActual === doc.id
          ) {
            let arrayTemp = doc.data();
            arrayTemp["id"] = doc.id;
            arrayTemp["idAgro"] = doc.data().idAgro;

            _this.listado_comerciales.push(arrayTemp);

            _this.comTechnicianId = doc.id;
            _this.comTechnicianName = doc.data().name;
            _this.comTechnicianIdAgro = doc.data().idAgro;
            _this.fincaChangeComercial();
          }
        });

        _this.listado_comerciales = orderBy(
          _this.listado_comerciales,
          ["name"],
          ["asc"]
        );
      });

    await db
      .collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          if (_this.usuarioRol === "appAdministrators") {
            let arrayTemp = doc.data();
            arrayTemp["id"] = doc.id;
            arrayTemp["idAgro"] = doc.data().idAgro;

            _this.listado_distribuidores.push(arrayTemp);
          }

          if (
            _this.usuarioRol === "distributors" &&
            _this.usuarioActual === doc.id
          ) {
            _this.comTechnicianId = doc.data().comercialId;
            _this.comTechnicianName = doc.data().comercialName;
            _this.comTechnicianIdAgro = doc.data().idAgro;
            _this.fincaChangeComercial();
          }
        });
      });
  },
};

export default FincasAdd;
</script>
