<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div
      v-if="registroEliminado === false"
      class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6"
    >
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos de la cuenta") }}
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Rol asignado") }}
            </label>
            <div class="inline-block relative mr-4 w-full">
              <div>
                <label>
                  <select
                    class="
                      block
                      appearance-none
                      w-full
                      bg-gray-200
                      text-gray-700
                      border border-red-500
                      rounded
                      py-3
                      px-4
                      mb-3
                      leading-tight
                      focus:outline-none
                    "
                    v-model="rol"
                    disabled
                  >
                    <option value="">{{ $t("Seleccione un rol") }}</option>
                    <option
                      v-if="usuarioRol === 'appSuperAdministrators'"
                      value="delegations"
                    >
                      {{ $t("Delegación") }}
                    </option>
                    <option
                      v-if="
                        usuarioRol === 'appSuperAdministrators' ||
                        usuarioRol === 'appAdministrators'
                      "
                      value="distributors"
                    >
                      {{ $t("Distribuidor") }}
                    </option>
                    <option
                      v-if="
                        usuarioRol === 'appSuperAdministrators' ||
                        usuarioRol === 'comTechniciansResp' ||
                        usuarioRol === 'appAdministrators'
                      "
                      value="comTechnicians"
                    >
                      {{ $t("Comercial") }}
                    </option>
                    <option
                      v-if="
                        usuarioRol === 'appSuperAdministrators' ||
                        usuarioRol === 'comTechniciansResp'
                      "
                      value="comTechniciansResp"
                    >
                      {{ $t("Responsable comercial") }}
                    </option>
                  </select>
                </label>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="country"
            >
              {{ $t("País") }}
            </label>
            <div class="block w-full relative mr-4">
              <selectCountry
                id="country"
                v-model="country"
                :default="country"
              ></selectCountry>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="lang"
            >
              {{ $t("Idioma") }}
            </label>
            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="lang"
                v-model="lang"
              >
                <option value="">{{ $t("Seleccione un idioma") }}</option>
                <option value="Español">{{ $t("Español") }}</option>
                <option value="Italiano">{{ $t("Italiano") }}</option>
                <option value="Frances">{{ $t("Frances") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <div>
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="email"
              >
                {{ $t("Email") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="email"
                type="text"
                :placeholder="this.$t('Email')"
                v-model="email"
              />
            </div>
          </div>

          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <div>
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="password"
              >
                {{ $t("Password") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="password"
                type="password"
                placeholder="*******"
                v-model="password"
              />
            </div>
          </div>
          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="businessAreas"
            >
              {{ $t("Plataformas asignadas") }}
            </label>
            <div class="block w-full relative mb-4">
              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="(business, index) in listBusinessAreas"
                :key="index"
              >
                <input
                  class="mr-2 leading-tight"
                  id="businessAreas"
                  type="checkbox"
                  v-model="businessAreas"
                  :value="business"
                  :disabled="disabledBusinessArea"
                />
                <span class="text-gray-700 text-xs">
                  {{ business.name }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div v-if="rol === 'delegations'">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos de la delegación") }}
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="nombre"
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="nombre"
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="nombre"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_country"
              >
                {{ $t("País") }}
              </label>
              <selectCountry
                id="delegations_country"
                v-model="delegations_country"
                :default="delegations_country"
              ></selectCountry>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_province"
              >
                {{ $t("Provincia") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="delegations_province"
                type="text"
                :placeholder="this.$t('Provincia')"
                v-model="delegations_province"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_city"
              >
                {{ $t("Ciudad") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="delegations_city"
                type="text"
                :placeholder="this.$t('Ciudad')"
                v-model="delegations_city"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_address"
              >
                {{ $t("Dirección") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="delegations_address"
                type="text"
                :placeholder="this.$t('Dirección')"
                v-model="delegations_address"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="createdDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="createdDate"
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_telephone"
              >
                {{ $t("Teléfono") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="delegations_telephone"
                type="text"
                :placeholder="this.$t('Teléfono')"
                v-model="delegations_telephone"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_cp"
              >
                {{ $t("Cp") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="delegations_cp"
                type="text"
                :placeholder="this.$t('Cp')"
                v-model="delegations_cp"
              />
            </div>

            <div class="w-full md:w-1/5 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_cif"
              >
                {{ $t("Cif") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="delegations_cif"
                type="text"
                :placeholder="this.$t('Cif')"
                v-model="delegations_cif"
              />
            </div>

            <div class="w-full md:w-1/5 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_idAgro"
              >
                {{ $t("Id agro") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="delegations_idAgro"
                type="text"
                :placeholder="this.$t('Id agro')"
                v-model="delegations_idAgro"
                readonly
              />
            </div>

            <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_isAdmin"
              >
                {{ $t("¿Es administrador?") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  id="delegations_isAdmin"
                  v-model="delegations_isAdmin"
                >
                  <option value="false">{{ $t("No") }}</option>
                  <option value="true">{{ $t("Si") }}</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-2/5 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="delegations_idCreatedToShare"
              >
                {{ $t("Id created to share") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="delegations_idCreatedToShare"
                type="text"
                :placeholder="this.$t('Id created to share')"
                v-model="delegations_idCreatedToShare"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="hidden w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Distribuidores") }}
              </label>

              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="distribuidor in listado_distribuidores"
                v-bind:key="distribuidor.id"
              >
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  id=""
                  v-model="delegations_distributors"
                  :value="distribuidor.id"
                />
                <span class="text-gray-700 text-xs">
                  {{ distribuidor.name }}
                </span>
              </label>
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Máquinas") }}
              </label>

              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="maquina in listado_maquinas"
                v-bind:key="maquina.id"
              >
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="delegations_machines"
                  :value="maquina.id"
                />
                <span class="text-gray-700 text-xs">
                  {{ maquina.name }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="rol === 'distributors'">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos del distribuidor") }}
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributor_nombre"
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributor_nombre"
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="nombre"
              />
            </div>

            <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributors_address"
              >
                {{ $t("Dirección") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributors_address"
                type="text"
                :placeholder="this.$t('Dirección')"
                v-model="distributors_address"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributors_cif"
              >
                {{ $t("Cif") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributors_cif"
                type="text"
                :placeholder="this.$t('Cif')"
                v-model="distributors_cif"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributors_idAgro"
              >
                {{ $t("Id agro") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributors_idAgro"
                type="text"
                :placeholder="this.$t('Id agro')"
                v-model="distributors_idAgro"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributors_idCreatedToShare"
              >
                {{ $t("Id created to share") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributors_idCreatedToShare"
                type="text"
                :placeholder="this.$t('Id created to share')"
                v-model="distributors_idCreatedToShare"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributor_createdDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributor_createdDate"
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributors_delegationId"
              >
                {{ $t("Delegación") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  id="distributors_delegationId"
                  v-model="distributors_delegationId"
                  @change="distribuidorChangeDelegacion"
                >
                  <option value="">
                    {{ $t("Seleccione una delegación") }}
                  </option>
                  <option
                    v-for="delegacion in listado_delegaciones"
                    :value="delegacion.id"
                    v-bind:key="delegacion.id"
                  >
                    {{ delegacion.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributors_delegationName"
              >
                {{ $t("Delegación nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributors_delegationName"
                type="text"
                :placeholder="this.$t('Delegación nombre')"
                v-model="distributors_delegationName"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributors_comercialId"
              >
                {{ $t("Comercial") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  id="distributors_comercialId"
                  v-model="distributors_comercialId"
                  @change="distribuidorChangeComercial"
                >
                  <option value="">{{ $t("Seleccione un comercial") }}</option>
                  <!--                                    <option v-for="comercial in listado_comerciales_distribuidor" :value="comercial.id" v-bind:key="comercial.id">{{ comercial.name }}</option>-->
                  <option
                    v-for="technician in technicianList"
                    :value="technician.id"
                    v-bind:key="technician.id"
                  >
                    {{ technician.name }} [{{ technician.responsableName }}]
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributors_comercialName"
              >
                {{ $t("Comercial nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributors_comercialName"
                type="text"
                :placeholder="this.$t('Comercial nombre')"
                v-model="distributors_comercialName"
              />
            </div>
          </div>
        </div>

        <div v-if="rol === 'comTechniciansResp'">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos del comercial responsable") }}
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="technicianResp_nombre"
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="technicianResp_nombre"
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="nombre"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="technicianResp_createdDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="technicianResp_createdDate"
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechniciansResp_delegationId"
              >
                {{ $t("Delegación") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-gray-200
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  id="comTechniciansResp_delegationId"
                  v-model="comTechniciansResp_delegationId"
                  @change="comercialRespChangeDelegacion"
                >
                  <option value="">
                    {{ $t("Seleccione una delegación") }}
                  </option>
                  <option
                    v-for="delegacion in listado_delegaciones"
                    :value="delegacion.id"
                    v-bind:key="delegacion.id"
                  >
                    {{ delegacion.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechniciansResp_delegationName"
              >
                {{ $t("Delegación nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="comTechniciansResp_delegationName"
                type="text"
                :placeholder="this.$t('Delegación nombre')"
                v-model="comTechniciansResp_delegationName"
                readonly="readonly"
              />
            </div>
          </div>
        </div>

        <div v-if="rol === 'comTechnicians'">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos del comercial") }}
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechnicians_nombre"
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="comTechnicians_nombre"
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="nombre"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechnicians_createdDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="comTechnicians_createdDate"
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                readonly
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechnicians_responsableId"
              >
                {{ $t("Responsable comercial") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  id="comTechnicians_responsableId"
                  v-model="comTechnicians_responsableId"
                  @change="comercialChangeResponsable"
                >
                  <option value="">
                    {{ $t("Seleccione un responsable") }}
                  </option>
                  <option
                    v-for="responsable in listado_comercialesResp"
                    :value="responsable.id"
                    v-bind:key="responsable.id"
                  >
                    ({{ responsable.delegationName }}) {{ responsable.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechnicians_responsableName"
              >
                {{ $t("Responsable comercial nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="comTechnicians_responsableName"
                type="text"
                :placeholder="this.$t('Responsable comercial nombre')"
                v-model="comTechnicians_responsableName"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6 hidden">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Distribuidores") }}
              </label>

              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="distribuidor in listado_distribuidores"
                :key="distribuidor.id"
              >
                <span
                  class="text-gray-700 text-xs"
                  v-if="showCommercialDistributor(distribuidor)"
                >
                  {{ distribuidor.name }}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="mensajeError !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ mensajeError }}</span>
          </div>

          <div
            v-if="mensajeExito !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(mensajeExito) }}</span>
          </div>
        </div>
        <div
          v-if="
            usuarioRol === 'appSuperAdministrators' ||
            usuarioRol === 'appAdministrators'
          "
          class="flex flex-row justify-end"
        >
          <button
            class="
              bg-white
              mr-2
              text-sm
              font-semibold
              border border-redagrozono
              hover:bg-redagrozono
              text-redagrozono
              hover:text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            v-if="!status"
            type="button"
            v-on:click="confirmInactive"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Inactivar usuario") }}
          </button>

          <button
            class="
              text-sm
              font-semibold
              text-white
              py-3
              px-4
              rounded
              focus:outline-none focus:shadow-outline
              bg-white
              hover:bg-greenlight
              text-greenlight
              hover:text-white
              border border-greenlight
              mr-4
            "
            v-if="status"
            type="button"
            v-on:click="confirmActive"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Activar usuario") }}
          </button>

          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="submitDatos"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Guardar cambios") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: datosCargados === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { firebaseGlobal } from "@/plugins/firebase.js";
import { db } from "@/plugins/firebase.js";
import { Datetime } from "vue-datetime";
import Loader from "@/components/Loader";
import SelectCountry from "@/components/SelectCountry";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";

const AreaComercialEdit = {
  name: "AreaComercialEdit",
  layout: "layout",
  components: {
    Loader,
    datetime: Datetime,
    SelectCountry,
    HeaderModal,
  },
  props: {
    title: {
      require: true,
    },
    params: {
      required: true,
    },
  },
  data() {
    return {
      datosCargados: true,
      mensajeExito: "",
      mensajeError: "",
      mensajeExitoObservaciones: "",
      mensajeErrorObservaciones: "",
      registroEliminado: false,

      usuarioRol: "",
      usuarioActual: "",

      status: false,
      country: "España",
      email: "",
      password: "",
      rol: "",
      nombre: "",
      createdDate: "",
      lang: "Español",
      businessAreas: [],
      disabledBusinessArea: false,

      listBusinessAreas: [],
      listado_distribuidores: [],
      listado_maquinas: [],
      listado_delegaciones: [],
      listado_tecnicos: [],
      listado_comercialesResp: [],
      listado_comerciales_distribuidor: [],

      delegations_cp: "",
      delegations_address: "",
      delegations_cif: "",
      delegations_city: "",
      delegations_country: "",
      delegations_distributors: [],
      delegations_idAgro: "",
      delegations_idCreatedToShare: "",
      delegations_isAdmin: false,
      delegations_machines: [],
      delegations_province: "",
      delegations_telephone: "",

      distributors_address: "",
      distributors_cif: "",
      distributors_delegationId: "",
      distributors_delegationName: "",
      distributors_idAgro: "",
      distributors_idCreatedToShare: "",
      distributors_comercialId: "",
      distributors_comercialName: "",

      comTechniciansResp_delegationId: "",
      comTechniciansResp_delegationName: "",

      comTechnicians_delegationId: "",
      comTechnicians_delegationName: "",
      comTechnicians_responsableId: "",
      comTechnicians_responsableName: "",
      comTechnicians_distributorIds: [],
    };
  },
  watch: {
    rol: async function (val) {
      if (val === "delegations") {
        let date = new Date();
        this.email =
          "delegation_temp" +
          date.getDate() +
          "-" +
          date.getMonth() +
          "-" +
          date.getFullYear() +
          "-" +
          Math.floor(Math.random() * 10) +
          "@gmail.com";
        this.businessAreas = this.listBusinessAreas;
      }
    },
  },
  computed: {
    technicianList() {
      return this.listado_comerciales_distribuidor.sort(function (a, b) {
        const nameA = a.name;
        const nameB = b.name;

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });
    },
  },
  methods: {
    showCommercialDistributor(distributor) {
      return this.comTechnicians_distributorIds.includes(distributor.id);
    },
    confirmInactive() {
      let user = this.email ?? this.nombre;

      if (this.params.rolType === "delegations") {
        user = this.nombre;
      }

      if (
        confirm(
          this.$t("¿Realmente deseas inactivar el registro?") +
            '"' +
            user +
            '"?'
        )
      ) {
        this.inactiveUser();
      }
    },
    confirmActive() {
      let user = this.email ?? this.nombre;

      if (this.params.rolType === "delegations") {
        user = this.nombre;
      }

      if (
        confirm(
          this.$t("¿Realmente deseas activar el registro?") + '"' + user + '"?'
        )
      ) {
        this.activeUser();
      }
    },
    inactiveUser() {
      this.datosCargados = false;
      let _this = this;

      db.collection(_this.params.rolType)
        .doc(_this.params.id)
        .update({
          deleted: true,
        })
        .then(() => {
          if (_this.params.rolType !== "delegations") {
            db.collection("users").doc(_this.params.id).delete();
          }

          this.$emit("close");
          this.$root.$emit("reloadListCommercial");
        });
    },
    activeUser() {
      this.datosCargados = false;
      let _this = this;

      if (_this.params.rolType !== "delegations") {
        if (
          this.email !== "" ||
          this.country !== "" ||
          this.lang !== "" ||
          this.businessAreas.length !== 0
        ) {
          db.collection(_this.params.rolType)
            .doc(_this.params.id)
            .update({
              deleted: false,
            })
            .then(() => {
              db.collection("users").doc(_this.params.id).set({
                email: _this.email,
                rol: _this.params.rolType,
                businessAreas: _this.businessAreas,
                uid: _this.params.id,
                lang: _this.lang,
                country: _this.country,
              });

              this.$emit("close");
              this.$root.$emit("reloadListCommercial");
            });
        } else {
          this.datosCargados = true;
          this.mensajeError = this.$t(
            "Debe llenar correctamente todos los campos para guardarlos en el sistema"
          );
        }
      }

      if (_this.params.rolType === "delegations") {
        db.collection(_this.params.rolType)
          .doc(_this.params.id)
          .update({
            deleted: false,
          })
          .then(() => {
            this.$emit("close");
            this.$root.$emit("reloadListCommercial");
          });
      }
    },
    submitDatos: async function () {
      this.datosCargados = false;
      this.mensajeExito = "";
      this.mensajeError = "";

      if (this.email !== "" && this.businessAreas.length !== 0) {
        let nuevosDatos;
        if (this.password !== "") {
          nuevosDatos = {
            id: this.params.id,
            email: this.email,
            emailVerified: false,
            password: this.password,
            displayName: this.name,
            disabled: false,
          };
        } else {
          nuevosDatos = {
            id: this.params.id,
            email: this.email,
            emailVerified: false,
            displayName: this.name,
            disabled: false,
          };
        }

        let _this = this;

        if (
          this.rol === "distributors" ||
          this.rol === "comTechniciansResp" ||
          this.rol === "comTechnicians"
        ) {
          let editUserAdmin = firebaseGlobal
            .functions()
            .httpsCallable("editUserAdmin");
          editUserAdmin(nuevosDatos)
            .then((result) => {
              if (result.data.code === 1 || result.data.code === 2) {
                if (result.data.code === 2) {
                  db.collection("users").doc(_this.params.id).set({
                    email: _this.email,
                    rol: _this.rol,
                    businessAreas: _this.businessAreas,
                    uid: _this.params.id,
                    country: _this.country,
                  });
                }

                if (this.rol === "distributors") {
                  db.collection("distributors")
                    .doc(_this.params.id)
                    .update({
                      name: _this.nombre,
                      email: _this.email,
                      mail: _this.email,

                      address: _this.distributors_address,
                      cif: _this.distributors_cif,
                      delegationId: _this.distributors_delegationId,
                      delegationName: _this.distributors_delegationName,
                      idAgro: String(_this.distributors_idAgro),
                      idCreatedToShare: _this.distributors_idCreatedToShare,
                      createdDate: _this.createdDate ?? "",
                      comercialId: _this.distributors_comercialId,
                      comercialName: _this.distributors_comercialName,
                      deleted: false,
                      uid: _this.params.id,
                    })
                    .then(() => {
                      _this.datosCargados = true;
                      _this.mensajeExito =
                        "El usuario ha sido editado exitosamente";

                      db.collection("users").doc(_this.params.id).update({
                        email: _this.email,
                        businessAreas: _this.businessAreas,
                        lang: _this.lang,
                        country: _this.country,
                      });

                      this.$emit("close");
                      this.$root.$emit("reloadListCommercial");
                    });
                }

                if (this.rol === "comTechniciansResp") {
                  db.collection("comTechniciansResp")
                    .doc(_this.params.id)
                    .update({
                      name: _this.nombre,
                      email: _this.email,
                      mail: _this.email,

                      delegationId: _this.comTechniciansResp_delegationId,
                      delegationName: _this.comTechniciansResp_delegationName,
                      createdDate: _this.createdDate ?? "",
                      deleted: false,
                      uid: _this.params.id,
                    })
                    .then(() => {
                      _this.datosCargados = true;
                      _this.mensajeExito =
                        "El usuario ha sido editado exitosamente";

                      db.collection("users").doc(_this.params.id).update({
                        email: _this.email,
                        businessAreas: _this.businessAreas,
                        lang: _this.lang,
                        country: _this.country,
                      });

                      this.$emit("close");
                      this.$root.$emit("reloadListCommercial");
                    });
                }

                if (this.rol === "comTechnicians") {
                  db.collection("comTechnicians")
                    .doc(_this.params.id)
                    .update({
                      name: _this.nombre,
                      email: _this.email,
                      mail: _this.email,

                      delegationId: _this.comTechnicians_delegationId,
                      delegationName: _this.comTechnicians_delegationName,
                      responsableId: _this.comTechnicians_responsableId,
                      responsableName: _this.comTechnicians_responsableName,
                      distributorIds: _this.comTechnicians_distributorIds,
                      createdDate: _this.createdDate ?? "",
                      deleted: false,
                      uid: _this.params.id,
                    })
                    .then(() => {
                      _this.datosCargados = true;
                      _this.mensajeExito =
                        "El usuario ha sido editado exitosamente";

                      db.collection("users").doc(_this.params.id).update({
                        email: _this.email,
                        businessAreas: _this.businessAreas,
                        lang: _this.lang,
                        country: _this.country,
                      });

                      this.$emit("close");
                      this.$root.$emit("reloadListCommercial");
                    });
                }
              } else {
                _this.datosCargados = true;
                _this.mensajeError = result.data.message;
              }
            })
            .catch(function (error) {
              let errorMessage = error.message;

              _this.datosCargados = true;
              _this.mensajeError =
                _this.$t(
                  "Ha ocurrido un error al intentar editar este usuario. Error:"
                ) + errorMessage;
            });
        }

        if (this.rol === "delegations") {
          this.delegations_isAdmin = this.delegations_isAdmin !== "false";

          db.collection("delegations")
            .doc(_this.params.id)
            .update({
              name: _this.nombre,
              CP: _this.delegations_cp,
              address: _this.delegations_address,
              cif: _this.delegations_cif,
              city: _this.delegations_city,
              country: _this.delegations_country,
              distributors: _this.delegations_distributors,
              idAgro: String(_this.delegations_idAgro),
              idCreatedToShare: _this.delegations_idCreatedToShare,
              isAdmin: _this.delegations_isAdmin,
              machines: _this.delegations_machines,
              province: _this.delegations_province,
              telephone: _this.delegations_telephone,
            })
            .then(() => {
              // PARA ACTUALIZAR EL LISTADO
              for (let i = 0; i < _this.$parent.$children.length; i++) {
                if (
                  _this.$parent.$children[i].$options.name === "AreaComercial"
                ) {
                  _this.$parent.$children[i].filtrarRegistros();
                  break;
                }
              }

              this.$emit("close");
              this.$root.$emit("reloadListCommercial");
            });
        }
      } else {
        this.datosCargados = true;
        this.mensajeError = this.$t(
          "Debe llenar correctamente todos los campos para guardarlos en el sistema"
        );
      }
    },
    distribuidorChangeDelegacion() {
      if (this.distributors_delegationId === "") {
        this.distributors_delegationName = "";
      } else {
        for (const x in this.listado_delegaciones) {
          if (
            this.listado_delegaciones[x].id === this.distributors_delegationId
          ) {
            this.distributors_delegationName =
              this.listado_delegaciones[x].name;

            this.listado_comerciales_distribuidor = [];
            for (const y in this.listado_tecnicos) {
              if (
                this.listado_tecnicos[y].delegationId ===
                this.distributors_delegationId
              ) {
                this.listado_comerciales_distribuidor.push(
                  this.listado_tecnicos[y]
                );
              }
            }

            break;
          }
        }
      }
    },
    distribuidorChangeComercial() {
      if (this.distributors_comercialId !== "") {
        for (const x in this.listado_tecnicos) {
          if (this.listado_tecnicos[x].id === this.distributors_comercialId) {
            this.distributors_comercialName = this.listado_tecnicos[x].name;
            break;
          }
        }
      }
    },
    comercialRespChangeDelegacion() {
      if (this.comTechniciansResp_delegationId === "") {
        this.comTechniciansResp_delegationName = "";
      } else {
        for (const x in this.listado_delegaciones) {
          if (
            this.listado_delegaciones[x].id ===
            this.comTechniciansResp_delegationId
          ) {
            this.comTechniciansResp_delegationName =
              this.listado_delegaciones[x].name;
            break;
          }
        }
      }
    },
    comercialChangeResponsable() {
      if (this.comTechnicians_responsableId === "") {
        this.comTechnicians_delegationId = "";
        this.comTechnicians_delegationName = "";

        this.comTechnicians_responsableName = "";
      } else {
        for (const x in this.listado_comercialesResp) {
          if (
            this.listado_comercialesResp[x].id ===
            this.comTechnicians_responsableId
          ) {
            this.comTechnicians_delegationId =
              this.listado_comercialesResp[x].delegationId;
            this.comTechnicians_delegationName =
              this.listado_comercialesResp[x].delegationName;

            this.comTechnicians_responsableName =
              this.listado_comercialesResp[x].name;
            break;
          }
        }
      }
    },
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.usuarioRol = sessionStorage.getItem("user_rol");
    this.usuarioActual = sessionStorage.getItem("user_id");

    let _this = this;
    await db
      .collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_distribuidores.push(arrayTemp);
        });
      });

    await db
      .collection("machines")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_maquinas.push(arrayTemp);
        });
      });

    await db
      .collection("delegations")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_delegaciones.push(arrayTemp);
        });
      });

    await db
      .collection("comTechniciansResp")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          if (
            _this.usuarioRol === "appSuperAdministrators" ||
            _this.usuarioRol === "appAdministrators"
          ) {
            _this.listado_comercialesResp.push(arrayTemp);
          }

          if (
            _this.usuarioRol === "comTechniciansResp" &&
            _this.usuarioActual === doc.id
          ) {
            _this.listado_comercialesResp.push(arrayTemp);
          }
        });
      });

    await db
      .collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          if (_this.usuarioRol === "appSuperAdministrators") {
            _this.listado_tecnicos.push(arrayTemp);
          }

          if (_this.usuarioRol === "appAdministrators") {
            _this.listado_tecnicos.push(arrayTemp);
          }

          if (
            _this.usuarioRol === "comTechnicians" &&
            _this.usuarioActual === doc.id
          ) {
            _this.listado_tecnicos.push(arrayTemp);
          }
        });
      });

    if (this.params.rolType === "delegations") {
      this.datosCargados = false;
      await db
        .collection("delegations")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.nombre = snapshot.data().name;
          this.email = snapshot.data().email;
          this.rol = this.params.rolType;
          this.status = snapshot.data().deleted;
          this.createdDate = snapshot.data().createdDate;

          this.delegations_cp = snapshot.data().CP;
          this.delegations_address = snapshot.data().address;
          this.delegations_cif = snapshot.data().cif;
          this.delegations_city = snapshot.data().city;
          this.delegations_country = snapshot.data().country;
          this.delegations_distributors = snapshot.data().distributors;
          this.delegations_idAgro = snapshot.data().idAgro;
          this.delegations_idCreatedToShare = snapshot.data().idCreatedToShare;

          if (snapshot.data().isAdmin === false) {
            this.delegations_isAdmin = "false";
          } else {
            this.delegations_isAdmin = "true";
          }

          this.delegations_machines = snapshot.data().machines;
          this.delegations_province = snapshot.data().province;
          this.delegations_telephone = snapshot.data().telephone;

          this.datosCargados = true;
        });
    }

    if (this.params.rolType === "distributors") {
      this.datosCargados = false;
      await db
        .collection("distributors")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.nombre = snapshot.data().name;
          this.email = snapshot.data().email;
          this.rol = this.params.rolType;
          this.status = snapshot.data().deleted;
          this.createdDate = snapshot.data().createdDate ?? "";

          this.distributors_address = snapshot.data().address;
          this.distributors_cif = snapshot.data().cif;
          this.distributors_delegationId = snapshot.data().delegationId;
          this.distributors_delegationName = snapshot.data().delegationName;
          this.distributors_idAgro = snapshot.data().idAgro;
          this.distributors_idCreatedToShare = snapshot.data().idCreatedToShare;
          this.distributors_comercialId = snapshot.data().comercialId ?? "";
          this.distributors_comercialName = snapshot.data().comercialName ?? "";

          // AQUI SE DEBEN CARGAR TODOS LOS COMERCIALES DE LA DELEGACION
          this.distribuidorChangeDelegacion();

          this.datosCargados = true;
        });
    }

    if (this.params.rolType === "comTechniciansResp") {
      this.datosCargados = false;
      await db
        .collection("comTechniciansResp")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.nombre = snapshot.data().name;
          this.email = snapshot.data().email;
          this.rol = this.params.rolType;
          this.status = snapshot.data().deleted;
          this.createdDate = snapshot.data().createdDate;

          this.comTechniciansResp_delegationId = snapshot.data().delegationId;
          this.comTechniciansResp_delegationName =
            snapshot.data().delegationName;

          this.datosCargados = true;
        });
    }

    if (this.params.rolType === "comTechnicians") {
      this.datosCargados = false;
      await db
        .collection("comTechnicians")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.nombre = snapshot.data().name;
          this.email = snapshot.data().email;
          this.rol = this.params.rolType;
          this.status = snapshot.data().deleted;
          this.createdDate = snapshot.data().createdDate;

          this.comTechnicians_delegationId = snapshot.data().delegationId;
          this.comTechnicians_delegationName = snapshot.data().delegationName;
          this.comTechnicians_responsableId = snapshot.data().responsableId;
          this.comTechnicians_responsableName = snapshot.data().responsableName;
          this.comTechnicians_distributorIds = snapshot.data().distributorIds;

          this.datosCargados = true;
        });
    }

    if (this.rol !== "delegations" && !this.status) {
      await db
        .collection("users")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.lang = snapshot.data().lang ? snapshot.data().lang : "Español";
          this.country = snapshot.data().country
            ? snapshot.data().country
            : "Español";
          this.businessAreas = snapshot.data().businessAreas
            ? snapshot.data().businessAreas
            : [];
        });
    }

    db.collection("businessAreas")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          _this.listBusinessAreas.push(doc.data());
          _this.listBusinessAreas.sort(function (a, b) {
            const nameA = a.name;
            const nameB = b.name;

            let comparison = 0;
            if (nameA > nameB) {
              comparison = 1;
            } else if (nameA < nameB) {
              comparison = -1;
            }
            return comparison;
          });

          if (doc.data().name === "Agro3" && _this.businessAreas.length === 0) {
            _this.businessAreas.push(doc.data());
          }
        });
      });
  },
};

export default AreaComercialEdit;
</script>
