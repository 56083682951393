<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos de la cuenta") }}
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Rol asignado") }}
            </label>
            <div class="inline-block relative mr-4 w-full">
              <div
                class="block w-full relative mr-4"
                v-if="
                  usuarioRol === 'appSuperAdministrators' ||
                  usuarioRol === 'appAdministrators'
                "
              >
                <label>
                  <select
                    class="
                      block
                      appearance-none
                      w-full
                      bg-white
                      border border-gray-400
                      rounded
                      hover:border-gray-500
                      px-4
                      py-3
                      pr-4
                      leading-tight
                      focus:outline-none focus:shadow-outline
                    "
                    v-model="rol"
                    @change="checkBusinessAreas"
                  >
                    <option value="">{{ $t("Seleccione un rol") }}</option>
                    <option
                      value="delegations"
                      v-if="usuarioRol === 'appSuperAdministrators'"
                    >
                      {{ $t("Delegación") }}
                    </option>
                    <option
                      value="distributors"
                      v-if="
                        usuarioRol === 'appSuperAdministrators' ||
                        usuarioRol === 'appAdministrators'
                      "
                    >
                      {{ $t("Distribuidor") }}
                    </option>
                    <option
                      value="comTechnicians"
                      v-if="
                        usuarioRol === 'appSuperAdministrators' ||
                        usuarioRol === 'appAdministrators'
                      "
                    >
                      {{ $t("Comercial") }}
                    </option>
                    <option
                      value="comTechniciansResp"
                      v-if="usuarioRol === 'appSuperAdministrators'"
                    >
                      {{ $t("Responsable comercial") }}
                    </option>
                  </select>
                </label>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
              <div v-if="usuarioRol === 'comTechniciansResp'">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    text-gray-700
                    border border-red-500
                    rounded
                    py-3
                    px-4
                    mb-3
                    leading-tight
                    focus:outline-none focus:bg-white
                  "
                  v-model="rol"
                  @change="checkBusinessAreas"
                >
                  <option value="">{{ $t("Seleccione un rol") }}</option>
                  <option value="comTechnicians">{{ $t("Comercial") }}</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <div>
              <label
                for="email"
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Email") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                id="email"
                type="text"
                :placeholder="this.$t('Email')"
                v-model="email"
              />
            </div>
          </div>
          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <div>
              <label
                for="email"
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Password") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="password"
                placeholder="*******"
                v-model="password"
              />
            </div>
          </div>
          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="country"
            >
              {{ $t("País") }}
            </label>
            <div class="block w-full relative mr-4">
              <selectCountry
                id="country"
                v-model="country"
                :default="country"
              ></selectCountry>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="lang"
            >
              {{ $t("Idioma") }}
            </label>
            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="lang"
                v-model="lang"
              >
                <option value="">{{ $t("Seleccione un idioma") }}</option>
                <option value="Español">{{ $t("Español") }}</option>
                <option value="Italiano">{{ $t("Italiano") }}</option>
                <option value="Frances">{{ $t("Frances") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: rol === 'delegations' }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="businessAreas"
            >
              {{ $t("Plataformas asignadas") }}
            </label>
            <div class="block w-full relative mb-4">
              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="(business, index) in listBusinessAreas"
                :key="index"
              >
                <input
                  class="mr-2 leading-tight"
                  id="businessAreas"
                  type="checkbox"
                  v-model="businessAreas"
                  :value="business"
                  :disabled="disabled"
                />
                <span class="text-gray-700 text-xs">
                  {{ business.name }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div v-if="rol === 'delegations'">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos de la delegación") }}
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="nombre"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("País") }}
              </label>
              <selectCountry v-model="delegations_country"></selectCountry>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Provincia") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Provincia')"
                v-model="delegations_province"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Ciudad") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Ciudad')"
                v-model="delegations_city"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Dirección") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Dirección')"
                v-model="delegations_address"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                disabled="disabled"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Teléfono") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Teléfono')"
                v-model="delegations_telephone"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Cp") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Cp')"
                v-model="delegations_cp"
              />
            </div>

            <div class="w-full md:w-1/5 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Cif") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Cif')"
                v-model="delegations_cif"
              />
            </div>

            <div class="w-full md:w-1/5 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Id agro") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Id agro')"
                v-model="delegations_idAgro"
                disabled
              />
            </div>

            <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("¿Es administrador?") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="delegations_isAdmin"
                >
                  <option value="false">{{ $t("No") }}</option>
                  <option value="true">{{ $t("Si") }}</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-2/5 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Id created to share") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Id created to share')"
                disabled
                v-model="delegations_idCreatedToShare"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="hidden w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Distribuidores") }}
              </label>

              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="distribuidor in listado_distribuidores"
                :key="distribuidor.id"
              >
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="delegations_distributors"
                  :value="distribuidor.id"
                />
                <span class="text-gray-700 text-xs">
                  {{ distribuidor.name }}
                </span>
              </label>
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Máquinas") }}
              </label>

              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="maquina in listado_maquinas"
                :key="maquina.id"
              >
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="delegations_machines"
                  :value="maquina.id"
                />
                <span class="text-gray-700 text-xs">
                  {{ maquina.name }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="rol === 'distributors'">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos del distribuidor") }}
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="nombre"
              />
            </div>

            <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Dirección") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Dirección')"
                v-model="distributors_address"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Cif") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Cif')"
                v-model="distributors_cif"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Id agro") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Id agro')"
                v-model="distributors_idAgro"
                disabled
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Id created to share") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Id created to share')"
                v-model="distributors_idCreatedToShare"
                disabled
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                disabled="disabled"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Delegación") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="distributors_delegationId"
                  @change="distribuidorChangeDelegacion"
                >
                  <option value="">
                    {{ $t("Seleccione una delegación") }}
                  </option>
                  <option
                    v-for="delegacion in listado_delegaciones"
                    :value="delegacion.id"
                    :key="delegacion.id"
                  >
                    {{ delegacion.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Delegación nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Delegación nombre')"
                v-model="distributors_delegationName"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Comercial") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="distributors_comercialId"
                  @change="distribuidorChangeComercial"
                >
                  <option value="">{{ $t("Seleccione un comercial") }}</option>
                  <option
                    v-for="technician in technicianList"
                    :value="technician.id"
                    :key="technician.id"
                  >
                    {{ technician.name }} [{{ technician.responsableName }}]
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Comercial nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Comercial nombre')"
                v-model="distributors_comercialName"
              />
            </div>
          </div>
        </div>

        <div v-if="rol === 'comTechniciansResp'">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos del comercial responsable") }}
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="nombre"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                disabled="disabled"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Delegación") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="comTechniciansResp_delegationId"
                  @change="comercialRespChangeDelegacion"
                >
                  <option value="">
                    {{ $t("Seleccione una delegación") }}
                  </option>
                  <option
                    v-for="delegacion in listado_delegaciones"
                    :value="delegacion.id"
                    :key="delegacion.id"
                  >
                    {{ delegacion.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Delegación nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Delegación nombre')"
                v-model="comTechniciansResp_delegationName"
              />
            </div>
          </div>
        </div>

        <div v-if="rol === 'comTechnicians'">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              {{ $t("Datos del comercial") }}
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="nombre"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                disabled="disabled"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Responsable comercial") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="comTechnicians_responsableId"
                  @change="comercialChangeResponsable"
                >
                  <option value="">
                    {{ $t("Seleccione un responsable") }}
                  </option>
                  <option
                    v-for="responsable in listado_comercialesResp"
                    :value="responsable.id"
                    :key="responsable.id"
                  >
                    ({{ responsable.delegationName }}) {{ responsable.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Responsable comercial nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Responsable comercial nombre')"
                v-model="comTechnicians_responsableName"
              />
            </div>
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="mensajeError !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ mensajeError }}</span>
          </div>

          <div
            v-if="mensajeExito !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(mensajeExito) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            @click="submitDatos"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Crear nuevo") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: datosCargados === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db, firebaseGlobal } from "@/plugins/firebase.js";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Loader from "@/components/Loader";
import SelectCountry from "@/components/SelectCountry";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";

const AreaComercialAdd = {
  name: "AreaComercialAdd",
  layout: "layout",
  components: {
    Loader,
    datetime: Datetime,
    SelectCountry,
    HeaderModal,
  },
  data() {
    return {
      datosCargados: true,
      mensajeExito: "",
      mensajeError: "",
      mensajeExitoObservaciones: "",
      mensajeErrorObservaciones: "",
      disabled: true,

      country: "España",
      email: "",
      password: "",
      rol: "",
      nombre: "",
      createdDate: "",
      lang: "Español",
      usuarioRol: "",
      usuarioActual: "",
      businessAreas: [],

      listBusinessAreas: [],
      listado_distribuidores: [],
      listado_distribuidores_cliente: [],
      listado_maquinas: [],
      listado_delegaciones: [],
      listado_tecnicos: [],
      listado_comercialesResp: [],
      listado_comercialesResp_distribuidor: [],
      listado_comerciales_distribuidor: [],

      delegations_cp: "",
      delegations_address: "",
      delegations_cif: "",
      delegations_city: "",
      delegations_country: "",
      delegations_distributors: [],
      delegations_idAgro: "",
      delegations_idCreatedToShare: "",
      delegations_isAdmin: false,
      delegations_machines: [],
      delegations_province: "",
      delegations_telephone: "",

      distributors_address: "",
      distributors_cif: "",
      distributors_delegationId: "",
      distributors_delegationName: "",
      distributors_idAgro: "",
      distributors_idCreatedToShare: "",
      distributors_comercialId: "",
      distributors_comercialName: "",

      comTechniciansResp_delegationId: "",
      comTechniciansResp_delegationName: "",

      comTechnicians_delegationId: "",
      comTechnicians_delegationName: "",
      comTechnicians_responsableId: "",
      comTechnicians_responsableName: "",
      comTechnicians_distributorIds: [],
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: false,
    },
  },
  watch: {
    rol: async function (val) {
      if (val === "delegations") {
        let date = new Date();
        this.email =
          "delegation_temp" +
          date.getDate() +
          "-" +
          date.getMonth() +
          "-" +
          date.getFullYear() +
          "-" +
          Math.floor(Math.random() * 10) +
          "@gmail.com";
        this.password = "123456";
        this.businessAreas = this.listBusinessAreas;

        this.delegations_idAgro = await Utilities.getNextIdAgro("delegations");
        this.delegations_idCreatedToShare = "ES/" + this.delegations_idAgro;
      }

      if (val === "distributors") {
        this.email = "";
        this.password = "";

        this.distributors_idAgro = await Utilities.getNextIdAgro(
          "distributors"
        );
        this.distributors_idCreatedToShare = "ES/" + this.distributors_idAgro;
      }

      if (val === "comTechniciansResp") {
        this.email = "";
        this.password = "";
      }

      if (val === "comTechnicians") {
        this.email = "";
        this.password = "";
      }
    },
  },
  computed: {
    technicianList() {
      return this.listado_comerciales_distribuidor.sort(function (a, b) {
        const nameA = a.name;
        const nameB = b.name;

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });
    },
  },
  methods: {
    checkBusinessAreas() {
      if (this.rol === "") {
        this.email = "";
        return (this.disabled = true);
      }

      this.disabled = false;
    },
    funcionTemporal() {
      for (let i = 0; i < this.$parent.$children.length; i++) {
        if (this.$parent.$children[i].$options.name === "AreaComercial") {
          this.$parent.$children[i].filtrarRegistros();
          break;
        }
      }
    },
    crearRegistroBD(uid) {
      // UNA VEZ CREADO EL USUARIO EN AUTH, SE CREA EL REGISTRO EN LA BD

      if (this.rol === "distributors") {
        db.collection("distributors")
          .doc(uid)
          .set({
            email: this.email,
            mail: this.email,
            name: this.nombre,
            uid: uid,

            address: this.distributors_address,
            cif: this.distributors_cif,
            delegationId: this.distributors_delegationId,
            delegationName: this.distributors_delegationName,
            idAgro: String(this.distributors_idAgro),
            idCreatedToShare: this.distributors_idCreatedToShare,
            comercialId: this.distributors_comercialId,
            comercialName: this.distributors_comercialName,
            deleted: false,
            createdDate: this.createdDate,
            disabledDate: "N/D",
          })
          .then(() => {
            this.datosCargados = true;
            this.mensajeExito = "El usuario ha sido creado exitosamente";

            let _this = this;
            db.collection("users").doc(uid).set({
              email: _this.email,
              rol: "distributors",
              businessAreas: _this.businessAreas,
              uid: uid,
              lang: _this.lang,
              country: _this.country,
            });

            this.email = "";
            this.password = "";
            this.rol = "";
            this.nombre = "";
            this.businessAreas = "";

            this.distributors_address = "";
            this.distributors_cif = "";
            this.distributors_delegationId = "";
            this.distributors_delegationName = "";
            this.distributors_idAgro = "";
            this.distributors_idCreatedToShare = "";
            this.distributors_comercialId = "";
            this.distributors_comercialName = "";

            // PARA ACTUALIZAR EL LISTADO
            for (let i = 0; i < this.$parent.$children.length; i++) {
              if (this.$parent.$children[i].$options.name === "AreaComercial") {
                this.$parent.$children[i].filtrarRegistros();
                break;
              }
            }

            this.$emit("close");
            this.$root.$emit("reloadListCommercial");
          })
          .catch(function (error) {
            let errorMessage = error.message;

            this.datosCargados = true;
            this.mensajeError =
              this.$t(
                "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
              ) + errorMessage;
          });
      }

      if (this.rol === "comTechniciansResp") {
        db.collection("comTechniciansResp")
          .doc(uid)
          .set({
            email: this.email,
            mail: this.email,
            name: this.nombre,
            uid: uid,

            delegationId: this.comTechniciansResp_delegationId,
            delegationName: this.comTechniciansResp_delegationName,
            deleted: false,
            createdDate: this.createdDate,
            disabledDate: "N/D",
          })
          .then(() => {
            this.datosCargados = true;
            this.mensajeExito = "El usuario ha sido creado exitosamente";

            let _this = this;
            db.collection("users").doc(uid).set({
              email: _this.email,
              rol: "comTechniciansResp",
              businessAreas: _this.businessAreas,
              uid: uid,
              lang: _this.lang,
              country: _this.country,
            });

            this.email = "";
            this.password = "";
            this.rol = "";
            this.nombre = "";
            this.businessAreas = "";

            this.comTechniciansResp_delegationId = "";
            this.comTechniciansResp_delegationName = "";

            // PARA ACTUALIZAR EL LISTADO
            for (let i = 0; i < this.$parent.$children.length; i++) {
              if (this.$parent.$children[i].$options.name === "AreaComercial") {
                this.$parent.$children[i].filtrarRegistros();
                break;
              }
            }

            this.$emit("close");
            this.$root.$emit("reloadListCommercial");
          })
          .catch(function (error) {
            let errorMessage = error.message;

            this.datosCargados = true;
            this.mensajeError =
              this.$t(
                "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
              ) + errorMessage;
          });
      }

      if (this.rol === "comTechnicians") {
        db.collection("comTechnicians")
          .doc(uid)
          .set({
            email: this.email,
            mail: this.email,
            name: this.nombre,
            uid: uid,

            delegationId: this.comTechnicians_delegationId,
            delegationName: this.comTechnicians_delegationName,
            responsableId: this.comTechnicians_responsableId,
            responsableName: this.comTechnicians_responsableName,
            distributorIds: this.comTechnicians_distributorIds,
            deleted: false,
            createdDate: this.createdDate,
            disabledDate: "N/D",
          })
          .then(() => {
            this.datosCargados = true;
            this.mensajeExito = "El usuario ha sido creado exitosamente";

            let _this = this;
            db.collection("users").doc(uid).set({
              email: _this.email,
              rol: "comTechnicians",
              businessAreas: _this.businessAreas,
              uid: uid,
              lang: _this.lang,
              country: _this.country,
            });

            this.email = "";
            this.password = "";
            this.rol = "";
            this.nombre = "";
            this.businessAreas = "";

            this.comTechnicians_delegationId = "";
            this.comTechnicians_delegationName = "";
            this.comTechnicians_responsableId = "";
            this.comTechnicians_responsableName = "";
            this.comTechnicians_distributorIds = [];

            // PARA ACTUALIZAR EL LISTADO
            for (let i = 0; i < this.$parent.$children.length; i++) {
              if (this.$parent.$children[i].$options.name === "AreaComercial") {
                this.$parent.$children[i].filtrarRegistros();
                break;
              }
            }

            this.$emit("close");
            this.$root.$emit("reloadListCommercial");
          })
          .catch(function (error) {
            let errorMessage = error.message;

            this.datosCargados = true;
            this.mensajeError =
              this.$t(
                "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
              ) + errorMessage;
          });
      }
    },
    createDelegation() {
      if (this.rol === "delegations") {
        this.delegations_isAdmin = this.delegations_isAdmin !== "false";
        let refDelegation = db.collection("delegations").doc();

        refDelegation
          .set({
            name: this.nombre,
            uid: refDelegation.id,

            CP: this.delegations_cp,
            address: this.delegations_address,
            cif: this.delegations_cif,
            city: this.delegations_city,
            country: this.delegations_country,
            distributors: this.delegations_distributors,
            idAgro: String(this.delegations_idAgro),
            idCreatedToShare: this.delegations_idCreatedToShare,
            isAdmin: this.delegations_isAdmin,
            machines: this.delegations_machines,
            province: this.delegations_province,
            telephone: this.delegations_telephone,
            deleted: false,
            createdDate: this.createdDate,
            disabledDate: "N/D",
          })
          .then(() => {
            // PARA ACTUALIZAR EL LISTADO
            for (let i = 0; i < this.$parent.$children.length; i++) {
              if (this.$parent.$children[i].$options.name === "AreaComercial") {
                this.$parent.$children[i].filtrarRegistros();
                break;
              }
            }

            this.$emit("close");
            this.$root.$emit("reloadListCommercial");
          })
          .catch(function (error) {
            let errorMessage = error.message;

            this.datosCargados = true;
            this.mensajeError =
              this.$t(
                "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
              ) + errorMessage;
          });
      }
    },
    submitDatos() {
      this.datosCargados = false;
      this.mensajeExito = "";
      this.mensajeError = "";

      if (
        this.email !== "" &&
        this.password !== "" &&
        this.rol !== "" &&
        this.businessAreas.length !== 0
      ) {
        let puedeContinuar = true;
        let mensajeNoPuedeContinuar = "";
        if (this.rol === "delegations") {
          puedeContinuar = true;
        } else if (this.rol === "distributors") {
          puedeContinuar = true;
        } else if (
          this.rol === "comTechniciansResp" &&
          this.comTechniciansResp_delegationId === ""
        ) {
          puedeContinuar = false;
          mensajeNoPuedeContinuar =
            "Debe llenar correctamente todos los campos del cliente para guardarlos en el sistema. (delegación)";
        } else if (this.rol === "comTechnicians") {
          puedeContinuar = true;
        }

        if (puedeContinuar === true) {
          if (this.rol !== "delegations") {
            let _this = this;
            let existUserAdmin = firebaseGlobal
              .functions()
              .httpsCallable("existUserAdmin");
            let addUserAdmin = firebaseGlobal
              .functions()
              .httpsCallable("addUserAdmin");

            existUserAdmin({
              email: this.email,
            })
              .then((result) => {
                if (result.data.code === 1) {
                  _this.datosCargados = true;
                  _this.mensajeError = _this.$t(
                    "El usuario ya se encuentra registrado en la base de datos"
                  );
                } else {
                  addUserAdmin({
                    email: _this.email,
                    password: _this.password,
                    displayName: _this.nombre,
                  })
                    .then((result) => {
                      if (result.data.code === 1) {
                        _this.crearRegistroBD(result.data.uid);
                      } else {
                        _this.datosCargados = true;
                        _this.mensajeError = result.data.message;
                      }
                    })
                    .catch(function (error) {
                      let errorMessage = error.message;

                      _this.datosCargados = true;
                      _this.mensajeError =
                        _this.$t(
                          "Ha ocurrido un error al intentar registrar este usuario. Error:"
                        ) + errorMessage;
                    });
                }
              })
              .catch((error) => {
                let errorMessage = error.message;

                this.datosCargados = true;
                this.mensajeError =
                  this.$t(
                    "Ha ocurrido un error al intentar crear el usuario. Error:"
                  ) + errorMessage;
              });
          }
          if (this.rol === "delegations") {
            this.createDelegation();
          }
        } else {
          this.datosCargados = true;
          this.mensajeError = mensajeNoPuedeContinuar;
        }
      } else {
        this.datosCargados = true;
        this.mensajeError = this.$t(
          "Debe llenar correctamente todos los campos para guardarlos en el sistema"
        );
      }
    },
    distribuidorChangeDelegacion() {
      if (this.distributors_delegationId === "") {
        this.distributors_delegationName = "";
      } else {
        for (const x in this.listado_delegaciones) {
          if (
            this.listado_delegaciones[x].id === this.distributors_delegationId
          ) {
            this.distributors_delegationName =
              this.listado_delegaciones[x].name;

            this.listado_comerciales_distribuidor =
              this.listado_tecnicos.filter((doc) => {
                if (doc.delegationId === this.distributors_delegationId) {
                  return doc;
                }
              });

            break;
          }
        }
      }
    },
    distribuidorChangeComercial() {
      if (this.distributors_comercialId !== "") {
        for (const x in this.listado_tecnicos) {
          if (this.listado_tecnicos[x].id === this.distributors_comercialId) {
            this.distributors_comercialName = this.listado_tecnicos[x].name;
            break;
          }
        }
      }
    },
    comercialRespChangeDelegacion() {
      if (this.comTechniciansResp_delegationId === "") {
        this.comTechniciansResp_delegationName = "";
      } else {
        for (const x in this.listado_delegaciones) {
          if (
            this.listado_delegaciones[x].id ===
            this.comTechniciansResp_delegationId
          ) {
            this.comTechniciansResp_delegationName =
              this.listado_delegaciones[x].name;
            break;
          }
        }
      }
    },
    comercialChangeResponsable() {
      if (this.comTechnicians_responsableId === "") {
        this.comTechnicians_delegationId = "";
        this.comTechnicians_delegationName = "";

        this.comTechnicians_responsableName = "";
      } else {
        for (const x in this.listado_comercialesResp) {
          if (
            this.listado_comercialesResp[x].id ===
            this.comTechnicians_responsableId
          ) {
            this.comTechnicians_delegationId =
              this.listado_comercialesResp[x].delegationId;
            this.comTechnicians_delegationName =
              this.listado_comercialesResp[x].delegationName;

            this.comTechnicians_responsableName =
              this.listado_comercialesResp[x].name;
            break;
          }
        }
      }
    },
  },
  beforeMount: function () {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.usuarioRol = sessionStorage.getItem("user_rol");
    this.usuarioActual = sessionStorage.getItem("user_id");

    let dateCurrent = new Date();
    this.createdDate =
      dateCurrent.getDate() +
      "/" +
      (dateCurrent.getMonth() + 1) +
      "/" +
      dateCurrent.getFullYear() +
      " " +
      dateCurrent.getHours() +
      ":" +
      dateCurrent.getMinutes() +
      ":" +
      dateCurrent.getSeconds();

    let _this = this;
    db.collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_distribuidores.push(arrayTemp);
        });
      });

    db.collection("machines")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_maquinas.push(arrayTemp);
        });
      });

    db.collection("delegations")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_delegaciones.push(arrayTemp);
        });
      });

    db.collection("comTechniciansResp")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          if (
            _this.usuarioRol === "appSuperAdministrators" ||
            _this.usuarioRol === "appAdministrators"
          ) {
            _this.listado_comercialesResp.push(arrayTemp);
          }

          if (
            _this.usuarioRol === "comTechniciansResp" &&
            _this.usuarioActual === doc.id
          ) {
            _this.listado_comercialesResp.push(arrayTemp);
          }
        });
      });

    db.collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          if (_this.usuarioRol === "appSuperAdministrators") {
            _this.listado_tecnicos.push(arrayTemp);
          }

          if (_this.usuarioRol === "appAdministrators") {
            _this.listado_tecnicos.push(arrayTemp);
          }

          if (
            _this.usuarioRol === "comTechnicians" &&
            _this.usuarioActual === doc.id
          ) {
            _this.listado_tecnicos.push(arrayTemp);
          }
        });
      });
    db.collection("businessAreas")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          _this.listBusinessAreas.push(doc.data());

          _this.listBusinessAreas.sort(function (a, b) {
            const nameA = a.name;
            const nameB = b.name;

            let comparison = 0;
            if (nameA > nameB) {
              comparison = 1;
            } else if (nameA < nameB) {
              comparison = -1;
            }
            return comparison;
          });

          if (doc.data().name === "Agro3") {
            _this.businessAreas.push(doc.data());
          }
        });
      });
  },
};

export default AreaComercialAdd;
</script>
