<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <div class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            <span class="mr-4" v-if="farmName">{{ farmName }}</span>
            <button
              v-if="
                userRol === 'appSuperAdministrators' ||
                userRol === 'respTechnicians' ||
                userRol === 'appTechnicians'
              "
              class="
                relative
                inline-flex
                items-center
                text-base
                px-4
                py-2
                leading-5
                font-bold
                rounded-md
                text-white
                focus:outline-none
                hover:text-white
              "
              type="button"
              @click="openAddDeliveryNote"
              :class="
                disabledButton
                  ? 'bg-gray-400 cursor-not-allowed border-none'
                  : 'text-blueagrozono hover:bg-blueagrozono border border-blueagrozono'
              "
              :disabled="disabledButton"
            >
              {{ $t("Añadir albarán") }}
            </button>
          </div>

          <table class="table-auto w-full text-left">
            <thead>
              <tr>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">{{ $t("Id") }}</div>
                </th>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">{{ $t("Fecha") }}</div>
                </th>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">{{ $t("Fecha envio") }}</div>
                </th>
                <th class="px-4 py-2 text-gray-800"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(deliveryNote, index) in deliveryNotes"
                v-bind:key="index"
              >
                <td class="px-4 py-3">
                  <div>
                    <span
                      class="
                        font-semibold
                        text-blueagrozono
                        hover:text-gray-800
                        cursor-pointer
                      "
                      @click="openDetailModal(deliveryNote)"
                      >{{ deliveryNote.uid }}</span
                    >
                  </div>
                </td>
                <td class="px-4 py-3">{{ deliveryNote.date | formatDate }}</td>
                <td class="px-4 py-3">
                  {{ deliveryNote.dateSentEmailDeliveryNote | formatDate }}
                </td>
                <td class="px-4 py-3 text-center" style="width: 150px">
                  <button
                    @click="sendDeliveryNote(deliveryNote.uid)"
                    class="
                      bg-yellow
                      mr-2
                      text-sm
                      font-semibold
                      hover:bg-greenlight
                      text-white
                      py-2
                      px-4
                      rounded
                      focus:outline-none focus:shadow-outline
                    "
                    type="button"
                  >
                    <Zondicon
                      icon="envelope"
                      class="
                        fill-current
                        inline-block
                        h-4
                        mr-2
                        text-white
                        cursor-pointer
                      "
                    />
                    {{ $t("Enviar") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase";
import HeaderModal from "@/components/HeaderModal";
import ApplicationDetailAddDeliveryNoteModal from "@/pages/ApplicationDetail/ApplicationDetailAddDeliveryNoteModal";
import ApplicationDetailModal from "@/pages/ApplicationDetail/ApplicationDetailModal";

const ApplicationDetailLayoutNoteModal = {
  name: "ApplicationDetailLayoutNoteModal",
  layout: "layout",
  components: {
    Zondicon,
    HeaderModal,
    ApplicationDetailModal,
    ApplicationDetailAddDeliveryNoteModal,
  },
  data: function () {
    return {
      deliveryNotes: [],
      farmName: "",
      userRol: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  computed: {
    disabledButton() {
      return this.params.treatment.state === "completed";
    },
  },
  methods: {
    openDetailModal(deliveryNote) {
      this.$emit("close");
      this.$modal.show(ApplicationDetailModal, {
        title: this.$t("Detalle del albarán"),
        params: {
          deliveryNote: deliveryNote,
          treatment: this.params.treatment,
        },
      });
    },
    openAddDeliveryNote() {
      this.$emit("close");
      let lastDeliveryNote = 1;

      if (this.deliveryNotes.length !== 0) {
        lastDeliveryNote = this.deliveryNotes.length + 1;
      }

      this.$modal.show(ApplicationDetailAddDeliveryNoteModal, {
        title: this.$t("Añadir albarán"),
        params: {
          treatmentId: this.params.treatmentID,
          treatment: this.params.treatment,
          lastDeliveryNote: lastDeliveryNote,
        },
      });
    },
    getDeliveryNotes() {
      this.deliveryNotes = [];
      let _this = this;

      db.collection("treatments")
        .doc(this.params.treatmentID)
        .collection("deliveryNotes")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            _this.deliveryNotes.push(doc.data());
          });
        });
    },
    sendDeliveryNote(id) {
      let refDeliveryNote = db
        .collection("treatments")
        .doc(this.params.treatmentID)
        .collection("deliveryNotes")
        .doc(id);

      let today = new Date();

      refDeliveryNote
        .update({
          dateSentEmailDeliveryNote: today.toISOString(),
        })
        .then(() => {
          this.getDeliveryNotes();
        })
        .catch(function (error) {
          console.log(
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
              error.message
          );
        });
    },
  },
  beforeMount: async function () {
    this.userRol = sessionStorage.getItem("user_rol");

    this.getDeliveryNotes();
    this.farmName = this.params.treatment.farmName;
  },
};

export default ApplicationDetailLayoutNoteModal;
</script>
