<template>
  <div>
    <img :src="urlImage" class="image" :alt="alt" />
  </div>
</template>

<script>
import { firebaseGlobal } from "@/plugins/firebase";

export default {
  data() {
    return {
      image: "",
    };
  },
  props: {
    src: {
      required: true,
    },
    alt: {
      required: false,
    },
  },
  methods: {
    getImage() {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(this.src)
        .getDownloadURL()
        .then(function (url) {
          _this.image = url;
        })
        .catch(function (error) {});

      return _this.image;
    },
  },
  computed: {
    urlImage() {
      return this.getImage();
    },
  },
};
</script>
