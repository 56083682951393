<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Área administrativa") }}
    </div>

    <loader v-if="dataLoaded === false"></loader>

    <div v-if="dataLoaded === true">
      <div>
        <div class="sm:flex">
          <div class="flex items-center">
            <label>
              <input
                class="
                  w-40
                  appearance-none
                  block
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Buscar')"
                v-model="search"
              />
            </label>

            <button
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              v-if="userRol === 'appSuperAdministrators'"
              type="button"
              @click="addUser"
            >
              {{ $t("Agregar usuario") }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Nombre") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Correo electrónico") }}
                    </div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">
                      {{ $t("Roles usuario") }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, key) in filteredUsers" :key="key">
                  <td class="px-4 py-3">
                    <div v-on:click="editUser(user.id, user.rol_type)">
                      <span
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{
                          user.nombre !== "" ? user.nombre : user.email
                        }}</span
                      >
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    {{ user.email }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    {{ user.rol }}
                  </td>
                </tr>

                <tr v-if="filteredUsers.length === 0">
                  <td class="border px-4 py-3" colspan="4">
                    {{
                      $t(
                        "No hay usuarios del área administrativa registrados en el sistema"
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import AdministrativeAreaAddPage from "@/pages/AdministrativeArea/AdministrativeAreaAddPage";
import AdministrativeAreaEditPage from "@/pages/AdministrativeArea/AdministrativeAreaEditPage";

export default {
  layout: "layout",
  name: "AdministrativeArea",
  metaInfo: {
    title: "Área administrativa",
  },
  components: {
    Loader,
  },
  data() {
    return {
      dataLoaded: false,
      users: [],
      search: "",
      userRol: sessionStorage.getItem("user_rol"),
      userCurrent: sessionStorage.getItem("user_id"),
    };
  },
  methods: {
    getUsers() {
      this.dataLoaded = false;
      this.search = "";

      this.users = [];

      if (this.userRol !== "comTechniciansAdmin") {
        db.collection("appAdministrators")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = [];
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["rol"] = "Administrador";
              arrayTemp["rol_type"] = "appAdministrators";

              _this.users.push(arrayTemp);
            });

            this.dataLoaded = true;
          });

        db.collection("appSuperAdministrators")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = [];
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["rol"] = "SuperAdministrador";
              arrayTemp["rol_type"] = "appSuperAdministrators";

              _this.users.push(arrayTemp);
            });

            this.dataLoaded = true;
          });

        db.collection("comTechniciansAdmin")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = [];
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["rol"] = "Administrador comercial";
              arrayTemp["rol_type"] = "comTechniciansAdmin";

              _this.users.push(arrayTemp);
            });

            this.dataLoaded = true;
          });
      }

      if (this.userRol === "comTechniciansAdmin") {
        db.collection("comTechniciansAdmin")
          .where("deleted", "==", false)
          .where("uid", "==", this.userCurrent)
          .get()
          .then((querySnapshot) => {
            let _this = this;
            querySnapshot.forEach(function (doc) {
              let arrayTemp = [];
              arrayTemp["id"] = doc.id;
              arrayTemp["nombre"] = doc.data().name;
              arrayTemp["email"] = doc.data().email;
              arrayTemp["rol"] = "Administrador comercial";
              arrayTemp["rol_type"] = "comTechniciansAdmin";

              _this.users.push(arrayTemp);
            });

            this.dataLoaded = true;
          });
      }
    },
    addUser() {
      this.$modal.show(
        AdministrativeAreaAddPage,
        {
          title: this.$t("Agregar usuario"),
          params: null,
        },
        { height: "auto" },
        { "before-close": this.getUsers }
      );
    },
    editUser(id, rolType) {
      this.$modal.show(
        AdministrativeAreaEditPage,
        {
          title: this.$t("Editar usuario"),
          params: {
            id: id,
            rolType: rolType,
          },
        },
        { height: "auto" },
        { "before-close": this.getUsers }
      );
    },
  },
  computed: {
    filteredUsers() {
      let arrayFiltered = [];

      this.users.forEach((element) => {
        if (this.search === "") {
          arrayFiltered.push(element);
        } else {
          if (
            element.nombre !== undefined &&
            element.telefono !== undefined &&
            element.email !== undefined
          ) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.telefono
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.email
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          } else if (
            element.nombre !== undefined &&
            element.email !== undefined
          ) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1 ||
              element.email
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          } else if (element.nombre !== undefined) {
            if (
              element.nombre
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              arrayFiltered.push(element);
            }
          }
        }
      });

      arrayFiltered = arrayFiltered.sort(this.compareName);

      return arrayFiltered;
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.getUsers();
  },
};
</script>
