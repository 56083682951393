<template>
  <tr>
    <td class="px-4 py-3" v-if="editStep">
      [{{ protocolReminders[editStep.step].repetitionInDays }} Días] Tratamiento
    </td>
    <td class="px-4 py-3">
      {{ editStep.state }}
    </td>
    <td class="px-4 py-3">
      {{ editStep.estimatedDate | formatDate }}
    </td>
    <td class="px-4 py-3">
      <div v-if="!disabledDate">
        {{ editStep.step_date | formatDate }}
      </div>
      <div v-if="disabledDate">
        <datetime
          input-class="appearance-none w-full block bg-white text-gray-700 border border-red-500 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white"
          id="fechaTratamiento"
          type="date"
          format="dd/MM/kkkk"
          placeholder="dd/mm/yyyy"
          v-model="editStep.step_date"
          zone="local"
          value-zone="local"
        ></datetime>
      </div>
    </td>
    <td class="px-4 py-3">
      <div
        v-if="
          (userRol === 'appSuperAdministrators' ||
            userRol === 'comTechnicians' ||
            userRol === 'distributors') &&
          editStep.state === 'Pendiente'
        "
      >
        <span v-if="!disabledDate" v-on:click="activeEditDate">
          <Zondicon
            icon="edit-pencil"
            class="
              fill-current
              inline-block
              h-4
              text-gray-500
              cursor-pointer
              hover:text-red
            "
          />
        </span>
        <div v-if="disabledDate" class="flex">
          <span
            v-on:click="checkEditDate"
            class="
              mr-2
              border border-gray-500
              rounded-full
              py-1
              px-2
              cursor-pointer
              text-gray-500
              hover:bg-green-200 hover:text-green-400 hover:border-green-400
            "
          >
            <Zondicon icon="checkmark" class="fill-current inline-block h-4" />
          </span>
          <span
            v-on:click="closeEditDate"
            class="
              border border-gray-500
              rounded-full
              cursor-pointer
              py-1
              px-2
              cursor-pointer
              text-gray-500
              hover:bg-pink-200 hover:text-pink-400 hover:border-pink-400
            "
          >
            <Zondicon icon="close" class="fill-current inline-block h-4" />
          </span>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import Zondicon from "vue-zondicons";
import Utilities from "@/plugins/Utilities";
import { Datetime } from "vue-datetime";

export default {
  components: {
    Zondicon,
    datetime: Datetime,
  },
  data() {
    return {
      editStep: this.step,
      step_date: "",
      disabledDate: false,
    };
  },
  props: {
    step: {
      required: true,
    },
    treatment: {
      required: true,
    },
    protocolReminders: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  computed: {},
  methods: {
    activeEditDate() {
      this.disabledDate = true;
    },
    closeEditDate() {
      this.disabledDate = false;
      this.editStep.step_date = "";
    },
    checkEditDate() {
      this.$emit("eventEditStep", this.editStep);
    },
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");
    this.userId = sessionStorage.getItem("user_id");
  },
};
</script>
