<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div
      class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6"
      v-if="inspection"
    >
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="inspectionDate"
            >
              {{ $t("Fecha inspección") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="inspectionDate"
              type="text"
              :placeholder="this.$t('Fecha inspección')"
              v-model="inspectionDate"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-5
              px-3
            "
          >
            {{ $t("Datos de la inspección") }}
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="cultivation"
            >
              {{ $t("Tipo de cultivo") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="cultivation"
              type="text"
              :placeholder="this.$t('Tipo de cultivo')"
              v-model="inspection.cultivation"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="cultivationSubtype"
            >
              {{ $t("Subtipo de cultivo") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="cultivationSubtype"
              type="text"
              :placeholder="this.$t('Subtipo de cultivo')"
              v-model="inspection.cultivationSubtype"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="plantationDate"
            >
              {{ $t("Fecha plantación") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="plantationDate"
              type="text"
              :placeholder="this.$t('Fecha plantación')"
              v-model="plantationDate"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="harvest"
            >
              {{ $t("Época de recolección") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="harvest"
              type="text"
              :placeholder="this.$t('Época de recolección')"
              v-model="inspection.harvest"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sustract"
            >
              {{ $t("Sustrato") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sustract"
              type="text"
              :placeholder="this.$t('Época de recolección')"
              v-model="inspection.sustract"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="cultivationState"
            >
              {{ $t("Estado de cultivo") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="cultivationState"
              type="text"
              :placeholder="this.$t('Estado de cultivo')"
              v-model="inspection.cultivationState"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-full px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Sectores") }}
            </label>

            <div class="bg-gray-200 pt-6 pb-4">
              <div class="flex flex-wrap">
                <div class="w-full md:w-1/4 px-3"></div>
                <div class="w-full md:w-3/4 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 1
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 2
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 3
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 4
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 5
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 6
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/4 px-3">
                  {{ $t("Superficie") }} (Ha)
                </div>

                <div class="w-full md:w-3/4 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="inspection.surface1"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="inspection.surface2"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="inspection.surface3"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="inspection.surface4"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="inspection.surface5"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="inspection.surface6"
                          readonly
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/4 px-3">
                  {{ $t("Caudal") }} (m3/h)
                </div>

                <div class="w-full md:w-3/4 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="inspection.flow1"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="inspection.flow2"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="inspection.flow3"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="inspection.flow4"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="inspection.flow5"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="inspection.flow6"
                          readonly
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/4 px-3">
                  {{ $t("Presión de trabajo") }} (Ha)
                </div>

                <div class="w-full md:w-3/4 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="inspection.presure1"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="inspection.presure2"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="inspection.presure3"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="inspection.presure4"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="inspection.presure5"
                          readonly
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="inspection.presure6"
                          readonly
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Agua") }}
          </div>

          <div class="w-full md:w-1/2 px-3 mb-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Tipo de riego") }}
            </label>

            <div class="block w-full relative mb-4">
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="inspection.irrigation"
                  :value="'Aspersión'"
                  readonly
                />
                <span class="text-gray-700 text-xs">
                  {{ $t("Aspersión") }}
                </span>
              </label>
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="inspection.irrigation"
                  :value="'Goteo'"
                  readonly
                />
                <span class="text-gray-700 text-xs"> {{ $t("Goteo") }} </span>
              </label>
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="inspection.irrigation"
                  :value="'Microaspersión'"
                  readonly
                />
                <span class="text-gray-700 text-xs">
                  {{ $t("Microaspersión") }}
                </span>
              </label>
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="inspection.irrigation"
                  :value="'Pivot'"
                  readonly
                />
                <span class="text-gray-700 text-xs"> {{ $t("Pivot") }} </span>
              </label>
            </div>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Procedencia del agua") }}
            </label>

            <div class="block w-full relative mb-4">
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="inspection.waterOrigin"
                  :value="'Balsa'"
                  readonly
                />
                <span class="text-gray-700 text-xs"> {{ $t("Balsa") }} </span>
              </label>

              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="inspection.waterOrigin"
                  :value="'Comunidad reg'"
                  readonly
                />
                <span class="text-gray-700 text-xs">
                  {{ $t("Comunidad reg") }}
                </span>
              </label>

              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="inspection.waterOrigin"
                  :value="'Desaladora'"
                  readonly
                />
                <span class="text-gray-700 text-xs">
                  {{ $t("Desaladora") }}
                </span>
              </label>

              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="inspection.waterOrigin"
                  :value="'Pozo'"
                  readonly
                />
                <span class="text-gray-700 text-xs"> {{ $t("Pozo") }} </span>
              </label>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="redox"
            >
              {{ $t("Redox agua") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="redox"
              type="text"
              :placeholder="this.$t('Redox agua')"
              v-model="inspection.redox"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="ph"
            >
              {{ $t("Ph agua") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="ph"
              type="text"
              :placeholder="this.$t('Ph agua')"
              v-model="inspection.ph"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="temperature"
            >
              {{ $t("Temperatura agua") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="temperature"
              type="text"
              :placeholder="this.$t('Temperatura agua')"
              v-model="inspection.temperature"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="phRegulator"
            >
              {{ $t("¿Dispone regulador ph agua?") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="phRegulator"
              type="text"
              :placeholder="this.$t('¿Dispone regulador ph agua?')"
              v-model="phRegulator"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="connectionDone"
            >
              {{ $t("¿Conexiones realizadas?") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="connectionDone"
              type="text"
              :placeholder="this.$t('¿Conexiones realizadas?')"
              v-model="connectionDone"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="connectionDate"
            >
              {{ $t("Fecha prevista conexión") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="connectionDate"
              type="text"
              :placeholder="this.$t('Fecha prevista conexión')"
              v-model="connectionDate"
              readonly
            />
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-full px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="protocolName"
            >
              {{ $t("Protocolo (superficie total:") }} {{ total_protocol }} )
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="protocolName"
              type="text"
              :placeholder="this.$t('Protocolo')"
              v-model="inspection.protocolName"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys1"
            >
              {{ $t("System") }} 1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys2"
            >
              {{ $t("System") }} 2
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys2"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys2"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys2_1"
            >
              {{ $t("System") }} 2.1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys2_1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys2_1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys3"
            >
              {{ $t("System") }} 3
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys3"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys3"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys4"
            >
              {{ $t("System") }} 4
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys4"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys4"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys5"
            >
              {{ $t("System") }} 5
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys5"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys5"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys6"
            >
              {{ $t("System") }} 6
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys6"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys6"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys6_1"
            >
              {{ $t("System") }} 6.1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys6_1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys6_1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys7"
            >
              {{ $t("System") }} 7
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys7"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys7"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sysbas"
            >
              {{ $t("Basic") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sysbas"
              type="text"
              :placeholder="this.$t('Basic')"
              v-model="sysbas"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-full px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="observation"
            >
              {{ $t("Otros") }}
            </label>
            <textarea
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="observation"
              type="text"
              :placeholder="this.$t('Otros')"
              v-model="inspection.observation"
              readonly
            />
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Fechas") }}
          </div>

          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="ozoneDate"
            >
              {{ $t("Fecha pedida cliente agrozono") }}
            </label>

            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="ozoneDate"
              type="text"
              :placeholder="this.$t('Otros')"
              v-model="ozoneDate"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="agrozonoDate"
            >
              {{ $t("Fecha definitiva agrozono") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="agrozonoDate"
              type="text"
              :placeholder="this.$t('Fecha definitiva agrozono')"
              v-model="agrozonoDate"
              readonly
            />
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Contacto") }}
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="contactName"
            >
              {{ $t("Nombre de contacto") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="contactName"
              type="text"
              :placeholder="this.$t('Nombre de contacto')"
              v-model="inspection.contactName"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="contactPosition"
            >
              {{ $t("Cargo de contacto") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="contactPosition"
              type="text"
              :placeholder="this.$t('Cargo de contacto')"
              v-model="inspection.contactPosition"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="contactEmail"
            >
              {{ $t("Email de contacto para envio albaranes") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="contactEmail"
              type="text"
              :placeholder="this.$t('Email de contacto para envio albaranes')"
              v-model="inspection.contactEmail"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="contactPhone"
            >
              {{ $t("Teléfono de contacto") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="contactPhone"
              type="text"
              :placeholder="this.$t('Teléfono de contacto')"
              v-model="inspection.contactPhone"
              readonly
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Firma contacto") }}
            </label>
            <img
              v-if="clientSignature"
              v-bind:src="clientSignature"
              class="image"
              alt="clientSignature"
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Firma técnico comercial") }}
            </label>
            <img
              v-if="technicSignature"
              v-bind:src="technicSignature"
              class="image"
              alt="technicSignature"
            />
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Imagenes") }}
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Imagen") }} 1
            </label>
            <img v-if="image1" v-bind:src="image1" class="image" alt="image1" />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Imagen") }} 2
            </label>
            <img v-if="image2" v-bind:src="image2" class="image" alt="image2" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db, firebaseGlobal } from "@/plugins/firebase.js";
import { DateTime as luxon } from "luxon";
import "vue-datetime/dist/vue-datetime.css";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";

const ApplicationAssignmentInspectionModal = {
  name: "ApplicationAssignmentInspectionModal",
  layout: "layout",
  components: {
    HeaderModal,
  },
  data() {
    return {
      surface1: "",
      surface2: "",
      surface3: "",
      surface4: "",
      surface5: "",
      surface6: "",
      sys1: "",
      sys2: "",
      sys2_1: "",
      sys3: "",
      sys4: "",
      sys5: "",
      sys6: "",
      sys6_1: "",
      sys7: "",
      sysbas: "",
      clientSignature: "",
      technicSignature: "",
      image1: "",
      image2: "",
      state: "",

      inspection: "",
      protocol: "",
      protocol_total_surface: 0,
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  computed: {
    inspectionDate() {
      return this.inspection.inspectionDate
        ? luxon.fromISO(this.inspection.inspectionDate).toFormat("dd-MM-yyyy")
        : "";
    },
    plantationDate() {
      return this.inspection.plantationDate
        ? luxon.fromISO(this.inspection.plantationDate).toFormat("dd-MM-yyyy")
        : "";
    },
    connectionDate() {
      return this.inspection.connectionDate
        ? luxon.fromISO(this.inspection.connectionDate).toFormat("dd-MM-yyyy")
        : "";
    },
    ozoneDate() {
      return this.inspection.ozoneDate
        ? luxon.fromISO(this.inspection.ozoneDate).toFormat("dd-MM-yyyy")
        : "";
    },
    agrozonoDate() {
      return this.inspection.agrozonoDate
        ? luxon.fromISO(this.inspection.agrozonoDate).toFormat("dd-MM-yyyy")
        : "";
    },
    phRegulator() {
      return this.inspection.phRegulator ? "Si" : "No";
    },
    connectionDone() {
      return this.inspection.connectionDone ? "Si" : "No";
    },
    total_protocol() {
      this.protocol_total_surface = 0.0;
      if (!isNaN(this.inspection.surface1) && this.inspection.surface1 !== "") {
        this.protocol_total_surface =
          this.protocol_total_surface + parseFloat(this.inspection.surface1);
      }

      if (!isNaN(this.inspection.surface2) && this.inspection.surface2 !== "") {
        this.protocol_total_surface =
          this.protocol_total_surface + parseFloat(this.inspection.surface2);
      }

      if (!isNaN(this.inspection.surface3) && this.inspection.surface3 !== "") {
        this.protocol_total_surface =
          this.protocol_total_surface + parseFloat(this.inspection.surface3);
      }

      if (!isNaN(this.inspection.surface4) && this.inspection.surface4 !== "") {
        this.protocol_total_surface =
          this.protocol_total_surface + parseFloat(this.inspection.surface4);
      }

      if (!isNaN(this.inspection.surface5) && this.inspection.surface5 !== "") {
        this.protocol_total_surface =
          this.protocol_total_surface + parseFloat(this.inspection.surface5);
      }

      if (!isNaN(this.inspection.surface6) && this.inspection.surface6 !== "") {
        this.protocol_total_surface =
          this.protocol_total_surface + parseFloat(this.inspection.surface6);
      }

      this.sys1 = (
        parseInt(this.protocol.SYS1) * this.protocol_total_surface
      ).toFixed(2);
      this.sys2 = (
        parseInt(this.protocol.SYS2) * this.protocol_total_surface
      ).toFixed(2);
      this.sys2_1 = (
        parseInt(this.protocol.SYS2_1) * this.protocol_total_surface
      ).toFixed(2);
      this.sys3 = (
        parseInt(this.protocol.SYS3) * this.protocol_total_surface
      ).toFixed(2);
      this.sys4 = (
        parseInt(this.protocol.SYS4) * this.protocol_total_surface
      ).toFixed(2);
      this.sys5 = (
        parseInt(this.protocol.SYS5) * this.protocol_total_surface
      ).toFixed(2);
      this.sys6 = (
        parseInt(this.protocol.SYS6) * this.protocol_total_surface
      ).toFixed(2);
      this.sys6_1 = (
        parseInt(this.protocol.SYS6_1) * this.protocol_total_surface
      ).toFixed(2);
      this.sys7 = (
        parseInt(this.protocol.SYS7) * this.protocol_total_surface
      ).toFixed(2);
      this.sysbas = (
        parseInt(this.protocol.SYSBA) * this.protocol_total_surface
      ).toFixed(2);

      return this.protocol_total_surface;
    },
  },
  methods: {
    getProtocol() {
      let _this = this;

      db.collection("protocols")
        .where("uid", "==", this.inspection.protocolId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.protocol = doc.data();
          });
        });
    },
    getImage1(imagePath) {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(imagePath)
        .getDownloadURL()
        .then(function (url) {
          _this.image1 = url;
        })
        .catch(function (error) {});

      return _this.image1;
    },
    getImage2(imagePath) {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(imagePath)
        .getDownloadURL()
        .then(function (url) {
          _this.image2 = url;
        })
        .catch(function (error) {});

      return _this.image2;
    },
    getPhotoTechnic(imagePath) {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(imagePath)
        .getDownloadURL()
        .then(function (url) {
          _this.technicSignature = url;
        })
        .catch(function (error) {});

      return _this.technicSignature;
    },
    getPhotoClient(imagePath) {
      let storageRef = firebaseGlobal.storage().ref();
      let _this = this;

      storageRef
        .child(imagePath)
        .getDownloadURL()
        .then(function (url) {
          _this.clientSignature = url;
        })
        .catch(function (error) {});

      return _this.clientSignature;
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }
    this.technicSignature = require("@/assets/capture-image.png");
    this.clientSignature = require("@/assets/capture-image.png");
    this.image1 = require("@/assets/capture-image.png");
    this.image2 = require("@/assets/capture-image.png");

    this.inspection = this.params.inspection;
    this.getProtocol();

    if (this.inspection.image1Path) {
      this.image1 = this.getImage1(this.inspection.image1Path);
    }

    if (this.inspection.image2Path) {
      this.image2 = this.getImage2(this.inspection.image2Path);
    }

    if (this.inspection.technicSignaturePath) {
      this.technicSignature = this.getPhotoTechnic(
        this.inspection.technicSignaturePath
      );
    }

    if (this.inspection.clientSignaturePath) {
      this.clientSignature = this.getPhotoClient(
        this.inspection.clientSignaturePath
      );
    }
  },
};

export default ApplicationAssignmentInspectionModal;
</script>
