import Vue from 'vue';
import VueRouter from 'vue-router';
import VuePapaParse from 'vue-papa-parse';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from '@/App';
import Routes from '@/routes/Routes';
import VueSignaturePad from 'vue-signature-pad';
import i18n from '@/plugins/i18n';
import moment from 'moment';
import VueExcelXlsx from "vue-excel-xlsx";
import '@/assets/styles/tailwind.css';
import Viewer from 'v-viewer';
import VModal from 'vue-js-modal';
import VueMeta from 'vue-meta'

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VuePapaParse);
Vue.use(VueAxios, axios);
Vue.use(VueSignaturePad);
Vue.use(VueExcelXlsx);
Vue.use(Viewer);
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: false,
  dynamicDefaults: {
    height: 'auto',
    width: "90%",
    maxWidth: 800,
    adaptive: true,
    scrollable: true
  }
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

window.Event = new (class {
  constructor() {
    this.vue = new Vue();
  }

  fire(event, data = null) {
    this.vue.$emit(event, data);
  }

  listen(event, callback) {
    this.vue.$on(event, callback);
  }
})();

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
});

Vue.filter('formatHour', function (value) {
  if (value) {
    let minute = new Date(value).getMinutes();
    if(minute < 10){
      minute = '0' + new Date(value).getMinutes();
    }

    return new Date(value).getHours() + ':' + minute;
  }
});

Vue.mixin({
  methods: {
    getLang(lang) {
      let locale = 'es';

      if(lang === 'Italiano') {
        locale = 'it';
      }

      if(lang === 'Frances') {
        locale = 'fr';
      }

      localStorage.setItem("user_locale", locale);

      return locale;
    },
    setLang(user) {
      i18n.locale = this.getLang(user);
    },
    randomUid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    compareName(a, b) {
      const nameA = a.nombre.toUpperCase();
      const nameB = b.nombre.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    },
    compareNameEn(a, b) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    },
    compareLangName(a, b) {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();

      if (this.langStorage === "it" && a.nameIt && b.nameIt) {
        nameA = a.nameIt.toUpperCase();
        nameB = b.nameIt.toUpperCase();
      }

      if (this.langStorage === "fr" && a.nameFr && b.nameFr) {
        nameA = a.nameFr.toUpperCase();
        nameB = b.nameFr.toUpperCase();
      }

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    compareFarmName(a, b) {
      const nameA = a.farmName.toUpperCase();
      const nameB = b.farmName.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    },
    getDatesBetweenDates(startDate, endDate) {
      let dates = []
      //to avoid modifying the original date
      const theDate = new Date(startDate)
      while (theDate < endDate) {
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + 1)
      }
      dates = [...dates, endDate]
      return dates
    },
  },
})

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: Routes
})

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
