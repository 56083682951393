<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div
      v-if="deletedRegister === false"
      class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6"
    >
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Tipo de usuario") }}
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="rolEdit"
            >
              {{ $t("Tipo de cliente/lead") }}
              <span class="text-bold text-lg text-red">*</span>
            </label>
            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-gray-200
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="rolEdit"
                v-model="rol"
                disabled="disabled"
              >
                <option value="">
                  {{ $t("Seleccione un tipo de usuario") }}
                </option>
                <option value="customers">{{ $t("Cliente") }}</option>
                <option value="leads">{{ $t("Lead") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="lang"
            >
              {{ $t("Idioma") }}
              <span class="text-bold text-lg text-red">*</span>
            </label>
            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="lang"
                v-model="lang"
              >
                <option value="">{{ $t("Seleccione un idioma") }}</option>
                <option value="Español">{{ $t("Español") }}</option>
                <option value="Italiano">{{ $t("Italiano") }}</option>
                <option value="Frances">{{ $t("Frances") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
                mt-2
              "
            >
              {{ $t("Plataformas asignadas") }}
            </label>
            <div class="block w-full relative mb-4">
              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="(business, index) in listBusinessAreas"
                :key="index"
                :for="`businessAreas${index}`"
              >
                <input
                  class="mr-2 leading-tight"
                  :id="`businessAreas${index}`"
                  type="checkbox"
                  v-model="businessAreas"
                  :value="business"
                  disabled
                />
                <span class="text-gray-700 text-xs">
                  {{ business.name }}
                </span>
              </label>
            </div>
          </div>

          <div class="w-full md:w-2/3 px-3">
            <div class="hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="password"
              >
                {{ $t("Contraseña") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="password"
                type="password"
                placeholder="*******"
                v-model="password"
              />
            </div>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div v-if="rol">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              <template v-if="rol === 'customers'">{{
                $t("Datos del cliente")
              }}</template>
              <template v-if="rol === 'leads'">{{
                $t("Datos del lead")
              }}</template>
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="name"
              >
                {{ $t("Nombre") }}
                <span class="text-bold text-lg text-red">*</span>
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="name"
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="name"
              />
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="email"
              >
                {{ $t("Email") }}
                <span class="text-bold text-lg text-red">*</span>
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="email"
                type="text"
                :placeholder="this.$t('Email')"
                v-model="email"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="country"
              >
                {{ $t("País") }}
                <span class="text-bold text-lg text-red">*</span>
              </label>
              <selectCountry
                id="country"
                v-model="country"
                :default="country"
              ></selectCountry>
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="province"
              >
                {{ $t("Provincia") }}
                <span class="text-bold text-lg text-red">*</span>
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="province"
                type="text"
                :placeholder="this.$t('Provincia')"
                v-model="province"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                  mt-2
                "
                for="createdDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="createdDate"
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                :class="rol === 'leads' ? 'mt-2' : ''"
                for="city"
              >
                {{ $t("Ciudad") }}
                <span
                  class="text-bold text-lg text-red"
                  v-if="rol === 'customers'"
                  >*</span
                >
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="city"
                type="text"
                :placeholder="this.$t('Ciudad')"
                v-model="city"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                :class="rol === 'leads' ? 'mt-2' : ''"
                for="address"
              >
                {{ $t("Dirección") }}
                <span
                  class="text-bold text-lg text-red"
                  v-if="rol === 'customers'"
                  >*</span
                >
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="address"
                type="text"
                :placeholder="this.$t('Dirección')"
                v-model="address"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="phone"
              >
                {{ $t("Teléfono") }}
                <span class="text-bold text-lg text-red">*</span>
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="phone"
                type="text"
                :placeholder="this.$t('Teléfono')"
                v-model="phone"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                :class="rol === 'leads' ? 'mt-2' : ''"
                for="cp"
              >
                {{ $t("Cp") }}
                <span
                  class="text-bold text-lg text-red"
                  v-if="rol === 'customers'"
                  >*</span
                >
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="cp"
                type="text"
                :placeholder="this.$t('Cp')"
                v-model="cp"
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                :class="rol === 'leads' ? 'mt-2' : ''"
                for="cif"
              >
                {{ $t("Cif") }}
                <span
                  class="text-bold text-lg text-red"
                  v-if="rol === 'customers'"
                  >*</span
                >
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="cif"
                type="text"
                :placeholder="this.$t('Cif')"
                v-model="cif"
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                  mt-2
                "
                for="idAgro"
              >
                {{ $t("Id agro") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="idAgro"
                type="text"
                :placeholder="this.$t('Id agro')"
                v-model="idAgro"
                readonly
              />
            </div>

            <div class="w-full md:w-1/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                  mt-2
                "
                for="idCreatedToShare"
              >
                {{ $t("Id created to share") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="idCreatedToShare"
                type="text"
                :placeholder="this.$t('Id created to share')"
                v-model="idCreatedToShare"
                readonly
              />
            </div>

            <div class="w-full md:w-2/4 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="contact"
              >
                {{ $t("Contacto") }}
                <span class="text-bold text-lg text-red">*</span>
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="contact"
                type="text"
                :placeholder="this.$t('Contacto')"
                v-model="contact"
              />
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-full px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="details"
              >
                {{ $t("Detalles") }}
              </label>
              <textarea
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="details"
                type="text"
                :placeholder="this.$t('Detalles')"
                v-model="details"
              ></textarea>
            </div>
          </div>

          <div v-if="rol === 'customers'" class="flex flex-wrap mb-6">
            <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechnicianId"
              >
                {{ $t("Comercial") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  :class="{
                    'bg-gray-200':
                      userRol === 'comTechnicians' ||
                      userRol === 'distributors',
                  }"
                  :disabled="
                    userRol === 'distributors' || userRol === 'comTechnicians'
                  "
                  id="comTechnicianId"
                  v-model="comTechnicianId"
                  @change="changeTechnician"
                >
                  <option value="">{{ $t("Seleccione un comercial") }}</option>
                  <option
                    v-for="technician in technicianList"
                    :value="technician.id"
                    v-bind:key="technician.id"
                  >
                    {{ technician.name }} [{{ technician.responsableName }}]
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="comTechnicianName"
              >
                {{ $t("Comercial nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="comTechnicianName"
                type="text"
                :placeholder="this.$t('Comercial nombre')"
                v-model="comTechnicianName"
              />
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributorId"
              >
                {{ $t("Distribuidor") }}
              </label>

              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  :class="{ 'bg-gray-200': userRol === 'distributors' }"
                  :disabled="userRol === 'distributors'"
                  id="distributorId"
                  v-model="distributorId"
                  @change="changeDistributor"
                >
                  <option value="">
                    {{ $t("Seleccione un distribuidor") }}
                  </option>
                  <option
                    v-for="distributor in customerDistributorsList"
                    :value="distributor.id"
                    v-bind:key="distributor.id"
                  >
                    {{ distributor.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 hidden">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="distributorName"
              >
                {{ $t("Distribuidor nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="distributorName"
                type="text"
                :placeholder="this.$t('Distribuidor nombre')"
                v-model="distributorName"
              />
            </div>
          </div>

          <hr class="px-3 mb-6" />

          <div class="flex flex-wrap mb-6">
            <div class="w-full md:w-full px-3 mb-6 md:mb-2">
              <div v-if="errorObservation" class="bg-red text-white px-4 py-2">
                <strong>{{ $t("Ha ocurrido un error") }}</strong>
                <div
                  v-for="(error, index) in errorMessageObservations"
                  :key="index"
                >
                  <span class="block">- {{ error.message }}</span>
                </div>
              </div>

              <div
                v-if="successMessageObservations !== ''"
                class="bg-greenlight text-white px-4 py-2"
              >
                <strong>{{ $t("Excelente") }}</strong>
                <span class="block sm:inline ml-2">{{
                  $t(successMessageObservations)
                }}</span>
              </div>
            </div>

            <div class="w-full md:w-full px-3 mb-6 md:mb-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Observaciones") }}
              </label>
            </div>

            <div class="w-full md:w-full px-3 mb-6 md:mb-2">
              <div
                class="
                  relative
                  rounded
                  overflow-hidden
                  border border-red-500
                  mb-2
                  text-sm
                "
                v-for="(observation, index) in filterObservations"
                v-bind:key="index"
              >
                <div class="px-4 pt-8">
                  <p class="text-gray-700">
                    <span class="font-semibold">{{
                      observation.date_observation
                    }}</span
                    >: {{ observation.observation }}
                  </p>
                </div>
                <div class="px-4 py-4 text-right">
                  <span
                    class="
                      inline-block
                      bg-gray-200
                      rounded-full
                      px-3
                      py-1
                      text-sm
                      font-semibold
                      text-gray-700
                      mr-2
                    "
                    v-if="observation.date_reminder !== ''"
                  >
                    {{ $t("Recordatorios") }} {{ observation.date_reminder }}
                  </span>

                  <div class="absolute top-0 right-0 mt-2 text-xs">
                    <a
                      class="rounded-full px-1 py-1 text-redagrozono mr-2"
                      href="#"
                      @click="loadObservation(index)"
                    >
                      {{ $t("Editar") }}
                    </a>
                    <a
                      class="rounded-full px-1 py-1 text-redagrozono mr-2"
                      href="#"
                      @click="deleteObservation(index)"
                    >
                      {{ $t("Eliminar") }}
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="text-gray-700 text-sm"
                v-if="filterObservations.length === 0"
              >
                {{ $t("No hay observaciones todavia") }}
              </div>
            </div>
          </div>

          <div class="flex flex-wrap mb-6">
            <div class="w-full px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="observationMessage"
              >
                {{ $t("Nueva observación") }}
              </label>
              <textarea
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="observationMessage"
                type="text"
                :placeholder="this.$t('Nueva observación')"
                v-model="observationMessage"
              ></textarea>
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="observationDateReminder"
              >
                {{ $t("Fecha de contacto") }}
              </label>
              <datetime
                input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                id="observationDateContact"
                type="date"
                format="dd/MM/kkkk"
                placeholder="dd/mm/yyyy"
                v-model="observationDateContact"
              ></datetime>
            </div>

            <div class="w-full md:w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="observationDateReminder"
              >
                {{ $t("Fecha recordatorio") }}
              </label>
              <datetime
                input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                id="observationDateReminder"
                type="date"
                format="dd/MM/kkkk"
                placeholder="dd/mm/yyyy"
                v-model="observationDateReminder"
              ></datetime>
            </div>

            <div class="w-full px-3 text-right mt-2">
              <button
                class="
                  bg-gray-600
                  mt-6
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                @click="validateObservation('create')"
                :class="{ hidden: observationEditIndex !== '' }"
              >
                {{ $t("Agregar observación") }}
              </button>

              <button
                class="
                  bg-yellow
                  mt-6
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                @click="validateObservation('edit')"
                :class="{ hidden: observationEditIndex === '' }"
              >
                {{ $t("Editar observación") }}
              </button>
            </div>
          </div>
        </div>

        <div class="mx-2 mb-4">
          <div
            v-if="errorMessage !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ $t(errorMessage) }}</span>
          </div>

          <div
            v-if="successMessage !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(successMessage) }}</span>
          </div>
        </div>
        <div
          v-if="deletedRegister === false"
          :class="{ hidden2: userRol !== 'appSuperAdministrators' }"
        >
          <div
            v-if="
              userRol === 'appSuperAdministrators' ||
              userRol === 'appAdministrators' ||
              userRol === 'comTechniciansResp' ||
              userRol === 'comTechnicians' ||
              userRol === 'distributors'
            "
            class="flex flex-row justify-end px-1"
          >
            <button
              class="
                bg-white
                mr-2
                text-sm
                font-semibold
                border border-redagrozono
                hover:bg-redagrozono
                text-redagrozono
                hover:text-white
                py-2
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              type="button"
              @click="confirmDisableCustomerLead"
              :class="{
                hidden:
                  dataLoaded === false || userRol !== 'appSuperAdministrators',
              }"
            >
              {{ $t("Inhabilitar registro") }}
            </button>

            <button
              v-if="rol === 'leads'"
              class="
                bg-blueagrozono
                text-white
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                py-2
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              type="button"
              @click="confirmConvertLeadToCustomer"
              :class="{ hidden: dataLoaded === false }"
            >
              {{ $t("Convertir en cliente") }}
            </button>

            <button
              class="
                bg-yellow
                mr-2
                px-3
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-2
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              type="button"
              @click="onSubmit"
              :class="{ hidden: dataLoaded === false }"
              v-if="
                userRol === 'appSuperAdministrators' ||
                userRol === 'comTechniciansResp' ||
                userRol === 'comTechnicians' ||
                userRol === 'distributors'
              "
            >
              {{ $t("Guardar cambios") }}
            </button>

            <button
              class="
                bg-gray-600
                mr-2
                text-sm
                font-semibold
                text-white
                py-2
                px-4
                rounded
              "
              type="button"
              :class="{ hidden: dataLoaded === true }"
            >
              {{ $t("Enviando datos") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import { Datetime } from "vue-datetime";
import { DateTime as luxon } from "luxon";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import SelectCountry from "@/components/SelectCountry";
import { orderBy } from "lodash";
import HeaderModal from "@/components/HeaderModal";

const AreaClientesEdit = {
  name: "AreaClientesEdit",
  layout: "layout",
  components: {
    Loader,
    SelectCountry,
    datetime: Datetime,
    HeaderModal,
  },
  data() {
    return {
      listErrors: [],
      dataLoaded: true,
      successMessage: "",
      errorMessage: "",
      successMessageObservations: "",
      errorMessageObservations: [],
      errorObservation: false,
      deletedRegister: false,
      businessAreaActive: "",
      disabledBusinessArea: true,
      userRol: sessionStorage.getItem("user_rol"),
      currentUser: sessionStorage.getItem("user_id"),
      email: "",
      password: "",
      rol: "",
      name: "",
      lang: "",
      createdDate: "",
      distributors: [],
      delegations: [],
      technicians: [],
      listBusinessAreas: [],
      businessAreas: [],
      id: "",
      cp: "",
      address: "",
      cif: "",
      city: "",
      country: "",
      idAgro: "",
      idCreatedToShare: "",
      contact: "",
      province: "",
      phone: "",
      details: "",
      distributorId: "",
      distributorName: "",
      delegationId: "",
      delegationName: "",
      comTechnicianId: "",
      comTechnicianName: "",
      observations: [],
      observationEditIndex: "",
      observationMessage: "",
      observationDateReminder: "",
      observationDateContact: "",
      date_today: "",
    };
  },
  props: {
    title: {
      require: true,
    },
    params: {
      required: true,
    },
  },
  computed: {
    filterObservations() {
      if (this.observations && this.observations.length !== 0) {
        return this.observations.filter((doc) => {
          if (this.businessAreaActive.uid === doc.businessAreaId) {
            return doc;
          }
        });
      }

      return "";
    },
    technicianList() {
      return orderBy(this.technicians, ["name"], ["asc"]);
    },
    customerDistributorsList() {
      if (this.distributors && this.distributors.length > 0) {
        return orderBy(this.distributors, ["name"], ["asc"]);
      }

      return this.distributors;
    },
    checkObservation() {
      let _this = this;
      if (_this.observations) {
        return _this.observations.filter(function (element) {
          let createdDate = luxon
            .fromFormat(element.date_reminder, "d/MM/y")
            .toISO();

          if (!createdDate) {
            createdDate = luxon
              .fromFormat(element.date_reminder, "d/M/y")
              .toISO();
          }

          if (createdDate > _this.date_today) {
            return element;
          }
        });
      }

      return (_this.observations = []);
    },
    checkObservationCustomer() {
      let _this = this;
      return _this.observaciones.filter(function (element) {
        let createdDate = luxon
          .fromFormat(element.date_reminder, "d/MM/y")
          .toISO();

        if (!createdDate) {
          createdDate = luxon
            .fromFormat(element.date_reminder, "d/M/y")
            .toISO();
        }

        if (createdDate > _this.date_today) {
          return element;
        }
      });
    },
  },
  methods: {
    confirmDisableCustomerLead() {
      if (confirm(this.$t("¿Realmente deseas inhabilitar el usuario?"))) {
        this.disableCustomerLead();
      }
    },
    disableCustomerLead() {
      this.dataLoaded = false;
      let _this = this;

      let dateCurrent = new Date();
      let dateDeleted =
        dateCurrent.getDate() +
        "/" +
        (dateCurrent.getMonth() + 1) +
        "/" +
        dateCurrent.getFullYear() +
        " " +
        dateCurrent.getHours() +
        ":" +
        dateCurrent.getMinutes() +
        ":" +
        dateCurrent.getSeconds();

      if (this.rol === "customers") {
        db.collection("customers")
          .doc(_this.params.id)
          .update({
            deleted: true,
            disabledDate: dateDeleted,
          })
          .then(() => {
            _this.dataLoaded = true;
            _this.successMessage =
              "El usuario ha sido inhabilitado exitosamente";
            _this.deletedRegister = true;

            this.$emit("close");
          });
      }

      if (this.rol === "leads") {
        db.collection("leads")
          .doc(_this.params.id)
          .update({
            deleted: true,
            disabledDate: dateDeleted,
          })
          .then(() => {
            _this.dataLoaded = true;
            _this.successMessage =
              "El usuario ha sido inhabilitado exitosamente";
            _this.deletedRegister = true;

            this.$emit("close");
          });
      }
    },
    confirmConvertLeadToCustomer() {
      if (
        confirm(
          this.$t("¿Realmente deseas convertir el lead") +
            this.email +
            this.$t("En cliente") +
            "?"
        )
      ) {
        this.convertLeadToCustomer();
      }
    },
    convertLeadToCustomer() {
      this.dataLoaded = false;
      let _this = this;

      if (
        _this.name === "" ||
        _this.email === "" ||
        _this.country === "" ||
        _this.city === "" ||
        _this.province === "" ||
        _this.phone === "" ||
        _this.address === "" ||
        _this.cp === "" ||
        _this.cif === "" ||
        _this.contact === ""
      ) {
        this.dataLoaded = true;
        this.errorMessage =
          "Debe llenar correctamente todos los campos del cliente para guardarlos en el sistema. (nombre, email, país, provincia, teléfono, ciudad, dirección, cp, cif y contacto)";

        return;
      }

      db.collection("customers")
        .doc(_this.params.id)
        .set({
          email: _this.email,
          mail: _this.email,
          name: _this.name,
          uid: _this.params.id,
          businessAreas: _this.businessAreas,
          createdDate: _this.createdDate,
          cp: _this.cp,
          address: _this.address,
          cif: _this.cif,
          city: _this.city,
          country: _this.country,
          idAgro: String(_this.idAgro),
          idCreatedToShare: _this.idCreatedToShare,
          contact: _this.contact,
          province: _this.province,
          phone: _this.phone,
          details: _this.details,
          distributorId: _this.distributorId,
          distributorName: _this.distributorName,
          delegationId: _this.delegationId,
          delegationName: _this.delegationName,
          comTechnicianId: _this.comTechnicianId,
          comTechnicianName: _this.comTechnicianName,
          observaciones: _this.observations,
          deleted: false,
        })
        .then(() => {
          _this.dataLoaded = true;
          _this.successMessage = "El usuario ha sido creado exitosamente";

          db.collection("users").doc(_this.params.id).update({
            rol: "customers",
          });

          db.collection("leads").doc(_this.params.id).delete();

          this.$emit("close");
        });
    },
    onSubmit() {
      let _this = this;
      this.dataLoaded = false;
      this.successMessage = "";
      this.errorMessage = "";

      if (this.email === "") {
        this.dataLoaded = true;
        this.errorMessage =
          "Debe llenar correctamente todos los campos para guardarlos en el sistema";

        return;
      }

      if (
        this.rol === "customers" &&
        (this.name === "" ||
          this.email === "" ||
          this.country === "" ||
          this.city === "" ||
          this.province === "" ||
          this.phone === "" ||
          this.address === "" ||
          this.cp === "" ||
          this.cif === "" ||
          this.contact === "" ||
          this.businessAreas.length === 0)
      ) {
        this.dataLoaded = true;
        this.errorMessage =
          "Debe llenar correctamente todos los campos del cliente para guardarlos en el sistema. (nombre, email, país, provincia, teléfono, ciudad, dirección, cp, cif y contacto)";

        return;
      }

      if (
        this.rol === "leads" &&
        (this.name === "" ||
          this.email === "" ||
          this.country === "" ||
          this.province === "" ||
          this.phone === "" ||
          this.businessAreas.length === 0 ||
          this.contact === "")
      ) {
        this.dataLoaded = true;
        this.errorMessage =
          "Debe llenar correctamente todos los campos del lead para guardarlos en el sistema. (nombre, email, país, provincia, teléfono y contacto)";

        return;
      }

      if (this.rol === "customers") {
        db.collection("customers")
          .doc(_this.params.id)
          .update({
            name: _this.name,
            email: _this.email,
            mail: _this.email,
            businessAreas: _this.businessAreas,
            cp: _this.cp,
            address: _this.address,
            cif: _this.cif,
            city: _this.city,
            country: _this.country,
            idAgro: String(_this.idAgro),
            idCreatedToShare: _this.idCreatedToShare,
            contact: _this.contact,
            province: _this.province,
            phone: _this.phone,
            details: _this.details,
            distributorId: _this.distributorId,
            distributorName: _this.distributorName,
            delegationId: _this.delegationId,
            delegationName: _this.delegationName,
            comTechnicianId: _this.comTechnicianId,
            comTechnicianName: _this.comTechnicianName,
            observaciones: _this.observations,
            deleted: false,
          })
          .then(() => {
            _this.dataLoaded = true;
            _this.successMessage = "El usuario ha sido editado exitosamente";

            db.collection("users").doc(_this.params.id).update({
              email: _this.email,
              lang: _this.lang,
            });

            this.$emit("close");
          });
      }

      if (this.rol === "leads") {
        db.collection("leads")
          .doc(_this.params.id)
          .update({
            name: _this.name,
            email: _this.email,
            mail: _this.email,
            businessAreas: _this.businessAreas,
            cp: _this.cp,
            address: _this.address,
            cif: _this.cif,
            city: _this.city,
            country: _this.country,
            idAgro: String(_this.idAgro),
            idCreatedToShare: _this.idCreatedToShare,
            contact: _this.contact,
            province: _this.province,
            phone: _this.phone,
            details: _this.details,
            distributorId: _this.distributorId,
            distributorName: _this.distributorName,
            delegationId: _this.delegationId,
            delegationName: _this.delegationName,
            comTechnicianId: _this.comTechnicianId,
            comTechnicianName: _this.comTechnicianName,
            observaciones: _this.observations,
            deleted: false,
          })
          .then(() => {
            _this.dataLoaded = true;
            _this.successMessage = "El usuario ha sido editado exitosamente";

            db.collection("users").doc(_this.params.id).update({
              email: _this.email,
              lang: _this.lang,
            });

            this.$emit("close");
          });
      }
    },
    changeDistributor() {
      if (this.distributorId === "") {
        this.distributorName = "";

        return;
      }

      this.distributors.filter((distributor) => {
        if (distributor.id === this.distributorId) {
          this.distributorName = distributor.name;

          this.generateIdShare();
        }
      });
    },
    changeDelegation() {
      if (this.customers_delegationId === "") {
        this.customers_delegationName = "";

        return;
      }

      this.delegations.forEach((delegation) => {
        if (delegation.id === this.customers_delegationId) {
          this.customers_delegationName = delegation.name;
        }
      });
    },
    changeTechnician() {
      if (this.comTechnicianId === "") {
        this.comTechnicianName = "";

        return;
      }

      this.technicians.forEach((technician) => {
        if (technician.id === this.comTechnicianId) {
          this.comTechnicianName = technician.name;
          this.customers_delegationId = technician.delegationId;

          this.distributors = this.distributors.filter((distributor) => {
            if (distributor.comercialId === this.comTechnicianId) {
              return distributor;
            }
          });

          this.changeDelegation();
          this.generateIdShare();
        }
      });
    },
    validateObservation(type) {
      this.errorMessageObservations = [];

      if (this.observationMessage === "") {
        this.listErrors.push({
          message: this.$t("Debe indicar una observación"),
        });
      }

      if (this.observationDateContact === "") {
        this.listErrors.push({
          message: this.$t(
            "No existe una fecha de contacto, es necesario introducirla"
          ),
        });
      }

      if (
        this.checkObservation.length === 0 &&
        this.observationDateReminder === ""
      ) {
        this.listErrors.push({
          message: this.$t(
            "No existe una fecha de recordatorio a futuro, es necesario introducirla"
          ),
        });
      }

      if (
        this.observationDateContact !== "" &&
        luxon.local().toISO() < this.observationDateContact
      ) {
        this.listErrors.push({
          message: this.$t(
            "La fecha de contacto debe de ser igual o inferior a la fecha actual"
          ),
        });
      }

      if (
        this.observationDateReminder !== "" &&
        luxon.local().toISO() > this.observationDateReminder
      ) {
        this.listErrors.push({
          message: this.$t(
            "La fecha introducida debe de ser superior a la fecha actual"
          ),
        });
      }

      if (this.listErrors.length > 0) {
        this.errorObservation = true;
        this.errorMessageObservations = this.listErrors;
        this.listErrors = [];

        setTimeout(() => {
          this.errorObservation = false;
        }, 5000);

        return;
      }

      this.errorObservation = false;
      this.errorMessageObservations = [];
      if (type === "create") {
        this.createObservation();
      }

      if (type === "edit") {
        this.editObservation();
      }
    },
    createObservation() {
      let date_reminder = this.observationDateReminder
        ? luxon.fromISO(this.observationDateReminder).toFormat("dd/MM/kkkk")
        : "";
      let date_contact = this.observationDateContact
        ? luxon.fromISO(this.observationDateContact).toFormat("dd/MM/kkkk")
        : "";

      this.observations.push({
        notified: false,
        observation:
          JSON.parse(sessionStorage.getItem("businessAreaActive")).name +
          " " +
          this.observationMessage,
        businessAreaName: JSON.parse(
          sessionStorage.getItem("businessAreaActive")
        ).name,
        businessAreaId: JSON.parse(sessionStorage.getItem("businessAreaActive"))
          .uid,
        uid: this.randomUid(),
        date_reminder: date_reminder,
        date_observation: date_contact,
        date_created: luxon.fromISO(this.date_today).toFormat("dd/MM/kkkk"),
      });

      this.observationMessage = "";
      this.observationDateReminder = "";
      this.observationDateContact = "";

      this.successMessageObservations =
        "La observación fue agregada exitosamente";

      setTimeout(() => {
        this.successMessageObservations = "";
      }, 3000);
    },
    loadObservation(index) {
      this.observationEditIndex = index;
      this.observationMessage = this.observations[index].observation.substr(
        this.observations[index].observation.indexOf(" ") + 1
      );

      if (this.observations[index].date_reminder) {
        let fechaString = this.observations[index].date_reminder;
        let fechaStringArray = fechaString.split("/");
        this.observationDateReminder =
          fechaStringArray[2] +
          "-" +
          fechaStringArray[1] +
          "-" +
          fechaStringArray[0];
      }

      if (this.observations[index].date_observation) {
        let fechaString = this.observations[index].date_observation;
        let fechaStringArray = fechaString.split("/");
        this.observationDateContact =
          fechaStringArray[2] +
          "-" +
          fechaStringArray[1] +
          "-" +
          fechaStringArray[0];
      }
    },
    editObservation() {
      this.observations[this.observationEditIndex].observation =
        JSON.parse(sessionStorage.getItem("businessAreaActive")).name +
        " " +
        this.observationMessage;
      this.observations[this.observationEditIndex].notified = false;
      this.observations[this.observationEditIndex].date_reminder = this
        .observationDateReminder
        ? luxon.fromISO(this.observationDateReminder).toFormat("dd/MM/kkkk")
        : "";
      this.observations[this.observationEditIndex].date_observation = luxon
        .fromISO(this.observationDateContact)
        .toFormat("dd/MM/kkkk");

      this.observationMessage = "";
      this.observationDateReminder = "";
      this.observationDateContact = "";
      this.observationEditIndex = "";

      this.successMessageObservations =
        "La observación fue editada exitosamente";

      let _this = this;
      setTimeout(() => {
        _this.successMessageObservations = "";
      }, 3000);
    },
    deleteObservation(index) {
      this.observationMessage = "";
      this.observationDateReminder = "";
      this.observationDateContact = "";
      this.observationEditIndex = "";

      this.observations.splice(index, 1);

      this.errorMessageObservations = "";
      this.successMessageObservations =
        "La observación fue eliminada exitosamente";

      let _this = this;
      setTimeout(() => {
        _this.successMessageObservations = "";
      }, 3000);
    },
    async generateIdShare() {
      if (
        this.comTechnicianId !== "" &&
        this.distributorId !== "" &&
        this.customers_delegationId !== ""
      ) {
        let delegationIdAgro = await Utilities.getIdAgro(
          "delegations",
          this.customers_delegationId
        );
        let distributorIdAgro = await Utilities.getIdAgro(
          "distributors",
          this.distributorId
        );

        let comercialIdAgro = "";
        this.idCreatedToShare =
          "ES/" +
          delegationIdAgro +
          "/" +
          distributorIdAgro +
          "/" +
          comercialIdAgro +
          "/" +
          this.idAgro;
      }
    },
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    let date = new Date(luxon.local().toISO());
    let date_format =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    this.date_today = luxon.fromFormat(date_format, "d/MM/y").toISO();

    if (!this.date_today) {
      this.date_today = luxon.fromFormat(date_format, "d/M/y").toISO();
    }

    this.listBusinessAreas = JSON.parse(
      sessionStorage.getItem("businessArea")
    ).sort(function (a, b) {
      const nameA = a.name;
      const nameB = b.name;

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    });

    if (this.params.rolType === "customers") {
      this.dataLoaded = false;
      db.collection("customers")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.name = snapshot.data().name;
          this.email = snapshot.data().email;
          this.rol = this.params.rolType;
          this.createdDate = snapshot.data().createdDate;
          this.businessAreas = snapshot.data().businessAreas;
          this.cp = snapshot.data().cp;
          this.address = snapshot.data().address;
          this.cif = snapshot.data().cif;
          this.city = snapshot.data().city;
          this.country = snapshot.data().country;
          this.idAgro = snapshot.data().idAgro;
          this.idCreatedToShare = snapshot.data().idCreatedToShare;
          this.contact = snapshot.data().contact;
          this.province = snapshot.data().province;
          this.phone = snapshot.data().phone;
          this.details = snapshot.data().details;
          this.distributorId = snapshot.data().distributorId;
          this.distributorName = snapshot.data().distributorName;
          this.delegationId = snapshot.data().delegationId;
          this.delegationName = snapshot.data().delegationName;
          this.comTechnicianId = snapshot.data().comTechnicianId;
          this.comTechnicianName = snapshot.data().comTechnicianName;
          this.observations = snapshot.data().observaciones;
          this.dataLoaded = true;
        });
    }

    if (this.params.rolType === "leads") {
      this.dataLoaded = false;
      db.collection("leads")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.name = snapshot.data().name;
          this.email = snapshot.data().email;
          this.rol = this.params.rolType;
          this.createdDate = snapshot.data().createdDate;
          this.businessAreas = snapshot.data().businessAreas;
          this.cp = snapshot.data().cp;
          this.address = snapshot.data().address;
          this.cif = snapshot.data().cif;
          this.city = snapshot.data().city;
          this.country = snapshot.data().country;
          this.idAgro = snapshot.data().idAgro;
          this.idCreatedToShare = snapshot.data().idCreatedToShare;
          this.contact = snapshot.data().contact;
          this.province = snapshot.data().province;
          this.phone = snapshot.data().phone;
          this.details = snapshot.data().details;
          this.distributorId = snapshot.data().distributorId;
          this.distributorName = snapshot.data().distributorName;
          this.delegationId = snapshot.data().delegationId;
          this.delegationName = snapshot.data().delegationName;
          this.comTechnicianId = snapshot.data().comTechnicianId;
          this.comTechnicianName = snapshot.data().comTechnicianName;
          this.observations = snapshot.data().observaciones;
          this.dataLoaded = true;
        });
    }

    let _this = this;
    db.collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.distributors.push(arrayTemp);
        });
      });

    db.collection("delegations")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.delegations.push(arrayTemp);
        });
      });

    db.collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.technicians.push(arrayTemp);
          _this.changeTechnician();
        });
      });

    await db
      .collection("users")
      .doc(_this.params.id)
      .get()
      .then((snapshot) => {
        _this.lang = snapshot.data() ? snapshot.data().lang : "Español";
      });

    _this.businessAreaActive = JSON.parse(
      sessionStorage.getItem("businessAreaActive")
    );
  },
};

export default AreaClientesEdit;
</script>
