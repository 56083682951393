<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="text-2xl font-semibold text-gray-800 p-6">
      <button
        class="
          bg-gray-200
          mr-2
          relative
          border border-gray-200
          text-xs
          font-semibold
          hover:bg-white
          text-gray-700
          pb-1
          px-3
          rounded
          focus:outline-none focus:shadow-outline
        "
        style="top: -2px"
        type="button"
        @click="openModal"
      >
        <Zondicon
          icon="arrow-thin-left"
          class="
            fill-current
            inline-block
            h-3
            text-gray-700
            cursor-pointer
            hover:text-gray-700
          "
        />
      </button>

      <div
        class="
          inline-block
          uppercase
          tracking-wide
          text-gray-700 text-xs
          font-bold
          mb-4
          px-3
        "
      >
        {{ deliveryNote.farmName }}
      </div>
      <div
        class="
          inline-block
          uppercase
          tracking-wide
          text-gray-700 text-xs
          font-bold
          mb-4
          px-3
        "
      >
        {{ deliveryNote.uid }}
      </div>
    </div>
    <div class="flex flex-row items-center mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-full px-3 mb-3 text-right">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Fecha") }}:
            </label>
            <span class="text-md"
              >{{ deliveryNote.date | formatDate }}
              {{ deliveryNote.date | formatHour }}</span
            >
          </div>

          <div class="w-full md:w-full px-3 mb-3 text-right">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Albarán ref") }}:
            </label>
            <span class="text-md">{{ deliveryNote.numRef }}</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Cliente") }}
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Nombre") }}:
            </label>
            <span class="text-md">{{ deliveryNote.customerName }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Teléfono") }}:
            </label>
            <span class="text-md">{{ deliveryNote.customerTelf }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Email") }}:
            </label>
            <span class="text-md">{{ deliveryNote.customerEmail }}</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-4
              mb-4
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Distribuidor") }}
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Nombre") }}:
            </label>
            <span class="text-md">{{ deliveryNote.distributorName }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Dirección") }}:
            </label>
            <span class="text-md">{{ deliveryNote.distributorAddress }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Cif") }}:
            </label>
            <span class="text-md">{{ deliveryNote.distributorCif }}</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-4
              mb-4
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Tratamiento") }}
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Nombre") }}:
            </label>
            <span class="text-md">{{
              deliveryNote.inspectionProtocolName
            }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Técnico aplicador") }}:
            </label>
            <span class="text-md">{{ deliveryNote.appTechName }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Ubicación parcela") }}:
            </label>
            <span class="text-md">{{ deliveryNote.farmAddress }}</span>
          </div>

          <div class="w-full md:w-full px-3 mb-3">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Tipo de cultivo") }}:
            </label>
            <span class="text-md">{{ deliveryNote.cultivation }}</span>
          </div>

          <div class="w-full md:w-1/4 px-3 mb-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Inicio tto") }}:
            </label>
            <span class="text-md">{{
              deliveryNote.initTimeTreatment | formatHour
            }}</span>
          </div>

          <div class="w-full md:w-1/4 px-3 mb-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Fin tto") }}:
            </label>
            <span class="text-md">{{
              deliveryNote.finishTimeTreatment | formatHour
            }}</span>
          </div>

          <div class="w-full md:w-2/4 px-3 mb-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("Superficie tratada") }}:
            </label>
            <span class="text-md">{{ deliveryNote.surface }} Ha</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-4
              mb-0
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Aplicación de productos system") }}
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System basic") }}:
            </label>
            <span class="text-md">{{
              checkSystem(deliveryNote.systemBasic)
            }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 1:
            </label>
            <span class="text-md">{{ checkSystem(deliveryNote.system1) }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 2:
            </label>
            <span class="text-md">{{ checkSystem(deliveryNote.system2) }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 2.1:
            </label>
            <span class="text-md">{{
              checkSystem(deliveryNote.system2_1)
            }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 3:
            </label>
            <span class="text-md">{{ checkSystem(deliveryNote.system3) }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 4:
            </label>
            <span class="text-md">{{ checkSystem(deliveryNote.system4) }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 5:
            </label>
            <span class="text-md">{{ checkSystem(deliveryNote.system5) }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 6:
            </label>
            <span class="text-md">{{ checkSystem(deliveryNote.system6) }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 6.1:
            </label>
            <span class="text-md">{{
              checkSystem(deliveryNote.system6_1)
            }}</span>
          </div>

          <div class="w-full md:w-1/5 px-3 border-gray-400 border p-2">
            <label
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                mr-2
              "
            >
              {{ $t("System") }} 7:
            </label>
            <span class="text-md">{{ checkSystem(deliveryNote.system7) }}</span>
          </div>

          <div
            class="
              w-full
              md:w-1/2
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-5
              mb-0
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Cliente") }}
          </div>
          <div
            class="
              w-full
              md:w-1/2
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mt-5
              mb-0
              px-3
              bg-gray-200
              p-2
            "
          >
            {{ $t("Técnico") }}
          </div>

          <div class="w-full md:w-1/2 px-3 border-gray-400 border p-2">
            <get-image :src="deliveryNote.customerSignaturePath" />
          </div>

          <div class="w-full md:w-1/2 px-3 border-gray-400 border p-2">
            <get-image :src="deliveryNote.techSignaturePath" />
          </div>

          <div class="w-full md:w-full px-3 mt-4 p-2 text-center">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mr-2
              "
            >
              {{ $t("Ejemplar para el cliente") }}
            </label>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import GetImage from "@/components/GetImage";
import ApplicationDetailLayoutNoteModal from "@/pages/ApplicationDetail/ApplicationDetailLayoutNoteModal";
import HeaderModal from "@/components/HeaderModal";

const ApplicationDetailModal = {
  name: "ApplicationDetailModal",
  layout: "layout",
  components: {
    GetImage,
    Zondicon,
    HeaderModal,
    ApplicationDetailLayoutNoteModal,
  },
  data: function () {
    return {
      deliveryNote: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  methods: {
    openModal: function () {
      this.$emit("close");
      this.$modal.show(ApplicationDetailLayoutNoteModal, {
        title: this.$t("Visualización de albaranes"),
        params: {
          treatmentID: this.deliveryNote.treatmentId,
          treatment: this.params.treatment,
        },
      });
    },
    checkSystem(data) {
      if (data) {
        return data;
      }

      return "0";
    },
  },
  beforeMount: async function () {
    this.deliveryNote = this.params.deliveryNote;
  },
};

export default ApplicationDetailModal;
</script>
