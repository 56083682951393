<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Aplicaciones por asignar") }}
    </div>

    <loader v-if="dataLoaded === false"></loader>

    <div v-if="dataLoaded === true">
      <div class="md:flex">
        <div class="flex items-center">
          <label>
            <input
              class="
                w-full
                appearance-none
                block
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              :placeholder="this.$t('Buscar')"
              v-model="search"
            />
          </label>
        </div>
      </div>

      <ApplicationByAssignInspection
        v-for="(inspection, index) in filterInspections"
        :key="index"
        :inspection="inspection"
      ></ApplicationByAssignInspection>
    </div>
  </div>
</template>

<script>
import Utilities from "@/plugins/Utilities";
import { db } from "@/plugins/firebase";
import Loader from "@/components/Loader";
import ApplicationByAssignInspection from "@/pages/ApplicationByAssign/ApplicationByAssignInspection";
import Fuse from "fuse.js";

const ApplicationByAssignList = {
  layout: "layout",
  name: "ApplicationByAssignList",
  metaInfo: {
    title: "Aplicaciones por asignar",
  },
  components: {
    Loader,
    ApplicationByAssignInspection,
  },
  data() {
    return {
      state: "all",
      inspections: [],
      dataLoaded: false,
      userRol: "",
      userPresent: "",
      search: "",
    };
  },
  computed: {
    filterInspections() {
      let inspections = this.inspections.sort(function (a, b) {
        const nameA = a.ozoneDate;
        const nameB = b.ozoneDate;

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      if (this.search !== "") {
        inspections = this.searchInspections();
      }

      return inspections;
    },
  },
  methods: {
    getInspections() {
      this.dataLoaded = false;
      this.inspections = [];

      let _this = this;
      db.collection("inspections")
        .where("state", "==", "sent")
        .where("deleted", "==", false)
        .where("asignedTreatment", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async function (doc) {
            let inspection = doc.data();

            await db
              .collection("customers")
              .doc(doc.data().customerId)
              .collection("farms")
              .doc(doc.data().farmId)
              .get()
              .then((querySnapshot) => {
                inspection["farm"] = querySnapshot.data();
              });

            _this.inspections.push(inspection);
          });

          this.dataLoaded = true;
        });
    },
    searchInspections() {
      const options = {
        includeScore: false,
        shouldSort: false,
        threshold: 0.2,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "customerName",
          "protocolNameLocalized",
          "farmName",
          "farmDistributorName",
          "comTechnicianName",
        ],
      };

      const fuse = new Fuse(this.inspections, options);

      return fuse.search(this.search.toLowerCase()).map((inspection) => {
        return inspection.item;
      });
    },
  },
  created() {
    this.$root.$on("reloadListApplicationByAssign", () => {
      this.getInspections();
    });
  },

  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");
    this.userPresent = sessionStorage.getItem("user_id");

    if (
      this.userRol !== "appSuperAdministrators" &&
      this.userRol !== "appAdministrators" &&
      this.userRol !== "respTechnicians"
    ) {
      await this.$router.push({ name: "home" });
    }

    this.getInspections();
  },
};

export default ApplicationByAssignList;
</script>
