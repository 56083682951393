import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from '../lang/es.json'
import it from '../lang/it.json'
import fr from '../lang/fr.json'

Vue.use(VueI18n);

const messages = {
    'es': es,
    'it': it,
    'fr': fr
};

const i18n = new VueI18n({
    locale: localStorage.getItem("user_locale") ?? 'es', // set locale
    fallbackLocale: 'it', // set fallback locale
    messages, // set locale messages
});

export default i18n;
