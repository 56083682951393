<template>
  <div>
    <div class="mt-6 mb-2 text-xl font-semibold">
      {{ date }}
    </div>
    <TaskCard
      v-for="(task, index) in orderTask"
      :key="index"
      :task="task"
    ></TaskCard>
  </div>
</template>

<script>
import TaskCard from "@/pages/Tasks/TaskCard";
import { orderBy } from "lodash";

export default {
  name: "TaskColumn",
  layout: "layout",
  components: {
    TaskCard,
  },
  data() {
    return {};
  },
  props: {
    groupTask: {
      required: true,
    },
    date: {
      required: true,
    },
  },
  computed: {
    orderTask() {
      return orderBy(this.groupTask, ["userName"], ["asc"]);
    },
  },
};
</script>
