<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos de la cuenta") }}
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="rol"
            >
              {{ $t("Rol asignado") }}
            </label>
            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="rol"
                v-model="rol"
                @change="checkRol"
              >
                <option value="">{{ $t("Seleccione un rol") }}</option>
                <option value="appAdministrators">
                  {{ $t("Administrador") }}
                </option>
                <option value="appSuperAdministrators">
                  {{ $t("Super administrador") }}
                </option>
                <option value="comTechniciansAdmin">
                  {{ $t("Administrador comercial") }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="email"
            >
              {{ $t("Email") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="email"
              type="text"
              :placeholder="this.$t('Email')"
              v-model="email"
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="password"
            >
              {{ $t("Contraseña") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="password"
              type="password"
              placeholder="*******"
              v-model="password"
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Plataformas asignadas") }}
            </label>
            <div class="block w-full relative mb-4">
              <label
                class="md:w-2/3 block cursor-pointer"
                v-for="(business, index) in listBusinessAreas"
                :key="index"
                :for="`businessAreas${index}`"
              >
                <input
                  class="mr-2 leading-tight"
                  :id="`businessAreas${index}`"
                  type="checkbox"
                  v-model="businessAreas"
                  :value="business"
                  :disabled="disabled"
                />
                <span class="text-gray-700 text-xs">
                  {{ business.name }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div v-if="rol">
          <div class="flex flex-wrap mb-4">
            <div
              class="
                w-full
                md:w-3/3
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                px-3
              "
            >
              <template v-if="rol === 'appAdministrators'">{{
                $t("Datos del administrador")
              }}</template>
              <template v-if="rol === 'appSuperAdministrators'">{{
                $t("Datos del super administrador")
              }}</template>
              <template v-if="rol === 'comTechniciansAdmin'">{{
                $t("Datos del administrador comercial")
              }}</template>
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="name"
              >
                {{ $t("Nombre") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                id="name"
                type="text"
                :placeholder="this.$t('Nombre')"
                v-model="name"
              />
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="country"
              >
                {{ $t("País") }}
              </label>
              <div class="block w-full relative mr-4">
                <selectCountry
                  id="country"
                  v-model="country"
                  :default="country"
                ></selectCountry>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="lang"
              >
                {{ $t("Idioma") }}
              </label>
              <div class="block w-full relative mr-4">
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  id="lang"
                  v-model="lang"
                >
                  <option value="">{{ $t("Seleccione un idioma") }}</option>
                  <option value="Español">{{ $t("Español") }}</option>
                  <option value="Italiano">{{ $t("Italiano") }}</option>
                  <option value="Frances">{{ $t("Frances") }}</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="createdDate"
              >
                {{ $t("Fecha creación") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                id="createdDate"
                type="text"
                :placeholder="this.$t('Fecha creación')"
                v-model="createdDate"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="errorMessage !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ errorMessage }}</span>
          </div>

          <div
            v-if="successMessage !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente.") }}</strong>
            <span class="block ml-2">{{ $t(successMessage) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            @click="onSubmit"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Crear nuevo") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db, firebaseGlobal } from "@/plugins/firebase.js";
import Utilities from "@/plugins/Utilities.js";
import SelectCountry from "@/components/SelectCountry";
import HeaderModal from "@/components/HeaderModal";

export default {
  name: "AdministrativeAreaAdd",
  layout: "layout",
  components: {
    SelectCountry,
    HeaderModal,
  },
  data() {
    return {
      dataLoaded: true,
      successMessage: "",
      errorMessage: "",
      email: "",
      password: "",
      rol: "",
      name: "",
      country: "España",
      lang: "Español",
      createdDate: "",
      businessAreas: [],
      listBusinessAreas: [],
      disabled: true,
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: false,
    },
  },
  methods: {
    checkRol() {
      this.disabled = true;
      if (this.rol !== "") {
        return (this.businessAreas = this.listBusinessAreas);
      }

      return (this.businessAreas = []);
    },
    createUser(uid) {
      if (this.rol === "appAdministrators") {
        db.collection("appAdministrators")
          .doc(uid)
          .set({
            email: this.email,
            mail: this.email,
            name: this.name,
            uid: uid,
            deleted: false,
            createdDate: this.createdDate,
            disabledDate: "N/D",
          })
          .then(() => {
            this.dataLoaded = true;
            this.successMessage = "El usuario ha sido creado exitosamente";

            let _this = this;
            db.collection("users").doc(uid).set({
              email: _this.email,
              rol: "appAdministrators",
              businessAreas: _this.businessAreas,
              uid: uid,
              country: _this.country,
              lang: _this.lang,
            });

            this.email = "";
            this.password = "";
            this.rol = "";
            this.name = "";

            this.$emit("close");
          })
          .catch(function (error) {
            let errorMessage = error.message;

            this.dataLoaded = true;
            this.errorMessage =
              this.$t(
                "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
              ) + errorMessage;
          });
      }

      if (this.rol === "appSuperAdministrators") {
        db.collection("appSuperAdministrators")
          .doc(uid)
          .set({
            email: this.email,
            mail: this.email,
            name: this.name,
            uid: uid,

            deleted: false,
            createdDate: this.createdDate,
            disabledDate: "N/D",
          })
          .then(() => {
            this.dataLoaded = true;
            this.successMessage = "El usuario ha sido creado exitosamente";

            let _this = this;
            db.collection("users").doc(uid).set({
              email: _this.email,
              rol: "appSuperAdministrators",
              businessAreas: _this.businessAreas,
              uid: uid,
              country: _this.country,
              lang: _this.lang,
            });

            this.email = "";
            this.password = "";
            this.rol = "";
            this.name = "";

            this.$emit("close");
          })
          .catch(function (error) {
            let errorMessage = error.message;

            this.dataLoaded = true;
            this.errorMessage =
              this.$t(
                "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
              ) + errorMessage;
          });
      }

      if (this.rol === "comTechniciansAdmin") {
        db.collection("comTechniciansAdmin")
          .doc(uid)
          .set({
            email: this.email,
            mail: this.email,
            name: this.name,
            uid: uid,
            deleted: false,
            createdDate: this.createdDate,
            disabledDate: "N/D",
          })
          .then(() => {
            this.dataLoaded = true;
            this.successMessage = "El usuario ha sido creado exitosamente";

            let _this = this;
            db.collection("users").doc(uid).set({
              email: _this.email,
              rol: "comTechniciansAdmin",
              businessAreas: _this.businessAreas,
              uid: uid,
              country: _this.country,
              lang: _this.lang,
            });

            this.email = "";
            this.password = "";
            this.rol = "";
            this.name = "";

            this.$emit("close");
          })
          .catch(function (error) {
            let errorMessage = error.message;

            this.dataLoaded = true;
            this.errorMessage =
              this.$t(
                "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
              ) + errorMessage;
          });
      }
    },
    onSubmit() {
      this.dataLoaded = false;
      this.successMessage = "";
      this.errorMessage = "";

      if (
        this.email === "" ||
        this.password === "" ||
        this.rol === "" ||
        this.businessAreas.length === 0
      ) {
        this.dataLoaded = true;
        this.errorMessage = this.$t(
          "Debe llenar correctamente todos los campos para guardarlos en el sistema"
        );
      }

      let _this = this;
      let existUserAdmin = firebaseGlobal
        .functions()
        .httpsCallable("existUserAdmin");
      let addUserAdmin = firebaseGlobal
        .functions()
        .httpsCallable("addUserAdmin");

      existUserAdmin({
        email: this.email,
      })
        .then((result) => {
          if (result.data.code === 1) {
            _this.dataLoaded = true;
            _this.errorMessage = _this.$t(
              "El usuario ya se encuentra registrado en la base de datos"
            );

            return;
          }

          addUserAdmin({
            email: _this.email,
            password: _this.password,
            displayName: _this.name,
          })
            .then((result) => {
              if (result.data.code === 1) {
                return _this.createUser(result.data.uid);
              }

              _this.dataLoaded = true;
              _this.errorMessage = result.data.message;
            })
            .catch(function (error) {
              let errorMessage = error.message;

              _this.dataLoaded = true;
              _this.errorMessage =
                _this.$t(
                  "Ha ocurrido un error al intentar registrar este usuario. Error:"
                ) + errorMessage;
            });
        })
        .catch((error) => {
          let errorMessage = error.message;

          this.dataLoaded = true;
          this.errorMessage =
            this.$t(
              "Ha ocurrido un error al intentar crear el usuario. Error:"
            ) + errorMessage;
        });
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    let dateCurrent = new Date();
    this.createdDate =
      dateCurrent.getDate() +
      "/" +
      (dateCurrent.getMonth() + 1) +
      "/" +
      dateCurrent.getFullYear() +
      " " +
      dateCurrent.getHours() +
      ":" +
      dateCurrent.getMinutes() +
      ":" +
      dateCurrent.getSeconds();

    let _this = this;
    db.collection("businessAreas")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          _this.listBusinessAreas.push(doc.data());
          _this.listBusinessAreas.sort(function (a, b) {
            const nameA = a.name;
            const nameB = b.name;

            let comparison = 0;
            if (nameA > nameB) {
              comparison = 1;
            } else if (nameA < nameB) {
              comparison = -1;
            }
            return comparison;
          });
        });
      });
  },
};
</script>
