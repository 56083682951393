<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div
      v-if="registroEliminado === false"
      class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6"
    >
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            v-if="mensajeError !== ''"
            class="bg-red text-white px-4 py-2 mb-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(mensajeError) }}</span>
          </div>

          <div
            v-if="mensajeExito !== ''"
            class="bg-greenlight text-white px-4 py-2 mb-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(mensajeExito) }}</span>
          </div>

          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ farmName }}
          </div>

          <table class="table-auto w-full text-left">
            <thead>
              <tr>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">{{ $t("Id") }}</div>
                </th>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">{{ $t("Fecha") }}</div>
                </th>
                <th class="px-4 py-2 text-gray-800"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="deliverynote in deliverynotes"
                v-bind:key="deliverynote.id"
              >
                <td class="px-4 py-3">
                  <div v-on:click="visualizarDeliveryNote(deliverynote.id)">
                    <span
                      class="
                        font-semibold
                        text-blueagrozono
                        hover:text-gray-800
                        cursor-pointer
                      "
                      >{{ deliverynote.id }}</span
                    >
                  </div>
                </td>
                <td class="px-4 py-3">{{ deliverynote.date_formated }}</td>
                <td class="px-4 py-3 text-center" style="width: 150px">
                  <button
                    class="
                      bg-yellow
                      mr-2
                      text-sm
                      font-semibold
                      hover:bg-greenlight
                      text-white
                      py-2
                      px-4
                      rounded
                      focus:outline-none focus:shadow-outline
                    "
                    type="button"
                    v-on:click="enviarDeliveryNote(deliverynote.id)"
                  >
                    <Zondicon
                      icon="envelope"
                      class="
                        fill-current
                        inline-block
                        h-4
                        mr-2
                        text-white
                        cursor-pointer
                      "
                    />
                    {{ $t("Enviar") }}
                  </button>
                </td>
              </tr>

              <tr v-if="deliverynotes.length === 0">
                <td class="border px-4 py-2" colspan="5">
                  {{ $t("No hay tratamientos registrados para esta finca") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>

    <div
      v-if="registroEliminado === false"
      class="flex flex-row justify-end"
    ></div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db, firebaseGlobal } from "@/plugins/firebase.js";
import { Datetime } from "vue-datetime";
import { DateTime } from "luxon";
import "vue-datetime/dist/vue-datetime.css";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";
import TratamientosDeliveryNotesEditPage from "@/pages/tratamientos/TratamientosDeliveryNotesEditPage";

const TratamientosDeliveryNotes = {
  name: "TratamientosDeliveryNotes",
  layout: "layout",
  components: {
    Zondicon,
    Loader,
    datetime: Datetime,
    HeaderModal,
  },
  data() {
    return {
      datosCargados: true,
      mensajeExito: "",
      mensajeError: "",
      registroEliminado: false,

      listado_tecnicos: [],
      listado_clientes: [],
      listado_fincas: [],
      listado_distribuidores: [],
      listado_comercialesResp: [],

      deliverynotes: [],
      farmName: "",

      ultimaFechaRealizada: "",
      usuarioRol: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: false,
    },
  },
  methods: {
    filtrarRegistros: async function () {
      let _this = this;

      _this.deliverynotes = [];

      await db
        .collection("treatments")
        .doc(_this.params.treatmentId)
        .get()
        .then(async function (doc) {
          _this.farmName = doc.data().farmName;

          await db
            .collection("treatments")
            .doc(_this.params.treatmentId)
            .collection("deliveryNotes")
            .get()
            .then((querySnapshot2) => {
              querySnapshot2.forEach(function (doc2) {
                let arrayTemp = doc2.data();
                arrayTemp["id"] = doc2.id;

                arrayTemp["date_formated"] = DateTime.fromISO(
                  doc2.data().date
                ).toFormat("dd/MM/kkkk hh:mm:ss");

                _this.deliverynotes.push(arrayTemp);
              });
            });
        });
    },
    visualizarDeliveryNote(id) {
      this.$emit("close");
      this.$modal.show(TratamientosDeliveryNotesEditPage, {
        title: this.$t("Detalles del albarán de la finca"),
        params: {
          treatmentId: this.params.treatmentId,
          id: id,
        },
      });
    },
    enviarDeliveryNote(id) {
      this.datosCargados = false;

      let _this = this;
      let sendDeliveryNoteIndividual = firebaseGlobal
        .functions()
        .httpsCallable("sendDeliveryNoteIndividual");
      sendDeliveryNoteIndividual({
        treatmentId: _this.params.treatmentId,
        deliveryNoteId: id,
      })
        .then(() => {
          this.datosCargados = true;
        })
        .catch((error) => {
          console.log(error);

          this.datosCargados = true;
        });
    },
    compareName(a, b) {
      const nameA = a.hours;
      const nameB = b.hours;

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }
      return comparison;
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.usuarioRol = sessionStorage.getItem("user_rol");

    let _this = this;

    await db
      .collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_tecnicos.push(arrayTemp);
        });
      });

    await db
      .collection("customers")
      .where("deleted", "==", false)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          _this.listado_clientes.push(arrayTemp);
        });

        this.datosCargados = true;
      });

    await db
      .collection("customers")
      .where("deleted", "==", false)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          db.collection("customers")
            .doc(doc.id)
            .collection("farms")
            .where("deleted", "==", false)
            .get()
            .then((querySnapshot2) => {
              querySnapshot2.forEach(function (doc2) {
                let arrayTemp2 = doc2.data();
                arrayTemp2["id"] = doc2.id;

                _this.listado_fincas.push(arrayTemp2);

                if (_this.$props.farmId === doc2.data().id) {
                  console.log(doc2.data());

                  _this.comTechnicianId = doc2.data().comTechnicianId;
                  _this.comTechnicianName = doc2.data().comTechnicianName;
                  _this.customerId = doc2.data().customerID;
                  _this.customerName = doc2.data().customerName;
                  _this.fincaId = _this.$props.farmId;
                  _this.fincaName = _this.$props.farmName;

                  _this.listado_clientes.forEach((element) => {
                    if (element.id === doc2.data().customerID) {
                      _this.customerName = element.name;
                    }
                  });
                }
              });
            });
        });

        this.datosCargados = true;
      });

    await db
      .collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_distribuidores.push(arrayTemp);
        });
      });

    await db
      .collection("comTechniciansResp")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_comercialesResp.push(arrayTemp);
        });
      });

    await this.filtrarRegistros();
  },
};

export default TratamientosDeliveryNotes;
</script>
