<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos de la máquina") }}
          </div>

          <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="name"
            >
              {{ $t("Nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="name"
              type="text"
              :placeholder="this.$t('Nombre')"
              v-model="name"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="delegationId"
            >
              {{ $t("Delegación") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="delegationId"
                v-model="delegationId"
                @change="changeDelegation"
              >
                <option value="">{{ $t("Seleccione una delegación") }}</option>
                <option
                  v-for="delegation in listDelegations"
                  :value="delegation.uid"
                  v-bind:key="delegation.uid"
                >
                  {{ delegation.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idAgro"
            >
              {{ $t("Id") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="idAgro"
              type="text"
              :placeholder="this.$t('Id')"
              v-model="idAgro"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idCreatedToShare"
            >
              {{ $t("Id created to share") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="idCreatedToShare"
              type="text"
              :placeholder="this.$t('Id created to share')"
              v-model="idCreatedToShare"
              readonly
            />
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="messageError !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ messageError }}</span>
          </div>

          <div
            v-if="messageSuccess !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(messageSuccess) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <div class="inline-block" v-if="userRol === 'appSuperAdministrators'">
            <button
              class="
                bg-yellow
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-2
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              type="button"
              v-on:click="submit"
              :class="{ hidden: dataLoaded === false }"
            >
              {{ $t("Crear nuevo") }}
            </button>

            <button
              class="
                bg-gray-600
                mr-2
                text-sm
                font-semibold
                text-white
                py-2
                px-4
                rounded
              "
              type="button"
              :class="{ hidden: dataLoaded === true }"
            >
              {{ $t("Enviando datos") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";

export default {
  name: "MachineAdd",
  layout: "layout",
  components: {
    HeaderModal,
  },
  data: function () {
    return {
      dataLoaded: true,
      messageSuccess: "",
      messageError: "",
      userRol: sessionStorage.getItem("user_rol"),
      name: "",
      idAgro: "",
      idCreatedToShare: "",
      delegationId: "",
      delegationName: "",
      delegationIdAgro: "",
      listDelegations: [],
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: false,
    },
  },
  methods: {
    changeDelegation() {
      this.delegationName = "";
      this.delegationIdAgro = "";
      this.idCreatedToShare = "";
      if (this.delegationId !== "") {
        this.listDelegations.filter((delegation) => {
          if (this.delegationId === delegation.uid) {
            this.delegationName = delegation.name;
            this.delegationIdAgro = delegation.idAgro;
          }
        });
        this.idCreatedToShare =
          "ES/" + this.delegationIdAgro + "/" + this.idAgro;
      }
    },
    submit() {
      this.dataLoaded = false;
      this.messageSuccess = "";
      this.messageError = "";

      if (this.name === "" && this.delegationId === "") {
        this.dataLoaded = true;
        this.messageError = this.$t(
          "Debe llenar correctamente todos los campos para guardarlos en el sistema"
        );
        return;
      }

      this.createMachine();
    },
    createMachine() {
      let refMachines = db.collection("machines").doc();
      refMachines
        .set({
          name: this.name,
          idAgro: String(this.idAgro),
          idCreatedToShare: this.idCreatedToShare,
          delegationId: this.delegationId,
          delegationName: this.delegationName,
          deleted: false,
          uid: refMachines.id,
        })
        .then(() => {
          this.dataLoaded = true;
          this.messageSuccess = "La máquina ha sido agregada exitosamente";

          this.$emit("close");
        })
        .catch(function (error) {
          let errorMessage = error.message;
          this.dataLoaded = true;
          this.messageError =
            this.$t(
              "Ha ocurrido un error al intentar guardar el registro en la BD. Error:"
            ) + errorMessage;
        });
    },
    getDelegations() {
      let _this = this;
      db.collection("delegations")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.listDelegations.push(doc.data());
          });
        });
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.idAgro = await Utilities.getNextIdAgro("machines");
    this.getDelegations();
  },
};
</script>
