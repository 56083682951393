<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos del tipo de cultivo") }}
          </div>

          <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nameEs"
            >
              {{ $t("Nombre") }} ({{ $t("Español") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              :placeholder="this.$t('Nombre')"
              id="nameEs"
              v-model="nameEs"
            />
          </div>

          <div class="w-full md:w-2/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nameIt"
            >
              {{ $t("Nombre") }} ({{ $t("Italiano") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="nameIt"
              :placeholder="this.$t('Nombre')"
              v-model="nameIt"
            />
          </div>

          <div class="w-full md:w-2/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nameFr"
            >
              {{ $t("Nombre") }} ({{ $t("Frances") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              id="nameFr"
              :placeholder="this.$t('Nombre')"
              v-model="nameFr"
            />
          </div>

          <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="value"
            >
              {{ $t("Valor") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="value"
              type="text"
              :placeholder="this.$t('Valor')"
              v-model="value"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idAgro"
            >
              {{ $t("Id") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="idAgro"
              type="text"
              :placeholder="this.$t('Id')"
              v-model="idAgro"
              readonly
            />
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="messageError !== ''"
            class="bg-red text-white px-4 py-2 mb-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ messageError }}</span>
          </div>

          <div
            v-if="messageSuccess !== ''"
            class="bg-greenlight text-white px-4 py-2 mb-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(messageSuccess) }}</span>
          </div>
        </div>
        <div
          v-if="userRol === 'appSuperAdministrators'"
          class="flex flex-row justify-end"
        >
          <button
            class="
              bg-white
              mr-2
              text-sm
              font-semibold
              border border-redagrozono
              hover:bg-redagrozono
              text-redagrozono
              hover:text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="confirmDisable"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Inhabilitar registro") }}
          </button>

          <button
            class="
              bg-white
              mr-2
              text-sm
              font-semibold
              border border-redagrozono
              hover:bg-redagrozono
              text-redagrozono
              hover:text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
              hidden
            "
            type="button"
            v-on:click="confirmDelete"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Eliminar registro") }}
          </button>

          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="submit"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Guardar cambios") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";

export default {
  name: "TreatmentStateEdit",
  layout: "layout",
  components: {
    HeaderModal,
  },
  props: {
    title: {
      require: true,
    },
    params: {
      required: true,
    },
  },
  data() {
    return {
      dataLoaded: true,
      messageSuccess: "",
      messageError: "",
      nameEs: "",
      nameIt: "",
      nameFr: "",
      value: "",
      idAgro: "",
      userRol: "",
    };
  },
  methods: {
    confirmDisable() {
      if (
        confirm(
          this.$t("¿Realmente deseas inhabilitar el tratamiento estatus?")
        )
      ) {
        this.disableTreatmentState();
      }
    },
    disableTreatmentState() {
      this.dataLoaded = false;
      let _this = this;

      db.collection("treatmentsStatus")
        .doc(_this.params.id)
        .update({
          deleted: true,
        })
        .then(() => {
          _this.dataLoaded = true;
          _this.messageSuccess =
            "El tratamiento estatus ha sido inhabilitado exitosamente";

          this.$emit("close");
        });
    },
    confirmDelete() {
      if (
        confirm(
          this.$t("¿Realmente deseas eliminar el tratamiento estatus") + "?"
        )
      ) {
        this.deleteTreatmentState();
      }
    },
    deleteTreatmentState() {
      this.dataLoaded = false;
      let _this = this;

      db.collection("treatmentsStatus")
        .doc(_this.params.id)
        .delete()
        .then(() => {
          _this.dataLoaded = true;
          _this.messageSuccess =
            "El tratamiento estatus ha sido eliminado exitosamente";

          this.$emit("close");
        });
    },
    submit() {
      this.dataLoaded = false;
      this.messageSuccess = "";
      this.messageError = "";

      if (
        this.nameEs === "" ||
        this.nameIt === "" ||
        this.nameFr === "" ||
        this.value === ""
      ) {
        this.dataLoaded = true;
        this.messageError =
          "Debe llenar correctamente todos los campos para guardarlos en el sistema";
        return;
      }

      this.editTreatmentState();
    },
    editTreatmentState() {
      let _this = this;
      db.collection("treatmentsStatus")
        .doc(_this.params.id)
        .update({
          name: _this.nameEs,
          nameIt: _this.nameIt,
          nameFr: _this.nameFr,
          value: _this.value,
          idAgro: String(_this.idAgro),
          deleted: false,
        })
        .then(() => {
          _this.dataLoaded = true;
          _this.messageSuccess =
            "El tratamiento estatus ha sido editado exitosamente";

          this.$root.$emit("reloadListTreatmentState");
          this.$emit("close");
        });
    },
    getTreatmentState() {
      this.dataLoaded = false;
      db.collection("treatmentsStatus")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.nameEs = snapshot.data().name;
          this.nameIt = snapshot.data().nameIt ?? "";
          this.nameFr = snapshot.data().nameFr ?? "";
          this.value = snapshot.data().value;
          this.idAgro = snapshot.data().idAgro;
          this.dataLoaded = true;
        });
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");
    this.getTreatmentState();
  },
};
</script>
