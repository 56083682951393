<template>
  <div id="app">
    <layout v-if="layout === 'layout'">
      <router-view class="view"></router-view>
    </layout>

    <div v-if="layout === ''">
      <router-view class="view"></router-view>
    </div>
  </div>
</template>

<script>
import Layout from "@/components/Layout";

const App = {
  name: "App",
  components: {
    Layout,
  },
  computed: {
    layout() {
      return this.$route.matched[0].components.default.layout || "";
    },
  },
};

export default App;
</script>
