<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Asignación de aplicaciones") }}
    </div>

    <loader v-if="dataLoaded === false"></loader>

    <div v-if="dataLoaded === true">
      <div class="md:flex">
        <div class="block relative mr-4 w-full sm:w-64 mb-3 sm:mb-0">
          <label>
            <select
              class="
                block
                appearance-none
                w-full
                bg-white
                border border-gray-400
                rounded
                hover:border-gray-500
                px-4
                py-3
                pr-4
                leading-tight
                focus:outline-none focus:shadow-outline
              "
              v-model="state"
            >
              <option value="all">{{ $t("Todos los estados") }}</option>
              <option value="pending">{{ $t("Activo") }}</option>
              <option value="completed">{{ $t("Completado") }}</option>
              <option value="canceled">{{ $t("Cancelado") }}</option>
            </select>
          </label>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
              text-gray-700
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div class="flex items-center">
          <label>
            <input
              class="
                w-full
                appearance-none
                block
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              :placeholder="this.$t('Buscar')"
              v-model="search"
            />
          </label>
        </div>
      </div>

      <ApplicationAssignmentTreatment
        v-for="(treatment, index) in filterRecords"
        :key="index"
        :treatment="treatment"
      ></ApplicationAssignmentTreatment>
    </div>
  </div>
</template>

<script>
import Utilities from "@/plugins/Utilities";
import { db } from "@/plugins/firebase";
import Loader from "@/components/Loader";
import ApplicationAssignmentTreatment from "@/pages/ApplicationAssignment/ApplicationAssignmentTreatment";
import Fuse from "fuse.js";

const ApplicationAssignmentList = {
  layout: "layout",
  name: "applicationAssignment",
  metaInfo: {
    title: "Asignación de aplicaciones",
  },
  components: {
    Loader,
    ApplicationAssignmentTreatment,
  },
  data() {
    return {
      state: "all",
      treatments: [],
      dataLoaded: false,
      userRol: "",
      userPresent: "",
      search: "",
    };
  },
  computed: {
    filterRecords() {
      let treatments = this.treatments.sort(function (a, b) {
        const nameA = a.agrozonoDate;
        const nameB = b.agrozonoDate;

        let comparison = 0;
        if (nameA > nameB) {
          comparison = -1;
        } else if (nameA < nameB) {
          comparison = 1;
        }
        return comparison;
      });

      if (this.search !== "") {
        treatments = this.searchTreatments();
      }

      if (this.state === "all") {
        return treatments;
      }

      return treatments.filter((treatment) => {
        if (
          this.state === "completed" &&
          (treatment.state === "completed" || treatment.state === "active")
        ) {
          return treatment;
        }

        if (
          this.state === "pending" &&
          (treatment.state === "pending" || !treatment.state)
        ) {
          return treatment;
        }

        if (this.state === "canceled" && treatment.state === "canceled") {
          return treatment;
        }
      });
    },
  },
  methods: {
    getRecords() {
      this.dataLoaded = false;
      this.treatments = [];

      let _this = this;
      db.collection("treatments")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async function (doc) {
            let treatment = doc.data();
            await db
              .collection("inspections")
              .doc(doc.data().inspectionId)
              .get()
              .then((querySnapshot) => {
                treatment["inspection"] = querySnapshot.data();
              });
            await db
              .collection("customers")
              .doc(doc.data().customerId)
              .collection("farms")
              .doc(doc.data().farmId)
              .get()
              .then((querySnapshot) => {
                treatment["farm"] = querySnapshot.data();
              });

            if (
              _this.userRol === "appSuperAdministrators" ||
              _this.userRol === "appAdministrators" ||
              _this.userRol === "respTechnicians"
            ) {
              _this.treatments.push(treatment);
            }

            if (
              _this.userRol === "appTechnicians" &&
              treatment.appTechnicianId === _this.userPresent
            ) {
              _this.treatments.push(treatment);
            }
          });

          this.dataLoaded = true;
        });
    },
    searchTreatments() {
      const options = {
        findAllMatches: true,
        threshold: 0.0,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "appTechnicianName",
          "machineName",
          "customerName",
          "protocolInspectionNameLocalized",
          "farmName",
        ],
      };

      const fuse = new Fuse(this.treatments, options);

      return fuse.search(this.search.toLowerCase()).map((inspection) => {
        return inspection.item;
      });
    },
  },
  created() {
    this.$root.$on("reloadListApplicationAssignment", () => {
      this.getRecords();
    });
  },

  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");
    this.userPresent = sessionStorage.getItem("user_id");

    this.getRecords();
  },
};

export default ApplicationAssignmentList;
</script>
