<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-2 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-3/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="excelFile"
            >
              {{ $t("Archivo excel") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                rounded
                py-3
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="excelFile"
              type="file"
              placeholder="Archivo EXCEL"
              @change="previewData"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>

          <div
            class="w-full md:w-3/3 px-3 mb-6 md:mb-0"
            v-for="row in rows"
            v-bind:key="row.id"
          >
            {{ row.message }}
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="errorMessage !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ $t(errorMessage) }}</span>
          </div>

          <div
            v-if="successMessage !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(successMessage) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="submit"
            v-if="!close"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Importar excel") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>

          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="closeModal"
            v-if="close"
          >
            {{ $t("Cerrar") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { firebaseGlobal } from "@/plugins/firebase.js";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";
import readXlsxFile from "read-excel-file";

const ProtocolsImport = {
  name: "ProtocolsImportModal",
  layout: "layout",
  components: {
    Zondicon,
    Loader,
    HeaderModal,
  },

  data: function () {
    return {
      dataLoaded: true,
      successMessage: "",
      errorMessage: "",
      close: false,

      file: "",
      rows: [],
      idAgroProtocols: "",
      idAgroProtocolReminders: "",
      createProtocol: true,
      refProtocol: "",
      refProtocolReminder: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    previewData: function (event) {
      this.file = event.target.files[0];
    },
    submit() {
      this.dataLoaded = false;
      this.successMessage = "";
      this.errorMessage = "";

      this.rows = [];

      if (this.file !== "") {
        let _this = this;
        let fileRef = firebaseGlobal
          .storage()
          .ref(`imports/${this.file.name}`)
          .put(this.file);
        fileRef.on(
          "state_changed",
          () => {},
          (error) => {
            this.dataLoaded = true;
            this.errorMessage =
              "Hubo un error al intentar subir el archivo suministrado. Error: " +
              error.message;
          },
          () => {
            readXlsxFile(this.file).then(async (rows) => {
              rows.splice(0, 1);

              for (const element of rows) {
                await _this.insertProtocols(element);
              }

              _this.dataLoaded = true;
              _this.close = true;
              _this.$root.$emit("reloadProtocolList");
              _this.successMessage = "Archivo subido exitosamente";
            });
          }
        );
      } else {
        this.dataLoaded = true;
        this.errorMessage =
          "Debe seleccionar un archivo para poder importar los protocolos. (archivo)";
      }
    },
    async insertProtocols(protocol) {
      let _this = this;

      if (protocol[2] === null) {
        _this.rows.push({
          id: _this.rows.length + 1,
          message: this.$t("Saltando esta línea"),
        });

        _this.createProtocol = true;
        return;
      }

      if (_this.createProtocol) {
        let dt = new Date();
        let dateCreated =
          dt.getFullYear() +
          "/" +
          (dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1) +
          "/" +
          (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate());
        _this.idAgroProtocols = _this.idAgroProtocols + 1;
        _this.createProtocol = false;
        _this.refProtocol = await db.collection("protocols").doc();
        await _this.refProtocol
          .set({
            idAgro: String(_this.idAgroProtocols),
            idCreatedToShare: "ES/" + _this.idAgroProtocols,
            name: protocol[5],
            nameFr: protocol[6],
            nameIt: protocol[7],
            order: 1,
            version: protocol[2],
            status: "pending",
            use: "in_use",
            country: "spain",
            date_creation: dateCreated,
            date_discontinuated: "",
            SYS1: 0,
            SYS2: 0,
            SYS2_1: 0,
            SYS3: 0,
            SYS4: 0,
            SYS5: 0,
            SYS6: 0,
            SYS6_1: 0,
            SYS7: 0,
            SYSBA: 0,
            deleted: false,
            uid: _this.refProtocol.id,
          })
          .then(async () => {
            _this.idAgroProtocolReminders = _this.idAgroProtocolReminders + 1;

            let sys1 = 0;
            if (protocol[10]) {
              sys1 =
                protocol[10].toString().indexOf(".") > -1
                  ? parseFloat(protocol[10])
                  : parseFloat(protocol[10].toString().replace(",", "."));
            }

            let sys2 = 0;
            if (protocol[11]) {
              sys2 =
                protocol[11].toString().indexOf(".") > -1
                  ? parseFloat(protocol[11])
                  : parseFloat(protocol[11].toString().replace(",", "."));
            }

            let sys2_1 = 0;
            if (protocol[12]) {
              sys2_1 =
                protocol[12].toString().indexOf(".") > -1
                  ? parseFloat(protocol[12])
                  : parseFloat(protocol[12].toString().replace(",", "."));
            }

            let sys3 = 0;
            if (protocol[13]) {
              sys3 =
                protocol[13].toString().indexOf(".") > -1
                  ? parseFloat(protocol[13])
                  : parseFloat(protocol[13].toString().replace(",", "."));
            }

            let sys4 = 0;
            if (protocol[14]) {
              sys4 =
                protocol[14].toString().indexOf(".") > -1
                  ? parseFloat(protocol[14])
                  : parseFloat(protocol[14].toString().replace(",", "."));
            }

            let sys5 = 0;
            if (protocol[15]) {
              sys5 =
                protocol[15].toString().indexOf(".") > -1
                  ? parseFloat(protocol[15])
                  : parseFloat(protocol[15].toString().replace(",", "."));
            }

            let sys6 = 0;
            if (protocol[16]) {
              sys6 =
                protocol[16].toString().indexOf(".") > -1
                  ? parseFloat(protocol[16])
                  : parseFloat(protocol[16].toString().replace(",", "."));
            }

            let sys6_1 = 0;
            if (protocol[17]) {
              sys6_1 =
                protocol[17].toString().indexOf(".") > -1
                  ? parseFloat(protocol[17])
                  : parseFloat(protocol[17].toString().replace(",", "."));
            }

            let sys7 = 0;
            if (protocol[18]) {
              sys7 =
                protocol[18].toString().indexOf(".") > -1
                  ? parseFloat(protocol[18])
                  : parseFloat(protocol[18].toString().replace(",", "."));
            }

            let sysbas = 0;
            if (protocol[19]) {
              sysbas =
                protocol[19].toString().indexOf(".") > -1
                  ? parseFloat(protocol[19])
                  : parseFloat(protocol[19].toString().replace(",", "."));
            }

            _this.refProtocolReminder = await db
              .collection("protocolReminders")
              .doc();
            await _this.refProtocolReminder
              .set({
                idAgro: String(_this.idAgroProtocolReminders),
                hours: parseInt(protocol[9]) * 24,
                repetitionInDays: parseInt(protocol[9]),
                sys1: sys1,
                sys2: sys2,
                sys2_1: sys2_1,
                sys3: sys3,
                sys4: sys4,
                sys5: sys5,
                sys6: sys6,
                sys6_1: sys6_1,
                sys7: sys7,
                sysbas: sysbas,
                comments: "",
                protocolId: _this.refProtocol.id,
                deleted: false,

                uid: _this.refProtocolReminder.id,
              })
              .then(async () => {
                _this.rows.push({
                  id: _this.rows.length + 1,
                  message:
                    this.$t("ProtocolReminder creado con id") +
                    " " +
                    _this.refProtocolReminder.id,
                });

                await Utilities.recalculateValuesProtocol(_this.refProtocol.id);
              })
              .catch(function (error) {
                console.log(error.message);
              });
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } else {
        _this.idAgroProtocolReminders = _this.idAgroProtocolReminders + 1;

        let sys1 = 0;
        if (protocol[10]) {
          sys1 =
            protocol[10].toString().indexOf(".") > -1
              ? parseFloat(protocol[10])
              : parseFloat(protocol[10].toString().replace(",", "."));
        }

        let sys2 = 0;
        if (protocol[11]) {
          sys2 =
            protocol[11].toString().indexOf(".") > -1
              ? parseFloat(protocol[11])
              : parseFloat(protocol[11].toString().replace(",", "."));
        }

        let sys2_1 = 0;
        if (protocol[12]) {
          sys2_1 =
            protocol[12].toString().indexOf(".") > -1
              ? parseFloat(protocol[12])
              : parseFloat(protocol[12].toString().replace(",", "."));
        }

        let sys3 = 0;
        if (protocol[13]) {
          sys3 =
            protocol[13].toString().indexOf(".") > -1
              ? parseFloat(protocol[13])
              : parseFloat(protocol[13].toString().replace(",", "."));
        }

        let sys4 = 0;
        if (protocol[14]) {
          sys4 =
            protocol[14].toString().indexOf(".") > -1
              ? parseFloat(protocol[14])
              : parseFloat(protocol[14].toString().replace(",", "."));
        }

        let sys5 = 0;
        if (protocol[15]) {
          sys5 =
            protocol[15].toString().indexOf(".") > -1
              ? parseFloat(protocol[15])
              : parseFloat(protocol[15].toString().replace(",", "."));
        }

        let sys6 = 0;
        if (protocol[16]) {
          sys6 =
            protocol[16].toString().indexOf(".") > -1
              ? parseFloat(protocol[16])
              : parseFloat(protocol[16].toString().replace(",", "."));
        }

        let sys6_1 = 0;
        if (protocol[17]) {
          sys6_1 =
            protocol[17].toString().indexOf(".") > -1
              ? parseFloat(protocol[17])
              : parseFloat(protocol[17].toString().replace(",", "."));
        }

        let sys7 = 0;
        if (protocol[18]) {
          sys7 =
            protocol[18].toString().indexOf(".") > -1
              ? parseFloat(protocol[18])
              : parseFloat(protocol[18].toString().replace(",", "."));
        }

        let sysbas = 0;
        if (protocol[19]) {
          sysbas =
            protocol[19].toString().indexOf(".") > -1
              ? parseFloat(protocol[19])
              : parseFloat(protocol[19].toString().replace(",", "."));
        }

        _this.refProtocolReminder = await db
          .collection("protocolReminders")
          .doc();
        await _this.refProtocolReminder
          .set({
            idAgro: String(_this.idAgroProtocolReminders),
            hours: parseInt(protocol[9]) * 24,
            repetitionInDays: parseInt(protocol[9]),
            sys1: sys1,
            sys2: sys2,
            sys2_1: sys2_1,
            sys3: sys3,
            sys4: sys4,
            sys5: sys5,
            sys6: sys6,
            sys6_1: sys6_1,
            sys7: sys7,
            sysbas: sysbas,
            comments: "",
            protocolId: _this.refProtocol.id,
            deleted: false,

            uid: _this.refProtocolReminder.id,
          })
          .then(async () => {
            _this.rows.push({
              id: _this.rows.length + 1,
              message:
                this.$t("ProtocolReminder creado con id") +
                " " +
                _this.refProtocolReminder.id,
            });

            await Utilities.recalculateValuesProtocol(_this.refProtocol.id);
          })
          .catch(function (error) {
            console.log(error.message);
          });
      }
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.idAgroProtocols = await Utilities.getNextIdAgro("protocols");
    this.idAgroProtocolReminders = await Utilities.getNextIdAgro(
      "protocolReminders"
    );
  },
};

export default ProtocolsImport;
</script>
