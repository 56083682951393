<template>
  <a
    class="
      bg-blueagrozono
      text-white text-sm
      font-semibold
      hover:bg-greenlight
      py-3
      px-4
      rounded
      focus:outline-none focus:shadow-outline
      cursor-pointer
    "
    target="_blank"
    :href="urlGoogleMaps"
  >
    {{ $t("Ver en Google Maps") }}
  </a>
</template>

<script>
const ButtonGoogleMaps = {
  name: "buttonGoogleMaps",
  components: {},
  props: {
    latitude: {
      required: true,
    },
    longitude: {
      required: true,
    },
  },
  computed: {
    urlGoogleMaps() {
      return (
        "https://maps.google.com/?q=" +
        this.latitude.replace(",", ".") +
        "," +
        this.longitude.replace(",", ".")
      );
    },
  },
};
export default ButtonGoogleMaps;
</script>
