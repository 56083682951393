<template>
  <div class="border border-gray-200 rounded p-4">
    <div
      class="
        w-full
        uppercase
        tracking-wide
        text-gray-700 text-xs
        font-bold
        mb-3
      "
    >
      {{ $t("Datos del recordatorio") }} {{ index + 1 }}/{{ total }}
    </div>
    <div class="space-y-2">
      <div class="grid grid-cols-6 gap-2">
        <div class="col-span-3 md:col-span-2">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >{{ $t("Id") }}</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-gray-200
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="idAgro"
            type="text"
            :placeholder="this.$t('Id')"
            v-model="idAgro"
            disabled
          />
        </div>
        <div class="col-span-3 md:col-span-2">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >{{ $t("Horas") }}</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-gray-200
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="hours"
            type="text"
            :placeholder="this.$t('Horas')"
            v-model="recalculateHours"
            disabled
          />
        </div>
        <div class="col-span-3 md:col-span-2">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
          >
            {{ $t("Repetición en dias") }}
          </label>
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="repetitionInDays"
            type="number"
            :placeholder="this.$t('Repetición en dias')"
            v-model="repetitionInDays"
          />
        </div>
      </div>
      <div class="grid grid-cols-6 lg:grid-cols-10 gap-2 md:gap-1">
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 1</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys1"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys1"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 2</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys2"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys2"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 2.1</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys2_1"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys2_1"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 3</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys3"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys3"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 4</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys4"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys4"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 5</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys5"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys5"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 6</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys6"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys6"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 6.1</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys6_1"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys6_1"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >SYS. 7</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sys7"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sys7"
          />
        </div>
        <div class="col-span-2 md:col-span-1">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >{{ $t("Basic") }}</label
          >
          <input
            class="
              appearance-none
              block
              w-full
              bg-white
              text-gray-700
              border border-red-500
              rounded
              py-3
              px-4
              mb-3
              leading-tight
              focus:outline-none focus:bg-white
            "
            id="sysbas"
            type="number"
            :placeholder="this.$t('System')"
            v-model="sysbas"
          />
        </div>
      </div>
      <div
        class="
          w-full
          flex flex-col
          md:flex-row
          items-center
          justify-end
          space-x-0
          md:space-x-4
          space-y-4
          md:space-y-0
        "
      >
        <div v-if="error" class="text-orange-800 text-white px-4">
          <div class="block sm:inline font-semibold">
            {{ $t("Debe de introducir todos los datos") }}
          </div>
        </div>
        <div v-if="success" class="text-greenlight text-white px-4">
          <span class="block sm:inline font-semibold">{{
            $t("Recordatorio editado correctamente")
          }}</span>
        </div>
        <button
          class="
            relative
            inline-flex
            items-center
            px-4
            py-2
            leading-5
            font-bold
            rounded-md
            text-white
            focus:outline-none
            bg-orange-500
            hover:bg-orange-400
          "
          @click="checkReminder"
        >
          <span>{{ $t("Guardar") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../plugins/firebase";
import Utilities from "../../plugins/Utilities";

export default {
  name: "ProtocolReminderComponent",
  layout: "layout",
  components: {},
  data() {
    return {
      idAgro: "",
      repetitionInDays: 0,
      sys1: 0,
      sys2: 0,
      sys2_1: 0,
      sys3: 0,
      sys4: 0,
      sys5: 0,
      sys6: 0,
      sys6_1: 0,
      sys7: 0,
      sysbas: 0,
      error: false,
      success: false,
    };
  },
  props: {
    reminder: {
      required: true,
    },
    index: {
      required: true,
    },
    total: {
      required: true,
    },
  },
  computed: {
    recalculateHours() {
      if (this.repetitionInDays) {
        return 24 * parseInt(this.repetitionInDays);
      }

      return 0;
    },
  },
  methods: {
    checkReminder() {
      if (
        this.idAgro === "" ||
        this.repetitionInDays === "" ||
        this.sys1 === "" ||
        this.sys2 === "" ||
        this.sys2_1 === "" ||
        this.sys3 === "" ||
        this.sys4 === "" ||
        this.sys5 === "" ||
        this.sys6 === "" ||
        this.sys6_1 === "" ||
        this.sys7 === "" ||
        this.sysbas === ""
      ) {
        this.error = true;
        return;
      }

      this.saveReminder();
    },
    saveReminder() {
      let _this = this;
      _this.error = false;
      let temp_sys1 = this.sys1 === "" || isNaN(this.sys1) ? 0 : this.sys1;
      let temp_sys2 = this.sys2 === "" || isNaN(this.sys2) ? 0 : this.sys2;
      let temp_sys2_1 =
        this.sys2_1 === "" || isNaN(this.sys2_1) ? 0 : this.sys2_1;
      let temp_sys3 = this.sys3 === "" || isNaN(this.sys3) ? 0 : this.sys3;
      let temp_sys4 = this.sys4 === "" || isNaN(this.sys4) ? 0 : this.sys4;
      let temp_sys5 = this.sys5 === "" || isNaN(this.sys5) ? 0 : this.sys5;
      let temp_sys6 = this.sys6 === "" || isNaN(this.sys6) ? 0 : this.sys6;
      let temp_sys6_1 =
        this.sys6_1 === "" || isNaN(this.sys6_1) ? 0 : this.sys6_1;
      let temp_sys7 = this.sys7 === "" || isNaN(this.sys7) ? 0 : this.sys7;
      let temp_sysbas =
        this.sysbas === "" || isNaN(this.sysbas) ? 0 : this.sysbas;

      db.collection("protocolReminders")
        .doc(_this.reminder.uid)
        .update({
          idAgro: String(_this.idAgro),
          hours: 24 * parseInt(_this.repetitionInDays),
          repetitionInDays: parseFloat(_this.repetitionInDays),
          sys1: parseFloat(temp_sys1),
          sys2: parseFloat(temp_sys2),
          sys2_1: parseFloat(temp_sys2_1),
          sys3: parseFloat(temp_sys3),
          sys4: parseFloat(temp_sys4),
          sys5: parseFloat(temp_sys5),
          sys6: parseFloat(temp_sys6),
          sys6_1: parseFloat(temp_sys6_1),
          sys7: parseFloat(temp_sys7),
          sysbas: parseFloat(temp_sysbas),
        })
        .then(() => {
          _this.dataLoaded = true;
          _this.success = true;
          setTimeout(() => (_this.success = false), 5000);
          Utilities.recalculateValuesProtocol(this.reminder.protocolId);
        });
    },
    getInfo() {
      this.idAgro = this.reminder.idAgro;
      this.repetitionInDays = this.reminder.repetitionInDays;
      this.sys1 = this.reminder.sys1;
      this.sys2 = this.reminder.sys2;
      this.sys2_1 = this.reminder.sys2_1;
      this.sys3 = this.reminder.sys3;
      this.sys4 = this.reminder.sys4;
      this.sys5 = this.reminder.sys5;
      this.sys6 = this.reminder.sys6;
      this.sys6_1 = this.reminder.sys6_1;
      this.sys7 = this.reminder.sys7;
      this.sysbas = this.reminder.sysbas;
    },
  },
  beforeMount() {
    this.getInfo();
  },
};
</script>
