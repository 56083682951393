<template>
  <div>
    <div class="h-screen flex flex-col">
      <header class="flex flex-shrink-0">
        <div
          class="flex-shrink-0 px-4 py-3 bg-white w-auto xl:w-64 xl:bg-white"
        >
          <button
            @click="sidebarOpen = true"
            class="block text-gray-700 xl:hidden"
          >
            <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
              <path
                d="M3 6a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zM4 17a1 1 0 100 2h7a1 1 0 100-2H4z"
              />
            </svg>
          </button>

          <div class="hidden xl:flex xl:items-center xl:w-full">
            <router-link to="/" class="leading-tight hover:bg-gray-200">
              <img
                class="block mx-auto sm:mx-0 sm:flex-shrink-0"
                src="@/assets/logo.png"
                alt="Logo"
              />
            </router-link>
          </div>
        </div>

        <div
          class="
            flex-1 flex
            items-center
            justify-between
            pl-2
            pr-6
            bg-white
            xl:px-6
          "
        >
          <div class="flex items-center w-1/2 xl:justify-start">
            <div class="flex-1 min-w-0 flex">
              <div class="hidden flex-shrink-1 ml-3 relative w-64 lg:ml-0">
                <span class="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    class="h-6 w-6 text-gray-600"
                    fill="none"
                    viewbox="0 0 24 24"
                  >
                    <path
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="currentColor"
                    />
                  </svg>
                </span>
                <input
                  class="
                    block
                    w-full
                    rounded-md
                    border border-gray-400
                    pl-10
                    pr-4
                    py-2
                    text-sm text-gray-900
                    placeholder-gray-600
                  "
                  placeholder="Search"
                />
              </div>
              <business-area-select
                :selected="businessArea"
              ></business-area-select>
            </div>
          </div>
          <div class="relative inline-block">
            <button
              @click="isOpen = true"
              class="
                inline-flex
                items-center
                pl-6
                pr-2
                py-2
                font-semibold
                text-gray-800
                focus:outline-none
              "
            >
              {{ userName }}
              <svg
                class="ml-2 h-6 w-6 text-gray-800"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                />
              </svg>
            </button>

            <div
              v-if="isOpen"
              @click="isOpen = false"
              class="fixed inset-0"
              tabindex="-1"
            ></div>

            <div
              v-if="isOpen"
              class="mt-2 absolute right-0 origin-top-right text-left"
            >
              <div class="w-64 bg-white rounded-lg shadow-lg">
                <div class="border-t-2 border-gray-200 py-1">
                  <router-link
                    to="/logout"
                    class="block px-6 py-3 leading-tight hover:bg-gray-200"
                  >
                    {{ $t("Salir") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="flex-1 flex overflow-hidden">
        <div
          :class="[
            sidebarOpen ? 'translate-x-0' : '-translate-x-full',
            this.mode === 'stagging' ? 'bg-gray-600' : 'bg-greenlight',
          ]"
          class="
            z-30
            fixed
            inset-y-0
            left-0
            w-64
            border-r
            overflow-y-auto
            transform
            transition
            duration-500
            ease-in-out
            xl:static xl:block xl:translate-x-0
          "
        >
          <div class="absolute xl:hidden pr-4 pt-3 right-0 top-0">
            <button
              v-on:click="closeSidebar"
              class="block text-white hover:text-gray-800"
            >
              <svg class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M17.293 18.707a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 00-1.414-1.414L12 10.586 6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 101.414 1.414L12 13.414l5.293 5.293z"
                />
              </svg>
            </button>
          </div>
          <nav class="mt-16 sm:mt-0">
            <div class="mt-8 px-6">
              <h2
                v-if="this.mode === 'stagging'"
                class="mt-8 text-xl font-bold text-white tracking-wide"
              >
                Staging
              </h2>
            </div>

            <div class="mt-8 px-6">
              <h2
                class="
                  mt-8
                  text-xs
                  font-bold
                  text-white
                  uppercase
                  tracking-wide
                "
                :class="[
                  permitirRol([
                    'appTechnicians',
                    'comTechnicians',
                    'comTechniciansResp',
                    'comTechniciansAdmin',
                    'respTechnicians',
                    'respTechnicians',
                    'distributors',
                    'appAdministrators',
                    'appSuperAdministrators',
                  ]),
                ]"
              >
                {{ $t("Operaciones") }}
              </h2>
              <div class="mt-4">
                <router-link
                  to="/area-clientes"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('areaClientes'),
                    permitirRol([
                      'comTechnicians',
                      'comTechniciansResp',
                      'comTechniciansAdmin',
                      'distributors',
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Clientes y leads") }}
                </router-link>

                <router-link
                  to="/fincas"
                  v-if="businessAreaActive.name === 'Agro3'"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('fincas'),
                    permitirRol([
                      'comTechnicians',
                      'comTechniciansResp',
                      'comTechniciansAdmin',
                      'distributors',
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Fincas e inspecciones") }}
                </router-link>

                <router-link
                  to="/tratamientos"
                  v-if="businessAreaActive.name === 'Agro3'"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('tratamientos'),
                    permitirRol([
                      'comTechnicians',
                      'comTechniciansResp',
                      'comTechniciansAdmin',
                      'distributors',
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Tratamientos") }}
                </router-link>

                <router-link
                  to="/aplicaciones-pendientes"
                  v-if="businessAreaActive.name === 'Agro3'"
                  class="
                    hidden
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('aplicacionesPendientes'),
                    permitirRol([
                      'comTechnicians',
                      'comTechniciansResp',
                      'comTechniciansAdmin',
                      'distributors',
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Aplicaciones pendientes") }}
                </router-link>

                <router-link
                  to="/applications-to-be-assigned"
                  v-if="businessAreaActive.name === 'Agro3'"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('applications-to-be-assigned'),
                    permitirRol([
                      'respTechnicians',
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Aplicaciones por asignar") }}
                </router-link>

                <router-link
                  to="/application-assignment"
                  v-if="businessAreaActive.name === 'Agro3'"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('application-assignment'),
                    permitirRol([
                      'appTechnicians',
                      'respTechnicians',
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Asignación de aplicaciones") }}
                </router-link>

                <router-link
                  to="/tasks"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('tasks'),
                    permitirRol([
                      'appAdministrators',
                      'appSuperAdministrators',
                      'appTechnicians',
                      'comTechnicians',
                      'comTechniciansResp',
                      'comTechniciansAdmin',
                      'distributors',
                      'respTechnicians',
                    ]),
                  ]"
                >
                  {{ $t("Tareas") }}
                </router-link>

                <router-link
                  to="/pending-tasks"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('pendingTasks'),
                    permitirRol([
                      'appAdministrators',
                      'appSuperAdministrators',
                      'appTechnicians',
                      'comTechnicians',
                      'comTechniciansResp',
                      'comTechniciansAdmin',
                      'distributors',
                      'respTechnicians',
                    ]),
                  ]"
                >
                  {{ $t("Tareas programadas") }}
                </router-link>
              </div>

              <h2
                class="
                  mt-4
                  text-xs
                  font-bold
                  text-white
                  uppercase
                  tracking-wide
                "
                v-if="businessAreaActive.name === 'Agro3'"
                :class="[
                  permitirRol(
                    [
                      'delegations',
                      'appAdministrators',
                      'appSuperAdministrators',
                      'comTechniciansAdmin',
                    ],
                    ['rcomercial@agrozono.net']
                  ),
                ]"
              >
                {{ $t("Gestión") }}
              </h2>

              <div class="mt-4" v-if="businessAreaActive.name === 'Agro3'">
                <router-link
                  to="/protocolos"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('protocolos'),
                    permitirRol(
                      [
                        'appAdministrators',
                        'appSuperAdministrators',
                        'comTechniciansAdmin',
                      ],
                      ['rcomercial@agrozono.net']
                    ),
                  ]"
                >
                  {{ $t("Protocolos") }}
                </router-link>

                <router-link
                  to="/maquinas"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('maquinas'),
                    permitirRol([
                      'delegations',
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Máquinas") }}
                </router-link>

                <router-link
                  to="/tipos-cultivo"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('tiposCultivo'),
                    permitirRol([
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Tipos de cultivo") }}
                </router-link>

                <router-link
                  to="/tratamientos-estatus"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('tratamientosEstatus'),
                    permitirRol([
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Tratamientos estatus") }}
                </router-link>
              </div>

              <h2
                class="
                  mt-8
                  text-xs
                  font-bold
                  text-white
                  uppercase
                  tracking-wide
                "
                v-if="businessAreaActive.name === 'Agro3'"
                :class="[
                  permitirRol([
                    'comTechnicians',
                    'comTechniciansResp',
                    'comTechniciansAdmin',
                    'distributors',
                    'delegations',
                    'appAdministrators',
                    'appSuperAdministrators',
                  ]),
                ]"
              >
                {{ $t("Usuario") }}
              </h2>
              <div class="mt-3" v-if="businessAreaActive.name === 'Agro3'">
                <router-link
                  to="/area-comercial"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('areaComercial'),
                    permitirRol([
                      'comTechnicians',
                      'comTechniciansResp',
                      'distributors',
                      'delegations',
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Área comercial") }}
                </router-link>

                <router-link
                  to="/area-tecnica"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-2
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('areaTecnica'),
                    permitirRol([
                      'appAdministrators',
                      'appSuperAdministrators',
                    ]),
                  ]"
                >
                  {{ $t("Área técnica") }}
                </router-link>

                <router-link
                  to="/area-administrativa"
                  class="
                    w-full
                    mx-3
                    px-3
                    py-2
                    mb-8
                    flex
                    items-center
                    text-sm
                    font-medium
                    bg-transparent
                    text-white
                    rounded-lg
                    hover:bg-gray-200 hover:text-gray-700
                  "
                  :class="[
                    getLinkActivo('areaAdministrativa'),
                    permitirRol([
                      'appAdministrators',
                      'appSuperAdministrators',
                      'comTechniciansAdmin',
                    ]),
                  ]"
                >
                  {{ $t("Área administrativa") }}
                </router-link>
              </div>
            </div>

            <div class="mt-4 p-6 border-t sm:hidden">
              <router-link
                to="#"
                class="block text-sm px-3 py-2 font-medium text-white"
              >
                Mi Cuenta
              </router-link>

              <router-link
                to="/logout"
                class="block text-sm px-3 py-2 font-medium text-white"
              >
                {{ $t("Salir") }}
              </router-link>
            </div>
          </nav>
        </div>

        <div class="flex-1 overflow-auto bg-gray-100">
          <main class="p-6 w-full h-full inline-flex">
            <slot></slot>
          </main>
        </div>
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
import BusinessAreaSelect from "@/components/BusinessAreaSelect";
import { modoApp } from "@/plugins/mode.js";

export default {
  name: "layout",
  components: {
    BusinessAreaSelect,
  },
  data() {
    return {
      sidebarOpen: false,
      isOpen: false,
      linkActivo: "",
      userName: sessionStorage.getItem("user_name"),
      businessArea: sessionStorage.getItem("businessArea"),
      businessAreaActive: "",
      userRol: sessionStorage.getItem("user_rol"),
      userEmail: sessionStorage.getItem("user"),
    };
  },
  props: {
    msg: String,
  },
  watch: {
    $route: function () {
      this.linkActivo = this.$route.name;
      this.closeSidebar();
    },
  },
  methods: {
    openSidebar() {
      this.sidebarOpen = true;
    },
    closeSidebar() {
      this.sidebarOpen = false;
    },
    getLinkActivo(link) {
      return this.linkActivo === link ? "bg-gray-200 text-gray-700" : "";
    },
    permitirRol(rol, emails) {
      let puedeMostrar = false;

      for (let i = 0; i < rol.length; i++) {
        if (this.userRol === rol[i]) {
          puedeMostrar = true;
          break;
        }
      }

      if (emails) {
        for (let i = 0; i < emails.length; i++) {
          if (this.userEmail === emails[i]) {
            puedeMostrar = true;
            break;
          }
        }
      }

      return puedeMostrar === true ? "" : "hidden";
    },
  },
  created() {
    Event.listen("reloadBusinessAreaActive", () => {
      this.businessAreaActive = JSON.parse(
        sessionStorage.getItem("businessAreaActive")
      );
    });
    Event.listen("checkBusinessArea", () => {
      if (
        this.businessAreaActive.name === "TO3" &&
        this.linkActivo !== "tasks" &&
        this.linkActivo !== "pendingTasks" &&
        this.linkActivo !== "areaClientes"
      ) {
        this.$router.push({ name: "home" });
      }
    });
  },
  beforeMount() {
    this.mode = modoApp;
    this.linkActivo = this.$route.name;
  },
};
</script>
