<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              text-gray-700 text-xl
              font-bold
              mb-4
              px-4
            "
          >
            {{ treatment.farmName }}
          </div>
          <div class="w-full md:w-1/2 text-base px-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Estatus del tratamiento") }}
            </label>
            <div class="flex w-full">
              <div class="block w-full relative">
                <select
                  :disabled="disabledRol"
                  :class="
                    disabledRol
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-white hover:border-gray-500 cursor-pointer'
                  "
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-400
                    rounded
                    px-4
                    py-3
                    pr-4
                    leading-tight
                  "
                  v-model="state"
                >
                  <option
                    v-for="status in listStatus"
                    :value="status.value"
                    v-bind:key="status.id"
                  >
                    {{ status.name }}
                  </option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    px-2
                    text-gray-700
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
              <button
                class="
                  w-full
                  ml-4
                  text-sm
                  font-semibold
                  text-white
                  py-2
                  px-4
                  rounded
                "
                type="button"
                v-on:click="checkStatus"
                :disabled="disabledRol"
                :class="
                  disabledRol
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-yellow hover:bg-greenlight'
                "
              >
                {{ $t("Guardar status") }}
              </button>
            </div>
          </div>
          <div
            class="w-full md:w-3/3 text-gray-800 text-base font-bold my-4 px-4"
          >
            <span class="font-bold">{{ $t("Fecha de inicio") }}:</span>
            {{ treatment.agrozonoDate | formatDate }}
          </div>
          <div class="w-full flex mb-2 hidden">
            <div
              class="
                w-full
                md:w-3/5
                text-gray-800 text-base
                px-4
                flex
                items-end
              "
            >
              <div>
                <p class="uppercase text-gray-700 text-xl font-bold mb-4">
                  {{ treatment.farmName }}
                </p>
                <p>
                  <span class="font-bold">{{ $t("Fecha de inicio") }}:</span>
                  {{ treatment.agrozonoDate | formatDate }}
                </p>
              </div>
            </div>
            <div class="w-full md:w-2/5 text-base px-4 md:px-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Estatus del tratamiento") }}
              </label>
              <div class="flex w-full">
                <div class="block w-full relative">
                  <select
                    :disabled="disabledRol"
                    :class="
                      disabledRol
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-white hover:border-gray-500 cursor-pointer'
                    "
                    class="
                      block
                      appearance-none
                      w-full
                      border border-gray-400
                      rounded
                      px-4
                      py-3
                      pr-4
                      leading-tight
                    "
                    v-model="state"
                  >
                    <option
                      v-for="status in listStatus"
                      :value="status.value"
                      v-bind:key="status.id"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                  <div
                    class="
                      pointer-events-none
                      absolute
                      inset-y-0
                      right-0
                      flex
                      items-center
                      px-2
                      text-gray-700
                    "
                  >
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
                <button
                  class="
                    w-full
                    ml-4
                    text-sm
                    font-semibold
                    text-white
                    py-2
                    px-4
                    rounded
                  "
                  type="button"
                  v-on:click="checkStatus"
                  :disabled="disabledRol"
                  :class="
                    disabledRol
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-yellow hover:bg-greenlight'
                  "
                >
                  {{ $t("Guardar status") }}
                </button>
              </div>
            </div>
          </div>
          <table class="table-auto w-full text-left">
            <thead>
              <tr>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">
                    {{ $t("Tratamientos") }} ({{ $t("Total") }}
                    {{ totalTreatments }})
                  </div>
                </th>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">{{ $t("Estado") }}</div>
                </th>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">
                    {{ $t("Fecha de inoculación estimada") }}
                  </div>
                </th>
                <th class="px-4 py-2 text-gray-800">
                  <div class="inline-block mr-2">{{ $t("Verificado") }}</div>
                </th>
                <th class="px-4 py-2 text-gray-800"></th>
              </tr>
            </thead>
            <tbody v-if="protocolReminders.length && listSteps">
              <treatment-step
                class="my-6"
                v-for="(step, index) in listSteps"
                :step="step"
                :treatment="treatment"
                :protocolReminders="protocolReminders"
                :index="index"
                :key="index"
                @eventEditStep="editSteps"
              ></treatment-step>
            </tbody>
          </table>
        </div>
        <div class="mx-2">
          <div v-if="errorForm" class="bg-red text-white px-4 py-2 w-full">
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">- {{ messageError }}</span>
          </div>

          <div
            v-if="messageConfirm"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(mensajeExito) }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Utilities from "@/plugins/Utilities";
import { db, firebaseGlobal } from "@/plugins/firebase";
import { modoApp } from "@/plugins/mode.js";
import { Datetime } from "vue-datetime";
import { orderBy } from "lodash";
import TreatmentStep from "@/pages/tratamientos/TreatmentStep";
import { DateTime as luxon } from "luxon";
import HeaderModal from "@/components/HeaderModal";

const TreatmentModal = {
  name: "TreatmentModal",
  layout: "layout",
  components: {
    datetime: Datetime,
    TreatmentStep,
    HeaderModal,
  },
  data() {
    return {
      messageError: false,
      messageConfirm: false,
      errorForm: false,
      userRol: "",
      userId: "",
      listStatus: [],
      listSteps: [],
      treatment: "",
      inspection: "",
      customer: "",
      commercial: "",
      commercialResp: "",
      distributor: "",
      protocolReminders: [],
      state: "",
      originalSteps: "",
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  computed: {
    totalTreatments() {
      return this.listSteps.length;
    },
    disabledRol() {
      return !(
        this.userRol === "appSuperAdministrators" ||
        this.userRol === "comTechnicians" ||
        this.userRol === "distributors"
      );
    },
  },
  methods: {
    checkStatus() {
      this.errorForm = false;
      if (this.state !== "completed") {
        this.changeStatus();
      }

      if (this.state === "completed") {
        let checkSteps = this.treatment.steps.filter(function (element) {
          if (!element.step_date) {
            return element;
          }
        });

        let state = checkSteps.length === 0;
        if (state) {
          this.changeStatus();
        } else {
          this.errorForm = true;
          this.messageError = this.$t(
            "Para completar el tratamiento, debe de introducir todas las fechas verificadas"
          );
        }
      }
    },
    changeStatus() {
      let refTreatment = db.collection("treatments").doc(this.treatment.uid);

      refTreatment
        .update({
          state: this.state,
        })
        .then(() => {
          this.getTreatment();
        })
        .catch(function (error) {});
    },
    getListStatus() {
      let _this = this;
      db.collection("treatmentsStatus")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.listStatus.push(doc.data());
          });
        });
    },
    getTreatment() {
      let _this = this;
      _this.treatment = "";
      _this.state = "";
      _this.originalSteps = "";
      _this.protocolReminders = [];
      _this.listSteps = [];

      db.collection("treatments")
        .where("uid", "==", _this.params.treatmentId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.treatment = doc.data();
            _this.state = doc.data().state;
            _this.originalSteps = orderBy(doc.data().steps, ["step"], ["asc"]);
            _this.getInspection();
            _this.getCustomer();

            doc.data().steps.forEach((element) => {
              element.state = element.step_date ? "Verificado" : "Pendiente";
              _this.listSteps.push(element);
            });

            db.collection("protocolReminders")
              .where("protocolId", "==", doc.data().protocolId)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                  _this.protocolReminders.push(doc.data());
                });
                _this.protocolReminders = orderBy(
                  _this.protocolReminders,
                  ["repetitionInDays"],
                  ["asc"]
                );
              });
          });
        });
    },
    editSteps(data) {
      let _this = this;
      _this.errorForm = false;
      if (data.step_date !== "") {
        if (luxon.local().toISO() >= data.step_date) {
          _this.originalSteps.forEach((element) => {
            if (element.step === data.step) {
              element.step_date = data.step_date;
            }
          });

          db.collection("treatments")
            .doc(_this.treatment.uid)
            .update({
              steps: _this.originalSteps,
            })
            .then(() => {
              this.sendEmails(data);
              this.getTreatment();
            })
            .catch(function (error) {});
        } else {
          this.errorForm = true;
          this.messageError = this.$t(
            "La fecha introducida no puede ser superior a la fecha actual"
          );
        }
      } else {
        this.errorForm = true;
        this.messageError = this.$t("Debe de introducir una fecha");
      }
    },
    sendEmails(data) {
      let _this = this;
      let sendNotificationEmail = firebaseGlobal
        .functions()
        .httpsCallable("sendNotificationEmail");

      let estimatedDate = luxon
        .fromISO(data.estimatedDate)
        .toFormat("dd/MM/yyyy");
      let step_date = luxon.fromISO(data.step_date).toFormat("dd/MM/yyyy");
      let emails_notification = [];
      let title_notification = "Recordatorio de tratamiento aplicado";
      let message_notification =
        "Se ha aplicado un recordatorio.  </br> \
                        </br> \
                        Cliente: " +
        _this.treatment.customerName +
        ". </br> \
                        Finca: " +
        _this.treatment.farmName +
        ". </br> \
                        Fecha programada: " +
        estimatedDate +
        ". </br> \
                        Fecha real: " +
        step_date +
        ". </br> \
                        Técnico comercial: " +
        _this.commercial.name +
        ". </br>";

      if (_this.customer.distributorName) {
        message_notification +=
          "Distribuidor: " + _this.customer.distributorName + ". </br>";
      }

      if (_this.treatment.lang === "it") {
        title_notification = "Promemoria del trattamento applicato";
        message_notification =
          "È stato applicato un promemoria.  </br> \
                          </br> \
                          Cliente: " +
          _this.treatment.customerName +
          ". </br> \
                          Fondo: " +
          _this.treatment.farmName +
          ". </br> \
                          Data programmata: " +
          estimatedDate +
          ". </br> \
                          Data reale: " +
          step_date +
          ". </br> \
                          Tecnico commerciale: " +
          _this.commercial.name +
          ". </br>";

        if (_this.customer.distributorName) {
          message_notification +=
            "Distributore: " + _this.customer.distributorName + ". </br>";
        }
      }

      if (_this.treatment.lang === "fr") {
        title_notification = "Rappel du traitement appliqué";
        message_notification =
          "Un rappel a été appliqué.  </br> \
                          </br> \
                          Client: " +
          _this.treatment.customerName +
          ". </br> \
                          Terrain: " +
          _this.treatment.farmName +
          ". </br> \
                          Date prévue: " +
          estimatedDate +
          ". </br> \
                          Date réelle: " +
          step_date +
          ". </br> \
                          Technicien commercial: " +
          _this.commercial.name +
          ". </br>";

        if (_this.customer.distributorName) {
          message_notification +=
            "Distributeur: " + _this.customer.distributorName + ". </br>";
        }
      }

      if (modoApp === "stagging") {
        emails_notification.push("dev+agrosta@bitvalencia.com");
      }

      if (modoApp === "production") {
        emails_notification.push(_this.customer.email);

        if (_this.distributor.email) {
          emails_notification.push(_this.distributor.email);
        }

        emails_notification.push(_this.commercial.email);
        emails_notification.push(_this.commercialResp.email);
        emails_notification.push("gestion@agrozono.net");
        emails_notification.push("dev+agroprod@bitvalencia.com");
      }

      sendNotificationEmail({
        emails: emails_notification,
        titulo: title_notification,
        mensaje: message_notification,
      })
        .then((result) => {
          if (result.data.code === 1) {
            this.$root.$emit("reloadListTask");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInspection() {
      let _this = this;

      db.collection("inspections")
        .where("id", "==", _this.treatment.inspectionId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.inspection = doc.data();
          });

          if (_this.inspection.farmDistributorName) {
            _this.getDistributor(_this.inspection.farmDistributorName);
          }

          _this.getRepCommercial(_this.inspection.comTechnicianId);
        });
    },
    getCustomer() {
      let _this = this;

      db.collection("customers")
        .where("uid", "==", _this.treatment.customerId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.customer = doc.data();
          });
        });
    },
    getRepCommercial(technicianId) {
      let _this = this;

      db.collection("comTechnicians")
        .where("uid", "==", technicianId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.commercial = doc.data();

            db.collection("comTechniciansResp")
              .where("uid", "==", doc.data().responsableId)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                  _this.commercialResp = doc.data();
                });
              });
          });
        });
    },
    getDistributor(distributorName) {
      let _this = this;
      db.collection("distributors")
        .where("name", "==", distributorName)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            _this.distributor = doc.data();
          });
        });
    },
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    this.getListStatus();
    this.getTreatment();

    this.userRol = sessionStorage.getItem("user_rol");
    this.userId = sessionStorage.getItem("user_id");
  },
};

export default TreatmentModal;
</script>
