<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="text-2xl font-semibold text-gray-800 p-6">
      <button
        class="
          bg-gray-200
          mr-2
          relative
          border border-gray-200
          text-xs
          font-semibold
          hover:bg-white
          text-gray-700
          pb-1
          px-3
          rounded
          focus:outline-none focus:shadow-outline
        "
        style="top: -2px"
        type="button"
        v-on:click="returnEditProtocol"
      >
        <Zondicon
          icon="arrow-thin-left"
          class="
            fill-current
            inline-block
            h-3
            text-gray-700
            cursor-pointer
            hover:text-gray-700
          "
        />
      </button>
      <div
        class="
          inline-block
          uppercase
          tracking-wide
          text-gray-700 text-xs
          font-bold
          mb-4
          px-3
        "
      >
        {{ nameProtocol }}
      </div>
    </div>

    <div
      v-if="dataLoaded === false"
      class="flex items-center justify-center flex-1 self-center"
    >
      <loader></loader>
    </div>

    <div v-if="dataLoaded === true" class="mb-20 xl:mb-4 p-6">
      <div>
        <div class="sm:flex">
          <div class="flex items-center mb-2">
            <button
              class="
                bg-yellow
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              v-if="
                userRol === 'appSuperAdministrators' ||
                userRol === 'comTechniciansAdmin' ||
                userEmail === 'rcomercial@agrozono.net'
              "
              type="button"
              @click="addReminder"
            >
              {{ $t("Agregar recordatorio") }}
            </button>
          </div>
        </div>
      </div>

      <div class="w-full space-y-4 mt-4">
        <protocol-reminder-component
          v-for="(reminder, index) in reminders"
          :reminder="reminder"
          :index="index"
          :total="reminders.length"
          :key="index"
        ></protocol-reminder-component>
      </div>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";
import ProtocolosEditPage from "@/pages/protocolos/ProtocolosEditPage";
import ProtocolosRecordatoriosAddPage from "@/pages/protocolos/ProtocolosRecordatoriosAddPage";
import { orderBy } from "lodash";
import ProtocolReminderComponent from "./ProtocolReminderComponent";

const ProtocolRemindersModal = {
  name: "ProtocolRemindersModal",
  layout: "layout",
  components: {
    ProtocolReminderComponent,
    Zondicon,
    Loader,
    HeaderModal,
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  data: function () {
    return {
      dataLoaded: false,
      reminders: [],
      userRol: "",
      userEmail: "",
      nameProtocol: "",
    };
  },
  methods: {
    getProtocolReminders: function () {
      this.dataLoaded = false;
      this.reminders = [];

      db.collection("protocolReminders")
        .where("protocolId", "==", this.params.protocolId)
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.reminders.push(doc.data());
          });
          this.reminders = orderBy(
            this.reminders,
            ["repetitionInDays"],
            ["asc"]
          );

          this.dataLoaded = true;
        });
    },
    addReminder: function () {
      this.$emit("close");
      this.$modal.show(ProtocolosRecordatoriosAddPage, {
        title: this.$t("Agregar recordatorio"),
        params: {
          protocolId: this.params.protocolId,
          protocolName: this.params.protocolName,
        },
      });
    },
    returnEditProtocol: function () {
      this.$emit("close");
      this.$modal.show(ProtocolosEditPage, {
        title: this.$t("Editar protocolo"),
        params: { id: this.params.protocolId },
      });
    },
  },
  beforeMount: function () {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");
    this.userEmail = sessionStorage.getItem("user");
    this.nameProtocol = this.params.protocolName;

    this.getProtocolReminders();
  },
};

export default ProtocolRemindersModal;
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: #bbcccc29;
}
</style>
