<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos del protocolo") }}
          </div>

          <div class="w-full md:w-2/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Nombre") }} ({{ $t("Español") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="nameEs"
              type="text"
              :placeholder="this.$t('Nombre')"
              v-model="nameEs"
            />
          </div>
          <div class="w-full md:w-2/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Nombre") }} ({{ $t("Italiano") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('Nombre')"
              id="nameIt"
              v-model="nameIt"
            />
          </div>
          <div class="w-full md:w-2/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Nombre") }} ({{ $t("Frances") }})
              <span class="text-bold text-red">*</span>
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              type="text"
              :placeholder="this.$t('Nombre')"
              id="nameFr"
              v-model="nameFr"
            />
          </div>
          <div class="w-full md:w-2/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="openRemindersModal"
            >
              {{ $t("Recordatorios") }}
            </label>
            <button
              class="
                bg-gray-200
                mr-2
                border border-gray-200
                text-sm
                font-semibold
                hover:bg-white
                text-gray-700
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              id="openRemindersModal"
              type="button"
              v-on:click="openRemindersModal"
            >
              <Zondicon
                icon="zoom-in"
                class="
                  fill-current
                  inline-block
                  h-4
                  text-gray-700
                  cursor-pointer
                  hover:text-gray-700
                "
              />
              {{ $t("Visualizar") }}
            </button>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idAgro"
            >
              {{ $t("Id") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="idAgro"
              type="text"
              :placeholder="this.$t('Id')"
              v-model="idAgro"
              readonly
            />
          </div>

          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idCreatedToShare"
            >
              {{ $t("Id created to share") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="idCreatedToShare"
              type="text"
              :placeholder="this.$t('Id created to share')"
              v-model="idCreatedToShare"
              readonly
            />
          </div>

          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="version"
            >
              {{ $t("Versión") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="version"
              type="text"
              :placeholder="this.$t('Versión')"
              v-model="version"
            />
          </div>

          <div class="w-full md:w-1/4 px-3 mb-6">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="status"
            >
              {{ $t("Estado") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="status"
                v-model="status"
              >
                <option value="">{{ $t("Seleccione un estado") }}</option>
                <option value="active">{{ $t("Activo") }}</option>
                <option value="inactive">{{ $t("Inactivo") }}</option>
                <option value="pending">{{ $t("Pendiente") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/3 px-3 mb-6">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="use"
            >
              {{ $t("Tipo de uso") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="use"
                v-model="use"
              >
                <option value="">{{ $t("Seleccione un tipo de uso") }}</option>
                <option value="in_use">{{ $t("En uso") }}</option>
                <option value="discontinuated">
                  {{ $t("Descontinuado") }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="country"
            >
              {{ $t("País") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="country"
                v-model="country"
              >
                <option value="">{{ $t("Seleccione un país") }}</option>
                <option value="spain">{{ $t("España") }}</option>
                <option value="france">{{ $t("Francia") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys1"
            >
              {{ $t("System") }} 1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys2"
            >
              {{ $t("System") }} 2
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys2"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys2"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys2_1"
            >
              {{ $t("System") }} 2.1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys2_1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys2_1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys3"
            >
              {{ $t("System") }} 3
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys3"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys3"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys4"
            >
              {{ $t("System") }} 4
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys4"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys4"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys5"
            >
              {{ $t("System") }} 5
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys5"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys5"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys6"
            >
              {{ $t("System") }} 6
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys6"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys6"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys6_1"
            >
              {{ $t("System") }} 6.1
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys6_1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys6_1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys7"
            >
              {{ $t("System") }} 7
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys7"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys7"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sysbas"
            >
              {{ $t("Basic") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sysbas"
              type="text"
              :placeholder="this.$t('Basic')"
              v-model="sysbas"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-4">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="date_creation"
            >
              {{ $t("Fecha creación") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="date_creation"
              type="text"
              :placeholder="this.$t('Fecha creación')"
              v-model="date_creation"
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="date_discontinuated"
            >
              {{ $t("Fecha descontinuado") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="date_discontinuated"
              type="text"
              :placeholder="this.$t('Fecha descontinuado')"
              v-model="date_discontinuated"
              readonly
            />
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div v-if="error" class="bg-red text-white px-4 py-2">
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <div v-for="(error, index) in errorMessages" :key="index">
              <span class="block ml-2"> {{ error.message }}</span>
            </div>
          </div>

          <div v-if="success" class="bg-greenlight text-white px-4 py-2 w-full">
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(successMessage) }}</span>
          </div>
        </div>
        <div
          v-if="
            userRol === 'appSuperAdministrators' ||
            userRol === 'comTechniciansAdmin' ||
            userEmail === 'rcomercial@agrozono.net'
          "
          class="flex flex-row justify-end"
        >
          <button
            class="
              bg-gray-200
              mr-2
              text-sm
              font-semibold
              border border-gray-200
              hover:bg-white
              text-gray-700
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="recalculateValues"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Recalcular valores") }}
          </button>

          <button
            class="
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
              bg-white
              hover:bg-greenlight
              text-greenlight
              hover:text-white
              border border-greenlight
            "
            type="button"
            v-on:click="checkCopyProtocol()"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Copiar protocolo") }}
          </button>

          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="editProtocol"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Guardar cambios") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import Utilities from "@/plugins/Utilities.js";
import HeaderModal from "@/components/HeaderModal";
import ProtocolRemindersModal from "@/pages/protocolos/ProtocolRemindersModal";
import ProtocolConfirmCopyModal from "@/pages/protocolos/ProtocolConfirmCopyModal";

export default {
  name: "ProtocolosEdit",
  layout: "layout",
  components: {
    Zondicon,
    HeaderModal,
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  data: function () {
    return {
      dataLoaded: true,
      success: false,
      successMessage: "",
      error: false,
      errorMessages: "",
      idAgroProtocols: "",
      idAgroProtocolReminders: "",

      idAgro: "",
      idCreatedToShare: "",
      nameEs: "",
      nameIt: "",
      nameFr: "",
      version: "",
      status: "",
      use: "",
      country: "",
      date_creation: "",
      date_discontinuated: "",
      sys1: "",
      sys2: "",
      sys2_1: "",
      sys3: "",
      sys4: "",
      sys5: "",
      sys6: "",
      sys6_1: "",
      sys7: "",
      sysbas: "",
      userRol: "",
      userEmail: "",

      nameEsData: "",
      nameItData: "",
      nameFrData: "",
      versionData: "",
      useData: "",
      confirmCopyProtocol: false,
    };
  },
  methods: {
    openCopyProtocolModal() {
      this.$emit("close");
      this.$modal.show(ProtocolConfirmCopyModal, {
        title: this.$t("Copiar protocolo"),
        params: {
          id: this.params.id,
        },
      });
    },
    checkCopyProtocol() {
      this.errorMessages = [];
      this.error = false;
      if (
        this.nameEs !== this.nameEsData ||
        this.nameIt !== this.nameItData ||
        this.nameFr !== this.nameFrData ||
        this.version !== this.versionData ||
        this.use !== this.useData
      ) {
        this.confirmCopyProtocol = true;
        this.error = true;
        this.errorMessages.push(
          {
            message: this.$t("Se ha detectado que se ha editado el protocolo."),
          },
          {
            message: this.$t(
              "Para continuar, primero debe de guardar el protocolo."
            ),
          }
        );

        return;
      }

      this.openCopyProtocolModal();
    },
    openRemindersModal: function () {
      this.$emit("close");
      this.$modal.show(ProtocolRemindersModal, {
        title: this.$t("Visualizar recordatorios"),
        params: {
          protocolId: this.params.id,
          protocolName: this.nameEs,
        },
      });
    },
    recalculateValues: function () {
      Utilities.recalculateValuesProtocol(this.params.id);

      let _this = this;
      this.dataLoaded = false;
      setTimeout(function () {
        db.collection("protocols")
          .doc(_this.params.id)
          .get()
          .then((snapshot) => {
            _this.idAgro = snapshot.data().idAgro;
            _this.nameEs = snapshot.data().name;
            _this.nameIt = snapshot.data().nameIt;
            _this.nameFr = snapshot.data().nameFr;
            _this.version = snapshot.data().version;
            _this.status = snapshot.data().status;
            _this.use = snapshot.data().use;
            _this.country = snapshot.data().country;
            _this.date_creation = snapshot.data().date_creation;
            _this.date_discontinuated = snapshot.data().date_discontinuated;
            _this.sys1 = snapshot.data().SYS1;
            _this.sys2 = snapshot.data().SYS2;
            _this.sys2_1 = snapshot.data().SYS2_1;
            _this.sys3 = snapshot.data().SYS3;
            _this.sys4 = snapshot.data().SYS4;
            _this.sys5 = snapshot.data().SYS5;
            _this.sys6 = snapshot.data().SYS6;
            _this.sys6_1 = snapshot.data().SYS6_1;
            _this.sys7 = snapshot.data().SYS7;
            _this.sysbas = snapshot.data().SYSBA;

            _this.dataLoaded = true;
          });
      }, 1000);
    },
    editProtocol: function () {
      this.dataLoaded = false;
      this.successMessage = "";
      this.success = false;
      this.errorMessages = [];
      this.error = false;

      if (
        this.idAgro !== "" &&
        this.nameEs !== "" &&
        this.nameIt !== "" &&
        this.nameFr !== "" &&
        this.status !== "" &&
        this.use !== "" &&
        this.country !== ""
      ) {
        if (this.status === "pending") {
          this.dataLoaded = true;
          this.error = true;
          this.errorMessages.push(
            {
              message: this.$t(
                "No se puede guardar el protocolo con el estado pendiente."
              ),
            },
            {
              message: this.$t(
                "Cambie el estado para poder guardar el protocolo"
              ),
            }
          );
          return;
        }

        let dt = new Date();
        let dtMes =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1;
        let dtDia = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();

        let fechaActual = dt.getFullYear() + "/" + dtMes + "/" + dtDia;

        if (this.use === "discontinuated") {
          this.date_discontinuated = fechaActual;
        } else {
          this.date_discontinuated = "";
        }

        let _this = this;
        db.collection("protocols")
          .doc(_this.params.id)
          .update({
            idAgro: String(this.idAgro),
            idCreatedToShare: this.idCreatedToShare,
            name: this.nameEs,
            nameIt: this.nameIt,
            nameFr: this.nameFr,
            version: this.version,
            status: this.status,
            use: this.use,
            country: this.country,
            date_creation: this.date_creation,
            date_discontinuated: this.date_discontinuated,
            SYS1: this.sys1,
            SYS2: this.sys2,
            SYS2_1: this.sys2_1,
            SYS3: this.sys3,
            SYS4: this.sys4,
            SYS5: this.sys5,
            SYS6: this.sys6,
            SYS6_1: this.sys6_1,
            SYS7: this.sys7,
            SYSBA: this.sysbas,
            deleted: false,
          })
          .then(() => {
            _this.dataLoaded = true;
            this.success = true;
            _this.successMessage = "El protocolo ha sido editado exitosamente";
            if (!this.confirmCopyProtocol) {
              _this.$root.$emit("reloadProtocolList");
              this.$emit("close");
            }

            if (this.confirmCopyProtocol) {
              this.confirmCopyProtocol = false;
              setTimeout(() => (_this.success = false), 5000);
            }
          });
      } else {
        this.dataLoaded = true;
        this.error = true;
        this.errorMessages.push({
          message: this.$t(
            "Debe llenar correctamente todos los campos para guardarlos en el sistema"
          ),
        });
      }
    },
    getProtocols() {
      db.collection("protocols")
        .doc(this.params.id)
        .get()
        .then((snapshot) => {
          this.idAgro = snapshot.data().idAgro;
          this.idCreatedToShare = snapshot.data().idCreatedToShare;
          this.nameEs = snapshot.data().name;
          this.nameEsData = snapshot.data().name;
          if (snapshot.data().nameIt) {
            this.nameIt = snapshot.data().nameIt;
            this.nameItData = snapshot.data().nameIt;
          }

          if (snapshot.data().nameFr) {
            this.nameFr = snapshot.data().nameFr;
            this.nameFrData = snapshot.data().nameFr;
          }

          this.version = snapshot.data().version;
          this.versionData = snapshot.data().version;
          this.status = snapshot.data().status;
          this.use = snapshot.data().use;
          this.useData = snapshot.data().use;
          this.country = snapshot.data().country;
          this.date_creation = snapshot.data().date_creation;
          this.date_discontinuated = snapshot.data().date_discontinuated;
          this.sys1 = snapshot.data().SYS1;
          this.sys2 = snapshot.data().SYS2;
          this.sys2_1 = snapshot.data().SYS2_1;
          this.sys3 = snapshot.data().SYS3;
          this.sys4 = snapshot.data().SYS4;
          this.sys5 = snapshot.data().SYS5;
          this.sys6 = snapshot.data().SYS6;
          this.sys6_1 = snapshot.data().SYS6_1;
          this.sys7 = snapshot.data().SYS7;
          this.sysbas = snapshot.data().SYSBA;

          this.dataLoaded = true;
        });
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.userRol = sessionStorage.getItem("user_rol");
    this.userEmail = sessionStorage.getItem("user");
    this.idAgroProtocols = await Utilities.getNextIdAgro("protocols");
    this.idAgroProtocolReminders = await Utilities.getNextIdAgro(
      "protocolReminders"
    );

    this.dataLoaded = false;

    this.getProtocols();
  },
};
</script>
