<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div
      v-if="registroEliminado === false"
      class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6"
    >
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos del cliente") }}
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="customerId"
            >
              {{ $t("Cliente") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-gray-200
                  border border-gray-400
                  rounded
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="customerId"
                v-model="customerId"
                disabled
              >
                <option value="">{{ $t("Seleccione un cliente") }}</option>
                <option
                  v-for="cliente in listado_clientes"
                  :value="cliente.id"
                  v-bind:key="cliente.id"
                >
                  {{ cliente.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idAgro"
            >
              {{ $t("Id") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="idAgro"
              type="text"
              :placeholder="this.$t('Id')"
              v-model="idAgro"
              disabled
              readonly
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="idCreatedToShare"
            >
              {{ $t("Id created to share") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="idCreatedToShare"
              type="text"
              :placeholder="this.$t('Id created to share')"
              v-model="idCreatedToShare"
              disabled
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Datos de la finca") }}
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="nombre"
            >
              {{ $t("Nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="nombre"
              type="text"
              :placeholder="this.$t('Nombre')"
              v-model="nombre"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="province"
            >
              {{ $t("Provincia") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="province"
              type="text"
              :placeholder="this.$t('Provincia')"
              v-model="province"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="city"
            >
              {{ $t("Ciudad") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="city"
              type="text"
              :placeholder="this.$t('Ciudad')"
              v-model="city"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-2/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="address"
            >
              {{ $t("Dirección") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="address"
              type="text"
              :placeholder="this.$t('Dirección')"
              v-model="address"
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="phone"
            >
              {{ $t("Teléfono") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="phone"
              type="text"
              :placeholder="this.$t('Teléfono')"
              v-model="phone"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="latitude"
            >
              {{ $t("Latitud") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="latitude"
              type="text"
              :placeholder="this.$t('Latitud')"
              v-model="latitude"
            />
          </div>

          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="longitude"
            >
              {{ $t("Longitud") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="longitude"
              type="text"
              :placeholder="this.$t('Longitud')"
              v-model="longitude"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/3 px-3 mb-6 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="distributorId"
            >
              {{ $t("Distribuidor") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="distributorId"
                v-model="distributorId"
                @change="fincaChangeDistribuidor"
              >
                <option value="">{{ $t("Seleccione un distribuidor") }}</option>
                <option
                  v-for="distribuidor in listado_distribuidores"
                  :value="distribuidor.id"
                  v-bind:key="distribuidor.id"
                >
                  {{ distribuidor.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="distributorName"
            >
              {{ $t("Distribuidor nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="distributorName"
              type="text"
              :placeholder="this.$t('Distribuidor nombre')"
              v-model="distributorName"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-6 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="delegationId"
            >
              {{ $t("Delegación") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="delegationId"
                v-model="delegationId"
                @change="fincaChangeDelegacion"
              >
                <option value="">{{ $t("Seleccione una delegación") }}</option>
                <option
                  v-for="delegacion in listado_delegaciones"
                  :value="delegacion.id"
                  v-bind:key="delegacion.id"
                >
                  {{ delegacion.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 hidden">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="delegationName"
            >
              {{ $t("Delegación nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="delegationName"
              type="text"
              placeholder="Delegacion Nombre"
              v-model="delegationName"
            />
          </div>

          <div
            class="w-full md:w-1/3 px-3 mb-6"
            :class="{
              hidden:
                usuarioRol === 'comTechnicians' ||
                usuarioRol === 'distributors',
            }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="comTechnicianId"
            >
              {{ $t("Comercial") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-gray-200
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="comTechnicianId"
                v-model="comTechnicianId"
                @change="fincaChangeComercial"
                disabled="disabled"
              >
                <option value="">{{ $t("Seleccione un comercial") }}</option>
                <option
                  v-for="comercial in listado_comerciales"
                  :value="comercial.id"
                  v-bind:key="comercial.id"
                >
                  {{ comercial.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            class="w-full md:w-1/3 px-3"
            :class="{ hidden: usuarioRol === 'admin' }"
          >
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="comTechnicianName"
            >
              {{ $t("Comercial nombre") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none focus:bg-white
              "
              id="comTechnicianName"
              type="text"
              :placeholder="this.$t('Comercial nombre')"
              disabled
              v-model="comTechnicianName"
            />
          </div>
        </div>

        <div class="flex hidden flex-wrap mb-6">
          <div class="w-full md:w-full px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Sectores") }}
              <span :class="{ hidden: sector_datosCargados === true }">{{
                $t("Cargando datos")
              }}</span>
            </label>

            <div class="bg-gray-200 pt-6 pb-4">
              <div class="flex flex-wrap">
                <div class="w-full md:w-1/4 px-3"></div>

                <div class="w-full md:w-3/4 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 1
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 2
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 3
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 4
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 5
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 6
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/4 px-3">
                  {{ $t("Superficie") }} (Ha)
                </div>

                <div class="w-full md:w-3/4 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="sector_surface1"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="sector_surface2"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="sector_surface3"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="sector_surface4"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="sector_surface5"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="sector_surface6"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/4 px-3">
                  {{ $t("Caudal") }} (m3/h)
                </div>

                <div class="w-full md:w-3/4 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="sector_flow1"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="sector_flow2"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="sector_flow3"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="sector_flow4"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="sector_flow5"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="sector_flow6"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/4 px-3">
                  {{ $t("Presión de trabajo") }} (Ha)
                </div>

                <div class="w-full md:w-3/4 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="sector_presure1"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="sector_presure2"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="sector_presure3"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="sector_presure4"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="sector_presure5"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-3">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none focus:bg-white
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="sector_presure6"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex hidden flex-wrap mb-4">
          <div class="w-full md:w-full px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Cultivos") }}
              <span :class="{ hidden: sector_datosCargados === true }">{{
                $t("Cargando información")
              }}</span>
            </label>

            <div class="bg-gray-200 pt-6 pb-4">
              <div class="flex flex-wrap">
                <div class="w-full md:w-full px-3">
                  <label
                    class="
                      block
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="cultivo_cultivation"
                  >
                    {{ $t("Tipo de cultivo") }}
                  </label>
                  <input
                    class="
                      appearance-none
                      block
                      w-full
                      bg-white
                      text-gray-700
                      border border-red-500
                      rounded
                      py-3
                      px-4
                      mb-3
                      leading-tight
                      focus:outline-none focus:bg-white
                    "
                    id="cultivo_cultivation"
                    type="text"
                    :placeholder="this.$t('Tipo de cultivo')"
                    v-model="cultivo_cultivation"
                  />
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/2 px-3">
                  <label
                    class="
                      block
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="cultivo_plantationDate"
                  >
                    {{ $t("Fecha plantación") }}
                  </label>
                  <input
                    class="
                      appearance-none
                      block
                      w-full
                      bg-white
                      text-gray-700
                      border border-red-500
                      rounded
                      py-3
                      px-4
                      mb-3
                      leading-tight
                      focus:outline-none focus:bg-white
                    "
                    id="cultivo_plantationDate"
                    type="text"
                    :placeholder="this.$t('Fecha plantación')"
                    v-model="cultivo_plantationDate"
                  />
                </div>

                <div class="w-full md:w-1/2 px-3">
                  <label
                    class="
                      block
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="cultivo_harvest"
                  >
                    {{ $t("Época de recolección") }}
                  </label>
                  <input
                    class="
                      appearance-none
                      block
                      w-full
                      bg-white
                      text-gray-700
                      border border-red-500
                      rounded
                      py-3
                      px-4
                      mb-3
                      leading-tight
                      focus:outline-none focus:bg-white
                    "
                    id="cultivo_harvest"
                    type="text"
                    :placeholder="this.$t('Época de recolección')"
                    v-model="cultivo_harvest"
                  />
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/2 px-3">
                  <label
                    class="
                      block
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="cultivo_cultivationState"
                  >
                    {{ $t("Estado de cultivo") }}
                  </label>
                  <input
                    class="
                      appearance-none
                      block
                      w-full
                      bg-white
                      text-gray-700
                      border border-red-500
                      rounded
                      py-3
                      px-4
                      mb-3
                      leading-tight
                      focus:outline-none focus:bg-white
                    "
                    id="cultivo_cultivationState"
                    type="text"
                    placeholder="Estado del Cultivo"
                    v-model="cultivo_cultivationState"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="mensajeError !== ''"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ $t(mensajeError) }}</span>
          </div>

          <div
            v-if="mensajeExito !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block ml-2">{{ $t(mensajeExito) }}</span>
          </div>
        </div>
        <div
          v-if="registroEliminado === false"
          class="flex flex-row justify-end"
        >
          <button
            class="
              bg-white
              mr-2
              text-sm
              font-semibold
              border border-redagrozono
              hover:bg-redagrozono
              text-redagrozono
              hover:text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            v-if="
              usuarioRol === 'comTechnicians' ||
              usuarioRol === 'comTechniciansResp' ||
              usuarioRol === 'distributors'
            "
            type="button"
            v-on:click="confirmarInhabilitar"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Inhabilitar registro") }}
          </button>

          <button
            class="
              bg-white
              hidden
              mr-2
              text-sm
              font-semibold
              border border-redagrozono
              hover:bg-redagrozono
              text-redagrozono
              hover:text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            v-if="
              usuarioRol === 'comTechnicians' ||
              usuarioRol === 'comTechniciansResp' ||
              usuarioRol === 'distributors'
            "
            type="button"
            v-on:click="confirmarEliminar"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Eliminar registro") }}
          </button>

          <button-google-maps
            v-if="latitude && longitude"
            :latitude="latitude"
            :longitude="longitude"
            class="mr-2"
          ></button-google-maps>

          <button
            class="
              bg-blueagrozono
              text-white
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="visualizarInspecciones"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Visualizar inspecciones") }}
          </button>

          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            v-if="
              usuarioRol === 'comTechnicians' ||
              usuarioRol === 'comTechniciansResp' ||
              usuarioRol === 'distributors'
            "
            type="button"
            v-on:click="submitDatos"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Guardar cambios") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            v-if="
              usuarioRol === 'comTechnicians' ||
              usuarioRol === 'comTechniciansResp' ||
              usuarioRol === 'distributors'
            "
            type="button"
            :class="{ hidden: datosCargados === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import { orderBy } from "lodash";
import HeaderModal from "@/components/HeaderModal";
import FincasInspeccionesPage from "@/pages/fincas/FincasInspeccionesPage";
import ButtonGoogleMaps from "@/components/ButtonGoogleMaps";

const FincasEdit = {
  name: "FincasEdit",
  layout: "layout",
  components: {
    Loader,
    HeaderModal,
    ButtonGoogleMaps,
  },
  data() {
    return {
      datosCargados: true,
      mensajeExito: "",
      mensajeError: "",
      registroEliminado: false,

      usuarioRol: "",
      usuarioActual: "",

      nombre: "",
      customerId: "",
      customerIdAgro: "",
      province: "",
      city: "",
      address: "",
      phone: "",
      idAgro: "",
      idCreatedToShare: "",
      latitude: "",
      longitude: "",
      delegationId: "",
      delegationName: "",
      delegationIdAgro: "",
      distributorId: "",
      distributorName: "",
      distributorIdAgro: "",
      comTechnicianId: "",
      comTechnicianName: "",
      comTechnicianIdAgro: "",

      inspectionId: "",

      sector_datosCargados: false,
      sector_surface1: "",
      sector_surface2: "",
      sector_surface3: "",
      sector_surface4: "",
      sector_surface5: "",
      sector_surface6: "",
      sector_flow1: "",
      sector_flow2: "",
      sector_flow3: "",
      sector_flow4: "",
      sector_flow5: "",
      sector_flow6: "",
      sector_presure1: "",
      sector_presure2: "",
      sector_presure3: "",
      sector_presure4: "",
      sector_presure5: "",
      sector_presure6: "",

      cultivo_cultivation: "",
      cultivo_plantationDate: "",
      cultivo_harvest: "",
      cultivo_cultivationState: "",

      listado_clientes: [],
      listado_delegaciones: [],
      listado_distribuidores: [],
      listado_comerciales: [],
    };
  },
  props: {
    title: {
      require: true,
    },
    params: {
      required: true,
    },
  },
  methods: {
    visualizarInspecciones() {
      this.$emit("close");
      this.$modal.show(FincasInspeccionesPage, {
        title: this.$t("Inspecciones de") + " " + this.nombre,
        params: {
          farmId: this.params.id,
          farmCustomer: this.params.customer,
          farmName: this.nombre,
        },
      });
    },
    confirmarInhabilitar() {
      if (
        confirm(
          this.$t("¿Realmente deseas inhabilitar la finca?") +
            ' "' +
            this.nombre +
            '"'
        )
      ) {
        this.inhabilitarRegistro();
      }
    },
    inhabilitarRegistro() {
      this.datosCargados = false;
      let _this = this;

      let dateCurrent = new Date();
      let fechaEliminado =
        dateCurrent.getDate() +
        "/" +
        (dateCurrent.getMonth() + 1) +
        "/" +
        dateCurrent.getFullYear() +
        " " +
        dateCurrent.getHours() +
        ":" +
        dateCurrent.getMinutes() +
        ":" +
        dateCurrent.getSeconds();

      console.log(fechaEliminado);

      db.collection("customers")
        .doc(_this.params.customer)
        .collection("farms")
        .doc(_this.params.id)
        .update({
          deleted: true,
          disabledDate: fechaEliminado,
        })
        .then(() => {
          _this.datosCargados = true;
          _this.mensajeExito = "La finca ha sido inhabilitada exitosamente";
          _this.registroEliminado = true;

          // PARA ACTUALIZAR EL LISTADO
          for (let i = 0; i < _this.$parent.$children.length; i++) {
            if (_this.$parent.$children[i].$options.name === "Fincas") {
              _this.$parent.$children[i].filtrarRegistros();
              break;
            }
          }

          this.$emit("close");
          this.$root.$emit("reloadListFarms");
        });
    },
    confirmarEliminar() {
      if (
        confirm(
          this.$t("¿Realmente deseas eliminar la finca?") +
            ' "' +
            this.nombre +
            '"'
        )
      ) {
        this.eliminarRegistro();
      }
    },
    eliminarRegistro() {
      this.datosCargados = false;
      let _this = this;

      db.collection("customers")
        .doc(_this.params.customer)
        .collection("farms")
        .doc(_this.params.id)
        .delete()
        .then(() => {
          _this.datosCargados = true;
          _this.mensajeExito = "La finca ha sido eliminada exitosamente";
          _this.registroEliminado = true;

          // PARA ACTUALIZAR EL LISTADO
          for (let i = 0; i < _this.$parent.$children.length; i++) {
            if (_this.$parent.$children[i].$options.name === "Fincas") {
              _this.$parent.$children[i].filtrarRegistros();
              break;
            }
          }
        });
    },
    submitDatos() {
      this.datosCargados = false;
      this.mensajeExito = "";
      this.mensajeError = "";

      if (this.nombre !== "" && this.customerId !== "") {
        let _this = this;
        db.collection("customers")
          .doc(_this.params.customer)
          .collection("farms")
          .doc(_this.params.id)
          .update({
            name: _this.nombre,
            customerID: _this.customerId,
            province: _this.province,
            city: _this.city,
            address: _this.address,
            phone: _this.phone,
            idAgro: String(_this.idAgro),
            idCreatedToShare: _this.idCreatedToShare,
            latitude: _this.latitude,
            longitude: _this.longitude,
            delegationId: _this.delegationId,
            delegationName: _this.delegationName,
            distributorId: _this.distributorId,
            distributorName: _this.distributorName,
            comTechnicianId: _this.comTechnicianId,
            comTechnicianName: _this.comTechnicianName,
            deleted: false,
          })
          .then(() => {
            _this.datosCargados = true;
            _this.mensajeExito = "La finca ha sido editada exitosamente";

            // PARA ACTUALIZAR LA INSPECCION SI EXISTE EL ID
            if (_this.inspectionId !== "") {
              let temp_surface1 =
                _this.sector_surface1 === "" || isNaN(_this.sector_surface1)
                  ? 0
                  : _this.sector_surface1;
              let temp_surface2 =
                _this.sector_surface2 === "" || isNaN(_this.sector_surface2)
                  ? 0
                  : _this.sector_surface2;
              let temp_surface3 =
                _this.sector_surface3 === "" || isNaN(_this.sector_surface3)
                  ? 0
                  : _this.sector_surface3;
              let temp_surface4 =
                _this.sector_surface4 === "" || isNaN(_this.sector_surface4)
                  ? 0
                  : _this.sector_surface4;
              let temp_surface5 =
                _this.sector_surface5 === "" || isNaN(_this.sector_surface5)
                  ? 0
                  : _this.sector_surface5;
              let temp_surface6 =
                _this.sector_surface6 === "" || isNaN(_this.sector_surface6)
                  ? 0
                  : _this.sector_surface6;
              let temp_flow1 =
                _this.sector_flow1 === "" || isNaN(_this.sector_flow1)
                  ? 0
                  : _this.sector_flow1;
              let temp_flow2 =
                _this.sector_flow2 === "" || isNaN(_this.sector_flow2)
                  ? 0
                  : _this.sector_flow2;
              let temp_flow3 =
                _this.sector_flow3 === "" || isNaN(_this.sector_flow3)
                  ? 0
                  : _this.sector_flow3;
              let temp_flow4 =
                _this.sector_flow4 === "" || isNaN(_this.sector_flow4)
                  ? 0
                  : _this.sector_flow4;
              let temp_flow5 =
                _this.sector_flow5 === "" || isNaN(_this.sector_flow5)
                  ? 0
                  : _this.sector_flow5;
              let temp_flow6 =
                _this.sector_flow6 === "" || isNaN(_this.sector_flow6)
                  ? 0
                  : _this.sector_flow6;
              let temp_presure1 =
                _this.sector_presure1 === "" || isNaN(_this.sector_presure1)
                  ? 0
                  : _this.sector_presure1;
              let temp_presure2 =
                _this.sector_presure2 === "" || isNaN(_this.sector_presure2)
                  ? 0
                  : _this.sector_presure2;
              let temp_presure3 =
                _this.sector_presure3 === "" || isNaN(_this.sector_presure3)
                  ? 0
                  : _this.sector_presure3;
              let temp_presure4 =
                _this.sector_presure4 === "" || isNaN(_this.sector_presure4)
                  ? 0
                  : _this.sector_presure4;
              let temp_presure5 =
                _this.sector_presure5 === "" || isNaN(_this.sector_presure5)
                  ? 0
                  : _this.sector_presure5;
              let temp_presure6 =
                _this.sector_presure6 === "" || isNaN(_this.sector_presure6)
                  ? 0
                  : _this.sector_presure6;

              _this.sector_datosCargados = false;
              db.collection("customers")
                .doc(_this.params.customer)
                .collection("farms")
                .doc(_this.params.id)
                .collection("inspections")
                .doc(_this.inspectionId)
                .update({
                  surface1: temp_surface1,
                  surface2: temp_surface2,
                  surface3: temp_surface3,
                  surface4: temp_surface4,
                  surface5: temp_surface5,
                  surface6: temp_surface6,
                  flow1: temp_flow1,
                  flow2: temp_flow2,
                  flow3: temp_flow3,
                  flow4: temp_flow4,
                  flow5: temp_flow5,
                  flow6: temp_flow6,
                  presure1: temp_presure1,
                  presure2: temp_presure2,
                  presure3: temp_presure3,
                  presure4: temp_presure4,
                  presure5: temp_presure5,
                  presure6: temp_presure6,

                  cultivation: _this.cultivo_cultivation
                    ? _this.cultivo_cultivation
                    : "",
                  plantationDate: _this.cultivo_plantationDate
                    ? _this.cultivo_plantationDate
                    : "",
                  harvest: _this.cultivo_harvest ? _this.cultivo_harvest : "",
                  cultivationState: _this.cultivo_cultivationState
                    ? _this.cultivo_cultivationState
                    : "",
                })
                .then(() => {
                  _this.sector_datosCargados = true;
                });
            }

            this.$emit("close");
            this.$root.$emit("reloadListFarms");
          });
      } else {
        this.datosCargados = true;
        this.mensajeError =
          "Debe llenar correctamente todos los campos para guardarlos en el sistema";
      }
    },
    fincaChangeDelegacion() {
      if (this.delegationId === "") {
        this.delegationName = "";
        this.delegationIdAgro = "";
      } else {
        for (const x in this.listado_delegaciones) {
          if (this.listado_delegaciones[x].id === this.delegationId) {
            this.delegationName = this.listado_delegaciones[x].name;
            this.delegationIdAgro = this.listado_delegaciones[x].idAgro;

            this.generateIdShare();
            break;
          }
        }
      }
    },
    fincaChangeDistribuidor() {
      if (this.distributorId === "") {
        this.distributorName = "";
        this.distributorIdAgro = "";
      } else {
        for (const x in this.listado_distribuidores) {
          if (this.listado_distribuidores[x].id === this.distributorId) {
            this.distributorName = this.listado_distribuidores[x].name;
            this.distributorIdAgro = this.listado_distribuidores[x].idAgro;

            this.generateIdShare();
            break;
          }
        }
      }
    },
    fincaChangeComercial() {
      if (this.comTechnicianId === "") {
        this.comTechnicianName = "";
        this.comTechnicianIdAgro = "";
      } else {
        for (const x in this.listado_comerciales) {
          if (this.listado_comerciales[x].id === this.comTechnicianId) {
            this.comTechnicianName = this.listado_comerciales[x].name;
            this.comTechnicianIdAgro = this.listado_comerciales[x].idAgro;

            this.generateIdShare();
            break;
          }
        }
      }
    },
    generateIdShare() {
      this.idCreatedToShare =
        "ES/" +
        this.comTechnicianIdAgro +
        "/" +
        this.customerIdAgro +
        "/" +
        this.idAgro;
    },
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.usuarioRol = sessionStorage.getItem("user_rol");
    this.usuarioActual = sessionStorage.getItem("user_id");

    this.datosCargados = false;
    db.collection("customers")
      .doc(this.params.customer)
      .collection("farms")
      .doc(this.params.id)
      .get()
      .then((snapshot) => {
        this.nombre = snapshot.data().name;
        this.customerId = snapshot.data().customerID;
        this.province = snapshot.data().province;
        this.city = snapshot.data().city;
        this.address = snapshot.data().address;
        this.phone = snapshot.data().phone;
        this.idAgro = snapshot.data().idAgro;
        this.idCreatedToShare = snapshot.data().idCreatedToShare;
        this.latitude = snapshot.data().latitude;
        this.longitude = snapshot.data().longitude;
        this.delegationId = snapshot.data().delegationId;
        this.delegationName = snapshot.data().delegationName;
        this.distributorId = snapshot.data().distributorId;
        this.distributorName = snapshot.data().distributorName;
        this.comTechnicianId = snapshot.data().comTechnicianId;
        this.comTechnicianName = snapshot.data().comTechnicianName;

        this.datosCargados = true;

        // PARA CARGAR LOS DATOS DEL SECTOR Y CULTIVO
        let _this = this;
        _this.sector_datosCargados = false;

        db.collection("customers")
          .doc(_this.params.customer)
          .collection("farms")
          .doc(_this.params.id)
          .collection("inspections")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              _this.inspectionId = doc.id;
              _this.sector_datosCargados = false;
              db.collection("customers")
                .doc(_this.params.customer)
                .collection("farms")
                .doc(_this.params.id)
                .collection("inspections")
                .doc(doc.id)
                .get()
                .then((snapshot) => {
                  _this.inspectionId = doc.id;
                  _this.sector_surface1 = snapshot.data().surface1;
                  _this.sector_surface2 = snapshot.data().surface2;
                  _this.sector_surface3 = snapshot.data().surface3;
                  _this.sector_surface4 = snapshot.data().surface4;
                  _this.sector_surface5 = snapshot.data().surface5;
                  _this.sector_surface6 = snapshot.data().surface6;
                  _this.sector_flow1 = snapshot.data().flow1;
                  _this.sector_flow2 = snapshot.data().flow2;
                  _this.sector_flow3 = snapshot.data().flow3;
                  _this.sector_flow4 = snapshot.data().flow4;
                  _this.sector_flow5 = snapshot.data().flow5;
                  _this.sector_flow6 = snapshot.data().flow6;
                  _this.sector_presure1 = snapshot.data().presure1;
                  _this.sector_presure2 = snapshot.data().presure2;
                  _this.sector_presure3 = snapshot.data().presure3;
                  _this.sector_presure4 = snapshot.data().presure4;
                  _this.sector_presure5 = snapshot.data().presure5;
                  _this.sector_presure6 = snapshot.data().presure6;

                  _this.cultivo_cultivation = snapshot.data().cultivation;
                  _this.cultivo_plantationDate = snapshot.data().plantationDate;
                  _this.cultivo_harvest = snapshot.data().harvest;
                  _this.cultivo_cultivationState =
                    snapshot.data().cultivationState;

                  _this.sector_datosCargados = true;
                });
            });

            _this.sector_datosCargados = true;
          });
      });

    let _this = this;
    db.collection("customers")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          arrayTemp["idAgro"] = doc.data().idAgro;

          _this.listado_clientes.push(arrayTemp);
        });

        _this.listado_clientes = orderBy(
          _this.listado_clientes,
          ["name"],
          ["asc"]
        );
      });

    db.collection("delegations")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          arrayTemp["idAgro"] = doc.data().idAgro;

          _this.listado_delegaciones.push(arrayTemp);
        });
      });

    db.collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          arrayTemp["idAgro"] = doc.data().idAgro;

          _this.listado_distribuidores.push(arrayTemp);
        });
      });

    db.collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          arrayTemp["idAgro"] = doc.data().idAgro;

          _this.listado_comerciales.push(arrayTemp);
        });

        _this.listado_comerciales = orderBy(
          _this.listado_comerciales,
          ["name"],
          ["asc"]
        );
      });
  },
};

export default FincasEdit;
</script>
