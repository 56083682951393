<template>
  <div class="h-screen flex justify-center items-center bg-graylogin">
    <div class="w-full max-w-xs">
      <div
        class="
          bg-greenlight
          px-5
          py-3
          text-white
          font-bold
          rounded rounded-b-none
        "
      >
        {{ $t("Iniciar sesión") }}
      </div>
      <form
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        @submit.prevent="onSubmit"
      >
        <div
          v-if="errorMessage !== ''"
          class="bg-red text-white px-4 py-2 mb-4"
        >
          <strong>{{ $t("Ha ocurrido un error") }}</strong>
          <span class="block sm:inline ml-2">{{ errorMessage }}</span>
        </div>

        <div
          v-if="successMessage !== ''"
          class="bg-greenlight text-white px-4 py-2 mb-4"
        >
          <strong>{{ $t("Excelente") }}</strong>
          <span class="block sm:inline ml-2">{{ successMessage }}</span>
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
            {{ $t("Email") }}
          </label>
          <input
            class="
              shadow
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-gray-700
              leading-tight
              focus:outline-none focus:shadow-outline
            "
            id="email"
            type="text"
            :placeholder="this.$t('Email')"
            v-model="email"
          />
        </div>
        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="password"
          >
            {{ $t("Contraseña") }}
          </label>
          <input
            class="
              shadow
              appearance-none
              border border-red-500
              rounded
              w-full
              py-2
              px-3
              text-gray-700
              mb-3
              leading-tight
              focus:outline-none focus:shadow-outline
            "
            id="password"
            type="password"
            placeholder="*******"
            v-model="password"
          />
        </div>
        <div class="mb-6">
          <label class="block text-gray-700">
            <input class="mr-2" type="checkbox" />
            <span class="text-sm"> {{ $t("Recuerdame") }} </span>
          </label>
        </div>
        <div class="flex justify-between">
          <button
            class="
              bg-yellow
              hover:bg-greenlight
              text-white
              font-bold
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="submit"
            :class="{ hidden: dataLoaded === false }"
          >
            {{ $t("Acceder") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: dataLoaded === true }"
          >
            {{ $t("Enviando datos") }}
          </button>

          <router-link
            to="/recovery"
            class="
              inline-block
              self-center
              text-right
              font-bold
              text-sm text-blue-500
              hover:text-blue-800
            "
          >
            {{ $t("¿Olvidaste tu contraseña?") }}
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { firebaseGlobal } from "@/plugins/firebase.js";
import { db } from "@/plugins/firebase.js";
import Utilities from "@/plugins/Utilities.js";

const Login = {
  layout: "",
  name: "Login",
  metaInfo: {
    title: "Login",
  },
  data: function () {
    return {
      dataLoaded: true,
      successMessage: "",
      errorMessage: "",
      email: "",
      password: "",
    };
  },
  methods: {
    onSubmit() {
      this.dataLoaded = false;
      this.successMessage = "";
      this.errorMessage = "";

      if (this.email === "" && this.password === "") {
        this.dataLoaded = true;
        this.errorMessage =
          "Debe llenar correctamente todos los campos para poder ingresar al sistema";
      }

      let _this = this;
      firebaseGlobal
        .auth()
        .signInWithEmailAndPassword(_this.email, _this.password)
        .then(function (result) {
          db.collection("users")
            .doc(result.user.uid)
            .get()
            .then((snapshot) => {
              let displayName =
                result.user.displayName !== null
                  ? result.user.displayName
                  : snapshot.data().email;

              sessionStorage.setItem("user", snapshot.data().email);
              sessionStorage.setItem(
                "businessArea",
                JSON.stringify(snapshot.data().businessAreas)
              );
              sessionStorage.setItem("user_id", result.user.uid);
              sessionStorage.setItem("user_name", displayName);
              sessionStorage.setItem("user_rol", snapshot.data().rol);
              sessionStorage.setItem(
                "user_hash",
                Utilities.generateHash(result.user.uid)
              );

              _this.setLang(snapshot.data().lang);
              _this.$router.push({ name: "home" });

              _this.dataLoaded = true;
            })
            .catch(function (error) {
              let errorMessage = error.message;

              _this.dataLoaded = true;
              _this.errorMessage =
                "Ha ocurrido un error al intentar cargar los roles del usuario. Error: " +
                errorMessage;

              firebaseGlobal.auth().signOut();
            });
        })
        .catch(function (error) {
          let errorMessage = error.message;

          _this.dataLoaded = true;
          _this.errorMessage =
            "Ha ocurrido un error al intentar ingresar al sistema. Error: " +
            errorMessage;
        });
    },
  },
};

export default Login;
</script>
