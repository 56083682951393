<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="flex flex-row items-center mt-6 mb-20 xl:mb-4 p-6">
      <div class="w-full">
        <div>
          <div class="grid grid-cols-8 gap-8">
            <div
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                md:mb-0
                col-span-8
              "
            >
              {{ $t("Caudales / sector m3/h") }}
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="flow1"
              >
                S1
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S1"
                id="flow1"
                v-model="flow1"
              />
            </div>

            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="flow2"
              >
                S2
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S2"
                id="flow2"
                v-model="flow2"
              />
            </div>

            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="flow3"
              >
                S3
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S3"
                id="flow3"
                v-model="flow3"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="flow4"
              >
                S4
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S4"
                id="flow4"
                v-model="flow4"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="flow5"
              >
                S5
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S5"
                id="flow5"
                v-model="flow5"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="flow6"
              >
                S6
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S6"
                id="flow6"
                v-model="flow6"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="flow7"
              >
                S7
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S7"
                id="flow7"
                v-model="flow7"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="flow8"
              >
                S8
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S8"
                id="flow8"
                v-model="flow8"
              />
            </div>
            <div
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                md:mb-0
                col-span-8
              "
            >
              {{ $t("Presión entrada / sector bar") }}
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="entryPressure1"
              >
                S1
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S1"
                id="entryPressure1"
                v-model="entryPressure1"
              />
            </div>

            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="entryPressure2"
              >
                S2
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S2"
                id="entryPressure2"
                v-model="entryPressure2"
              />
            </div>

            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="entryPressure3"
              >
                S3
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S3"
                id="entryPressure3"
                v-model="entryPressure3"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="entryPressure4"
              >
                S4
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S4"
                id="entryPressure4"
                v-model="entryPressure4"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="entryPressure5"
              >
                S5
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S5"
                id="entryPressure5"
                v-model="entryPressure5"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="entryPressure6"
              >
                S6
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S6"
                id="entryPressure6"
                v-model="entryPressure6"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="entryPressure7"
              >
                S7
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S7"
                id="entryPressure7"
                v-model="entryPressure7"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="entryPressure8"
              >
                S8
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S8"
                id="entryPressure8"
                v-model="entryPressure8"
              />
            </div>
            <div
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                md:mb-0
                col-span-8
              "
            >
              {{ $t("Presión salida / sector bar") }}
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="exitPressure1"
              >
                S1
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S1"
                id="exitPressure1"
                v-model="exitPressure1"
              />
            </div>

            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="exitPressure2"
              >
                S2
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S2"
                id="exitPressure2"
                v-model="exitPressure2"
              />
            </div>

            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="exitPressure3"
              >
                S3
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S3"
                id="exitPressure3"
                v-model="exitPressure3"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="exitPressure4"
              >
                S4
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S4"
                id="exitPressure4"
                v-model="exitPressure4"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="exitPressure5"
              >
                S5
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S5"
                id="exitPressure5"
                v-model="exitPressure5"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="exitPressure6"
              >
                S6
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S6"
                id="exitPressure6"
                v-model="exitPressure6"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="exitPressure7"
              >
                S7
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S7"
                id="exitPressure7"
                v-model="exitPressure7"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="exitPressure8"
              >
                S8
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S8"
                id="exitPressure8"
                v-model="exitPressure8"
              />
            </div>
            <div
              class="
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-4
                md:mb-0
                col-span-8
              "
            >
              {{ $t("Superficies / sector") }}
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="surface1"
              >
                S1
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S1"
                id="surface1"
                v-model="surface1"
              />
            </div>

            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="surface2"
              >
                S2
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S2"
                id="surface2"
                v-model="surface2"
              />
            </div>

            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="surface3"
              >
                S3
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S3"
                id="surface3"
                v-model="surface3"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="surface4"
              >
                S4
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S4"
                id="surface4"
                v-model="surface4"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="surface5"
              >
                S5
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S5"
                id="surface5"
                v-model="surface5"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="surface6"
              >
                S6
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S6"
                id="surface6"
                v-model="surface6"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="surface7"
              >
                S7
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S7"
                id="surface7"
                v-model="surface7"
              />
            </div>
            <div class="col-span-4 sm:col-span-2 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="surface8"
              >
                S8
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="number"
                placeholder="S8"
                id="surface8"
                v-model="surface8"
              />
            </div>
          </div>
          <div class="grid grid-cols-6 gap-4 mt-4">
            <div class="col-span-6 sm:col-span-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="realTreatmentDate"
              >
                {{ $t("Fecha real tratamiento") }}
              </label>
              <datetime
                input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="date"
                format="dd/MM/kkkk"
                placeholder="dd/mm/yyyy"
                zone="local"
                id="realTreatmentDate"
                value-zone="local"
                v-model="realTreatmentDate"
              ></datetime>
            </div>
            <div
              class="
                col-span-6
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
              "
            >
              {{ $t("Horas totales máquina") }}
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="hourMachineInit"
              >
                {{ $t("Inicio") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                :class="styleDateTime"
                id="hourMachineInit"
                type="number"
                placeholder="Inicio"
                v-model="hourMachineInit"
                :disabled="disabledHours"
              />
            </div>

            <div class="col-span-6 sm:col-span-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="hourMachineFinish"
              >
                {{ $t("Fin") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                :class="styleDateTime"
                id="hourMachineFinish"
                type="number"
                placeholder="Fin"
                v-model="hourMachineFinish"
                :disabled="disabledHours"
              />
            </div>

            <div class="col-span-6 sm:col-span-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Total") }}
              </label>
              <p
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
              >
                {{ totalMachine }}
              </p>
            </div>
          </div>
          <div class="grid grid-cols-6 gap-4 mt-4">
            <div
              class="
                col-span-6
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
              "
            >
              {{ $t("Horas totales grupo") }}
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="hourGroupInit"
              >
                {{ $t("Inicio") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                :class="styleDateTime"
                id="hourGroupInit"
                type="number"
                placeholder="Inicio"
                v-model="hourGroupInit"
                :disabled="disabledHours"
              />
            </div>

            <div class="col-span-6 sm:col-span-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="hourGroupFinish"
              >
                {{ $t("Fin") }}
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none
                "
                :class="styleDateTime"
                id="hourGroupFinish"
                type="number"
                placeholder="Fin"
                v-model="hourGroupFinish"
                :disabled="disabledHours"
              />
            </div>

            <div class="col-span-6 sm:col-span-2">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
              >
                {{ $t("Total") }}
              </label>
              <p
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-200
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
              >
                {{ totalGroup }}
              </p>
            </div>
          </div>
          <div class="grid grid-cols-6 gap-4 mt-4">
            <div
              class="
                col-span-6
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
              "
            >
              {{ $t("Otros") }}
            </div>
            <div class="col-span-6">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="observation"
              >
                {{ $t("Observaciones") }}
              </label>
              <textarea
                class="
                  appearance-none
                  block
                  w-full
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Observaciones')"
                id="observation"
                v-model="observation"
              ></textarea>
            </div>
          </div>
          <div class="grid grid-cols-6 gap-4 mt-4">
            <div
              class="
                col-span-6
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
              "
            >
              {{ $t("Regulación de ph") }}
            </div>
            <div class="col-span-6">
              <div class="block w-full relative mb-4">
                <label class="md:w-2/3 block cursor-pointer">
                  <input
                    class="mr-2 leading-tight"
                    type="radio"
                    value="yesTeam"
                    name="phRegulation"
                    v-model="phRegulationStatus"
                  />
                  <span class="text-gray-700">{{ $t("Si, el equipo") }}</span>
                </label>
                <label class="md:w-2/3 block cursor-pointer">
                  <input
                    class="mr-2 leading-tight"
                    type="radio"
                    value="yesClient"
                    name="phRegulation"
                    v-model="phRegulationStatus"
                  />
                  <span class="text-gray-700">{{ $t("Si, el cliente") }}</span>
                </label>
                <label class="md:w-2/3 block cursor-pointer">
                  <input
                    class="mr-2 leading-tight"
                    type="radio"
                    value="no"
                    name="phRegulation"
                    v-model="phRegulationStatus"
                  />
                  <span class="text-gray-700">{{ $t("No") }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="bg-red text-white px-4 py-2 mb-4 w-full" v-if="errorForm">
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <div v-for="(error, index) in messageErrors" :key="index">
              <span class="block ml-2">- {{ error.message }}</span>
            </div>
          </div>
          <div class="flex justify-end mt-4">
            <button
              class="
                inline-flex
                items-center
                px-4
                py-2
                text-white
                bg-orange-500
                leading-5
                font-bold
                rounded-md
                hover:bg-orange-400
              "
              type="button"
              @click="validationForm"
            >
              {{ $t("Crear detalle") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { db } from "@/plugins/firebase";
import HeaderModal from "@/components/HeaderModal";

const ApplicationDetailAddDetailModal = {
  name: "ApplicationDetailAddDetailModal",
  layout: "layout",
  components: {
    datetime: Datetime,
    HeaderModal,
  },
  data: function () {
    return {
      flow1: "",
      flow2: "",
      flow3: "",
      flow4: "",
      flow5: "",
      flow6: "",
      flow7: "",
      flow8: "",
      entryPressure1: "",
      entryPressure2: "",
      entryPressure3: "",
      entryPressure4: "",
      entryPressure5: "",
      entryPressure6: "",
      entryPressure7: "",
      entryPressure8: "",
      exitPressure1: "",
      exitPressure2: "",
      exitPressure3: "",
      exitPressure4: "",
      exitPressure5: "",
      exitPressure6: "",
      exitPressure7: "",
      exitPressure8: "",
      surface1: "",
      surface2: "",
      surface3: "",
      surface4: "",
      surface5: "",
      surface6: "",
      surface7: "",
      surface8: "",
      hourMachineInit: "",
      hourMachineFinish: "",
      totalMachinaHours: "0",
      hourGroupInit: "",
      hourGroupFinish: "",
      totalGroupHours: "0",
      realTreatmentDate: "",
      observation: "",
      phRegulationStatus: "",
      treatment: [],
      messageErrors: [],
      errorForm: false,
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  computed: {
    disabledHours() {
      return this.realTreatmentDate === "";
    },
    totalMachine() {
      if (
        this.hourMachineInit !== "" &&
        this.hourMachineFinish !== "" &&
        this.realTreatmentDate !== ""
      ) {
        return (this.totalMachinaHours =
          this.hourMachineFinish - this.hourMachineInit);
      }

      return 0;
    },
    totalGroup() {
      if (
        this.hourGroupInit !== "" &&
        this.hourGroupFinish !== "" &&
        this.realTreatmentDate !== ""
      ) {
        return (this.totalGroupHours =
          this.hourGroupFinish - this.hourGroupInit);
      }

      return 0;
    },
    styleDateTime() {
      if (this.realTreatmentDate !== "") {
        return "bg-white";
      }

      return "bg-gray-200";
    },
  },
  methods: {
    assignData() {
      if (this.treatment.flow1) {
        this.flow1 = this.treatment.flow1;
      }

      if (this.treatment.flow2) {
        this.flow2 = this.treatment.flow2;
      }

      if (this.treatment.flow3) {
        this.flow3 = this.treatment.flow3;
      }

      if (this.treatment.flow4) {
        this.flow4 = this.treatment.flow4;
      }
      if (this.treatment.flow5) {
        this.flow5 = this.treatment.flow5;
      }

      if (this.treatment.flow6) {
        this.flow6 = this.treatment.flow6;
      }

      if (this.treatment.flow7) {
        this.flow7 = this.treatment.flow7;
      }

      if (this.treatment.flow8) {
        this.flow8 = this.treatment.flow8;
      }

      if (this.treatment.entryPressure1) {
        this.entryPressure1 = this.treatment.entryPressure1;
      }

      if (this.treatment.entryPressure2) {
        this.entryPressure2 = this.treatment.entryPressure2;
      }

      if (this.treatment.entryPressure3) {
        this.entryPressure3 = this.treatment.entryPressure3;
      }

      if (this.treatment.entryPressure4) {
        this.entryPressure4 = this.treatment.entryPressure4;
      }

      if (this.treatment.entryPressure5) {
        this.entryPressure5 = this.treatment.entryPressure5;
      }

      if (this.treatment.entryPressure6) {
        this.entryPressure6 = this.treatment.entryPressure6;
      }

      if (this.treatment.entryPressure7) {
        this.entryPressure7 = this.treatment.entryPressure7;
      }

      if (this.treatment.entryPressure8) {
        this.entryPressure8 = this.treatment.entryPressure8;
      }

      if (this.treatment.exitPressure1) {
        this.exitPressure1 = this.treatment.exitPressure1;
      }

      if (this.treatment.exitPressure2) {
        this.exitPressure2 = this.treatment.exitPressure2;
      }

      if (this.treatment.exitPressure3) {
        this.exitPressure3 = this.treatment.exitPressure3;
      }

      if (this.treatment.exitPressure4) {
        this.exitPressure4 = this.treatment.exitPressure4;
      }

      if (this.treatment.exitPressure5) {
        this.exitPressure5 = this.treatment.exitPressure5;
      }

      if (this.treatment.exitPressure6) {
        this.exitPressure6 = this.treatment.exitPressure6;
      }

      if (this.treatment.exitPressure7) {
        this.exitPressure7 = this.treatment.exitPressure7;
      }

      if (this.treatment.exitPressure8) {
        this.exitPressure8 = this.treatment.exitPressure8;
      }

      if (this.treatment.surface1) {
        this.surface1 = this.treatment.surface1;
      }

      if (this.treatment.surface2) {
        this.surface2 = this.treatment.surface2;
      }

      if (this.treatment.surface3) {
        this.surface3 = this.treatment.surface3;
      }

      if (this.treatment.surface4) {
        this.surface4 = this.treatment.surface4;
      }

      if (this.treatment.surface5) {
        this.surface5 = this.treatment.surface5;
      }

      if (this.treatment.surface6) {
        this.surface6 = this.treatment.surface6;
      }

      if (this.treatment.surface7) {
        this.surface7 = this.treatment.surface7;
      }

      if (this.treatment.surface8) {
        this.surface8 = this.treatment.surface8;
      }

      if (this.treatment.hourMachineInit) {
        this.hourMachineInit = this.treatment.hourMachineInit;
      }

      if (this.treatment.hourMachineFinish) {
        this.hourMachineFinish = this.treatment.hourMachineFinish;
      }

      if (this.treatment.totalMachinaHours) {
        this.totalMachinaHours = this.treatment.totalMachinaHours;
      }

      if (this.treatment.hourGroupInit) {
        this.hourGroupInit = this.treatment.hourGroupInit;
      }

      if (this.treatment.hourGroupFinish) {
        this.hourGroupFinish = this.treatment.hourGroupFinish;
      }

      if (this.treatment.totalGroupHours) {
        this.totalGroupHours = this.treatment.totalGroupHours;
      }

      if (this.treatment.realTreatmentDate) {
        this.realTreatmentDate = this.treatment.realTreatmentDate;
      }

      if (this.treatment.observation) {
        this.observation = this.treatment.observation;
      }

      if (this.treatment.phRegulationStatus) {
        this.phRegulationStatus = this.treatment.phRegulationStatus;
      }
    },
    validationForm() {
      this.messageErrors = [];
      let errors = {
        message: "",
      };
      let listErrors = [];
      let saveContinue = false;
      let minSurfaceRange = 0.01;
      let maxSurfaceRange = 10;
      let minFlowRange = 10;
      let maxFlowRange = 100;
      let minPresureRange = 0.5;
      let maxPresureRange = 6.5;

      this.surface1 = this.surface1 ? parseFloat(this.surface1) : "";
      this.surface2 = this.surface2 ? parseFloat(this.surface2) : "";

      this.surface3 = this.surface3 ? parseFloat(this.surface3) : "";
      this.surface4 = this.surface4 ? parseFloat(this.surface4) : "";
      this.surface5 = this.surface5 ? parseFloat(this.surface5) : "";
      this.surface6 = this.surface6 ? parseFloat(this.surface6) : "";
      this.surface7 = this.surface7 ? parseFloat(this.surface7) : "";
      this.surface8 = this.surface8 ? parseFloat(this.surface8) : "";
      this.flow1 = this.flow1 ? parseFloat(this.flow1) : "";
      this.flow2 = this.flow2 ? parseFloat(this.flow2) : "";
      this.flow3 = this.flow3 ? parseFloat(this.flow3) : "";
      this.flow4 = this.flow4 ? parseFloat(this.flow4) : "";
      this.flow5 = this.flow5 ? parseFloat(this.flow5) : "";
      this.flow6 = this.flow6 ? parseFloat(this.flow6) : "";
      this.flow7 = this.flow7 ? parseFloat(this.flow7) : "";
      this.flow8 = this.flow8 ? parseFloat(this.flow8) : "";
      this.entryPressure1 = this.entryPressure1
        ? parseFloat(this.entryPressure1)
        : "";
      this.entryPressure2 = this.entryPressure2
        ? parseFloat(this.entryPressure2)
        : "";
      this.entryPressure3 = this.entryPressure3
        ? parseFloat(this.entryPressure3)
        : "";
      this.entryPressure4 = this.entryPressure4
        ? parseFloat(this.entryPressure4)
        : "";
      this.entryPressure5 = this.entryPressure5
        ? parseFloat(this.entryPressure5)
        : "";
      this.entryPressure6 = this.entryPressure6
        ? parseFloat(this.entryPressure6)
        : "";
      this.entryPressure7 = this.entryPressure7
        ? parseFloat(this.entryPressure7)
        : "";
      this.entryPressure8 = this.entryPressure8
        ? parseFloat(this.entryPressure8)
        : "";
      this.exitPressure1 = this.exitPressure1
        ? parseFloat(this.exitPressure1)
        : "";
      this.exitPressure2 = this.exitPressure2
        ? parseFloat(this.exitPressure2)
        : "";
      this.exitPressure3 = this.exitPressure3
        ? parseFloat(this.exitPressure3)
        : "";
      this.exitPressure4 = this.exitPressure4
        ? parseFloat(this.exitPressure4)
        : "";
      this.exitPressure5 = this.exitPressure5
        ? parseFloat(this.exitPressure5)
        : "";
      this.exitPressure6 = this.exitPressure6
        ? parseFloat(this.exitPressure6)
        : "";
      this.exitPressure7 = this.exitPressure7
        ? parseFloat(this.exitPressure7)
        : "";
      this.exitPressure8 = this.exitPressure8
        ? parseFloat(this.exitPressure8)
        : "";

      if (this.hourMachineInit !== "" && this.hourMachineFinish !== "") {
        if (this.hourMachineInit >= this.hourMachineFinish) {
          saveContinue = true;
          errors.message = this.$t(
            "La hora de inicio de máquina no puede ser superior o igual a la hora fin de máquina"
          );
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.hourGroupInit !== "" && this.hourGroupFinish !== "") {
        if (this.hourGroupInit >= this.hourGroupFinish) {
          saveContinue = true;
          errors.message = this.$t(
            "La hora de inicio de grupo no puede ser superior o igual a la hora fin de grupo"
          );
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.phRegulationStatus === "") {
        saveContinue = true;
        errors.message = this.$t("Debe introducir el ph");
        listErrors.push(errors);
        errors = {
          message: "",
        };
      }

      if (this.realTreatmentDate === "") {
        saveContinue = true;
        errors.message = this.$t(
          "Debe introducir la fecha real de tratamiento"
        );
        listErrors.push(errors);
        errors = {
          message: "",
        };
      }

      if (this.surface1 !== 0 && this.surface1 !== "") {
        if (
          this.surface1 < minSurfaceRange ||
          this.surface1 > maxSurfaceRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo superficie") +
            " " +
            this.$t("Sector") +
            " 1 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minSurfaceRange +
            "-" +
            maxSurfaceRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.surface2 !== 0 && this.surface2 !== "") {
        if (
          this.surface2 < minSurfaceRange ||
          this.surface2 > maxSurfaceRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo superficie") +
            " " +
            this.$t("Sector") +
            " 2 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minSurfaceRange +
            "-" +
            maxSurfaceRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.surface3 !== 0 && this.surface3 !== "") {
        if (
          this.surface3 < minSurfaceRange ||
          this.surface3 > maxSurfaceRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo superficie") +
            " " +
            this.$t("Sector") +
            " 3 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minSurfaceRange +
            "-" +
            maxSurfaceRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.surface4 !== 0 && this.surface4 !== "") {
        if (
          this.surface4 < minSurfaceRange ||
          this.surface4 > maxSurfaceRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo superficie") +
            " " +
            this.$t("Sector") +
            " 4 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minSurfaceRange +
            "-" +
            maxSurfaceRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.surface5 !== 0 && this.surface5 !== "") {
        if (
          this.surface5 < minSurfaceRange ||
          this.surface5 > maxSurfaceRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo superficie") +
            " " +
            this.$t("Sector") +
            " 5 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minSurfaceRange +
            "-" +
            maxSurfaceRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.surface6 !== 0 && this.surface6 !== "") {
        if (
          this.surface6 < minSurfaceRange ||
          this.surface6 > maxSurfaceRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo superficie") +
            " " +
            this.$t("Sector") +
            " 6 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minSurfaceRange +
            "-" +
            maxSurfaceRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.surface7 !== 0 && this.surface7 !== "") {
        if (
          this.surface7 < minSurfaceRange ||
          this.surface7 > maxSurfaceRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo superficie") +
            " " +
            this.$t("Sector") +
            " 7 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minSurfaceRange +
            "-" +
            maxSurfaceRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.surface8 !== 0 && this.surface8 !== "") {
        if (
          this.surface8 < minSurfaceRange ||
          this.surface8 > maxSurfaceRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo superficie") +
            " " +
            this.$t("Sector") +
            " 8 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minSurfaceRange +
            "-" +
            maxSurfaceRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.flow1 !== 0 && this.flow1 !== "") {
        if (this.flow1 < minFlowRange || this.flow1 > maxFlowRange) {
          saveContinue = true;
          errors.message =
            this.$t("El campo caudal") +
            " " +
            this.$t("Sector") +
            " 1 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minFlowRange +
            "-" +
            maxFlowRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.flow2 !== 0 && this.flow2 !== "") {
        if (this.flow2 < minFlowRange || this.flow2 > maxFlowRange) {
          saveContinue = true;
          errors.message =
            this.$t("El campo caudal") +
            " " +
            this.$t("Sector") +
            " 2 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minFlowRange +
            "-" +
            maxFlowRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.flow3 !== 0 && this.flow3 !== "") {
        if (this.flow3 < minFlowRange || this.flow3 > maxFlowRange) {
          saveContinue = true;
          errors.message =
            this.$t("El campo caudal") +
            " " +
            this.$t("Sector") +
            " 3 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minFlowRange +
            "-" +
            maxFlowRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.flow4 !== 0 && this.flow4 !== "") {
        if (this.flow4 < minFlowRange || this.flow4 > maxFlowRange) {
          saveContinue = true;
          errors.message =
            this.$t("El campo caudal") +
            " " +
            this.$t("Sector") +
            " 4 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minFlowRange +
            "-" +
            maxFlowRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.flow5 !== 0 && this.flow5 !== "") {
        if (this.flow5 < minFlowRange || this.flow5 > maxFlowRange) {
          saveContinue = true;
          errors.message =
            this.$t("El campo caudal") +
            " " +
            this.$t("Sector") +
            " 5 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minFlowRange +
            "-" +
            maxFlowRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.flow6 !== 0 && this.flow6 !== "") {
        if (this.flow6 < minFlowRange || this.flow6 > maxFlowRange) {
          saveContinue = true;
          errors.message =
            this.$t("El campo caudal") +
            " " +
            this.$t("Sector") +
            " 6 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minFlowRange +
            "-" +
            maxFlowRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.flow7 !== 0 && this.flow7 !== "") {
        if (this.flow7 < minFlowRange || this.flow7 > maxFlowRange) {
          saveContinue = true;
          errors.message =
            this.$t("El campo caudal") +
            " " +
            this.$t("Sector") +
            " 7 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minFlowRange +
            "-" +
            maxFlowRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.flow8 !== 0 && this.flow8 !== "") {
        if (this.flow8 < minFlowRange || this.flow8 > maxFlowRange) {
          saveContinue = true;
          errors.message =
            this.$t("El campo caudal") +
            " " +
            this.$t("Sector") +
            " 8 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minFlowRange +
            "-" +
            maxFlowRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.entryPressure1 !== 0 && this.entryPressure1 !== "") {
        if (
          this.entryPressure1 < minPresureRange ||
          this.entryPressure1 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 1 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.entryPressure2 !== 0 && this.entryPressure2 !== "") {
        if (
          this.entryPressure2 < minPresureRange ||
          this.entryPressure2 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 2 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.entryPressure3 !== 0 && this.entryPressure3 !== "") {
        if (
          this.entryPressure3 < minPresureRange ||
          this.entryPressure3 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 3 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.entryPressure4 !== 0 && this.entryPressure4 !== "") {
        if (
          this.entryPressure4 < minPresureRange ||
          this.entryPressure4 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 4 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.entryPressure5 !== 0 && this.entryPressure5 !== "") {
        if (
          this.entryPressure5 < minPresureRange ||
          this.entryPressure5 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 5 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.entryPressure6 !== 0 && this.entryPressure6 !== "") {
        if (
          this.entryPressure6 < minPresureRange ||
          this.entryPressure6 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 6 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.entryPressure7 !== 0 && this.entryPressure7 !== "") {
        if (
          this.entryPressure7 < minPresureRange ||
          this.entryPressure7 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 7 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.entryPressure8 !== 0 && this.entryPressure8 !== "") {
        if (
          this.entryPressure8 < minPresureRange ||
          this.entryPressure8 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 8 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.exitPressure1 !== 0 && this.exitPressure1 !== "") {
        if (
          this.exitPressure1 < minPresureRange ||
          this.exitPressure1 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 1 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.exitPressure2 !== 0 && this.exitPressure2 !== "") {
        if (
          this.exitPressure2 < minPresureRange ||
          this.exitPressure2 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 2 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.exitPressure3 !== 0 && this.exitPressure3 !== "") {
        if (
          this.exitPressure3 < minPresureRange ||
          this.exitPressure3 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 3 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.exitPressure4 !== 0 && this.exitPressure4 !== "") {
        if (
          this.exitPressure4 < minPresureRange ||
          this.exitPressure4 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 4 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.exitPressure5 !== 0 && this.exitPressure5 !== "") {
        if (
          this.exitPressure5 < minPresureRange ||
          this.exitPressure5 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 5 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.exitPressure6 !== 0 && this.exitPressure6 !== "") {
        if (
          this.exitPressure6 < minPresureRange ||
          this.exitPressure6 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 6 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.exitPressure7 !== 0 && this.exitPressure7 !== "") {
        if (
          this.exitPressure7 < minPresureRange ||
          this.exitPressure7 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 7 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      if (this.exitPressure8 !== 0 && this.exitPressure8 !== "") {
        if (
          this.exitPressure8 < minPresureRange ||
          this.exitPressure8 > maxPresureRange
        ) {
          saveContinue = true;
          errors.message =
            this.$t("El campo presión") +
            " " +
            this.$t("Sector") +
            " 8 " +
            this.$t("Debe estar entre los limites") +
            " " +
            minPresureRange +
            "-" +
            maxPresureRange;
          listErrors.push(errors);
          errors = {
            message: "",
          };
        }
      }

      let totalFlow =
        this.flow1 +
        this.flow2 +
        this.flow3 +
        this.flow4 +
        this.flow5 +
        this.flow6 +
        this.flow7 +
        this.flow8;
      let totalEntryPressure =
        this.entryPressure1 +
        this.entryPressure2 +
        this.entryPressure3 +
        this.entryPressure4 +
        this.entryPressure5 +
        this.entryPressure6 +
        this.entryPressure7 +
        this.entryPressure8;
      let totalExitPressure =
        this.exitPressure1 +
        this.exitPressure2 +
        this.exitPressure3 +
        this.exitPressure4 +
        this.exitPressure5 +
        this.exitPressure6 +
        this.exitPressure7 +
        this.exitPressure8;
      let totalSurface =
        this.surface1 +
        this.surface2 +
        this.surface3 +
        this.surface4 +
        this.surface5 +
        this.surface6 +
        this.surface7 +
        this.surface8;

      if (
        totalFlow === 0 ||
        totalFlow === "" ||
        totalEntryPressure === 0 ||
        totalEntryPressure === "" ||
        totalExitPressure === 0 ||
        totalExitPressure === "" ||
        totalSurface === 0 ||
        totalSurface === ""
      ) {
        saveContinue = true;
        errors.message = this.$t(
          "Debe introducir los datos de al menos un sector: superficie, caudal, presión de entrada y salida"
        );
        listErrors.push(errors);
        errors = {
          message: "",
        };
      }

      if (
        (this.flow1 !== "" &&
          (this.entryPressure1 === "" ||
            this.exitPressure1 === "" ||
            this.surface1 === "")) ||
        (this.entryPressure1 !== "" &&
          (this.exitPressure1 === "" ||
            this.surface1 === "" ||
            this.flow1 === "")) ||
        (this.exitPressure1 !== "" &&
          (this.surface1 === "" ||
            this.flow1 === "" ||
            this.entryPressure1 === "")) ||
        (this.surface1 !== "" &&
          (this.flow1 === "" ||
            this.entryPressure1 === "" ||
            this.exitPressure1 === "")) ||
        (this.flow2 !== "" &&
          (this.entryPressure2 === "" ||
            this.exitPressure2 === "" ||
            this.surface2 === "")) ||
        (this.entryPressure2 !== "" &&
          (this.exitPressure2 === "" ||
            this.surface2 === "" ||
            this.flow2 === "")) ||
        (this.exitPressure2 !== "" &&
          (this.surface2 === "" ||
            this.flow2 === "" ||
            this.entryPressure2 === "")) ||
        (this.surface2 !== "" &&
          (this.flow2 === "" ||
            this.entryPressure2 === "" ||
            this.exitPressure2 === "")) ||
        (this.flow3 !== "" &&
          (this.entryPressure3 === "" ||
            this.exitPressure3 === "" ||
            this.surface3 === "")) ||
        (this.entryPressure3 !== "" &&
          (this.exitPressure3 === "" ||
            this.surface3 === "" ||
            this.flow3 === "")) ||
        (this.exitPressure3 !== "" &&
          (this.surface3 === "" ||
            this.flow3 === "" ||
            this.entryPressure3 === "")) ||
        (this.surface3 !== "" &&
          (this.flow3 === "" ||
            this.entryPressure3 === "" ||
            this.exitPressure3 === "")) ||
        (this.flow4 !== "" &&
          (this.entryPressure4 === "" ||
            this.exitPressure4 === "" ||
            this.surface4 === "")) ||
        (this.entryPressure4 !== "" &&
          (this.exitPressure4 === "" ||
            this.surface4 === "" ||
            this.flow4 === "")) ||
        (this.exitPressure4 !== "" &&
          (this.surface4 === "" ||
            this.flow4 === "" ||
            this.entryPressure4 === "")) ||
        (this.surface4 !== "" &&
          (this.flow4 === "" ||
            this.entryPressure4 === "" ||
            this.exitPressure4 === "")) ||
        (this.flow5 !== "" &&
          (this.entryPressure5 === "" ||
            this.exitPressure5 === "" ||
            this.surface5 === "")) ||
        (this.entryPressure5 !== "" &&
          (this.exitPressure5 === "" ||
            this.surface5 === "" ||
            this.flow5 === "")) ||
        (this.exitPressure5 !== "" &&
          (this.surface5 === "" ||
            this.flow5 === "" ||
            this.entryPressure5 === "")) ||
        (this.surface5 !== "" &&
          (this.flow5 === "" ||
            this.entryPressure5 === "" ||
            this.exitPressure5 === "")) ||
        (this.flow6 !== "" &&
          (this.entryPressure6 === "" ||
            this.exitPressure6 === "" ||
            this.surface6 === "")) ||
        (this.entryPressure6 !== "" &&
          (this.exitPressure6 === "" ||
            this.surface6 === "" ||
            this.flow6 === "")) ||
        (this.exitPressure6 !== "" &&
          (this.surface6 === "" ||
            this.flow6 === "" ||
            this.entryPressure6 === "")) ||
        (this.surface6 !== "" &&
          (this.flow6 === "" ||
            this.entryPressure6 === "" ||
            this.exitPressure6 === "")) ||
        (this.flow7 !== "" &&
          (this.entryPressure7 === "" ||
            this.exitPressure7 === "" ||
            this.surface7 === "")) ||
        (this.entryPressure7 !== "" &&
          (this.exitPressure7 === "" ||
            this.surface7 === "" ||
            this.flow7 === "")) ||
        (this.exitPressure7 !== "" &&
          (this.surface7 === "" ||
            this.flow7 === "" ||
            this.entryPressure7 === "")) ||
        (this.surface7 !== "" &&
          (this.flow7 === "" ||
            this.entryPressure7 === "" ||
            this.exitPressure7 === "")) ||
        (this.flow8 !== "" &&
          (this.entryPressure8 === "" ||
            this.exitPressure8 === "" ||
            this.surface8 === "")) ||
        (this.entryPressure8 !== "" &&
          (this.exitPressure8 === "" ||
            this.surface8 === "" ||
            this.flow8 === "")) ||
        (this.exitPressure8 !== "" &&
          (this.surface8 === "" ||
            this.flow8 === "" ||
            this.entryPressure8 === "")) ||
        (this.surface8 !== "" &&
          (this.flow8 === "" ||
            this.entryPressure8 === "" ||
            this.exitPressure8 === ""))
      ) {
        saveContinue = true;
        errors.message = this.$t("Debe completar los datos de los sectores");
        listErrors.push(errors);
        errors = {
          message: "",
        };
      }

      if (saveContinue) {
        this.errorForm = true;
        this.messageErrors = listErrors;
      } else {
        this.messageErrors = [];
        this.saveDetail();
      }
    },
    saveDetail() {
      let _this = this;

      let refTreatment = db.collection("treatments").doc(_this.treatment.uid);

      refTreatment
        .update({
          flow1: !isNaN(parseFloat(_this.flow1)) ? parseFloat(_this.flow1) : "",
          flow2: !isNaN(parseFloat(_this.flow2)) ? parseFloat(_this.flow2) : "",
          flow3: !isNaN(parseFloat(_this.flow3)) ? parseFloat(_this.flow3) : "",
          flow4: !isNaN(parseFloat(_this.flow4)) ? parseFloat(_this.flow4) : "",
          flow5: !isNaN(parseFloat(_this.flow5)) ? parseFloat(_this.flow5) : "",
          flow6: !isNaN(parseFloat(_this.flow6)) ? parseFloat(_this.flow6) : "",
          flow7: !isNaN(parseFloat(_this.flow7)) ? parseFloat(_this.flow7) : "",
          flow8: !isNaN(parseFloat(_this.flow8)) ? parseFloat(_this.flow8) : "",
          entryPressure1: !isNaN(parseFloat(_this.entryPressure1))
            ? parseFloat(_this.entryPressure1)
            : "",
          entryPressure2: !isNaN(parseFloat(_this.entryPressure2))
            ? parseFloat(_this.entryPressure2)
            : "",
          entryPressure3: !isNaN(parseFloat(_this.entryPressure3))
            ? parseFloat(_this.entryPressure3)
            : "",
          entryPressure4: !isNaN(parseFloat(_this.entryPressure4))
            ? parseFloat(_this.entryPressure4)
            : "",
          entryPressure5: !isNaN(parseFloat(_this.entryPressure5))
            ? parseFloat(_this.entryPressure5)
            : "",
          entryPressure6: !isNaN(parseFloat(_this.entryPressure6))
            ? parseFloat(_this.entryPressure6)
            : "",
          entryPressure7: !isNaN(parseFloat(_this.entryPressure7))
            ? parseFloat(_this.entryPressure7)
            : "",
          entryPressure8: !isNaN(parseFloat(_this.entryPressure8))
            ? parseFloat(_this.entryPressure8)
            : "",
          exitPressure1: !isNaN(parseFloat(_this.exitPressure1))
            ? parseFloat(_this.exitPressure1)
            : "",
          exitPressure2: !isNaN(parseFloat(_this.exitPressure2))
            ? parseFloat(_this.exitPressure2)
            : "",
          exitPressure3: !isNaN(parseFloat(_this.exitPressure3))
            ? parseFloat(_this.exitPressure3)
            : "",
          exitPressure4: !isNaN(parseFloat(_this.exitPressure4))
            ? parseFloat(_this.exitPressure4)
            : "",
          exitPressure5: !isNaN(parseFloat(_this.exitPressure5))
            ? parseFloat(_this.exitPressure5)
            : "",
          exitPressure6: !isNaN(parseFloat(_this.exitPressure6))
            ? parseFloat(_this.exitPressure6)
            : "",
          exitPressure7: !isNaN(parseFloat(_this.exitPressure7))
            ? parseFloat(_this.exitPressure7)
            : "",
          exitPressure8: !isNaN(parseFloat(_this.exitPressure8))
            ? parseFloat(_this.exitPressure8)
            : "",
          surface1: !isNaN(parseFloat(_this.surface1))
            ? parseFloat(_this.surface1)
            : "",
          surface2: !isNaN(parseFloat(_this.surface2))
            ? parseFloat(_this.surface2)
            : "",
          surface3: !isNaN(parseFloat(_this.surface3))
            ? parseFloat(_this.surface3)
            : "",
          surface4: !isNaN(parseFloat(_this.surface4))
            ? parseFloat(_this.surface4)
            : "",
          surface5: !isNaN(parseFloat(_this.surface5))
            ? parseFloat(_this.surface5)
            : "",
          surface6: !isNaN(parseFloat(_this.surface6))
            ? parseFloat(_this.surface6)
            : "",
          surface7: !isNaN(parseFloat(_this.surface7))
            ? parseFloat(_this.surface7)
            : "",
          surface8: !isNaN(parseFloat(_this.surface8))
            ? parseFloat(_this.surface8)
            : "",
          hourMachineInit: _this.hourMachineInit,
          hourMachineFinish: _this.hourMachineFinish,
          totalMachinaHours: _this.totalMachinaHours,
          hourGroupInit: _this.hourGroupInit,
          hourGroupFinish: _this.hourGroupFinish,
          totalGroupHours: _this.totalGroupHours,
          realTreatmentDate: _this.realTreatmentDate,
          observation: _this.observation,
          phRegulationStatus: _this.phRegulationStatus,
        })
        .then(() => {
          this.$emit("close");
          this.$root.$emit("reloadDetails");
        })
        .catch(function (error) {
          _this.errorForm = true;
          let errors = {
            message: error.message,
          };
          _this.messageErrors.push(errors);
          console.log(
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
              error.message
          );
        });
    },
  },
  beforeMount() {
    this.treatment = this.params.treatment;
    this.assignData();
  },
};

export default ApplicationDetailAddDetailModal;
</script>

<style>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
