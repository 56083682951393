<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Fincas e inspecciones") }}
    </div>

    <loader v-if="datosCargados === false"></loader>

    <div v-if="datosCargados === true">
      <div>
        <div class="sm:flex">
          <div class="block relative mr-4 w-full sm:w-64 mb-3 sm:mb-0">
            <label>
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                @change="filtrarRegistros"
                v-model="mostrar"
              >
                <option value="Todos">
                  {{ $t("Todas las delegaciones") }}
                </option>
                <option
                  v-for="delegacion in listado_delegaciones"
                  v-bind:key="delegacion.id"
                  :value="delegacion.id"
                >
                  {{ delegacion.name }}
                </option>
              </select>
            </label>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>

          <div class="flex items-center">
            <label>
              <input
                class="
                  w-40
                  appearance-none
                  block
                  bg-white
                  text-gray-700
                  border border-red-500
                  rounded
                  py-3
                  px-4
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                :placeholder="this.$t('Buscar')"
                v-model="buscar"
              />
            </label>

            <button
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              v-if="
                usuarioRol === 'comTechnicians' || usuarioRol === 'distributors'
              "
              type="button"
              @click="agregarRegistro"
            >
              {{ $t("Agregar finca") }}
            </button>
          </div>

          <vue-excel-xlsx
            class="
              bg-yellow
              ml-4
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-3
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            :data="exportExcel"
            :columns="columns"
            filename="fincas"
            sheetname="fincas"
          >
            {{ $t("Exportar listado") }}
          </vue-excel-xlsx>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Comercial") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Cliente") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Finca") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Provincia") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Ciudad") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Delegación") }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="finca in fincasFiltrados" v-bind:key="finca.id">
                  <td class="px-6 py-4 whitespace-no-wrap">
                    {{ finca.comercial }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    {{ finca.cliente }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div
                      v-on:click="editarRegistro(finca.id, finca.customerID)"
                    >
                      <span
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ finca.nombre }}</span
                      >
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    {{ finca.province }}
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">{{ finca.city }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    {{ finca.delegacion }}
                  </td>
                </tr>

                <tr v-if="fincas.length === 0">
                  <td class="border px-4 py-2" colspan="4">
                    {{ $t("No hay fincas registradas en el sistema") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import FincasAddPage from "@/pages/fincas/FincasAddPage";
import FincasEditPage from "@/pages/fincas/FincasEditPage";

const Fincas = {
  layout: "layout",
  name: "Fincas",
  metaInfo: {
    title: "Fincas e inspecciones",
  },
  components: {
    Zondicon,
    Loader,
  },
  data() {
    return {
      datosCargados: false,
      fincas: [],
      mostrar: "Todos",
      buscar: "",

      usuarioRol: "",
      usuarioActual: "",

      listado_delegaciones: [],
      listado_distribuidores: [],
      listado_tecnicos: [],
      columns: [
        {
          label: this.$t("Comercial"),
          field: "comercial",
        },
        {
          label: this.$t("Cliente"),
          field: "cliente",
        },
        {
          label: this.$t("Finca"),
          field: "nombre",
        },
        {
          label: this.$t("Provincia"),
          field: "province",
        },
        {
          label: this.$t("Ciudad"),
          field: "city",
        },
        {
          label: this.$t("Delegación"),
          field: "delegacion",
        },
      ],
    };
  },
  methods: {
    filtrarRegistros() {
      this.datosCargados = false;
      this.buscar = "";

      this.fincas = [];

      let _this = this;

      if (this.mostrar === "Todos") {
        this.datosCargados = false;

        db.collection("customers")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              let puede_continuar = false;

              if (
                _this.usuarioRol === "appSuperAdministrators" ||
                _this.usuarioRol === "appAdministrators" ||
                _this.usuarioRol === "comTechniciansAdmin"
              ) {
                puede_continuar = true;
              }

              if (_this.usuarioRol === "comTechniciansResp") {
                for (const x in _this.listado_tecnicos) {
                  if (
                    _this.listado_tecnicos[x].responsableId ===
                    _this.usuarioActual
                  ) {
                    // if (_this.listado_tecnicos[x].uid === doc.data().comTechnicianId) {
                    puede_continuar = true;
                    // }

                    break;
                  }
                }
              }

              if (_this.usuarioRol === "comTechnicians") {
                if (_this.usuarioActual === doc.data().comTechnicianId) {
                  puede_continuar = true;
                }
              }

              if (
                _this.usuarioRol === "distributors" &&
                _this.usuarioActual === doc.data().distributorId
              ) {
                puede_continuar = true;
              }

              if (puede_continuar === true) {
                db.collection("customers")
                  .doc(doc.id)
                  .collection("farms")
                  .where("deleted", "==", false)
                  .get()
                  .then((querySnapshot2) => {
                    querySnapshot2.forEach(function (doc2) {
                      /*
                                                    if(doc2.data().deleted === undefined){
                                                        db.collection('customers').doc(doc2.data().customerID).collection('farms')
                                                        .doc(doc2.id)
                                                        .update({
                                                            deleted: false,
                                                        })
                                                        .then(() => {

                                                        })

                                                        console.log("Finca Arreglada: " + doc2.data().name);
                                                    }
                                                    */

                      let arrayTemp = [];
                      arrayTemp["id"] = doc2.id;
                      arrayTemp["nombre"] = doc2.data().name;
                      arrayTemp["comercial"] = doc2.data().comTechnicianName;
                      arrayTemp["cliente"] = doc.data().name;
                      arrayTemp["province"] = doc2.data().province;
                      arrayTemp["city"] = doc2.data().city;
                      arrayTemp["customerID"] = doc2.data().customerID;
                      arrayTemp["delegacion"] = doc2.data().delegationName;

                      _this.fincas.push(arrayTemp);
                    });
                  });
              }
            });

            this.datosCargados = true;
          });
      } else {
        this.datosCargados = false;

        let _this = this;
        db.collection("customers")
          .where("deleted", "==", false)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              if (
                _this.usuarioRol === "appSuperAdministrators" ||
                _this.usuarioRol === "appAdministrators" ||
                _this.usuarioRol === "comTechniciansAdmin" ||
                (_this.usuarioRol === "comTechnicians" &&
                  _this.usuarioActual === doc.data().comTechnicianId) ||
                (_this.usuarioRol === "distributors" &&
                  _this.usuarioActual === doc.data().distributorId)
              ) {
                db.collection("customers")
                  .doc(doc.id)
                  .collection("farms")
                  .where("delegationId", "==", _this.mostrar)
                  .where("deleted", "==", false)
                  .get()
                  .then((querySnapshot2) => {
                    querySnapshot2.forEach(function (doc2) {
                      let arrayTemp = [];
                      arrayTemp["id"] = doc2.id;
                      arrayTemp["nombre"] = doc2.data().name;
                      arrayTemp["comercial"] = doc2.data().comTechnicianName;
                      arrayTemp["cliente"] = doc.data().name;
                      arrayTemp["province"] = doc2.data().province;
                      arrayTemp["city"] = doc2.data().city;
                      arrayTemp["customerID"] = doc2.data().customerID;
                      arrayTemp["delegacion"] = doc2.data().delegationName;

                      _this.fincas.push(arrayTemp);
                    });
                  });
              }
            });

            this.datosCargados = true;
          });
      }
    },
    agregarRegistro() {
      this.$modal.show(FincasAddPage, {
        title: this.$t("Agregar finca"),
        params: null,
      });
    },
    editarRegistro(id, customer) {
      this.$modal.show(FincasEditPage, {
        title: this.$t("Editar finca"),
        params: {
          id: id,
          customer: customer,
        },
      });
    },
  },
  computed: {
    exportExcel() {
      return this.fincasFiltrados;
    },
    fincasFiltrados() {
      let arrayFiltrado = [];
      this.fincas.forEach((element) => {
        if (this.buscar === "") {
          arrayFiltrado.push(element);
        } else {
          if (
            element.nombre
              .toString()
              .toLowerCase()
              .indexOf(this.buscar.toLowerCase()) !== -1 ||
            element.comercial
              .toString()
              .toLowerCase()
              .indexOf(this.buscar.toLowerCase()) !== -1 ||
            element.cliente
              .toString()
              .toLowerCase()
              .indexOf(this.buscar.toLowerCase()) !== -1
          ) {
            arrayFiltrado.push(element);
          }
        }
      });

      // PARA ORDENAR EL ARREGLO DE USUARIOS
      arrayFiltrado = arrayFiltrado.sort(this.compareName);

      return arrayFiltrado;
    },
    techniciansFilteredByCurrentUser() {
      return this.listado_tecnicos.filter((technical) => {
        if (technical.responsableId === this.usuarioActual) {
          return technical;
        }
      });
    },
  },
  created() {
    this.$root.$on("reloadListFarms", () => {
      this.filtrarRegistros();
    });
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.usuarioRol = sessionStorage.getItem("user_rol");
    this.usuarioActual = sessionStorage.getItem("user_id");

    let _this = this;

    db.collection("delegations")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_delegaciones.push(arrayTemp);
        });
      });

    db.collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_distribuidores.push(arrayTemp);
        });
      });

    db.collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_tecnicos.push(arrayTemp);
        });
      });

    this.filtrarRegistros();
  },
};

export default Fincas;
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: #bbcccc29;
}
</style>
