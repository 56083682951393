<template>
  <div
    class="w-full"
    v-if="
      userRol === 'appSuperAdministrators' ||
      userRol === 'appAdministrators' ||
      userRol === 'comTechniciansAdmin' ||
      userEmail === 'rcomercial@agrozono.net'
    "
  >
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Protocolos") }}
    </div>

    <loader v-if="dataLoaded === false"></loader>

    <div v-if="dataLoaded === true">
      <div>
        <div
          class="flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-4"
        >
          <div
            class="
              flex flex-col
              space-y-4
              md:space-y-0 md:space-x-4 md:flex-row
            "
          >
            <div class="block relative w-full md:w-64">
              <label>
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-gray-400
                    rounded
                    hover:border-gray-500
                    px-4
                    py-3
                    pr-4
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  @change="getProtocols"
                  v-model="filterStatus"
                >
                  <option value="all">{{ $t("Todos los estados") }}</option>
                  <option value="active">{{ $t("Activo") }}</option>
                  <option value="inactive">{{ $t("Inactivo") }}</option>
                  <option value="pending">{{ $t("Pendiente") }}</option>
                </select>
              </label>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
            <div class="block relative w-full md:w-64">
              <label>
                <select
                  class="
                    block
                    appearance-none
                    w-full
                    border border-gray-400
                    rounded
                    px-4
                    py-3
                    pr-4
                    leading-tight
                  "
                  :class="
                    filterStatus !== 'active'
                      ? 'text-white bg-gray-400 cursor-not-allowed'
                      : 'text-black bg-white hover:border-gray-500 focus:outline-none focus:shadow-outline'
                  "
                  v-model="filterCommercial"
                  :disabled="filterStatus !== 'active'"
                >
                  <option value="all">{{ $t("Todos los comerciales") }}</option>
                  <option
                    v-for="commercial in commercials"
                    v-bind:key="commercial.uid"
                    :value="commercial.uid"
                  >
                    {{ commercial.name }}
                  </option>
                </select>
              </label>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            class="
              flex flex-col
              space-y-4
              md:space-y-0 md:space-x-4 md:flex-row
            "
          >
            <input
              class="
                w-full
                md:w-40
                appearance-none
                block
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none focus:bg-white
              "
              type="text"
              :placeholder="this.$t('Buscar')"
              v-model="search"
            />
            <button
              class="
                bg-yellow
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              type="button"
              @click="exportProtocols"
            >
              {{ $t("Importar protocolos") }}
            </button>
            <vue-excel-xlsx
              class="
                text-sm
                font-semibold
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
                bg-white
                hover:bg-greenlight
                text-greenlight
                hover:text-white
                border border-greenlight
              "
              :data="exportExcel"
              :columns="columnsExcel"
              filename="protocolos"
              sheetname="protocolos"
            >
              {{ $t("Exportar protocolos") }}
            </vue-excel-xlsx>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  ></th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Nombre") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Estado") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Uso") }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="protocol in filteredProtocols"
                  v-bind:key="protocol.uid"
                >
                  <td class="px-6 py-4 whitespace-no-wrap w-10">
                    <input
                      type="checkbox"
                      class="w-6 h-6"
                      @change="changeProtocol"
                      v-model="commercialProtocols[protocol.uid]"
                      :disabled="filterCommercial === 'all'"
                      :class="
                        filterCommercial !== 'all'
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      "
                    />
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div v-on:click="editProtocol(protocol.uid)">
                      <span
                        v-if="
                          langStorage === 'es' ||
                          (langStorage === 'it' && !protocol.nameIt) ||
                          (langStorage === 'fr' && !protocol.nameFr)
                        "
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ protocol.name }}
                      </span>
                      <span
                        v-if="langStorage === 'it'"
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ protocol.nameIt }}
                      </span>
                      <span
                        v-if="langStorage === 'fr'"
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ protocol.nameFr }}
                      </span>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    <div v-if="protocol.status === 'active'">
                      {{ $t("Activo") }}
                    </div>
                    <div v-if="protocol.status === 'inactive'">
                      {{ $t("Inactivo") }}
                    </div>
                    <div v-if="protocol.status === 'pending'">
                      {{ $t("Pendiente") }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap w-48">
                    <div v-if="protocol.use === 'in_use'">
                      {{ $t("En uso") }}
                    </div>
                    <div v-if="protocol.use === 'discontinuated'">
                      {{ $t("Descontinuado") }}
                    </div>
                  </td>
                </tr>

                <tr v-if="protocols.length === 0">
                  <td class="border px-4 py-2" colspan="4">
                    {{ $t("No hay protocolos registrados en el sistema") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import ProtocolsImportModal from "@/pages/protocolos/ProtocolsImportModal";
import ProtocolosEditPage from "@/pages/protocolos/ProtocolosEditPage";
import { orderBy } from "lodash";

export default {
  layout: "layout",
  name: "Protocols",
  metaInfo: {
    title: "Protocolos",
  },
  components: {
    Loader,
  },
  data() {
    return {
      commercialProtocols: {},
      dataLoaded: false,
      protocols: [],
      filterStatus: "all",
      filterCommercial: "all",
      search: "",
      userRol: sessionStorage.getItem("user_rol"),
      userEmail: sessionStorage.getItem("user"),
      langStorage: localStorage.user_locale,
      columnsExcel: [
        {
          label:
            this.$t("Estado").toUpperCase() +
            "(" +
            this.$t("Sistema").toUpperCase() +
            ")",
          field: "status",
        },
        {
          label:
            this.$t("Id protocolo").toUpperCase() +
            "(" +
            this.$t("Sistema").toUpperCase() +
            ")",
          field: "idProtocol",
        },
        {
          label:
            this.$t("Versión protocolo").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "version",
        },
        {
          label:
            this.$t("Fecha creación").toUpperCase() +
            "(" +
            this.$t("Sistema").toUpperCase() +
            ")",
          field: "dateCreation",
        },
        {
          label:
            this.$t("Fecha descontinuado").toUpperCase() +
            "(" +
            this.$t("Sistema").toUpperCase() +
            ")",
          field: "dateDiscontinuated",
        },
        {
          label:
            this.$t("Nombre protocolo").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "nameES",
        },
        {
          label:
            this.$t("Nombre protocolo francés").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "nameFR",
        },
        {
          label:
            this.$t("Nombre protocolo italiano").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "nameIT",
        },
        {
          label:
            this.$t("Id recordatorio").toUpperCase() +
            "(" +
            this.$t("Sistema").toUpperCase() +
            ")",
          field: "idReminder",
        },
        {
          label:
            this.$t("Dias a origen").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "days",
        },
        {
          label:
            this.$t("Agro3-System1").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys1",
        },
        {
          label:
            this.$t("Agro3-System2").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys2",
        },
        {
          label:
            this.$t("Agro3-System2_1").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys2_1",
        },
        {
          label:
            this.$t("Agro3-System3").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys3",
        },
        {
          label:
            this.$t("Agro3-System4").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys4",
        },
        {
          label:
            this.$t("Agro3-System5").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys5",
        },
        {
          label:
            this.$t("Agro3-System6").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys6",
        },
        {
          label:
            this.$t("Agro3-System6_1").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys6_1",
        },
        {
          label:
            this.$t("Agro3-System7").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sys7",
        },
        {
          label:
            this.$t("Agro3-SystemBasic").toUpperCase() +
            "(" +
            this.$t("Agrozono").toUpperCase() +
            ")",
          field: "sysBasic",
        },
      ],
      commercials: [],
    };
  },
  watch: {
    filterCommercial: async function (commercialId) {
      this.commercialProtocols = {};
      if (commercialId !== "all") {
        await db
          .collection("comTechnicians")
          .doc(commercialId)
          .get()
          .then((snapshot) => {
            if (snapshot.data().protocols) {
              let protocol = {};
              snapshot.data().protocols.forEach((element) => {
                protocol[element] = true;
              });
              this.commercialProtocols = protocol;
            }
          });
      }
    },
  },
  computed: {
    exportExcel() {
      let protocols = [];
      this.filteredProtocols.forEach((element) => {
        element.reminders.forEach((item) => {
          let protocol = {};
          let status = "activo";
          if (element.status === "inactive") {
            status = "inactivo";
          }

          if (element.status === "pending") {
            status = "pendiente";
          }

          protocol["status"] = status;
          protocol["idProtocol"] = parseInt(element.idAgro);
          protocol["version"] = element.version;
          protocol["dateCreation"] = element.date_creation;
          protocol["dateDiscontinuated"] = element.date_discontinuated;
          protocol["nameES"] = element.name;
          protocol["nameFR"] = element.nameFr;
          protocol["nameIT"] = element.nameIt;
          protocol["idReminder"] = parseInt(item.idAgro);
          protocol["days"] = parseInt(item.repetitionInDays);
          protocol["sys1"] = item.sys1;
          protocol["sys2"] = item.sys2;
          protocol["sys2_1"] = item.sys2_1;
          protocol["sys3"] = item.sys3;
          protocol["sys4"] = item.sys4;
          protocol["sys5"] = item.sys5;
          protocol["sys6"] = item.sys6;
          protocol["sys6_1"] = item.sys6_1;
          protocol["sys7"] = item.sys7;
          protocol["sysBasic"] = item.sysbas;

          protocols.push(protocol);
        });

        let emptyLine = {};
        protocols.push(emptyLine);
      });

      return protocols;
    },
    filteredProtocols() {
      let filteredProtocols = [];
      this.protocols.forEach((element) => {
        if (this.search === "") {
          filteredProtocols.push(element);
        } else {
          if (this.langStorage === "es") {
            if (
              element.name
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              filteredProtocols.push(element);
            }
          }

          if (this.langStorage === "it") {
            if (
              element.nameIt
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              filteredProtocols.push(element);
            }
          }

          if (this.langStorage === "fr") {
            if (
              element.nameFr
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              filteredProtocols.push(element);
            }
          }
        }
      });

      return filteredProtocols.sort(this.compareLangName);
    },
  },
  methods: {
    changeProtocol() {
      let protocols = [];

      for (let key in this.commercialProtocols) {
        if (this.commercialProtocols[key]) {
          protocols.push(key);
        }
      }

      db.collection("comTechnicians").doc(this.filterCommercial).update({
        protocols: protocols,
      });
    },
    getProtocols() {
      this.dataLoaded = false;
      this.search = "";
      this.filterCommercial = "all";

      this.protocols = [];

      let protocolsDB = db.collection("protocols");
      protocolsDB = protocolsDB.where("deleted", "==", false);

      if (this.filterStatus !== "all")
        protocolsDB = protocolsDB.where("status", "==", this.filterStatus);

      protocolsDB.get().then((querySnapshot) => {
        let _this = this;
        querySnapshot.forEach(function (doc) {
          let protocol = doc.data();
          let protocolReminders = [];

          db.collection("protocolReminders")
            .where("protocolId", "==", protocol.uid)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                protocolReminders.push(doc.data());
              });
              protocol.reminders = orderBy(
                protocolReminders,
                ["repetitionInDays"],
                ["asc"]
              );
              _this.protocols.push(protocol);
            });
        });

        this.dataLoaded = true;
      });
    },
    getCommercials() {
      db.collection("comTechnicians")
        .where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          let _this = this;
          let commercials = [];
          querySnapshot.forEach(function (doc) {
            commercials.push(doc.data());
          });
          _this.commercials = orderBy(commercials, ["name"], ["asc"]);
        });
    },
    editProtocol(id) {
      this.$modal.show(ProtocolosEditPage, {
        title: this.$t("Editar protocolo"),
        params: { id: id },
      });
    },
    exportProtocols() {
      this.$modal.show(ProtocolsImportModal, {
        title: this.$t("Importar protocolos via excel"),
        params: null,
      });
    },
  },
  created() {
    this.$root.$on("reloadProtocolList", () => {
      this.getProtocols();
    });
  },
  beforeMount() {
    if (!Utilities.isLogged()) {
      this.$router.push({ name: "login" });
    }

    this.getProtocols();
    this.getCommercials();
  },
};
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: #bbcccc29;
}
</style>
