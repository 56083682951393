<template>
  <div>
    <header-modal :title="title" @close="$emit('close')"></header-modal>

    <div class="text-2xl font-semibold text-gray-800 p-6">
      <button
        class="
          bg-gray-200
          mr-2
          relative
          border border-gray-200
          text-xs
          font-semibold
          hover:bg-white
          text-gray-700
          pb-1
          px-3
          rounded
          focus:outline-none focus:shadow-outline
        "
        style="top: -2px"
        type="button"
        v-on:click="volverListadoInspecciones"
      >
        <Zondicon
          icon="arrow-thin-left"
          class="
            fill-current
            inline-block
            h-3
            text-gray-700
            cursor-pointer
            hover:text-gray-700
          "
        />
      </button>
      <div
        class="
          inline-block
          uppercase
          tracking-wide
          text-gray-700 text-xs
          font-bold
          mb-4
          px-3
        "
      >
        {{ params.farmName }}
      </div>
    </div>

    <div class="flex flex-row items-center mb-20 xl:mb-4 p-6">
      <form class="w-full">
        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          ></div>

          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="inspectionDate"
            >
              {{ $t("Fecha inspección") }}
            </label>
            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
              id="inspectionDate"
              type="date"
              format="dd/MM/kkkk"
              placeholder="dd/mm/yyyy"
              zone="local"
              value-zone="local"
              v-model="inspectionDate"
            ></datetime>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-5
              px-3
            "
          >
            {{ $t("Datos de la inspección") }}
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="cultivation"
            >
              {{ $t("Tipo de cultivo") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="cultivation"
                v-model="cultivation"
              >
                <option value="">{{ $t("Seleccione una opción") }}</option>
                <option
                  v-for="tipo_cultivo in listado_tipos_cultivos"
                  :value="tipo_cultivo.name"
                  v-bind:key="tipo_cultivo.name"
                >
                  <span
                    v-if="
                      lang === 'es' ||
                      (lang === 'it' && !tipo_cultivo.nameIt) ||
                      (lang === 'fr' && !tipo_cultivo.nameFr)
                    "
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                    >{{ tipo_cultivo.name }}</span
                  >
                  <span
                    v-if="lang === 'it'"
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                    >{{ tipo_cultivo.nameIt }}</span
                  >
                  <span
                    v-if="lang === 'fr'"
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                    >{{ tipo_cultivo.nameFr }}</span
                  >
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="cultivationSubtype"
            >
              {{ $t("Subtipo de cultivo") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="cultivationSubtype"
              type="text"
              :placeholder="this.$t('Subtipo de cultivo')"
              v-model="cultivationSubtype"
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="plantationDate"
            >
              {{ $t("Fecha plantación") }}
            </label>
            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
              id="plantationDate"
              type="date"
              format="dd/MM/kkkk"
              placeholder="dd/mm/yyyy"
              zone="local"
              value-zone="local"
              v-model="plantationDate"
            ></datetime>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="harvest"
            >
              {{ $t("Época de recolección") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="harvest"
                v-model="harvest"
              >
                <option value="">{{ $t("Seleccione una opción") }}</option>
                <option value="Enero">{{ $t("Enero") }}</option>
                <option value="Febrero">{{ $t("Febrero") }}</option>
                <option value="Marzo">{{ $t("Marzo") }}</option>
                <option value="Abril">{{ $t("Abril") }}</option>
                <option value="Mayo">{{ $t("Mayo") }}</option>
                <option value="Junio">{{ $t("Junio") }}</option>
                <option value="Julio">{{ $t("Julio") }}</option>
                <option value="Agosto">{{ $t("Agosto") }}</option>
                <option value="Septiembre">{{ $t("Septiembre") }}</option>
                <option value="Octubre">{{ $t("Octubre") }}</option>
                <option value="Noviembre">{{ $t("Noviembre") }}</option>
                <option value="Diciembre">{{ $t("Diciembre") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sustract"
            >
              {{ $t("Sustrato") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="sustract"
                v-model="sustract"
              >
                <option value="">{{ $t("Seleccione una opción") }}</option>
                <option value="Enciconado">{{ $t("Enciconado") }}</option>
                <option value="Fibra de Coco">{{ $t("Fibra de coco") }}</option>
                <option value="Tierra">{{ $t("Tierra") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="cultivationState"
            >
              {{ $t("Estado de cultivo") }}
            </label>

            <div class="block w-full relative mr-4">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="cultivationState"
                v-model="cultivationState"
              >
                <option value="">{{ $t("Seleccione una opción") }}</option>
                <option value="Brotación">{{ $t("Brotación") }}</option>
                <option value="Fin de Cultivo">
                  {{ $t("Fin de cultivo") }}
                </option>
                <option value="Floración">{{ $t("Floración") }}</option>
                <option value="Fructificación">
                  {{ $t("Fructificación") }}
                </option>
                <option value="Plantación">{{ $t("Plantación") }}</option>
                <option value="Recolección">{{ $t("Recolección") }}</option>
                <option value="Sin Cultivo">{{ $t("Sin cultivo") }}</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-full px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Sectores") }}
            </label>

            <div class="bg-gray-200 pt-6 pb-4">
              <div class="flex flex-wrap">
                <div class="w-full md:w-1/6 px-3"></div>

                <div class="w-full md:w-5/6 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 1
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 2
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 3
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 4
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 5
                    </div>

                    <div class="w-full md:w-1/6 px-3 text-center text-sm">
                      {{ $t("Sector") }} 6
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/6 px-3">
                  {{ $t("Superficie") }} (Ha)
                </div>

                <div class="w-full md:w-5/6 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="surface1"
                          @change="changeProtocolo"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="surface2"
                          @change="changeProtocolo"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="surface3"
                          @change="changeProtocolo"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="surface4"
                          @change="changeProtocolo"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="surface5"
                          @change="changeProtocolo"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Superficie')"
                          v-model="surface6"
                          @change="changeProtocolo"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/6 px-3">
                  {{ $t("Caudal") }} (m3/h)
                </div>

                <div class="w-full md:w-5/6 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="flow1"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="flow2"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="flow3"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="flow4"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="flow5"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Caudal')"
                          v-model="flow6"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full md:w-1/6 px-3">
                  {{ $t("Presión de trabajo") }} (Ha)
                </div>

                <div class="w-full md:w-5/6 px-3">
                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="presure1"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="presure2"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="presure3"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="presure4"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="presure5"
                        />
                      </label>
                    </div>

                    <div class="w-full md:w-1/6 px-2">
                      <label>
                        <input
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-700
                            border border-red-500
                            rounded
                            py-3
                            px-4
                            mb-3
                            leading-tight
                            focus:outline-none
                          "
                          type="text"
                          :placeholder="this.$t('Presión de trabajo')"
                          v-model="presure6"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Agua") }}
          </div>

          <div class="w-full md:w-1/2 px-3 mb-5">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="irrigation"
            >
              {{ $t("Tipo de riego") }}
            </label>

            <div class="block w-full relative mb-4">
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  id="irrigation"
                  type="checkbox"
                  v-model="irrigation"
                  :value="'Aspersión'"
                />
                <span class="text-gray-700 text-xs">
                  {{ $t("Aspersión") }}
                </span>
              </label>
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="irrigation"
                  :value="'Goteo'"
                />
                <span class="text-gray-700 text-xs"> {{ $t("Goteo") }} </span>
              </label>
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="irrigation"
                  :value="'Microaspersión'"
                />
                <span class="text-gray-700 text-xs">
                  {{ $t("Microaspersión") }}
                </span>
              </label>
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="irrigation"
                  :value="'Pivot'"
                />
                <span class="text-gray-700 text-xs"> {{ $t("Pivot") }} </span>
              </label>
            </div>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
            >
              {{ $t("Procedencia del agua") }}
            </label>

            <div class="block w-full relative mb-4">
              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="waterOrigin"
                  :value="'Balsa'"
                />
                <span class="text-gray-700 text-xs"> {{ $t("Balsa") }} </span>
              </label>

              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="waterOrigin"
                  :value="'Comunidad Reg'"
                />
                <span class="text-gray-700 text-xs">
                  {{ $t("Comunidad reg") }}
                </span>
              </label>

              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="waterOrigin"
                  :value="'Desaladora'"
                />
                <span class="text-gray-700 text-xs">
                  {{ $t("Desaladora") }}
                </span>
              </label>

              <label class="md:w-2/3 block cursor-pointer">
                <input
                  class="mr-2 leading-tight"
                  type="checkbox"
                  v-model="waterOrigin"
                  :value="'Pozo'"
                />
                <span class="text-gray-700 text-xs"> {{ $t("Pozo") }} </span>
              </label>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="redox"
            >
              {{ $t("Redox agua") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="redox"
              type="text"
              :placeholder="this.$t('Redox agua')"
              v-model="redox"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="ph"
            >
              {{ $t("Ph agua") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="ph"
              type="text"
              :placeholder="this.$t('Ph agua')"
              v-model="ph"
            />
          </div>

          <div class="w-full md:w-1/3 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="temperature"
            >
              {{ $t("Temperatura agua") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="temperature"
              type="text"
              :placeholder="this.$t('Temperatura agua')"
              v-model="temperature"
            />
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="phRegulator"
            >
              {{ $t("¿Dispone regulador ph agua?") }}
            </label>
            <div class="relative">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="phRegulator"
                v-model="phRegulator"
              >
                <option value="">{{ $t("Seleccione una opción") }}</option>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="connectionDone"
            >
              {{ $t("¿Conexiones realizadas?") }}
            </label>
            <div class="relative">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="connectionDone"
                v-model="connectionDone"
              >
                <option value="">{{ $t("Seleccione una opción") }}</option>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="connectionDate"
            >
              {{ $t("Fecha prevista conexión") }}
            </label>
            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
              id="connectionDate"
              type="date"
              format="dd/MM/kkkk"
              placeholder="dd/mm/yyyy"
              zone="local"
              value-zone="local"
              v-model="connectionDate"
            ></datetime>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-full px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="protocolId"
            >
              {{ $t("Protocolo (superficie total:") }} {{ superficie_total }})
            </label>
            <div class="relative">
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  rounded
                  hover:border-gray-500
                  px-4
                  py-3
                  pr-4
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="protocolId"
                v-model="protocolId"
                @change="changeProtocolo"
              >
                <option value="">{{ $t("Seleccione un protocolo") }}</option>
                <option
                  v-for="protocolo in listProtocols"
                  :value="protocolo.id"
                  v-bind:key="protocolo.id"
                >
                  {{ protocolo.name }}
                </option>
              </select>
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  px-2
                  text-gray-700
                "
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys1"
            >
              {{ $t("System") }} 1 (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys2"
            >
              {{ $t("System") }} 2 (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys2"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys2"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys2_1"
            >
              {{ $t("System") }} 2.1 (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys2_1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys2_1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys3"
            >
              {{ $t("System") }} 3 (Kg)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys3"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys3"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys4"
            >
              {{ $t("System") }} 4 (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys4"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys4"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys5"
            >
              {{ $t("System") }} 5 (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys5"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys5"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys6"
            >
              {{ $t("System") }} 6 (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys6"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys6"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys6_1"
            >
              {{ $t("System") }} 6.1 (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys6_1"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys6_1"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sys7"
            >
              {{ $t("System") }} 7 (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sys7"
              type="text"
              :placeholder="this.$t('System')"
              v-model="sys7"
              readonly
            />
          </div>

          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="sysbas"
            >
              {{ $t("Basic") }} (L)
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="sysbas"
              type="text"
              :placeholder="this.$t('Basic')"
              v-model="sysbas"
              readonly
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full md:w-full px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="observation"
            >
              {{ $t("Otros") }}
            </label>
            <textarea
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="observation"
              type="text"
              :placeholder="this.$t('Otros')"
              v-model="observation"
            ></textarea>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Fechas") }}
          </div>

          <div class="w-full md:w-1/2 px-3">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="ozoneDate"
            >
              {{ $t("Fecha pedida cliente agrozono") }}
            </label>

            <datetime
              input-class="appearance-none block w-full bg-white text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
              id="ozoneDate"
              type="date"
              format="dd/MM/kkkk"
              placeholder="dd/mm/yyyy"
              zone="local"
              value-zone="local"
              v-model="ozoneDate"
            ></datetime>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="agrozonoDate"
            >
              {{ $t("Fecha definitiva agrozono") }}
            </label>

            <input
              class="
                appearance-none
                block
                w-full
                bg-gray-200
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="agrozonoDate"
              type="text"
              placeholder="dd/mm/yyyy"
              v-model="agrozonoDate"
              readonly
            />
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-6">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Contacto") }}
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="contactName"
            >
              {{ $t("Nombre de contacto") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="contactName"
              type="text"
              :placeholder="this.$t('Nombre de contacto')"
              v-model="contactName"
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="contactPosition"
            >
              {{ $t("Cargo de contacto") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="contactPosition"
              type="text"
              :placeholder="this.$t('Cargo de contacto')"
              v-model="contactPosition"
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="contactEmail"
            >
              {{ $t("Email de contacto para seguimiento tratamiento") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="contactEmail"
              type="text"
              :placeholder="
                this.$t('Email de contacto para seguimiento tratamiento')
              "
              v-model="contactEmail"
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="contactPhone"
            >
              {{ $t("Teléfono de contacto") }}
            </label>
            <input
              class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="contactPhone"
              type="text"
              :placeholder="this.$t('Teléfono de contacto')"
              v-model="contactPhone"
            />
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="clientSignaturePath"
            >
              {{ $t("Firma contacto") }}
            </label>
            <textarea
              class="
                hidden
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="clientSignaturePath"
              type="text"
              :placeholder="this.$t('Firma contacto')"
              v-model="clientSignaturePath"
            ></textarea>

            <img
              v-if="clientSignature"
              v-bind:src="clientSignature"
              class="image"
              alt="client signature"
            />

            <VueSignaturePad
              class="border border-gray-400"
              width="100%"
              height="200px"
              ref="signatureClientPad"
              :class="{ hidden: clientSignature !== '' }"
            />

            <div class="text-sm text-gray-500">
              {{ $t(clientSignatureStatus) }}
            </div>

            <div class="text-right" :class="{ hidden: clientSignature !== '' }">
              <button
                class="
                  bg-gray-600
                  mt-2
                  ml-4
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-on:click="clearSignatureClient"
              >
                {{ $t("Limpiar") }}
              </button>

              <button
                class="
                  bg-gray-600
                  mt-2
                  ml-4
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-on:click="saveSignatureClient"
              >
                {{ $t("Terminar firma") }}
              </button>
            </div>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-4">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="technicSignaturePath"
            >
              {{ $t("Firma técnico comercial") }}
            </label>
            <textarea
              class="
                hidden
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="technicSignaturePath"
              type="text"
              :placeholder="this.$t('Firma técnico comercial')"
              v-model="technicSignaturePath"
            ></textarea>

            <img
              v-if="technicSignature"
              v-bind:src="technicSignature"
              class="image"
              alt="technic signature"
            />

            <VueSignaturePad
              class="border border-gray-400"
              width="100%"
              height="200px"
              ref="signatureTechnicPad"
              :class="{ hidden: technicSignature !== '' }"
            />

            <div class="text-sm text-gray-500">
              {{ $t(technicSignatureStatus) }}
            </div>

            <div
              class="text-right"
              :class="{ hidden: technicSignature !== '' }"
            >
              <button
                class="
                  bg-gray-600
                  mt-2
                  ml-4
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-on:click="clearSignatureTechnic"
              >
                {{ $t("Limpiar") }}
              </button>

              <button
                class="
                  bg-gray-600
                  mt-2
                  ml-4
                  text-sm
                  font-semibold
                  hover:bg-greenlight
                  text-white
                  py-2
                  px-4
                  rounded
                  focus:outline-none focus:shadow-outline
                "
                type="button"
                v-on:click="saveSignatureTechnic"
              >
                {{ $t("Terminar firma") }}
              </button>
            </div>
          </div>
        </div>

        <hr class="px-3 mb-6" />

        <div class="flex flex-wrap mb-4">
          <div
            class="
              w-full
              md:w-3/3
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-4
              px-3
            "
          >
            {{ $t("Imagenes") }}
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="image1Path"
            >
              {{ $t("Imagen") }} 1
            </label>
            <textarea
              class="
                hidden
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="image1Path"
              type="text"
              placeholder="Firma Contacto"
              v-model="image1Path"
            ></textarea>

            <p>
              <input
                v-on:change="uploadImage1"
                type="file"
                name="image1"
                accept="image/*"
              />
            </p>
            <img
              v-if="image1"
              v-bind:src="image1"
              class="image"
              alt="image 1"
            />
            <div class="text-sm text-gray-500">{{ $t(image1Status) }}</div>
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="image2Path"
            >
              {{ $t("Imagen") }} 2
            </label>
            <textarea
              class="
                hidden
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border border-red-500
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
              "
              id="image2Path"
              type="text"
              placeholder="Firma Tecnico Comercial"
              v-model="image2Path"
            ></textarea>

            <p>
              <input
                v-on:change="uploadImage2"
                type="file"
                name="image2"
                accept="image/*"
              />
            </p>
            <img
              v-if="image2"
              v-bind:src="image2"
              class="image"
              alt="image 2"
            />
            <div class="text-sm text-gray-500">{{ $t(image2Status) }}</div>
          </div>
        </div>
        <div class="mx-2 mb-4">
          <div
            v-if="mensajeError !== '' || errorMessageSectors.length !== 0"
            class="bg-red text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Ha ocurrido un error") }}</strong>
            <span class="block ml-2">{{ $t(mensajeError) }}</span>
            <div
              v-for="(error, index) in errorMessageSectors"
              v-bind:key="index"
            >
              <span class="block ml-2">{{ error.message }}</span>
            </div>
          </div>

          <div
            v-if="mensajeExito !== ''"
            class="bg-greenlight text-white px-4 py-2 w-full"
          >
            <strong>{{ $t("Excelente") }}</strong>
            <span class="block sm:inline ml-2">{{ $t(mensajeExito) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-end">
          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
              hidden
            "
            type="button"
            v-on:click="submitDatosDespues"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Crear inspección") }}
          </button>

          <button
            class="
              bg-yellow
              mr-2
              text-sm
              font-semibold
              hover:bg-greenlight
              text-white
              py-2
              px-4
              rounded
              focus:outline-none focus:shadow-outline
            "
            type="button"
            v-on:click="submitDatos"
            :class="{ hidden: datosCargados === false }"
          >
            {{ $t("Crear inspección y enviar") }}
          </button>

          <button
            class="
              bg-gray-600
              mr-2
              text-sm
              font-semibold
              text-white
              py-2
              px-4
              rounded
            "
            type="button"
            :class="{ hidden: datosCargados === true }"
          >
            {{ $t("Enviando datos") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db, firebaseGlobal } from "@/plugins/firebase.js";
import { Datetime } from "vue-datetime";
import { DateTime as luxon } from "luxon";
import "vue-datetime/dist/vue-datetime.css";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import { orderBy } from "lodash";
import HeaderModal from "@/components/HeaderModal";
import FincasInspeccionesPage from "@/pages/fincas/FincasInspeccionesPage";

const FincasInspeccionesAdd = {
  name: "FincasInspeccionesAdd",
  layout: "layout",
  components: {
    Zondicon,
    Loader,
    datetime: Datetime,
    HeaderModal,
  },
  data() {
    return {
      datosCargados: true,
      mensajeExito: "",
      mensajeError: "",
      errorMessageSectors: [],

      globalObject: "",
      id: "",

      idCreatedToShare: "",
      asignedTreatment: false,
      canDoInspection: true,
      inspectionDate: "",
      comTechnicianId: "",
      comTechnicianEmail: "",
      comTechnicianName: "",
      connectionDone: "",
      customerId: "",
      customerName: "",
      fincaId: "",
      fincaName: "",
      fincaDistributorAddress: "N/A",
      fincaDistributorCIF: "N/A",
      fincaDistributorName: "N/A",
      idAgro: "",
      lang: "",
      cultivation: "",
      cultivationSubtype: "",
      plantationDate: "",
      harvest: "",
      sustract: "",
      cultivationState: "",
      surface1: "",
      surface2: "",
      surface3: "",
      surface4: "",
      surface5: "",
      surface6: "",
      flow1: "",
      flow2: "",
      flow3: "",
      flow4: "",
      flow5: "",
      flow6: "",
      presure1: "",
      presure2: "",
      presure3: "",
      presure4: "",
      presure5: "",
      presure6: "",
      irrigation: [],
      waterOrigin: [],
      redox: "",
      ph: "",
      temperature: "",
      phRegulator: "",
      conexionesRealizadas: "",
      connectionDate: "",
      protocolId: "",
      protocolName: "",
      protocolNameLocalized: "",
      sys1: "",
      sys2: "",
      sys2_1: "",
      sys3: "",
      sys4: "",
      sys5: "",
      sys6: "",
      sys6_1: "",
      sys7: "",
      sysbas: "",
      observation: "",
      agrozonoDate: "",
      ozoneDate: "",
      contactName: "",
      contactPosition: "",
      contactEmail: "",
      contactPhone: "",
      clientSignature: "",
      customerBase64Signature: "",
      clientSignaturePath: "",
      clientSignatureStatus: "",
      technicSignature: "",
      techBase64Signature: "",
      technicSignaturePath: "",
      technicSignatureStatus: "",
      respTechDelegationId: "",
      image1: "",
      image1Path: "",
      image1Status: "",
      image2: "",
      image2Path: "",
      image2Status: "",
      state: "pending",

      userRol: "",
      userCurrent: "",
      superficie_total: 0,

      listado_tecnicos: [],
      listado_delegations: [],
      listado_distribuidores: [],
      listado_respTechnicians: [],
      listado_clientes: [],
      listado_clientes_filtrados: [],
      listado_fincas: [],
      listado_fincas_filtrados: [],
      listado_protocolos: [],
      listado_tipos_cultivos: [],
    };
  },
  props: {
    title: {
      required: true,
    },
    params: {
      required: true,
    },
  },
  computed: {
    listProtocols() {
      if (this.listado_protocolos && this.listado_protocolos.length > 0) {
        return orderBy(this.listado_protocolos, ["name"], ["asc"]);
      }
    },
  },
  methods: {
    volverListadoInspecciones() {
      this.$emit("close");
      this.$modal.show(FincasInspeccionesPage, {
        title: this.$t("Inspecciones de") + " " + this.params.farmName,
        params: {
          farmId: this.params.farmId,
          farmCustomer: this.params.farmCustomer,
          farmName: this.params.farmName,
        },
      });
    },
    crearRegistroBD() {
      let farmDistributorAddress = "N/A";
      let farmDistributorCIF = "N/A";
      let farmDistributorName = "N/A";

      if (this.fincaDistributorAddress !== undefined)
        farmDistributorAddress = this.fincaDistributorAddress;

      if (this.fincaDistributorCIF !== undefined)
        farmDistributorCIF = this.fincaDistributorCIF;

      if (this.fincaDistributorName !== undefined)
        farmDistributorName = this.fincaDistributorName;

      let data_inspeccion = {
        id: this.id,
        idAgro: "1",

        idCreatedToShare: "ES/46/01/09//1/1/1",
        asignedTreatment: this.asignedTreatment,
        canDoInspection: this.canDoInspection,
        inspectionDate: this.inspectionDate,
        dateCreated: luxon.local().toISO(),
        comTechnicianId: this.comTechnicianId,
        comTechnicianEmail: this.comTechnicianEmail,
        comTechnicianName: this.comTechnicianName,
        connectionDone: eval(this.connectionDone),
        customerId: this.customerId,
        customerName: this.customerName,
        farmId: this.fincaId,
        farmName: this.fincaName,
        farmDistributorAddress: farmDistributorAddress,
        farmDistributorCIF: farmDistributorCIF,
        farmDistributorName: farmDistributorName,
        lang: this.lang,
        cultivation: this.cultivation,
        cultivationState: this.cultivationState,
        cultivationSubtype: this.cultivationSubtype,
        plantationDate: this.plantationDate,
        harvest: this.harvest,
        sustract: this.sustract,

        surface1: this.surface1,
        surface2: this.surface2,
        surface3: this.surface3,
        surface4: this.surface4,
        surface5: this.surface5,
        surface6: this.surface6,
        flow1: this.flow1,
        flow2: this.flow2,
        flow3: this.flow3,
        flow4: this.flow4,
        flow5: this.flow5,
        flow6: this.flow6,
        presure1: this.presure1,
        presure2: this.presure2,
        presure3: this.presure3,
        presure4: this.presure4,
        presure5: this.presure5,
        presure6: this.presure6,
        irrigation: this.irrigation,
        waterOrigin: this.waterOrigin,
        redox: parseFloat(this.redox),
        ph: parseFloat(this.ph),
        temperature: parseFloat(this.temperature),
        phRegulator: eval(this.phRegulator),
        connectionDate: this.connectionDate,
        protocolId: this.protocolId,
        protocolName: this.protocolName,
        protocolNameLocalized: this.protocolNameLocalized,
        observation: this.observation,
        agrozonoDate: this.agrozonoDate,
        ozoneDate: this.ozoneDate,
        contactName: this.contactName,
        contactPosition: this.contactPosition,
        contactEmail: this.contactEmail,
        contactPhone: this.contactPhone,
        clientSignaturePath: this.clientSignaturePath,
        customerBase64Signature: this.customerBase64Signature,
        technicSignaturePath: this.technicSignaturePath,
        techBase64Signature: this.techBase64Signature,
        respTechDelegationId: this.respTechDelegationId,
        image1Path: this.image1Path,
        image2Path: this.image2Path,
        state: this.state,
        deleted: false,
        disabledDate: "N/D",
      };

      this.globalObject
        .set(data_inspeccion)
        .then(() => {
          this.datosCargados = true;
          this.mensajeExito = "La inspección ha sido agregada exitosamente";

          let _this = this;

          this.inspectionDate = "";
          this.canDoInspection = true;
          this.comTechnicianId = "";
          this.comTechnicianName = "";
          this.customerId = "";
          this.customerName = "";
          this.fincaId = "";
          this.fincaName = "";
          this.cultivation = "";
          this.cultivationSubtype = "";
          this.plantationDate = "";
          this.harvest = "";
          this.sustract = "";
          this.cultivationState = "";
          this.surface1 = "";
          this.surface2 = "";
          this.surface3 = "";
          this.surface4 = "";
          this.surface5 = "";
          this.surface6 = "";
          this.flow1 = "";
          this.flow2 = "";
          this.flow3 = "";
          this.flow4 = "";
          this.flow5 = "";
          this.flow6 = "";
          this.presure1 = "";
          this.presure2 = "";
          this.presure3 = "";
          this.presure4 = "";
          this.presure5 = "";
          this.presure6 = "";
          this.irrigation = [];
          this.waterOrigin = [];
          this.redox = "";
          this.ph = "";
          this.temperature = "";
          this.phRegulator = "";
          this.connectionDate = "";
          this.protocolId = "";
          this.protocolName = "";
          this.protocolNameLocalized = "";
          this.observation = "";
          this.agrozonoDate = "";
          this.ozoneDate = "";
          this.contactName = "";
          this.contactPosition = "";
          this.contactEmail = "";
          this.contactPhone = "";
          this.clientSignaturePath = "";
          this.clientSignatureStatus = "";
          this.technicSignaturePath = "";
          this.technicSignatureStatus = "";
          this.image1 = require("@/assets/capture-image.png");
          this.image1Path = "";
          this.image1Status = "";
          this.image2 = require("@/assets/capture-image.png");
          this.image2Path = "";
          this.image2Status = "";

          this.globalObject = db.collection("inspections").doc();
          this.id = this.globalObject.id;

          this.inspectionDate = luxon.local().toISO();

          this.clearSignatureClient();
          this.clearSignatureTechnic();

          this.duplicarInspeccionCliente(data_inspeccion);

          // PARA ACTUALIZAR EL LISTADO
          setTimeout(function () {
            _this.volverListadoInspecciones();
          }, 1000);
        })
        .catch(function (error) {
          let errorMessage = error.message;

          this.datosCargados = true;
          this.mensajeError =
            "Ha ocurrido un error al intentar guardar el registro en la BD. Error:" +
            errorMessage;
        });
    },
    submitDatos() {
      this.datosCargados = false;
      this.mensajeExito = "";
      this.mensajeError = "";
      this.errorMessageSectors = [];

      if (
        this.cultivation !== "" &&
        this.cultivationSubtype !== "" &&
        this.plantationDate !== "" &&
        this.harvest !== "" &&
        this.sustract !== "" &&
        this.cultivationState !== "" &&
        this.irrigation.length >= 1 &&
        this.waterOrigin.length >= 1 &&
        this.redox !== "" &&
        this.ph !== "" &&
        this.temperature !== "" &&
        this.phRegulator !== "" &&
        this.connectionDone !== "" &&
        this.protocolId !== "" &&
        this.ozoneDate !== "" &&
        this.contactName !== "" &&
        this.contactPosition !== "" &&
        this.contactEmail !== "" &&
        this.contactPhone !== "" &&
        this.clientSignaturePath !== "" &&
        this.technicSignaturePath !== "" &&
        this.image1Path !== "" &&
        this.image2Path !== ""
      ) {
        let puedeContinuar = true;
        let errors = {
          message: "",
        };
        let errorSectors = [];

        let minSurfaceRange = 0.01;
        let maxSurfaceRange = 10;
        let minFlowRange = 10;
        let maxFlowRange = 100;
        let minPresureRange = 0.5;
        let maxPresureRange = 6.5;

        let minPhRange = 0;
        let maxPhRange = 14;
        let minRedoxRange = -1000;
        let maxRedoxRange = 1500;

        if (this.surface1 !== "") {
          if (
            this.surface1 < minSurfaceRange ||
            this.surface1 > maxSurfaceRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo superficie") +
              " " +
              this.$t("Sector") +
              " 1 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minSurfaceRange +
              "-" +
              maxSurfaceRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.surface2 !== "") {
          if (
            this.surface2 < minSurfaceRange ||
            this.surface2 > maxSurfaceRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo superficie") +
              " " +
              this.$t("Sector") +
              " 2 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minSurfaceRange +
              "-" +
              maxSurfaceRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.surface3 !== "") {
          if (
            this.surface3 < minSurfaceRange ||
            this.surface3 > maxSurfaceRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo superficie") +
              " " +
              this.$t("Sector") +
              " 3 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minSurfaceRange +
              "-" +
              maxSurfaceRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.surface4 !== "") {
          if (
            this.surface4 < minSurfaceRange ||
            this.surface4 > maxSurfaceRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo superficie") +
              " " +
              this.$t("Sector") +
              " 4 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minSurfaceRange +
              "-" +
              maxSurfaceRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.surface5 !== "") {
          if (
            this.surface5 < minSurfaceRange ||
            this.surface5 > maxSurfaceRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo superficie") +
              " " +
              this.$t("Sector") +
              " 5 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minSurfaceRange +
              "-" +
              maxSurfaceRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.surface6 !== "") {
          if (
            this.surface6 < minSurfaceRange ||
            this.surface6 > maxSurfaceRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo superficie") +
              " " +
              this.$t("Sector") +
              " 6 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minSurfaceRange +
              "-" +
              maxSurfaceRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.flow1 !== "") {
          if (this.flow1 < minFlowRange || this.flow1 > maxFlowRange) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo caudal") +
              " " +
              this.$t("Sector") +
              " 1 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minFlowRange +
              "-" +
              maxFlowRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.flow2 !== "") {
          if (this.flow2 < minFlowRange || this.flow2 > maxFlowRange) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo caudal") +
              " " +
              this.$t("Sector") +
              " 2 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minFlowRange +
              "-" +
              maxFlowRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.flow3 !== "") {
          if (this.flow3 < minFlowRange || this.flow3 > maxFlowRange) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo caudal") +
              " " +
              this.$t("Sector") +
              " 3 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minFlowRange +
              "-" +
              maxFlowRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.flow4 !== "") {
          if (this.flow4 < minFlowRange || this.flow4 > maxFlowRange) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo caudal") +
              " " +
              this.$t("Sector") +
              " 4 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minFlowRange +
              "-" +
              maxFlowRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.flow5 !== "") {
          if (this.flow5 < minFlowRange || this.flow5 > maxFlowRange) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo caudal") +
              " " +
              this.$t("Sector") +
              " 5 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minFlowRange +
              "-" +
              maxFlowRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.flow6 !== "") {
          if (this.flow6 < minFlowRange || this.flow6 > maxFlowRange) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo caudal") +
              " " +
              this.$t("Sector") +
              " 6 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minFlowRange +
              "-" +
              maxFlowRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.presure1 !== "") {
          if (
            this.presure1 < minPresureRange ||
            this.presure1 > maxPresureRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo presión") +
              " " +
              this.$t("Sector") +
              " 1 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minPresureRange +
              "-" +
              maxPresureRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.presure2 !== "") {
          if (
            this.presure2 < minPresureRange ||
            this.presure2 > maxPresureRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo presión") +
              " " +
              this.$t("Sector") +
              " 2 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minPresureRange +
              "-" +
              maxPresureRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.presure3 !== "") {
          if (
            this.presure3 < minPresureRange ||
            this.presure3 > maxPresureRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo presión") +
              " " +
              this.$t("Sector") +
              " 3 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minPresureRange +
              "-" +
              maxPresureRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.presure4 !== "") {
          if (
            this.presure4 < minPresureRange ||
            this.presure4 > maxPresureRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo presión") +
              " " +
              this.$t("Sector") +
              " 4 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minPresureRange +
              "-" +
              maxPresureRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.presure5 !== "") {
          if (
            this.presure5 < minPresureRange ||
            this.presure5 > maxPresureRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo presión") +
              " " +
              this.$t("Sector") +
              " 5 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minPresureRange +
              "-" +
              maxPresureRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.presure6 !== "") {
          if (
            this.presure6 < minPresureRange ||
            this.presure6 > maxPresureRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo presión") +
              " " +
              this.$t("Sector") +
              " 6 " +
              this.$t("Debe estar entre los limites") +
              " " +
              minPresureRange +
              "-" +
              maxPresureRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.ph !== "") {
          if (
            parseFloat(this.ph) < minPhRange ||
            parseFloat(this.ph) > maxPhRange
          ) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo ph debe estar entre los limites") +
              " " +
              minPhRange +
              "-" +
              maxPhRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (this.redox !== "") {
          if (this.redox < minRedoxRange && this.redox > maxRedoxRange) {
            puedeContinuar = false;
            errors.message =
              this.$t("El campo redox debe estar entre los limites") +
              " " +
              minRedoxRange +
              "-" +
              maxRedoxRange;
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        // PARA LA VALIDACION DE LAS CONEXIONES REALIZADAS
        if (this.connectionDone === "false") {
          if (this.connectionDate === "") {
            puedeContinuar = false;
            errors.message = this.$t(
              "Debe establecer una fecha prevista de conexión para poder continuar"
            );
            errorSectors.push(errors);
            errors = {
              message: "",
            };
          }
        }

        if (
          this.inspectionDate !== "" &&
          luxon.local().toISO() < this.inspectionDate
        ) {
          puedeContinuar = false;
          errors.message = this.$t(
            "La fecha de inspección debe de ser igual o inferior a la fecha actual"
          );
          errorSectors.push(errors);
          errors = {
            message: "",
          };
        }

        if (puedeContinuar === true) {
          this.surface1 = !isNaN(parseFloat(this.surface1))
            ? parseFloat(this.surface1)
            : 0;
          this.surface2 = !isNaN(parseFloat(this.surface2))
            ? parseFloat(this.surface2)
            : 0;
          this.surface3 = !isNaN(parseFloat(this.surface3))
            ? parseFloat(this.surface3)
            : 0;
          this.surface4 = !isNaN(parseFloat(this.surface4))
            ? parseFloat(this.surface4)
            : 0;
          this.surface5 = !isNaN(parseFloat(this.surface5))
            ? parseFloat(this.surface5)
            : 0;
          this.surface6 = !isNaN(parseFloat(this.surface6))
            ? parseFloat(this.surface6)
            : 0;

          this.flow1 = !isNaN(parseFloat(this.flow1))
            ? parseFloat(this.flow1)
            : 0;
          this.flow2 = !isNaN(parseFloat(this.flow2))
            ? parseFloat(this.flow2)
            : 0;
          this.flow3 = !isNaN(parseFloat(this.flow3))
            ? parseFloat(this.flow3)
            : 0;
          this.flow4 = !isNaN(parseFloat(this.flow4))
            ? parseFloat(this.flow4)
            : 0;
          this.flow5 = !isNaN(parseFloat(this.flow5))
            ? parseFloat(this.flow5)
            : 0;
          this.flow6 = !isNaN(parseFloat(this.flow6))
            ? parseFloat(this.flow6)
            : 0;

          this.presure1 = !isNaN(parseFloat(this.presure1))
            ? parseFloat(this.presure1)
            : 0;
          this.presure2 = !isNaN(parseFloat(this.presure2))
            ? parseFloat(this.presure2)
            : 0;
          this.presure3 = !isNaN(parseFloat(this.presure3))
            ? parseFloat(this.presure3)
            : 0;
          this.presure4 = !isNaN(parseFloat(this.presure4))
            ? parseFloat(this.presure4)
            : 0;
          this.presure5 = !isNaN(parseFloat(this.presure5))
            ? parseFloat(this.presure5)
            : 0;
          this.presure6 = !isNaN(parseFloat(this.presure6))
            ? parseFloat(this.presure6)
            : 0;

          this.redox = !isNaN(parseFloat(this.redox))
            ? parseFloat(this.redox)
            : 0;
          this.ph = !isNaN(parseFloat(this.ph)) ? parseFloat(this.ph) : 0;
          this.temperature = !isNaN(parseFloat(this.temperature))
            ? parseFloat(this.temperature)
            : 0;

          this.state = "sent";

          this.crearRegistroBD();
        } else {
          this.datosCargados = true;
          this.errorMessageSectors = errorSectors;
        }
      } else {
        this.datosCargados = true;
        this.mensajeError =
          "Debe llenar correctamente todos los campos para guardarlos en el sistema";
      }
    },
    submitDatosDespues() {
      this.datosCargados = false;
      this.mensajeExito = "";
      this.mensajeError = "";

      this.surface1 = !isNaN(parseFloat(this.surface1))
        ? parseFloat(this.surface1)
        : 0;
      this.surface2 = !isNaN(parseFloat(this.surface2))
        ? parseFloat(this.surface2)
        : 0;
      this.surface3 = !isNaN(parseFloat(this.surface3))
        ? parseFloat(this.surface3)
        : 0;
      this.surface4 = !isNaN(parseFloat(this.surface4))
        ? parseFloat(this.surface4)
        : 0;
      this.surface5 = !isNaN(parseFloat(this.surface5))
        ? parseFloat(this.surface5)
        : 0;
      this.surface6 = !isNaN(parseFloat(this.surface6))
        ? parseFloat(this.surface6)
        : 0;

      this.flow1 = !isNaN(parseFloat(this.flow1)) ? parseFloat(this.flow1) : 0;
      this.flow2 = !isNaN(parseFloat(this.flow2)) ? parseFloat(this.flow2) : 0;
      this.flow3 = !isNaN(parseFloat(this.flow3)) ? parseFloat(this.flow3) : 0;
      this.flow4 = !isNaN(parseFloat(this.flow4)) ? parseFloat(this.flow4) : 0;
      this.flow5 = !isNaN(parseFloat(this.flow5)) ? parseFloat(this.flow5) : 0;
      this.flow6 = !isNaN(parseFloat(this.flow6)) ? parseFloat(this.flow6) : 0;

      this.presure1 = !isNaN(parseFloat(this.presure1))
        ? parseFloat(this.presure1)
        : 0;
      this.presure2 = !isNaN(parseFloat(this.presure2))
        ? parseFloat(this.presure2)
        : 0;
      this.presure3 = !isNaN(parseFloat(this.presure3))
        ? parseFloat(this.presure3)
        : 0;
      this.presure4 = !isNaN(parseFloat(this.presure4))
        ? parseFloat(this.presure4)
        : 0;
      this.presure5 = !isNaN(parseFloat(this.presure5))
        ? parseFloat(this.presure5)
        : 0;
      this.presure6 = !isNaN(parseFloat(this.presure6))
        ? parseFloat(this.presure6)
        : 0;

      this.redox = !isNaN(parseFloat(this.redox)) ? parseFloat(this.redox) : 0;
      this.ph = !isNaN(parseFloat(this.ph)) ? parseFloat(this.ph) : 0;
      this.temperature = !isNaN(parseFloat(this.temperature))
        ? parseFloat(this.temperature)
        : 0;

      this.state = "pending";

      this.crearRegistroBD();
    },
    duplicarInspeccionCliente(data_inspeccion) {
      db.collection("customers")
        .doc(data_inspeccion.customerId)
        .collection("farms")
        .doc(data_inspeccion.farmId)
        .collection("inspections")
        .doc(data_inspeccion.id)
        .set(data_inspeccion)
        .then(() => {});
    },
    changeTecnico() {
      if (this.comTechnicianId === "") {
        this.comTechnicianName = "";

        this.comCustomerId = "";
        this.comCustomerName = "";
        this.fincaId = "";
        this.fincaName = "";

        this.listado_clientes_filtrados = [];
        this.listado_fincas_filtrados = [];
      } else {
        for (const x in this.listado_tecnicos) {
          if (this.listado_tecnicos[x].id === this.comTechnicianId) {
            this.comTechnicianName = this.listado_tecnicos[x].name;

            this.customerId = "";
            this.customerName = "";

            this.fincaId = "";
            this.fincaName = "";

            this.listado_clientes_filtrados = [];
            this.listado_fincas_filtrados = [];

            for (const y in this.listado_clientes) {
              if (
                this.listado_clientes[y].comTechnicianId ===
                this.listado_tecnicos[x].id
              ) {
                this.listado_clientes_filtrados.push(this.listado_clientes[y]);
              }
            }

            break;
          }
        }
      }
    },
    changeProtocolo() {
      if (this.protocolId === "") {
        this.protocolName = "";
        this.protocolNameLocalized = "";

        this.sys1 = "";
        this.sys2 = "";
        this.sys2_1 = "";
        this.sys3 = "";
        this.sys4 = "";
        this.sys5 = "";
        this.sys6 = "";
        this.sys6_1 = "";
        this.sys7 = "";
        this.sysbas = "";

        this.superficie_total = 0.0;
      } else {
        for (const x in this.listado_protocolos) {
          if (this.listado_protocolos[x].id === this.protocolId) {
            this.protocolName = this.listado_protocolos[x].name;
            this.protocolNameLocalized = this.listado_protocolos[x].name;

            this.superficie_total = 0.0;
            if (!isNaN(this.surface1) && this.surface1 !== "")
              this.superficie_total =
                this.superficie_total + parseFloat(this.surface1);
            if (!isNaN(this.surface2) && this.surface2 !== "")
              this.superficie_total =
                this.superficie_total + parseFloat(this.surface2);
            if (!isNaN(this.surface3) && this.surface3 !== "")
              this.superficie_total =
                this.superficie_total + parseFloat(this.surface3);
            if (!isNaN(this.surface4) && this.surface4 !== "")
              this.superficie_total =
                this.superficie_total + parseFloat(this.surface4);
            if (!isNaN(this.surface5) && this.surface5 !== "")
              this.superficie_total =
                this.superficie_total + parseFloat(this.surface5);
            if (!isNaN(this.surface6) && this.surface6 !== "")
              this.superficie_total =
                this.superficie_total + parseFloat(this.surface6);

            this.sys1 =
              parseInt(this.listado_protocolos[x].SYS1) * this.superficie_total;
            this.sys2 =
              parseInt(this.listado_protocolos[x].SYS2) * this.superficie_total;
            this.sys2_1 =
              parseInt(this.listado_protocolos[x].SYS2_1) *
              this.superficie_total;
            this.sys3 =
              parseInt(this.listado_protocolos[x].SYS3) * this.superficie_total;
            this.sys4 =
              parseInt(this.listado_protocolos[x].SYS4) * this.superficie_total;
            this.sys5 =
              parseInt(this.listado_protocolos[x].SYS5) * this.superficie_total;
            this.sys6 =
              parseInt(this.listado_protocolos[x].SYS6) * this.superficie_total;
            this.sys6_1 =
              parseInt(this.listado_protocolos[x].SYS6_1) *
              this.superficie_total;
            this.sys7 =
              parseInt(this.listado_protocolos[x].SYS7) * this.superficie_total;
            this.sysbas =
              parseInt(this.listado_protocolos[x].SYSBA) *
              this.superficie_total;

            break;
          }
        }
      }
    },
    clearSignatureClient() {
      this.$refs.signatureClientPad.undoSignature();
      this.clientSignatureStatus = "";
    },
    saveSignatureClient() {
      const { data } = this.$refs.signatureClientPad.saveSignature();

      let _this = this;
      let storageRef = firebaseGlobal.storage().ref();

      let random =
        Math.random().toString(36).substring(2) +
        Math.random().toString(36).substring(2) +
        Math.random().toString(36).substring(2);
      let url = "images/inspections/" + _this.id + "/" + random + ".jpg";
      let imageRef = storageRef.child(url);

      _this.clientSignature = data;

      imageRef.putString(data, "data_url").then(function () {
        _this.clientSignaturePath = url;
        _this.clientSignatureStatus = "Imagen subida al servidor correctamente";
      });
    },
    clearSignatureTechnic() {
      this.$refs.signatureTechnicPad.undoSignature();
      this.technicSignatureStatus = "";
    },
    saveSignatureTechnic() {
      const { data } = this.$refs.signatureTechnicPad.saveSignature();

      let _this = this;
      let storageRef = firebaseGlobal.storage().ref();

      let random =
        Math.random().toString(36).substring(2) +
        Math.random().toString(36).substring(2) +
        Math.random().toString(36).substring(2);
      let url = "images/inspections/" + _this.id + "/" + random + ".jpg";
      let imageRef = storageRef.child(url);

      _this.technicSignature = data;

      imageRef.putString(data, "data_url").then(function () {
        _this.technicSignaturePath = url;
        _this.technicSignatureStatus =
          "Imagen subida al servidor correctamente";
      });
    },
    uploadImage1() {
      let file = document.querySelector("input[name=image1]").files[0];

      let _this = this;
      let reader = new FileReader();
      reader.onload = function (e) {
        _this.image1 = e.target.result;
        _this.image1Status = "Subiendo imagen al servidor";

        let storageRef = firebaseGlobal.storage().ref();

        let random =
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2);
        let url = "images/inspections/" + _this.id + "/" + random + ".jpg";
        let imageRef = storageRef.child(url);

        imageRef.putString(e.target.result, "data_url").then(function () {
          _this.image1Path = url;
          _this.image1Status = "Imagen subida al servidor correctamente";
        });
      };

      reader.onerror = function (error) {
        alert(error);
      };

      reader.readAsDataURL(file);
    },
    uploadImage2() {
      let file = document.querySelector("input[name=image2]").files[0];

      let _this = this;
      let reader = new FileReader();
      reader.onload = function (e) {
        _this.image2 = e.target.result;
        _this.image2Status = "Subiendo imagen al servidor";

        let storageRef = firebaseGlobal.storage().ref();

        let random =
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2) +
          Math.random().toString(36).substring(2);
        let url = "images/inspections/" + _this.id + "/" + random + ".jpg";
        let imageRef = storageRef.child(url);

        imageRef.putString(e.target.result, "data_url").then(function () {
          _this.image2Path = url;
          _this.image2Status = "Imagen subida al servidor correctamente";
        });
      };

      reader.onerror = function (error) {
        alert(error);
      };

      reader.readAsDataURL(file);
    },
    async getProtocols() {
      let commercialId = this.userCurrent;
      if (this.userRol === "distributors") {
        await db
          .collection("distributors")
          .doc(this.userCurrent)
          .get()
          .then((snapshot) => {
            commercialId = snapshot.data().comercialId;
          });
      }

      db.collection("comTechnicians")
        .doc(commercialId)
        .get()
        .then((snapshot) => {
          let protocols = [];
          snapshot.data().protocols.forEach(function (protocolId) {
            db.collection("protocols")
              .doc(protocolId)
              .get()
              .then((doc) => {
                if (doc.data().status === "active") {
                  let protocol = doc.data();
                  protocol.id = doc.id;
                  protocols.push(protocol);
                }
              });
          });
          this.listado_protocolos = protocols;
        });
    },
  },
  async beforeMount() {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.userRol = sessionStorage.getItem("user_rol");
    this.userCurrent = sessionStorage.getItem("user_id");
    this.lang = localStorage.getItem("user_locale");

    this.globalObject = db.collection("inspections").doc();
    this.id = this.globalObject.id;

    this.inspectionDate = luxon.local().toISO();

    this.image1 = require("@/assets/capture-image.png");
    this.image2 = require("@/assets/capture-image.png");

    let _this = this;

    await db
      .collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          if (
            _this.userRol === "comTechnicians" &&
            _this.userCurrent === doc.id
          ) {
            _this.listado_tecnicos.push(arrayTemp);

            (_this.comTechnicianId = doc.id),
              (_this.comTechnicianEmail = doc.data().email),
              (_this.comTechnicianName = doc.data().name),
              _this.changeTecnico();
          }
        });
      });

    await db
      .collection("customers")
      .where("deleted", "==", false)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          _this.listado_clientes.push(arrayTemp);
        });

        this.datosCargados = true;
      });

    await db
      .collection("delegations")
      .where("deleted", "==", false)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          _this.listado_delegations.push(arrayTemp);
        });

        this.datosCargados = true;
      });

    await db
      .collection("customers")
      .where("deleted", "==", false)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          db.collection("customers")
            .doc(doc.id)
            .collection("farms")
            .where("deleted", "==", false)
            .get()
            .then((querySnapshot2) => {
              querySnapshot2.forEach(function (doc2) {
                let arrayTemp2 = doc2.data();
                arrayTemp2["id"] = doc2.id;

                _this.listado_fincas.push(arrayTemp2);

                if (_this.params.farmId === doc2.data().id) {
                  _this.customerId = doc2.data().customerID;
                  _this.customerName = doc2.data().customerName;
                  _this.fincaId = _this.params.farmId;
                  _this.fincaName = _this.params.farmName;

                  // PARA LOS DATOS DEL COMERCIAL
                  if (doc2.data().comTechnicianId !== "") {
                    _this.listado_tecnicos.forEach((tecnico) => {
                      // console.log(tecnico.id + ' vs ' + _this.comTechnicianId + ' = ' + tecnico.email)

                      if (tecnico.id === _this.comTechnicianId) {
                        _this.comTechnicianId = doc2.data().comTechnicianId;
                        _this.comTechnicianName = doc2.data().comTechnicianName;
                        _this.comTechnicianEmail = tecnico.email;

                        _this.listado_delegations.forEach((delegation) => {
                          if (tecnico.delegationId === delegation.uid) {
                            _this.respTechDelegationId = delegation.uid;
                          }
                        });
                      }
                    });
                  }

                  // PARA LOS DATOS DEL DISTRIBUIDOR
                  if (doc2.data().distributorId !== "") {
                    _this.listado_distribuidores.forEach((distribuidor) => {
                      // console.log(tecnico.id + ' vs ' + _this.comTechnicianId + ' = ' + tecnico.email)

                      if (distribuidor.id === doc2.data().distributorId) {
                        _this.fincaDistributorAddress = distribuidor.address;
                        _this.fincaDistributorCIF = distribuidor.cif;
                        _this.fincaDistributorName = distribuidor.name;

                        _this.listado_delegations.forEach((delegation) => {
                          if (distribuidor.delegationId === delegation.uid) {
                            _this.respTechDelegationId = delegation.id;
                          }
                        });
                      }
                    });
                  }

                  _this.listado_clientes.forEach((element) => {
                    if (element.id === doc2.data().customerID) {
                      _this.customerName = element.name;
                    }
                  });
                }
              });
            });
        });

        this.datosCargados = true;
      });

    this.getProtocols();

    await db
      .collection("cropTypes")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          arrayTemp["name"] = doc.data().name;

          _this.listado_tipos_cultivos.push(arrayTemp);
        });
        _this.listado_tipos_cultivos = orderBy(
          _this.listado_tipos_cultivos,
          ["name"],
          ["asc"]
        );
      });

    await db
      .collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;
          _this.listado_distribuidores.push(arrayTemp);
          if (
            _this.userRol === "distributors" &&
            _this.userCurrent === doc.id
          ) {
            _this.listado_tecnicos.push(arrayTemp);

            _this.comTechnicianId = doc.id;
            _this.comTechnicianEmail = doc.data().email;
            _this.comTechnicianName = doc.data().name;
          }
        });
      });
  },
};

export default FincasInspeccionesAdd;
</script>
