<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-md my-6">
    <ul>
      <li>
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div
            class="
              -ml-4
              -mt-4
              flex
              justify-between
              items-center
              flex-wrap
              sm:flex-no-wrap
            "
          >
            <div class="ml-4 mt-4">
              <div class="flex items-center">
                <div class="flex-shrink-0 w-32">
                  <div
                    v-if="
                      treatment.state === 'Completado' ||
                      treatment.state === 'completed' ||
                      treatment.state === 'active'
                    "
                    class="
                      w-full
                      inline-flex
                      grid grid-cols-1
                      gap-1
                      justify-items-center
                      px-3
                      py-3
                      rounded-md
                      text-sm
                      font-semibold
                      leading-5
                      text-green-900
                    "
                    style="background: #d9eca5"
                  >
                    {{ $t("Completado") }}
                  </div>
                  <div
                    v-if="treatment.state === 'pending' || !treatment.state"
                    class="
                      w-full
                      inline-flex
                      grid grid-cols-1
                      gap-1
                      justify-items-center
                      px-3
                      py-3
                      rounded-md
                      text-sm
                      font-semibold
                      leading-5
                      bg-orange-200
                      text-orange-900
                    "
                  >
                    {{ $t("Activo") }}
                  </div>
                  <div
                    v-if="treatment.state === 'canceled'"
                    class="
                      w-full
                      inline-flex
                      grid grid-cols-1
                      gap-1
                      justify-items-center
                      px-3
                      py-3
                      rounded-md
                      text-sm
                      font-semibold
                      leading-5
                      bg-red
                      text-redagrozono
                    "
                  >
                    {{ $t("Cancelado") }}
                  </div>
                </div>
                <div class="ml-4">
                  <h3 class="text-lg leading-6 font-medium text-gray-700">
                    {{ $t("Técnico aplicador") }}:
                    <span class="text-black font-semibold">{{
                      treatment.appTechnicianName
                    }}</span>
                  </h3>
                  <p class="text-sm leading-5 text-gray-700">
                    {{ $t("Máquina") }}:
                    <span class="text-black font-semibold">{{
                      treatment.machineName
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="ml-4 mt-4 flex-shrink-0 flex">
              <span
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'respTechnicians'
                "
                class="inline-flex rounded-md shadow-sm"
              >
                <button
                  type="button"
                  class="
                    relative
                    inline-flex
                    items-center
                    px-4
                    py-2
                    leading-5
                    font-bold
                    rounded-md
                  "
                  @click="deleteTreatment"
                  v-if="treatment.state !== 'canceled'"
                  :class="
                    disabledAssign
                      ? 'text-white bg-gray-400 cursor-not-allowed'
                      : 'bg-white hover:bg-redagrozono text-redagrozono hover:text-white border border-redagrozono'
                  "
                  :disabled="disabledAssign"
                >
                  <span>{{ $t("Eliminar") }}</span>
                </button>
                <button
                  type="button"
                  class="
                    relative
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-blueagrozono
                    leading-5
                    font-bold
                    bg-white
                    hover:bg-blueagrozono hover:text-white
                    rounded-md
                    text-blueagrozono
                  "
                  @click="activeTreatment"
                  v-if="treatment.state === 'canceled'"
                >
                  <span>{{ $t("Activar") }}</span>
                </button>
              </span>
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <router-link
                  :to="{
                    name: 'application-details',
                    params: { id: treatment.uid },
                  }"
                  class="
                    relative
                    inline-flex
                    items-center
                    px-4
                    py-2
                    leading-5
                    font-bold
                    rounded-md
                  "
                  :is="disabledDelete ? 'span' : 'router-link'"
                  :class="
                    disabledDelete
                      ? 'text-white bg-gray-400 cursor-not-allowed'
                      : 'bg-white hover:bg-blueagrozono text-blueagrozono hover:text-white border border-blueagrozono'
                  "
                  :disabled="disabledDelete"
                >
                  {{ $t("Detalles") }}
                </router-link>
              </span>
              <span
                v-if="
                  userRol === 'appSuperAdministrators' ||
                  userRol === 'respTechnicians'
                "
                class="ml-3 inline-flex rounded-md shadow-sm"
              >
                <button
                  type="button"
                  class="
                    relative
                    inline-flex
                    items-center
                    px-4
                    py-2
                    leading-5
                    font-bold
                    rounded-md
                    text-white
                    focus:outline-none
                  "
                  @click="openModal"
                  :class="
                    disabledAssign
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-orange-500 hover:bg-orange-400'
                  "
                  :disabled="disabledAssign"
                >
                  <span>{{ $t("Asignar") }}</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div
              class="
                min-w-0
                flex-1
                px-4
                md:grid md:grid-cols-2
                xl:grid-cols-3
                md:gap-4
              "
            >
              <div>
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Fecha inspección") }}:
                  <span class="text-gray-900 font-semibold">{{
                    treatment.inspection.inspectionDate | formatDate
                  }}</span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Fecha pedida") }}:
                  <span class="text-gray-900 font-semibold">{{
                    treatment.inspection.ozoneDate | formatDate
                  }}</span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Fecha definitiva") }}:
                  <span class="text-gray-900 font-semibold">{{
                    treatment.agrozonoDate | formatDate
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="border-t border-gray-200">
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
              <div>
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Provincia") }}:
                  <span class="text-gray-900 font-semibold">{{
                    treatment.farm.province
                  }}</span>
                </div>
                <div class="mt-2 leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Localidad") }}:
                  <span class="text-gray-900 font-semibold">{{
                    treatment.farm.city
                  }}</span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("País") }}:
                  <span class="text-gray-900 font-semibold">{{
                    $t("España")
                  }}</span>
                </div>
                <div class="mt-2 leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Delegación") }}:
                  <span class="text-gray-900 font-semibold">{{
                    treatment.farm.delegationName
                  }}</span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Comercial") }}:
                  <span class="text-gray-900 font-semibold">{{
                    treatment.farm.comTechnicianName
                  }}</span>
                </div>
                <div class="mt-2 leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Distribuidor") }}:
                  <span class="text-gray-900 font-semibold">{{
                    treatment.farm.distributorName
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="border-t border-gray-200">
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
              <div>
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Finca") }}:
                  <span
                    @click="openFarmModal"
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                  >
                    {{ treatment.farm.name }}
                  </span>
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Cliente") }}:
                  <span
                    @click="openClientModal"
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                  >
                    {{ treatment.customerName }}</span
                  >
                </div>
              </div>
              <div class="mt-2 md:mt-0">
                <div class="leading-5 font-medium text-gray-700 truncate">
                  {{ $t("Inspección") }}:
                  <span
                    @click="openInspectionModal"
                    class="
                      font-semibold
                      text-blueagrozono
                      hover:text-gray-800
                      cursor-pointer
                    "
                  >
                    {{ treatment.inspection.protocolName }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ApplicationAssignmentModal from "@/pages/ApplicationAssignment/ApplicationAssignmentModal";
import ApplicationAssignmentFarmModal from "@/pages/ApplicationAssignment/ApplicationAssignmentFarmModal";
import ApplicationAssignmentInspectionModal from "@/pages/ApplicationAssignment/ApplicationAssignmentInspectionModal";
import ApplicationAssignmentCustomerModal from "@/pages/ApplicationAssignment/ApplicationAssignmentCustomerModal";
import { db } from "@/plugins/firebase";

const ApplicationAssignmentTreatment = {
  name: "applicationAssignmentTreatment",
  components: {
    ApplicationAssignmentModal,
    ApplicationAssignmentFarmModal,
    ApplicationAssignmentInspectionModal,
    ApplicationAssignmentCustomerModal,
  },
  data() {
    return {
      inspection: [],
      farm: [],
      userRol: sessionStorage.getItem("user_rol"),
      userPresent: sessionStorage.getItem("user_id"),
    };
  },
  props: {
    treatment: {
      required: true,
    },
  },
  computed: {
    disabledAssign() {
      return (
        this.treatment.state === "completed" ||
        this.treatment.state === "active" ||
        this.treatment.state === "canceled"
      );
    },
    disabledDelete() {
      return this.treatment.state === "canceled";
    },
  },
  methods: {
    deleteTreatment() {
      if (confirm(this.$t("¿Realmente desea cancelar el tratamiento?"))) {
        let refTreatment = db.collection("treatments").doc(this.treatment.uid);

        refTreatment
          .update({
            state: "canceled",
          })
          .then(() => {
            this.treatment.state = "canceled";
          })
          .catch(function (error) {
            console.log(
              "Ha ocurrido un error al intentar cancelar el registro en la BD. Error:" +
                error.message
            );
          });
      }
    },
    activeTreatment() {
      if (confirm(this.$t("¿Realmente desea activar el tratamiento?"))) {
        let refTreatment = db.collection("treatments").doc(this.treatment.uid);

        refTreatment
          .update({
            state: "pending",
          })
          .then(() => {
            this.treatment.state = "pending";
          })
          .catch(function (error) {
            console.log(
              "Ha ocurrido un error al intentar activar el registro en la BD. Error:" +
                error.message
            );
          });
      }
    },
    openModal() {
      this.$modal.show(ApplicationAssignmentModal, {
        title: this.$t("Asignar"),
        params: { treatment: this.treatment },
      });
    },
    openFarmModal() {
      this.$modal.show(ApplicationAssignmentFarmModal, {
        title: this.$t("Detalle finca"),
        params: { farm: this.treatment.farm },
      });
    },
    openClientModal() {
      this.$modal.show(ApplicationAssignmentCustomerModal, {
        title: this.$t("Detalle cliente"),
        params: { customerID: this.treatment.customerId },
      });
    },
    openInspectionModal() {
      this.$modal.show(ApplicationAssignmentInspectionModal, {
        title: this.$t("Detalle inspección"),
        params: { inspection: this.treatment.inspection },
      });
    },
  },
};

export default ApplicationAssignmentTreatment;
</script>
