<template>
  <div class="w-full">
    <div class="text-2xl font-semibold text-gray-800 mb-4">
      {{ $t("Tratamientos") }}
    </div>

    <loader v-if="datosCargados === false"></loader>

    <div v-if="datosCargados === true">
      <div>
        <div class="sm:flex">
          <div class="flex items-center">
            <vue-excel-xlsx
              class="
                bg-yellow
                ml-4
                mr-2
                text-sm
                font-semibold
                hover:bg-greenlight
                text-white
                py-3
                px-4
                rounded
                focus:outline-none focus:shadow-outline
              "
              :data="exportExcel"
              :columns="columns"
              filename="tratamientos"
              sheetname="tratamientos"
            >
              {{ $t("Exportar listado") }}
            </vue-excel-xlsx>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <div class="-my-2 py-2 overflow-x-auto sm:overflow-x-visible">
          <div class="align-middle inline-block min-w-full">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <label>
                      <input
                        class="
                          w-full
                          appearance-none
                          block
                          bg-white
                          text-gray-700
                          border border-red-500
                          rounded
                          py-3
                          px-4
                          leading-tight
                          focus:outline-none focus:bg-white
                        "
                        type="text"
                        :placeholder="this.$t('Comercial')"
                        v-model="buscar_comercial"
                      />
                    </label>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <label>
                      <input
                        class="
                          w-full
                          appearance-none
                          block
                          bg-white
                          text-gray-700
                          border border-red-500
                          rounded
                          py-3
                          px-4
                          leading-tight
                          focus:outline-none focus:bg-white
                        "
                        type="text"
                        :placeholder="this.$t('Cliente')"
                        v-model="buscar_cliente"
                      />
                    </label>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <label>
                      <input
                        class="
                          w-full
                          appearance-none
                          block
                          bg-white
                          text-gray-700
                          border border-red-500
                          rounded
                          py-3
                          px-4
                          leading-tight
                          focus:outline-none focus:bg-white
                        "
                        type="text"
                        :placeholder="this.$t('Finca')"
                        v-model="buscar_finca"
                      />
                    </label>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <label>
                      <input
                        class="
                          w-full
                          appearance-none
                          block
                          bg-white
                          text-gray-700
                          border border-red-500
                          rounded
                          py-3
                          px-4
                          leading-tight
                          focus:outline-none focus:bg-white
                        "
                        type="text"
                        :placeholder="this.$t('Protocolo')"
                        v-model="buscar_protocolo"
                      />
                    </label>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  ></th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  ></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Comercial") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Cliente") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Finca") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Protocolo") }}</div>
                  </th>
                  <th class="px-4 py-2 text-gray-800 hidden">
                    <div class="inline-block mr-2">
                      {{ $t("Fecha tratamiento") }}
                    </div>
                  </th>
                  <th class="px-4 py-2 text-gray-800">
                    <div class="inline-block mr-2" style="width: 200px">
                      {{ $t("Albaranes") }}
                    </div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  >
                    <div class="inline-block mr-2">{{ $t("Estado") }}</div>
                  </th>
                  <th
                    class="
                      px-6
                      py-3
                      border-b border-gray-200
                      bg-gray-50
                      text-left text-base
                      leading-4
                      font-bold
                      text-gray-800
                      tracking-wider
                    "
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(tratamiento, index) in tratamientosFiltrados"
                  v-bind:key="index"
                >
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="hidden">{{ tratamiento.comTechnicianId }}</div>
                    <div>{{ tratamiento.comTechnicianName }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="hidden">{{ tratamiento.customerId }}</div>
                    <div>{{ tratamiento.customerName }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="hidden">{{ tratamiento.farmId }}</div>

                    <div v-if="tratamiento.protocolId !== ''">
                      <div
                        v-on:click="
                          editarRegistro(
                            tratamiento.uid,
                            tratamiento.protocolId
                          )
                        "
                      >
                        <span
                          class="
                            font-semibold
                            text-blueagrozono
                            hover:text-gray-800
                            cursor-pointer
                          "
                          >{{ tratamiento.farmName }}</span
                        >
                      </div>
                    </div>

                    <div v-if="tratamiento.protocolId === ''">
                      <div>{{ tratamiento.farmName }}</div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="hidden">{{ tratamiento.protocolId }}</div>
                    <div>{{ tratamiento.protocolName }}</div>
                    <div>
                      <template v-if="tratamiento.steps"
                        >{{ tratamiento.steps.length }}/{{
                          tratamiento.total_tratamientos
                        }}
                      </template>
                    </div>
                    <div class="hidden">
                      {{ tratamiento.total_tratamientos }}
                    </div>
                  </td>
                  <td>
                    <div
                      class="text-center"
                      v-on:click="visualizarDeliveryNotes(tratamiento.uid)"
                    >
                      <span
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ $t("Visualización de albaranes") }}</span
                      >
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div v-if="tratamiento.state === 'active'">
                      <Zondicon
                        icon="fast-forward"
                        class="
                          fill-current
                          inline-block
                          h-5
                          text-yellow
                          cursor-pointer
                          hover:text-gray-700
                        "
                      />
                      <span class="inline-block ml-3">{{
                        $t("En curso")
                      }}</span>
                    </div>
                    <div v-if="tratamiento.state === 'completed'">
                      <Zondicon
                        icon="flag"
                        class="
                          fill-current
                          inline-block
                          h-5
                          text-greenlight
                          cursor-pointer
                          hover:text-gray-700
                        "
                      />
                      <span class="inline-block ml-3">{{
                        $t("Completado")
                      }}</span>
                    </div>
                    <div v-if="tratamiento.state === 'pending'">
                      <Zondicon
                        icon=" information-solid"
                        class="
                          fill-current
                          inline-block
                          h-5
                          text-yellow
                          cursor-pointer
                          hover:text-gray-700
                        "
                      />
                      <span class="inline-block ml-3">{{
                        $t("Pendiente")
                      }}</span>
                    </div>
                    <div v-if="tratamiento.state === 'canceled'">
                      <Zondicon
                        icon="close-solid"
                        class="
                          fill-current
                          inline-block
                          h-5
                          text-red
                          cursor-pointer
                          hover:text-gray-700
                        "
                      />
                      <span class="inline-block ml-3">{{
                        $t("Cancelado")
                      }}</span>
                    </div>
                  </td>

                  <td class="px-4 py-3 hidden">
                    <div>{{ tratamiento.tratamiento_fecha }}</div>
                  </td>
                  <td class="px-4 py-3 text-center">
                    <div
                      v-on:click="
                        editarRegistro(tratamiento.uid, tratamiento.protocolId)
                      "
                      style="width: 200px"
                    >
                      <span
                        class="
                          font-semibold
                          text-blueagrozono
                          hover:text-gray-800
                          cursor-pointer
                        "
                        >{{ $t("Visualizar tratamiento") }}</span
                      >
                    </div>
                  </td>
                </tr>

                <tr v-if="tratamientos.length === 0">
                  <td class="border px-4 py-2" colspan="6">
                    {{ $t("No hay tratamientos registrados en el sistema") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Zondicon from "vue-zondicons";
import { db } from "@/plugins/firebase.js";
import Loader from "@/components/Loader";
import Utilities from "@/plugins/Utilities.js";
import TreatmentModal from "@/pages/tratamientos/TreatmentModal";
import TratamientosDeliveryNotesPage from "@/pages/tratamientos/TratamientosDeliveryNotesPage";

const Tratamientos = {
  layout: "layout",
  name: "Tratamientos",
  metaInfo: {
    title: "Tratamientos",
  },
  components: {
    Zondicon,
    Loader,
  },
  data() {
    return {
      datosCargados: false,
      tratamientos: [],
      tratamientosAgrupados: [],
      mostrar: "Todos",
      buscar: "",
      buscar_comercial: "",
      buscar_cliente: "",
      buscar_finca: "",
      buscar_protocolo: "",
      url_link_exportar: "",

      listado_comerciales: [],
      listado_clientes: [],
      listado_fincas: [],
      listado_protocolos: [],
      listado_protocolos_reminders: [],

      usuarioRol: "",
      usuarioActual: "",

      listado_distribuidores: [],
      listado_delegaciones: [],

      columns: [
        {
          label: "Comercial",
          field: "comercial",
        },
        {
          label: "Cliente",
          field: "cliente",
        },
        {
          label: "Finca",
          field: "finca",
        },
        {
          label: "Protocolo",
          field: "protocolo",
        },
        {
          label: "Tratamiento",
          field: "tratamiento",
        },
        {
          label: "Fecha Tratamiento",
          field: "fechaTratamiento",
        },
        {
          label: "Estatus",
          field: "estatus",
        },
      ],
    };
  },
  methods: {
    filtrarRegistros() {
      this.datosCargados = false;
      this.buscar = "";

      this.tratamientos = [];
      this.tratamientosAgrupados = [];

      this.datosCargados = false;

      let _this = this;
      db.collection("treatments")
        //				.where("deleted", "==", false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async function (doc) {
            let puede_continuar = false;

            if (
              _this.usuarioRol === "appSuperAdministrators" ||
              _this.usuarioRol === "appAdministrators" ||
              _this.usuarioRol === "comTechniciansAdmin"
            ) {
              puede_continuar = true;
            }

            if (_this.usuarioRol === "comTechniciansResp") {
              _this.listado_comerciales.forEach((comercial) => {
                if (_this.usuarioActual === comercial.responsableId) {
                  puede_continuar = true;
                }
              });
            }

            if (_this.usuarioRol === "comTechnicians") {
              _this.listado_clientes.forEach((cliente) => {
                if (cliente.id === doc.data().customerId) {
                  if (_this.usuarioActual === cliente.comTechnicianId) {
                    puede_continuar = true;
                  }
                }
              });
            }

            if (_this.usuarioRol === "distributors") {
              let distribuidor_encontrado = false;
              let distribuidor_obj = null;
              _this.listado_distribuidores.forEach((distribuidor) => {
                if (_this.usuarioActual === distribuidor.uid) {
                  distribuidor_obj = distribuidor;
                  distribuidor_encontrado = true;
                }
              });

              if (distribuidor_encontrado === true) {
                if (
                  distribuidor_encontrado.comercialId === doc.data().customerId
                ) {
                  puede_continuar = true;
                }
              }
            }

            if (puede_continuar === true) {
              let cantidadTratamientos = 0;

              let arrayTemp = doc.data();
              arrayTemp["id"] = doc.id;

              let cliente = _this.obtenerClienteDesdeId(doc.data().customerId);
              arrayTemp["comTechnicianId"] = cliente.comTechnicianId;
              arrayTemp["comTechnicianName"] = cliente.comTechnicianName;

              let protocolo = _this.obtenerProtocoloDesdeId(
                doc.data().protocolId
              );
              arrayTemp["protocolId"] = doc.data().protocolId;
              arrayTemp["protocolName"] = protocolo.name;

              let total_tratamientos = 0;
              _this.listado_protocolos_reminders.forEach((element) => {
                if (element.protocolId === doc.data().protocolId) {
                  total_tratamientos = total_tratamientos + 1;
                }
              });

              arrayTemp["cantidad_tratamientos"] = cantidadTratamientos;
              arrayTemp["total_tratamientos"] = total_tratamientos;
              arrayTemp["tratamiento_fecha"] = "?";

              // PARA EL CASO QUE NO TENGA DELIVERYNOTES
              if (cantidadTratamientos === 0) {
                cantidadTratamientos = 0;

                let arrayTemp = doc.data();
                arrayTemp["id"] = doc.id;

                let cliente = _this.obtenerClienteDesdeId(
                  doc.data().customerId
                );
                arrayTemp["comTechnicianId"] = cliente.comTechnicianId;
                arrayTemp["comTechnicianName"] = cliente.comTechnicianName;

                arrayTemp["protocolId"] = "";
                arrayTemp["protocolName"] = "";

                arrayTemp["cantidad_tratamientos"] = "0";
                arrayTemp["total_tratamientos"] = "?";

                arrayTemp["tratamiento_fecha"] = "";

                let protocolo = _this.obtenerProtocoloDesdeId(
                  doc.data().protocolId
                );
                arrayTemp["protocolId"] = doc.data().protocolId;
                arrayTemp["protocolName"] = protocolo.name;

                let total_tratamientos = 0;
                _this.listado_protocolos_reminders.forEach((element) => {
                  if (element.protocolId === doc.data().protocolId) {
                    total_tratamientos = total_tratamientos + 1;
                  }
                });

                arrayTemp["cantidad_tratamientos"] = cantidadTratamientos;
                arrayTemp["total_tratamientos"] = total_tratamientos;

                _this.tratamientos.push(arrayTemp);

                // PARA AGRUPAR TODAS LAS FINCAS EN UNA SOLA FILA
                let existeFinca = false;
                _this.tratamientosAgrupados.forEach((element) => {
                  if (element.id === doc.id) {
                    existeFinca = true;
                  }
                });

                if (existeFinca === false) {
                  _this.tratamientosAgrupados.push(arrayTemp);
                } else {
                  _this.tratamientosAgrupados.forEach((element, index) => {
                    if (element.id === doc.id) {
                      _this.tratamientosAgrupados[index] = arrayTemp;
                    }
                  });
                }
              }
            }
          });

          this.datosCargados = true;
        });
    },
    editarRegistro(treatmentId, protocolId) {
      this.$modal.show(TreatmentModal, {
        title: this.$t("Detalles de la finca"),
        params: {
          treatmentId: treatmentId,
          protocolId: protocolId,
        },
      });
    },
    visualizarDeliveryNotes(treatmentId) {
      this.$modal.show(TratamientosDeliveryNotesPage, {
        title: this.$t("Albaranes de la finca"),
        params: {
          treatmentId: treatmentId,
        },
      });
    },
    obtenerClienteDesdeId(id) {
      let cliente = [];
      this.listado_clientes.forEach((element) => {
        if (element.id === id) {
          cliente = element;
        }
      });

      return cliente;
    },
    obtenerProtocoloDesdeId(id) {
      let protocolo = [];
      this.listado_protocolos.forEach((element) => {
        if (element.id === id) {
          protocolo = element;
        }
      });

      return protocolo;
    },
  },
  computed: {
    exportExcel() {
      return this.tratamientosFiltrados.map((element) => {
        let fechaTemporal = new Date(element.agrozonoDate.split("T")[0]);
        fechaTemporal.setDate(fechaTemporal.getDate() + 1);

        return {
          comercial: element.comTechnicianName,
          cliente: element.customerName,
          finca: element.farmName,
          protocolo: element.protocolName,
          tratamiento:
            element.cantidad_tratamientos + "/" + element.total_tratamientos,
          fechaTratamiento:
            fechaTemporal.getDate() +
            "/" +
            (fechaTemporal.getMonth() + 1) +
            "/" +
            fechaTemporal.getFullYear(),
          estatus: element.state,
        };
      });
    },
    tratamientosFiltrados() {
      let arrayFiltrado = [];
      this.tratamientosAgrupados.forEach((element) => {
        let puedeMostrarRow = true;

        if (this.buscar_comercial !== "") {
          if (element.comTechnicianName !== undefined) {
            if (
              element.comTechnicianName
                .toString()
                .toLowerCase()
                .indexOf(this.buscar_comercial.toLowerCase()) === -1
            ) {
              puedeMostrarRow = false;
            }
          } else {
            puedeMostrarRow = false;
          }
        }

        if (this.buscar_cliente !== "") {
          if (element.customerName !== undefined) {
            if (
              element.customerName
                .toString()
                .toLowerCase()
                .indexOf(this.buscar_cliente.toLowerCase()) === -1
            ) {
              puedeMostrarRow = false;
            }
          } else {
            puedeMostrarRow = false;
          }
        }

        if (this.buscar_finca !== "") {
          if (element.farmName !== undefined) {
            if (
              element.farmName
                .toString()
                .toLowerCase()
                .indexOf(this.buscar_finca.toLowerCase()) === -1
            ) {
              puedeMostrarRow = false;
            }
          } else {
            puedeMostrarRow = false;
          }
        }

        if (this.buscar_protocolo !== "") {
          if (element.protocolName !== undefined) {
            if (
              element.protocolName
                .toString()
                .toLowerCase()
                .indexOf(this.buscar_protocolo.toLowerCase()) === -1
            ) {
              puedeMostrarRow = false;
            }
          } else {
            puedeMostrarRow = false;
          }
        }

        if (puedeMostrarRow) {
          arrayFiltrado.push(element);
        }
      });

      // PARA ORDENAR EL ARREGLO DE USUARIOS
      // arrayFiltrado = arrayFiltrado.sort(this.compareName)

      return arrayFiltrado;
    },
  },
  beforeMount: async function () {
    if (!Utilities.isLogged()) {
      await this.$router.push({ name: "login" });
    }

    // PARA ASIGNAR LAS VARIABLES DE SESSION
    this.usuarioRol = sessionStorage.getItem("user_rol");
    this.usuarioActual = sessionStorage.getItem("user_id");

    let _this = this;

    await db
      .collection("distributors")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_distribuidores.push(arrayTemp);
        });
      });

    await db
      .collection("comTechnicians")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_comerciales.push(arrayTemp);
        });
      });

    await db
      .collection("customers")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_clientes.push(arrayTemp);

          await db
            .collection("customers")
            .doc(doc.id)
            .collection("farms")
            .where("deleted", "==", false)
            .get()
            .then((querySnapshot2) => {
              querySnapshot2.forEach(function (doc2) {
                let arrayTemp2 = doc2.data();
                arrayTemp2["id"] = doc2.id;

                _this.listado_fincas.push(arrayTemp2);
              });
            });
        });
      });

    await db
      .collection("protocols")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_protocolos.push(arrayTemp);
        });
      });

    await db
      .collection("protocolReminders")
      .where("deleted", "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let arrayTemp = doc.data();
          arrayTemp["id"] = doc.id;

          _this.listado_protocolos_reminders.push(arrayTemp);
        });
      });

    this.filtrarRegistros();
  },
};

export default Tratamientos;
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: #bbcccc29;
}
</style>
